const AREA = [
  {
    code: 810000,
    name_cn: "香港特别行政区",
    name_en: "Hong Kong",
    name_tw: "香港特別行政區",
    children: [
      {
        code: 810100,
        name_cn: "香港岛",
        name_en: "Hong Kong Island",
        name_tw: "香港島",
        children: [
          {
            code: 810101,
            name_cn: "中西区",
            name_en: "Central and Western District",
            name_tw: "中西區",
            yzcode: "999077",
          },
          {
            code: 810102,
            name_cn: "湾仔区",
            name_en: "Wan Chai District",
            name_tw: "灣仔區",
            yzcode: "999077",
          },
          {
            code: 810103,
            name_cn: "东区",
            name_en: "Eastern District",
            name_tw: "東區",
            yzcode: "999077",
          },
          {
            code: 810104,
            name_cn: "南区",
            name_en: "Southern District",
            name_tw: "南區",
            yzcode: "999077",
          },
        ],
      },
      {
        code: 810200,
        name_cn: "九龙",
        name_en: "Kowloon",
        name_tw: "九龍",
        children: [
          {
            code: 810201,
            name_cn: "油尖旺区",
            name_en: "Yau Tsim Mong",
            name_tw: "油尖旺區",
            yzcode: "999077",
          },
          {
            code: 810202,
            name_cn: "深水埗区",
            name_en: "Sham Shui Po",
            name_tw: "深水埗區",
            yzcode: "999077",
          },
          {
            code: 810203,
            name_cn: "九龙城区",
            name_en: "Jiulongcheng",
            name_tw: "九龍城區",
            yzcode: "999077",
          },
          {
            code: 810204,
            name_cn: "黄大仙区",
            name_en: "Wong Tai Sin",
            name_tw: "黃大仙區",
            yzcode: "999077",
          },
          {
            code: 810205,
            name_cn: "观塘区",
            name_en: "Kwun Tong",
            name_tw: "觀塘區",
            yzcode: "999077",
          },
        ],
      },
      {
        code: 810300,
        name_cn: "新界",
        name_en: "New Territories",
        name_tw: "新界",
        children: [
          {
            code: 810301,
            name_cn: "荃湾区",
            name_en: "Tsuen Wan",
            name_tw: "荃灣區",
            yzcode: "999077",
          },
          {
            code: 810302,
            name_cn: "屯门区",
            name_en: "Tuen Mun",
            name_tw: "屯門區",
            yzcode: "999077",
          },
          {
            code: 810303,
            name_cn: "元朗区",
            name_en: "Yuen Long",
            name_tw: "元朗區",
            yzcode: "999077",
          },
          {
            code: 810304,
            name_cn: "北区",
            name_en: "North District",
            name_tw: "北區",
            yzcode: "999077",
          },
          {
            code: 810305,
            name_cn: "大埔区",
            name_en: "Tai Po",
            name_tw: "大埔區",
            yzcode: "999077",
          },
          {
            code: 810306,
            name_cn: "西贡区",
            name_en: "Sai Kung",
            name_tw: "西貢區",
            yzcode: "999077",
          },
          {
            code: 810307,
            name_cn: "沙田区",
            name_en: "Sha Tin",
            name_tw: "沙田區",
            yzcode: "999077",
          },
          {
            code: 810308,
            name_cn: "葵青区",
            name_en: "Kwai Tsing",
            name_tw: "葵青區",
            yzcode: "999077",
          },
          {
            code: 810309,
            name_cn: "离岛区",
            name_en: "Outlying Islands",
            name_tw: "離島區",
            yzcode: "999077",
          },
        ],
      },
    ],
  },
  {
    code: 820000,
    name_cn: "澳门特别行政区",
    name_en: "Macau",
    name_tw: "澳門特別行政區",
    children: [
      {
        code: 820100,
        name_cn: "澳门半岛",
        name_en: "MacauPeninsula",
        name_tw: "澳門半島",
        children: [
          {
            code: 820101,
            name_cn: "花地玛堂区",
            name_en: "Nossa Senhora de Fatima",
            name_tw: "花地瑪堂區",
            yzcode: "999078",
          },
          {
            code: 820102,
            name_cn: "圣安多尼堂区",
            name_en: "Santo Antonio",
            name_tw: "聖安多尼堂區",
            yzcode: "999078",
          },
          {
            code: 820103,
            name_cn: "大堂区",
            name_en: "Sé",
            name_tw: "大堂區",
            yzcode: "999078",
          },
          {
            code: 820104,
            name_cn: "望德堂区",
            name_en: "Sao Lazaro",
            name_tw: "望德堂區",
            yzcode: "999078",
          },
          {
            code: 820105,
            name_cn: "风顺堂区",
            name_en: "Sao Lourenco",
            name_tw: "風順堂區",
            yzcode: "999078",
          },
        ],
      },
      {
        code: 820200,
        name_cn: "氹仔岛",
        name_en: "Taipa",
        name_tw: "氹仔島",
        children: [
          {
            code: 820201,
            name_cn: "嘉模堂区",
            name_en: "Our Lady Of Carmel's Parish",
            name_tw: "嘉模堂區",
            yzcode: "999078",
          },
        ],
      },
      {
        code: 820300,
        name_cn: "路环岛",
        name_en: "Coloane",
        name_tw: "路環島",
        children: [
          {
            code: 820301,
            name_cn: "圣方济各堂区",
            name_en: "St Francis Xavier's Parish",
            name_tw: "聖方濟各堂區",
            yzcode: "999078",
          },
        ],
      },
    ],
  },
  {
    code: 710000,
    name_cn: "台湾",
    name_en: "Taiwan",
    name_tw: "台灣",
    children: [
      {
        code: 710100,
        name_cn: "台北市",
        name_en: "Taipei",
        name_tw: "台北市",
        children: [
          {
            code: 710101,
            name_cn: "松山区",
            name_en: "Songshan",
            name_tw: "松山區",
            yzcode: "105",
          },
          {
            code: 710102,
            name_cn: "信义区",
            name_en: "Xinyi",
            name_tw: "信義區",
            yzcode: "110",
          },
          {
            code: 710103,
            name_cn: "大安区",
            name_en: "Da'an",
            name_tw: "大安區",
            yzcode: "106",
          },
          {
            code: 710104,
            name_cn: "中山区",
            name_en: "Zhongshan",
            name_tw: "中山區",
            yzcode: "104",
          },
          {
            code: 710105,
            name_cn: "中正区",
            name_en: "Zhongzheng",
            name_tw: "中正區",
            yzcode: "100",
          },
          {
            code: 710106,
            name_cn: "大同区",
            name_en: "Datong",
            name_tw: "大同區",
            yzcode: "103",
          },
          {
            code: 710107,
            name_cn: "万华区",
            name_en: "Wanhua",
            name_tw: "萬華區",
            yzcode: "108",
          },
          {
            code: 710108,
            name_cn: "文山区",
            name_en: "Wenshan",
            name_tw: "文山區",
            yzcode: "116",
          },
          {
            code: 710109,
            name_cn: "南港区",
            name_en: "Nangang",
            name_tw: "南港區",
            yzcode: "115",
          },
          {
            code: 710110,
            name_cn: "内湖区",
            name_en: "Nahu",
            name_tw: "內湖區",
            yzcode: "114",
          },
          {
            code: 710111,
            name_cn: "士林区",
            name_en: "Shilin",
            name_tw: "士林區",
            yzcode: "111",
          },
          {
            code: 710112,
            name_cn: "北投区",
            name_en: "Beitou",
            name_tw: "北投區",
            yzcode: "112",
          },
        ],
      },
      {
        code: 710200,
        name_cn: "高雄市",
        name_en: "Kaohsiung",
        name_tw: "高雄市",
        children: [
          {
            code: 710201,
            name_cn: "盐埕区",
            name_en: "Yancheng",
            name_tw: "鹽埕區",
            yzcode: "803",
          },
          {
            code: 710202,
            name_cn: "鼓山区",
            name_en: "Gushan",
            name_tw: "鼓山區",
            yzcode: "804",
          },
          {
            code: 710203,
            name_cn: "左营区",
            name_en: "Zuoying",
            name_tw: "左營區",
            yzcode: "813",
          },
          {
            code: 710204,
            name_cn: "楠梓区",
            name_en: "Nanzi",
            name_tw: "楠梓區",
            yzcode: "811",
          },
          {
            code: 710205,
            name_cn: "三民区",
            name_en: "Sanmin",
            name_tw: "三民區",
            yzcode: "807",
          },
          {
            code: 710206,
            name_cn: "新兴区",
            name_en: "Xinxing",
            name_tw: "新興區",
            yzcode: "800",
          },
          {
            code: 710207,
            name_cn: "前金区",
            name_en: "Qianjin",
            name_tw: "前金區",
            yzcode: "801",
          },
          {
            code: 710208,
            name_cn: "苓雅区",
            name_en: "Lingya",
            name_tw: "苓雅區",
            yzcode: "802",
          },
          {
            code: 710209,
            name_cn: "前镇区",
            name_en: "Qianzhen",
            name_tw: "前鎮區",
            yzcode: "806",
          },
          {
            code: 710210,
            name_cn: "旗津区",
            name_en: "Qijin",
            name_tw: "旗津區",
            yzcode: "805",
          },
          {
            code: 710211,
            name_cn: "小港区",
            name_en: "Xiaogang",
            name_tw: "小港區",
            yzcode: "812",
          },
          {
            code: 710212,
            name_cn: "凤山区",
            name_en: "Fengshan",
            name_tw: "鳳山區",
            yzcode: "830",
          },
          {
            code: 710213,
            name_cn: "林园区",
            name_en: "Linyuan",
            name_tw: "林園區",
            yzcode: "832",
          },
          {
            code: 710214,
            name_cn: "大寮区",
            name_en: "Daliao",
            name_tw: "大寮區",
            yzcode: "831",
          },
          {
            code: 710215,
            name_cn: "大树区",
            name_en: "Dashu",
            name_tw: "大樹區",
            yzcode: "840",
          },
          {
            code: 710216,
            name_cn: "大社区",
            name_en: "Dashe",
            name_tw: "大社區",
            yzcode: "815",
          },
          {
            code: 710217,
            name_cn: "仁武区",
            name_en: "Renwu",
            name_tw: "仁武區",
            yzcode: "814",
          },
          {
            code: 710218,
            name_cn: "鸟松区",
            name_en: "Niaosong",
            name_tw: "鳥松區",
            yzcode: "833",
          },
          {
            code: 710219,
            name_cn: "冈山区",
            name_en: "Gangshan",
            name_tw: "岡山區",
            yzcode: "820",
          },
          {
            code: 710220,
            name_cn: "桥头区",
            name_en: "Qiaotou",
            name_tw: "橋頭區",
            yzcode: "825",
          },
          {
            code: 710221,
            name_cn: "燕巢区",
            name_en: "Yanchao",
            name_tw: "燕巢區",
            yzcode: "824",
          },
          {
            code: 710222,
            name_cn: "田寮区",
            name_en: "Tianliao",
            name_tw: "田寮區",
            yzcode: "823",
          },
          {
            code: 710223,
            name_cn: "阿莲区",
            name_en: "Alian",
            name_tw: "阿蓮區",
            yzcode: "822",
          },
          {
            code: 710224,
            name_cn: "路竹区",
            name_en: "Luzhu",
            name_tw: "路竹區",
            yzcode: "821",
          },
          {
            code: 710225,
            name_cn: "湖内区",
            name_en: "Huna",
            name_tw: "湖內區",
            yzcode: "829",
          },
          {
            code: 710226,
            name_cn: "茄萣区",
            name_en: "Qieding",
            name_tw: "茄萣區",
            yzcode: "852",
          },
          {
            code: 710227,
            name_cn: "永安区",
            name_en: "Yong'an",
            name_tw: "永安區",
            yzcode: "828",
          },
          {
            code: 710228,
            name_cn: "弥陀区",
            name_en: "Mituo",
            name_tw: "彌陀區",
            yzcode: "827",
          },
          {
            code: 710229,
            name_cn: "梓官区",
            name_en: "Ziguan",
            name_tw: "梓官區",
            yzcode: "826",
          },
          {
            code: 710230,
            name_cn: "旗山区",
            name_en: "Qishan",
            name_tw: "旗山區",
            yzcode: "842",
          },
          {
            code: 710231,
            name_cn: "美浓区",
            name_en: "Meinong",
            name_tw: "美濃區",
            yzcode: "843",
          },
          {
            code: 710232,
            name_cn: "六龟区",
            name_en: "Liugui",
            name_tw: "六龜區",
            yzcode: "844",
          },
          {
            code: 710233,
            name_cn: "甲仙区",
            name_en: "Jiaxian",
            name_tw: "甲仙區",
            yzcode: "847",
          },
          {
            code: 710234,
            name_cn: "杉林区",
            name_en: "Shanlin",
            name_tw: "杉林區",
            yzcode: "846",
          },
          {
            code: 710235,
            name_cn: "内门区",
            name_en: "Namen",
            name_tw: "內門區",
            yzcode: "845",
          },
          {
            code: 710236,
            name_cn: "茂林区",
            name_en: "Maolin",
            name_tw: "茂林區",
            yzcode: "851",
          },
          {
            code: 710237,
            name_cn: "桃源区",
            name_en: "Taoyuan",
            name_tw: "桃源區",
            yzcode: "848",
          },
          {
            code: 710238,
            name_cn: "那玛夏区",
            name_en: "Namaxia",
            name_tw: "那瑪夏區",
            yzcode: "849",
          },
        ],
      },
      {
        code: 710300,
        name_cn: "基隆市",
        name_en: "Keelung",
        name_tw: "基隆市",
        children: [
          {
            code: 710301,
            name_cn: "中正区",
            name_en: "Zhongzheng",
            name_tw: "中正區",
            yzcode: "202",
          },
          {
            code: 710302,
            name_cn: "七堵区",
            name_en: "Qidu",
            name_tw: "七堵區",
            yzcode: "206",
          },
          {
            code: 710303,
            name_cn: "暖暖区",
            name_en: "Nuannuan",
            name_tw: "暖暖區",
            yzcode: "205",
          },
          {
            code: 710304,
            name_cn: "仁爱区",
            name_en: "Renai",
            name_tw: "仁愛區",
            yzcode: "200",
          },
          {
            code: 710305,
            name_cn: "中山区",
            name_en: "Zhongshan",
            name_tw: "中山區",
            yzcode: "203",
          },
          {
            code: 710306,
            name_cn: "安乐区",
            name_en: "Anle",
            name_tw: "安樂區",
            yzcode: "204",
          },
          {
            code: 710307,
            name_cn: "信义区",
            name_en: "Xinyi",
            name_tw: "信義區",
            yzcode: "201",
          },
        ],
      },
      {
        code: 710400,
        name_cn: "台中市",
        name_en: "Taichung",
        name_tw: "台中市",
        children: [
          {
            code: 710401,
            name_cn: "中区",
            name_en: "Zhongqu",
            name_tw: "中區",
            yzcode: "400",
          },
          {
            code: 710402,
            name_cn: "东区",
            name_en: "Dongqu",
            name_tw: "東區",
            yzcode: "401",
          },
          {
            code: 710403,
            name_cn: "南区",
            name_en: "Nanqu",
            name_tw: "南區",
            yzcode: "402",
          },
          {
            code: 710404,
            name_cn: "西区",
            name_en: "Xiqu",
            name_tw: "西區",
            yzcode: "403",
          },
          {
            code: 710405,
            name_cn: "北区",
            name_en: "Beiqu",
            name_tw: "北區",
            yzcode: "404",
          },
          {
            code: 710406,
            name_cn: "西屯区",
            name_en: "Xitun",
            name_tw: "西屯區",
            yzcode: "407",
          },
          {
            code: 710407,
            name_cn: "南屯区",
            name_en: "Nantun",
            name_tw: "南屯區",
            yzcode: "408",
          },
          {
            code: 710408,
            name_cn: "北屯区",
            name_en: "Beitun",
            name_tw: "北屯區",
            yzcode: "406",
          },
          {
            code: 710409,
            name_cn: "丰原区",
            name_en: "Fengyuan",
            name_tw: "豐原區",
            yzcode: "420",
          },
          {
            code: 710410,
            name_cn: "东势区",
            name_en: "Dongshi",
            name_tw: "東勢區",
            yzcode: "423",
          },
          {
            code: 710411,
            name_cn: "大甲区",
            name_en: "Dajia",
            name_tw: "大甲區",
            yzcode: "437",
          },
          {
            code: 710412,
            name_cn: "清水区",
            name_en: "Qingshui",
            name_tw: "清水區",
            yzcode: "436",
          },
          {
            code: 710413,
            name_cn: "沙鹿区",
            name_en: "Shalu",
            name_tw: "沙鹿區",
            yzcode: "433",
          },
          {
            code: 710414,
            name_cn: "梧栖区",
            name_en: "Wuqi",
            name_tw: "梧棲區",
            yzcode: "435",
          },
          {
            code: 710415,
            name_cn: "后里区",
            name_en: "Houli",
            name_tw: "後裏區",
            yzcode: "421",
          },
          {
            code: 710416,
            name_cn: "神冈区",
            name_en: "Shengang",
            name_tw: "神岡區",
            yzcode: "429",
          },
          {
            code: 710417,
            name_cn: "潭子区",
            name_en: "Tanzi",
            name_tw: "潭子區",
            yzcode: "427",
          },
          {
            code: 710418,
            name_cn: "大雅区",
            name_en: "Daya",
            name_tw: "大雅區",
            yzcode: "428",
          },
          {
            code: 710419,
            name_cn: "新社区",
            name_en: "Xinshe",
            name_tw: "新社區",
            yzcode: "426",
          },
          {
            code: 710420,
            name_cn: "石冈区",
            name_en: "Shigang",
            name_tw: "石岡區",
            yzcode: "422",
          },
          {
            code: 710421,
            name_cn: "外埔区",
            name_en: "Waipu",
            name_tw: "外埔區",
            yzcode: "438",
          },
          {
            code: 710422,
            name_cn: "大安区",
            name_en: "Da'an",
            name_tw: "大安區",
            yzcode: "439",
          },
          {
            code: 710423,
            name_cn: "乌日区",
            name_en: "Wuri",
            name_tw: "烏日區",
            yzcode: "414",
          },
          {
            code: 710424,
            name_cn: "大肚区",
            name_en: "Dadu",
            name_tw: "大肚區",
            yzcode: "432",
          },
          {
            code: 710425,
            name_cn: "龙井区",
            name_en: "Longjing",
            name_tw: "龍井區",
            yzcode: "434",
          },
          {
            code: 710426,
            name_cn: "雾峰区",
            name_en: "Wufeng",
            name_tw: "霧峰區",
            yzcode: "413",
          },
          {
            code: 710427,
            name_cn: "太平区",
            name_en: "Taiping",
            name_tw: "太平區",
            yzcode: "411",
          },
          {
            code: 710428,
            name_cn: "大里区",
            name_en: "Dali",
            name_tw: "大裏區",
            yzcode: "412",
          },
          {
            code: 710429,
            name_cn: "和平区",
            name_en: "Heping",
            name_tw: "和平區",
            yzcode: "424",
          },
        ],
      },
      {
        code: 710500,
        name_cn: "台南市",
        name_en: "Tainan",
        name_tw: "台南市",
        children: [
          {
            code: 710501,
            name_cn: "东区",
            name_en: "Dongqu",
            name_tw: "東區",
            yzcode: "701",
          },
          {
            code: 710502,
            name_cn: "南区",
            name_en: "Nanqu",
            name_tw: "南區",
            yzcode: "702",
          },
          {
            code: 710504,
            name_cn: "北区",
            name_en: "Beiqu",
            name_tw: "北區",
            yzcode: "704",
          },
          {
            code: 710506,
            name_cn: "安南区",
            name_en: "Annan",
            name_tw: "安南區",
            yzcode: "709",
          },
          {
            code: 710507,
            name_cn: "安平区",
            name_en: "Anping",
            name_tw: "安平區",
            yzcode: "708",
          },
          {
            code: 710508,
            name_cn: "中西区",
            name_en: "Zhongxi",
            name_tw: "中西區",
            yzcode: "700",
          },
          {
            code: 710509,
            name_cn: "新营区",
            name_en: "Xinying",
            name_tw: "新營區",
            yzcode: "730",
          },
          {
            code: 710510,
            name_cn: "盐水区",
            name_en: "Yanshui",
            name_tw: "鹽水區",
            yzcode: "737",
          },
          {
            code: 710511,
            name_cn: "白河区",
            name_en: "Baihe",
            name_tw: "白河區",
            yzcode: "732",
          },
          {
            code: 710512,
            name_cn: "柳营区",
            name_en: "Liuying",
            name_tw: "柳營區",
            yzcode: "736",
          },
          {
            code: 710513,
            name_cn: "后壁区",
            name_en: "Houbi",
            name_tw: "後壁區",
            yzcode: "731",
          },
          {
            code: 710514,
            name_cn: "东山区",
            name_en: "Dongshan",
            name_tw: "東山區",
            yzcode: "733",
          },
          {
            code: 710515,
            name_cn: "麻豆区",
            name_en: "Madou",
            name_tw: "麻豆區",
            yzcode: "721",
          },
          {
            code: 710516,
            name_cn: "下营区",
            name_en: "Xiaying",
            name_tw: "下營區",
            yzcode: "735",
          },
          {
            code: 710517,
            name_cn: "六甲区",
            name_en: "Liujia",
            name_tw: "六甲區",
            yzcode: "734",
          },
          {
            code: 710518,
            name_cn: "官田区",
            name_en: "Guantian",
            name_tw: "官田區",
            yzcode: "720",
          },
          {
            code: 710519,
            name_cn: "大内区",
            name_en: "Dana",
            name_tw: "大內區",
            yzcode: "742",
          },
          {
            code: 710520,
            name_cn: "佳里区",
            name_en: "Jiali",
            name_tw: "佳裏區",
            yzcode: "722",
          },
          {
            code: 710521,
            name_cn: "学甲区",
            name_en: "Xuejia",
            name_tw: "學甲區",
            yzcode: "726",
          },
          {
            code: 710522,
            name_cn: "西港区",
            name_en: "Xigang",
            name_tw: "西港區",
            yzcode: "723",
          },
          {
            code: 710523,
            name_cn: "七股区",
            name_en: "Qigu",
            name_tw: "七股區",
            yzcode: "724",
          },
          {
            code: 710524,
            name_cn: "将军区",
            name_en: "Jiangjun",
            name_tw: "將軍區",
            yzcode: "725",
          },
          {
            code: 710525,
            name_cn: "北门区",
            name_en: "Beimen",
            name_tw: "北門區",
            yzcode: "727",
          },
          {
            code: 710526,
            name_cn: "新化区",
            name_en: "Xinhua",
            name_tw: "新化區",
            yzcode: "712",
          },
          {
            code: 710527,
            name_cn: "善化区",
            name_en: "Shanhua",
            name_tw: "善化區",
            yzcode: "741",
          },
          {
            code: 710528,
            name_cn: "新市区",
            name_en: "Xinshi",
            name_tw: "新市區",
            yzcode: "744",
          },
          {
            code: 710529,
            name_cn: "安定区",
            name_en: "Anding",
            name_tw: "安定區",
            yzcode: "745",
          },
          {
            code: 710530,
            name_cn: "山上区",
            name_en: "Shanshang",
            name_tw: "山上區",
            yzcode: "743",
          },
          {
            code: 710531,
            name_cn: "玉井区",
            name_en: "Yujing",
            name_tw: "玉井區",
            yzcode: "714",
          },
          {
            code: 710532,
            name_cn: "楠西区",
            name_en: "Nanxi",
            name_tw: "楠西區",
            yzcode: "715",
          },
          {
            code: 710533,
            name_cn: "南化区",
            name_en: "Nanhua",
            name_tw: "南化區",
            yzcode: "716",
          },
          {
            code: 710534,
            name_cn: "左镇区",
            name_en: "Zuozhen",
            name_tw: "左鎮區",
            yzcode: "713",
          },
          {
            code: 710535,
            name_cn: "仁德区",
            name_en: "Rende",
            name_tw: "仁德區",
            yzcode: "717",
          },
          {
            code: 710536,
            name_cn: "归仁区",
            name_en: "Guiren",
            name_tw: "歸仁區",
            yzcode: "711",
          },
          {
            code: 710537,
            name_cn: "关庙区",
            name_en: "Guanmiao",
            name_tw: "關廟區",
            yzcode: "718",
          },
          {
            code: 710538,
            name_cn: "龙崎区",
            name_en: "Longqi",
            name_tw: "龍崎區",
            yzcode: "719",
          },
          {
            code: 710539,
            name_cn: "永康区",
            name_en: "Yongkang",
            name_tw: "永康區",
            yzcode: "710",
          },
        ],
      },
      {
        code: 710600,
        name_cn: "新竹市",
        name_en: "Hsinchu",
        name_tw: "新竹市",
        children: [
          {
            code: 710601,
            name_cn: "东区",
            name_en: "Dongqu",
            name_tw: "東區",
            yzcode: "300",
          },
          {
            code: 710602,
            name_cn: "北区",
            name_en: "Beiqu",
            name_tw: "北區",
            yzcode: "",
          },
          {
            code: 710603,
            name_cn: "香山区",
            name_en: "Xiangshan",
            name_tw: "香山區",
            yzcode: "",
          },
        ],
      },
      {
        code: 710700,
        name_cn: "嘉义市",
        name_en: "Chiayi",
        name_tw: "嘉義市",
        children: [
          {
            code: 710701,
            name_cn: "东区",
            name_en: "Dongqu",
            name_tw: "東區",
            yzcode: "600",
          },
          {
            code: 710702,
            name_cn: "西区",
            name_en: "Xiqu",
            name_tw: "西區",
            yzcode: "600",
          },
        ],
      },
      {
        code: 710800,
        name_cn: "新北市",
        name_en: "New Taipei",
        name_tw: "新北市",
        children: [
          {
            code: 710801,
            name_cn: "板桥区",
            name_en: "Banqiao",
            name_tw: "板橋區",
            yzcode: "220",
          },
          {
            code: 710802,
            name_cn: "三重区",
            name_en: "Sanzhong",
            name_tw: "三重區",
            yzcode: "241",
          },
          {
            code: 710803,
            name_cn: "中和区",
            name_en: "Zhonghe",
            name_tw: "中和區",
            yzcode: "235",
          },
          {
            code: 710804,
            name_cn: "永和区",
            name_en: "Yonghe",
            name_tw: "永和區",
            yzcode: "234",
          },
          {
            code: 710805,
            name_cn: "新庄区",
            name_en: "Xinzhuang",
            name_tw: "新莊區",
            yzcode: "242",
          },
          {
            code: 710806,
            name_cn: "新店区",
            name_en: "Xindian",
            name_tw: "新店區",
            yzcode: "231",
          },
          {
            code: 710807,
            name_cn: "树林区",
            name_en: "Shulin",
            name_tw: "樹林區",
            yzcode: "238",
          },
          {
            code: 710808,
            name_cn: "莺歌区",
            name_en: "Yingge",
            name_tw: "鶯歌區",
            yzcode: "239",
          },
          {
            code: 710809,
            name_cn: "三峡区",
            name_en: "Sanxia",
            name_tw: "三峽區",
            yzcode: "237",
          },
          {
            code: 710810,
            name_cn: "淡水区",
            name_en: "Danshui",
            name_tw: "淡水區",
            yzcode: "251",
          },
          {
            code: 710811,
            name_cn: "汐止区",
            name_en: "Xizhi",
            name_tw: "汐止區",
            yzcode: "221",
          },
          {
            code: 710812,
            name_cn: "瑞芳区",
            name_en: "Ruifang",
            name_tw: "瑞芳區",
            yzcode: "224",
          },
          {
            code: 710813,
            name_cn: "土城区",
            name_en: "Tucheng",
            name_tw: "土城區",
            yzcode: "236",
          },
          {
            code: 710814,
            name_cn: "芦洲区",
            name_en: "Luzhou",
            name_tw: "蘆洲區",
            yzcode: "247",
          },
          {
            code: 710815,
            name_cn: "五股区",
            name_en: "Wugu",
            name_tw: "五股區",
            yzcode: "248",
          },
          {
            code: 710816,
            name_cn: "泰山区",
            name_en: "Taishan",
            name_tw: "泰山區",
            yzcode: "243",
          },
          {
            code: 710817,
            name_cn: "林口区",
            name_en: "Linkou",
            name_tw: "林口區",
            yzcode: "244",
          },
          {
            code: 710818,
            name_cn: "深坑区",
            name_en: "Shenkeng",
            name_tw: "深坑區",
            yzcode: "222",
          },
          {
            code: 710819,
            name_cn: "石碇区",
            name_en: "Shiding",
            name_tw: "石碇區",
            yzcode: "223",
          },
          {
            code: 710820,
            name_cn: "坪林区",
            name_en: "Pinglin",
            name_tw: "坪林區",
            yzcode: "232",
          },
          {
            code: 710821,
            name_cn: "三芝区",
            name_en: "Sanzhi",
            name_tw: "三芝區",
            yzcode: "252",
          },
          {
            code: 710822,
            name_cn: "石门区",
            name_en: "Shimen",
            name_tw: "石門區",
            yzcode: "253",
          },
          {
            code: 710823,
            name_cn: "八里区",
            name_en: "Bali",
            name_tw: "八裏區",
            yzcode: "249",
          },
          {
            code: 710824,
            name_cn: "平溪区",
            name_en: "Pingxi",
            name_tw: "平溪區",
            yzcode: "226",
          },
          {
            code: 710825,
            name_cn: "双溪区",
            name_en: "Shuangxi",
            name_tw: "雙溪區",
            yzcode: "227",
          },
          {
            code: 710826,
            name_cn: "贡寮区",
            name_en: "Gongliao",
            name_tw: "貢寮區",
            yzcode: "228",
          },
          {
            code: 710827,
            name_cn: "金山区",
            name_en: "Jinshan",
            name_tw: "金山區",
            yzcode: "208",
          },
          {
            code: 710828,
            name_cn: "万里区",
            name_en: "Wanli",
            name_tw: "萬裏區",
            yzcode: "207",
          },
          {
            code: 710829,
            name_cn: "乌来区",
            name_en: "Wulai",
            name_tw: "烏來區",
            yzcode: "233",
          },
        ],
      },
      {
        code: 712200,
        name_cn: "宜兰县",
        name_en: "Yilan",
        name_tw: "宜蘭縣",
        children: [
          {
            code: 712201,
            name_cn: "宜兰市",
            name_en: "Yilan",
            name_tw: "宜蘭市",
            yzcode: "260",
          },
          {
            code: 712221,
            name_cn: "罗东镇",
            name_en: "Luodong",
            name_tw: "羅東鎮",
            yzcode: "265",
          },
          {
            code: 712222,
            name_cn: "苏澳镇",
            name_en: "Suao",
            name_tw: "蘇澳鎮",
            yzcode: "270",
          },
          {
            code: 712223,
            name_cn: "头城镇",
            name_en: "Toucheng",
            name_tw: "頭城鎮",
            yzcode: "261",
          },
          {
            code: 712224,
            name_cn: "礁溪乡",
            name_en: "Jiaoxi",
            name_tw: "礁溪鄉",
            yzcode: "262",
          },
          {
            code: 712225,
            name_cn: "壮围乡",
            name_en: "Zhuangwei",
            name_tw: "壯圍鄉",
            yzcode: "263",
          },
          {
            code: 712226,
            name_cn: "员山乡",
            name_en: "Yuanshan",
            name_tw: "員山鄉",
            yzcode: "264",
          },
          {
            code: 712227,
            name_cn: "冬山乡",
            name_en: "Dongshan",
            name_tw: "冬山鄉",
            yzcode: "269",
          },
          {
            code: 712228,
            name_cn: "五结乡",
            name_en: "Wujie",
            name_tw: "五結鄉",
            yzcode: "268",
          },
          {
            code: 712229,
            name_cn: "三星乡",
            name_en: "Sanxing",
            name_tw: "三星鄉",
            yzcode: "266",
          },
          {
            code: 712230,
            name_cn: "大同乡",
            name_en: "Datong",
            name_tw: "大同鄉",
            yzcode: "267",
          },
          {
            code: 712231,
            name_cn: "南澳乡",
            name_en: "Nanao",
            name_tw: "南澳鄉",
            yzcode: "272",
          },
        ],
      },
      {
        code: 712300,
        name_cn: "桃园县",
        name_en: "Taoyuan",
        name_tw: "桃園縣",
        children: [
          {
            code: 712301,
            name_cn: "桃园市",
            name_en: "Taoyuan",
            name_tw: "桃園市",
            yzcode: "330",
          },
          {
            code: 712302,
            name_cn: "中坜市",
            name_en: "Zhongli",
            name_tw: "中壢市",
            yzcode: "320",
          },
          {
            code: 712303,
            name_cn: "平镇市",
            name_en: "Pingzhen",
            name_tw: "平鎮市",
            yzcode: "324",
          },
          {
            code: 712304,
            name_cn: "八德市",
            name_en: "Bade",
            name_tw: "八德市",
            yzcode: "334",
          },
          {
            code: 712305,
            name_cn: "杨梅市",
            name_en: "Yangmei",
            name_tw: "楊梅市",
            yzcode: "326",
          },
          {
            code: 712306,
            name_cn: "芦竹市",
            name_en: "Luzhu",
            name_tw: "蘆竹市",
            yzcode: "338",
          },
          {
            code: 712321,
            name_cn: "大溪镇",
            name_en: "Daxi",
            name_tw: "大溪鎮",
            yzcode: "335",
          },
          {
            code: 712324,
            name_cn: "大园乡",
            name_en: "Dayuan",
            name_tw: "大園鄉",
            yzcode: "337",
          },
          {
            code: 712325,
            name_cn: "龟山乡",
            name_en: "Guishan",
            name_tw: "龜山鄉",
            yzcode: "333",
          },
          {
            code: 712327,
            name_cn: "龙潭乡",
            name_en: "Longtan",
            name_tw: "龍潭鄉",
            yzcode: "325",
          },
          {
            code: 712329,
            name_cn: "新屋乡",
            name_en: "Xinwu",
            name_tw: "新屋鄉",
            yzcode: "327",
          },
          {
            code: 712330,
            name_cn: "观音乡",
            name_en: "Guanyin",
            name_tw: "觀音鄉",
            yzcode: "328",
          },
          {
            code: 712331,
            name_cn: "复兴乡",
            name_en: "Fuxing",
            name_tw: "復興鄉",
            yzcode: "336",
          },
        ],
      },
      {
        code: 712400,
        name_cn: "新竹县",
        name_en: "Hsinchu",
        name_tw: "新竹縣",
        children: [
          {
            code: 712401,
            name_cn: "竹北市",
            name_en: "Zhubei",
            name_tw: "竹北市",
            yzcode: "302",
          },
          {
            code: 712421,
            name_cn: "竹东镇",
            name_en: "Zhudong",
            name_tw: "竹東鎮",
            yzcode: "310",
          },
          {
            code: 712422,
            name_cn: "新埔镇",
            name_en: "Xinpu",
            name_tw: "新埔鎮",
            yzcode: "305",
          },
          {
            code: 712423,
            name_cn: "关西镇",
            name_en: "Guanxi",
            name_tw: "關西鎮",
            yzcode: "306",
          },
          {
            code: 712424,
            name_cn: "湖口乡",
            name_en: "Hukou",
            name_tw: "湖口鄉",
            yzcode: "303",
          },
          {
            code: 712425,
            name_cn: "新丰乡",
            name_en: "Xinfeng",
            name_tw: "新豐鄉",
            yzcode: "304",
          },
          {
            code: 712426,
            name_cn: "芎林乡",
            name_en: "Xionglin",
            name_tw: "芎林鄉",
            yzcode: "307",
          },
          {
            code: 712427,
            name_cn: "横山乡",
            name_en: "Hengshan",
            name_tw: "橫山鄉",
            yzcode: "312",
          },
          {
            code: 712428,
            name_cn: "北埔乡",
            name_en: "Beipu",
            name_tw: "北埔鄉",
            yzcode: "314",
          },
          {
            code: 712429,
            name_cn: "宝山乡",
            name_en: "Baoshan",
            name_tw: "寶山鄉",
            yzcode: "308",
          },
          {
            code: 712430,
            name_cn: "峨眉乡",
            name_en: "Emei",
            name_tw: "峨眉鄉",
            yzcode: "315",
          },
          {
            code: 712431,
            name_cn: "尖石乡",
            name_en: "Jianshi",
            name_tw: "尖石鄉",
            yzcode: "313",
          },
          {
            code: 712432,
            name_cn: "五峰乡",
            name_en: "Wufeng",
            name_tw: "五峰鄉",
            yzcode: "311",
          },
        ],
      },
      {
        code: 712500,
        name_cn: "苗栗县",
        name_en: "Miaoli",
        name_tw: "苗栗縣",
        children: [
          {
            code: 712501,
            name_cn: "苗栗市",
            name_en: "Miaoli",
            name_tw: "苗栗市",
            yzcode: "360",
          },
          {
            code: 712521,
            name_cn: "苑里镇",
            name_en: "Yuanli",
            name_tw: "苑裏鎮",
            yzcode: "358",
          },
          {
            code: 712522,
            name_cn: "通霄镇",
            name_en: "Tongxiao",
            name_tw: "通霄鎮",
            yzcode: "357",
          },
          {
            code: 712523,
            name_cn: "竹南镇",
            name_en: "Zhunan",
            name_tw: "竹南鎮",
            yzcode: "350",
          },
          {
            code: 712524,
            name_cn: "头份镇",
            name_en: "Toufen",
            name_tw: "頭份鎮",
            yzcode: "351",
          },
          {
            code: 712525,
            name_cn: "后龙镇",
            name_en: "Houlong",
            name_tw: "後龍鎮",
            yzcode: "356",
          },
          {
            code: 712526,
            name_cn: "卓兰镇",
            name_en: "Zhuolan",
            name_tw: "卓蘭鎮",
            yzcode: "369",
          },
          {
            code: 712527,
            name_cn: "大湖乡",
            name_en: "Dahu",
            name_tw: "大湖鄉",
            yzcode: "364",
          },
          {
            code: 712528,
            name_cn: "公馆乡",
            name_en: "Gongguan",
            name_tw: "公館鄉",
            yzcode: "363",
          },
          {
            code: 712529,
            name_cn: "铜锣乡",
            name_en: "Tongluo",
            name_tw: "銅鑼鄉",
            yzcode: "366",
          },
          {
            code: 712530,
            name_cn: "南庄乡",
            name_en: "Nanzhuang",
            name_tw: "南莊鄉",
            yzcode: "353",
          },
          {
            code: 712531,
            name_cn: "头屋乡",
            name_en: "Touwu",
            name_tw: "頭屋鄉",
            yzcode: "362",
          },
          {
            code: 712532,
            name_cn: "三义乡",
            name_en: "Sanyi",
            name_tw: "三義鄉",
            yzcode: "367",
          },
          {
            code: 712533,
            name_cn: "西湖乡",
            name_en: "Xihu",
            name_tw: "西湖鄉",
            yzcode: "368",
          },
          {
            code: 712534,
            name_cn: "造桥乡",
            name_en: "Zaoqiao",
            name_tw: "造橋鄉",
            yzcode: "361",
          },
          {
            code: 712535,
            name_cn: "三湾乡",
            name_en: "Sanwan",
            name_tw: "三灣鄉",
            yzcode: "352",
          },
          {
            code: 712536,
            name_cn: "狮潭乡",
            name_en: "Shitan",
            name_tw: "獅潭鄉",
            yzcode: "354",
          },
          {
            code: 712537,
            name_cn: "泰安乡",
            name_en: "Tai'an",
            name_tw: "泰安鄉",
            yzcode: "365",
          },
        ],
      },
      {
        code: 712700,
        name_cn: "彰化县",
        name_en: "Changhua",
        name_tw: "彰化縣",
        children: [
          {
            code: 712701,
            name_cn: "彰化市",
            name_en: "Zhanghuashi",
            name_tw: "彰化市",
            yzcode: "500",
          },
          {
            code: 712721,
            name_cn: "鹿港镇",
            name_en: "Lugang",
            name_tw: "鹿港鎮",
            yzcode: "505",
          },
          {
            code: 712722,
            name_cn: "和美镇",
            name_en: "Hemei",
            name_tw: "和美鎮",
            yzcode: "508",
          },
          {
            code: 712723,
            name_cn: "线西乡",
            name_en: "Xianxi",
            name_tw: "線西鄉",
            yzcode: "507",
          },
          {
            code: 712724,
            name_cn: "伸港乡",
            name_en: "Shengang",
            name_tw: "伸港鄉",
            yzcode: "509",
          },
          {
            code: 712725,
            name_cn: "福兴乡",
            name_en: "Fuxing",
            name_tw: "福興鄉",
            yzcode: "506",
          },
          {
            code: 712726,
            name_cn: "秀水乡",
            name_en: "Xiushui",
            name_tw: "秀水鄉",
            yzcode: "504",
          },
          {
            code: 712727,
            name_cn: "花坛乡",
            name_en: "Huatan",
            name_tw: "花壇鄉",
            yzcode: "503",
          },
          {
            code: 712728,
            name_cn: "芬园乡",
            name_en: "Fenyuan",
            name_tw: "芬園鄉",
            yzcode: "502",
          },
          {
            code: 712729,
            name_cn: "员林镇",
            name_en: "Yuanlin",
            name_tw: "員林鎮",
            yzcode: "510",
          },
          {
            code: 712730,
            name_cn: "溪湖镇",
            name_en: "Xihu",
            name_tw: "溪湖鎮",
            yzcode: "514",
          },
          {
            code: 712731,
            name_cn: "田中镇",
            name_en: "Tianzhong",
            name_tw: "田中鎮",
            yzcode: "520",
          },
          {
            code: 712732,
            name_cn: "大村乡",
            name_en: "Dacun",
            name_tw: "大村鄉",
            yzcode: "515",
          },
          {
            code: 712733,
            name_cn: "埔盐乡",
            name_en: "Puyan",
            name_tw: "埔鹽鄉",
            yzcode: "516",
          },
          {
            code: 712734,
            name_cn: "埔心乡",
            name_en: "Puxin",
            name_tw: "埔心鄉",
            yzcode: "513",
          },
          {
            code: 712735,
            name_cn: "永靖乡",
            name_en: "Yongjing",
            name_tw: "永靖鄉",
            yzcode: "512",
          },
          {
            code: 712736,
            name_cn: "社头乡",
            name_en: "Shetou",
            name_tw: "社頭鄉",
            yzcode: "511",
          },
          {
            code: 712737,
            name_cn: "二水乡",
            name_en: "Ershui",
            name_tw: "二水鄉",
            yzcode: "530",
          },
          {
            code: 712738,
            name_cn: "北斗镇",
            name_en: "Beidou",
            name_tw: "北鬥鎮",
            yzcode: "521",
          },
          {
            code: 712739,
            name_cn: "二林镇",
            name_en: "Erlin",
            name_tw: "二林鎮",
            yzcode: "526",
          },
          {
            code: 712740,
            name_cn: "田尾乡",
            name_en: "Tianwei",
            name_tw: "田尾鄉",
            yzcode: "522",
          },
          {
            code: 712741,
            name_cn: "埤头乡",
            name_en: "Pitou",
            name_tw: "埤頭鄉",
            yzcode: "523",
          },
          {
            code: 712742,
            name_cn: "芳苑乡",
            name_en: "Fangyuan",
            name_tw: "芳苑鄉",
            yzcode: "528",
          },
          {
            code: 712743,
            name_cn: "大城乡",
            name_en: "Dacheng",
            name_tw: "大城鄉",
            yzcode: "527",
          },
          {
            code: 712744,
            name_cn: "竹塘乡",
            name_en: "Zhutang",
            name_tw: "竹塘鄉",
            yzcode: "525",
          },
          {
            code: 712745,
            name_cn: "溪州乡",
            name_en: "Xizhou",
            name_tw: "溪州鄉",
            yzcode: "524",
          },
        ],
      },
      {
        code: 712800,
        name_cn: "南投县",
        name_en: "Nantou",
        name_tw: "南投縣",
        children: [
          {
            code: 712801,
            name_cn: "南投市",
            name_en: "Nantoushi",
            name_tw: "南投市",
            yzcode: "540",
          },
          {
            code: 712821,
            name_cn: "埔里镇",
            name_en: "Puli",
            name_tw: "埔裏鎮",
            yzcode: "545",
          },
          {
            code: 712822,
            name_cn: "草屯镇",
            name_en: "Caotun",
            name_tw: "草屯鎮",
            yzcode: "542",
          },
          {
            code: 712823,
            name_cn: "竹山镇",
            name_en: "Zhushan",
            name_tw: "竹山鎮",
            yzcode: "557",
          },
          {
            code: 712824,
            name_cn: "集集镇",
            name_en: "Jiji",
            name_tw: "集集鎮",
            yzcode: "552",
          },
          {
            code: 712825,
            name_cn: "名间乡",
            name_en: "Mingjian",
            name_tw: "名間鄉",
            yzcode: "551",
          },
          {
            code: 712826,
            name_cn: "鹿谷乡",
            name_en: "Lugu",
            name_tw: "鹿谷鄉",
            yzcode: "558",
          },
          {
            code: 712827,
            name_cn: "中寮乡",
            name_en: "Zhongliao",
            name_tw: "中寮鄉",
            yzcode: "541",
          },
          {
            code: 712828,
            name_cn: "鱼池乡",
            name_en: "Yuchi",
            name_tw: "魚池鄉",
            yzcode: "555",
          },
          {
            code: 712829,
            name_cn: "国姓乡",
            name_en: "Guoxing",
            name_tw: "國姓鄉",
            yzcode: "544",
          },
          {
            code: 712830,
            name_cn: "水里乡",
            name_en: "Shuili",
            name_tw: "水裏鄉",
            yzcode: "553",
          },
          {
            code: 712831,
            name_cn: "信义乡",
            name_en: "Xinyi",
            name_tw: "信義鄉",
            yzcode: "556",
          },
          {
            code: 712832,
            name_cn: "仁爱乡",
            name_en: "Renai",
            name_tw: "仁愛鄉",
            yzcode: "546",
          },
        ],
      },
      {
        code: 712900,
        name_cn: "云林县",
        name_en: "Yunlin",
        name_tw: "雲林縣",
        children: [
          {
            code: 712901,
            name_cn: "斗六市",
            name_en: "Douliu",
            name_tw: "鬥六市",
            yzcode: "640",
          },
          {
            code: 712921,
            name_cn: "斗南镇",
            name_en: "Dounan",
            name_tw: "鬥南鎮",
            yzcode: "630",
          },
          {
            code: 712922,
            name_cn: "虎尾镇",
            name_en: "Huwei",
            name_tw: "虎尾鎮",
            yzcode: "632",
          },
          {
            code: 712923,
            name_cn: "西螺镇",
            name_en: "Xiluo",
            name_tw: "西螺鎮",
            yzcode: "648",
          },
          {
            code: 712924,
            name_cn: "土库镇",
            name_en: "Tuku",
            name_tw: "土庫鎮",
            yzcode: "633",
          },
          {
            code: 712925,
            name_cn: "北港镇",
            name_en: "Beigang",
            name_tw: "北港鎮",
            yzcode: "651",
          },
          {
            code: 712926,
            name_cn: "古坑乡",
            name_en: "Gukeng",
            name_tw: "古坑鄉",
            yzcode: "646",
          },
          {
            code: 712927,
            name_cn: "大埤乡",
            name_en: "Dapi",
            name_tw: "大埤鄉",
            yzcode: "631",
          },
          {
            code: 712928,
            name_cn: "莿桐乡",
            name_en: "Citong",
            name_tw: "莿桐鄉",
            yzcode: "647",
          },
          {
            code: 712929,
            name_cn: "林内乡",
            name_en: "Linna",
            name_tw: "林內鄉",
            yzcode: "643",
          },
          {
            code: 712930,
            name_cn: "二仑乡",
            name_en: "Erlun",
            name_tw: "二侖鄉",
            yzcode: "649",
          },
          {
            code: 712931,
            name_cn: "仑背乡",
            name_en: "Lunbei",
            name_tw: "侖背鄉",
            yzcode: "637",
          },
          {
            code: 712932,
            name_cn: "麦寮乡",
            name_en: "Mailiao",
            name_tw: "麥寮鄉",
            yzcode: "638",
          },
          {
            code: 712933,
            name_cn: "东势乡",
            name_en: "Dongshi",
            name_tw: "東勢鄉",
            yzcode: "635",
          },
          {
            code: 712934,
            name_cn: "褒忠乡",
            name_en: "Baozhong",
            name_tw: "褒忠鄉",
            yzcode: "634",
          },
          {
            code: 712935,
            name_cn: "台西乡",
            name_en: "Taixi",
            name_tw: "台西鄉",
            yzcode: "636",
          },
          {
            code: 712936,
            name_cn: "元长乡",
            name_en: "Yuanchang",
            name_tw: "元長鄉",
            yzcode: "655",
          },
          {
            code: 712937,
            name_cn: "四湖乡",
            name_en: "Sihu",
            name_tw: "四湖鄉",
            yzcode: "654",
          },
          {
            code: 712938,
            name_cn: "口湖乡",
            name_en: "Kouhu",
            name_tw: "口湖鄉",
            yzcode: "653",
          },
          {
            code: 712939,
            name_cn: "水林乡",
            name_en: "Shuilin",
            name_tw: "水林鄉",
            yzcode: "652",
          },
        ],
      },
      {
        code: 713000,
        name_cn: "嘉义县",
        name_en: "Chiayi",
        name_tw: "嘉義縣",
        children: [
          {
            code: 713001,
            name_cn: "太保市",
            name_en: "Taibao",
            name_tw: "太保市",
            yzcode: "612",
          },
          {
            code: 713002,
            name_cn: "朴子市",
            name_en: "Puzi",
            name_tw: "樸子市",
            yzcode: "613",
          },
          {
            code: 713023,
            name_cn: "布袋镇",
            name_en: "Budai",
            name_tw: "布袋鎮",
            yzcode: "625",
          },
          {
            code: 713024,
            name_cn: "大林镇",
            name_en: "Dalin",
            name_tw: "大林鎮",
            yzcode: "622",
          },
          {
            code: 713025,
            name_cn: "民雄乡",
            name_en: "Minxiong",
            name_tw: "民雄鄉",
            yzcode: "621",
          },
          {
            code: 713026,
            name_cn: "溪口乡",
            name_en: "Xikou",
            name_tw: "溪口鄉",
            yzcode: "623",
          },
          {
            code: 713027,
            name_cn: "新港乡",
            name_en: "Xingang",
            name_tw: "新港鄉",
            yzcode: "616",
          },
          {
            code: 713028,
            name_cn: "六脚乡",
            name_en: "Liujiao",
            name_tw: "六腳鄉",
            yzcode: "615",
          },
          {
            code: 713029,
            name_cn: "东石乡",
            name_en: "Dongshi",
            name_tw: "東石鄉",
            yzcode: "614",
          },
          {
            code: 713030,
            name_cn: "义竹乡",
            name_en: "Yizhu",
            name_tw: "義竹鄉",
            yzcode: "624",
          },
          {
            code: 713031,
            name_cn: "鹿草乡",
            name_en: "Lucao",
            name_tw: "鹿草鄉",
            yzcode: "611",
          },
          {
            code: 713032,
            name_cn: "水上乡",
            name_en: "Shuishang",
            name_tw: "水上鄉",
            yzcode: "608",
          },
          {
            code: 713033,
            name_cn: "中埔乡",
            name_en: "Zhongpu",
            name_tw: "中埔鄉",
            yzcode: "606",
          },
          {
            code: 713034,
            name_cn: "竹崎乡",
            name_en: "Zhuqi",
            name_tw: "竹崎鄉",
            yzcode: "604",
          },
          {
            code: 713035,
            name_cn: "梅山乡",
            name_en: "Meishan",
            name_tw: "梅山鄉",
            yzcode: "603",
          },
          {
            code: 713036,
            name_cn: "番路乡",
            name_en: "Fanlu",
            name_tw: "番路鄉",
            yzcode: "602",
          },
          {
            code: 713037,
            name_cn: "大埔乡",
            name_en: "Dapu",
            name_tw: "大埔鄉",
            yzcode: "607",
          },
          {
            code: 713038,
            name_cn: "阿里山乡",
            name_en: "Alishan",
            name_tw: "阿裏山鄉",
            yzcode: "605",
          },
        ],
      },
      {
        code: 713300,
        name_cn: "屏东县",
        name_en: "Pingtung",
        name_tw: "屏東縣",
        children: [
          {
            code: 713301,
            name_cn: "屏东市",
            name_en: "Pingdong",
            name_tw: "屏東市",
            yzcode: "900",
          },
          {
            code: 713321,
            name_cn: "潮州镇",
            name_en: "Chaozhou",
            name_tw: "潮州鎮",
            yzcode: "920",
          },
          {
            code: 713322,
            name_cn: "东港镇",
            name_en: "Donggang",
            name_tw: "東港鎮",
            yzcode: "928",
          },
          {
            code: 713323,
            name_cn: "恒春镇",
            name_en: "Hengchun",
            name_tw: "恒春鎮",
            yzcode: "946",
          },
          {
            code: 713324,
            name_cn: "万丹乡",
            name_en: "Wandan",
            name_tw: "萬丹鄉",
            yzcode: "913",
          },
          {
            code: 713325,
            name_cn: "长治乡",
            name_en: "Changzhi",
            name_tw: "長治鄉",
            yzcode: "908",
          },
          {
            code: 713326,
            name_cn: "麟洛乡",
            name_en: "Linluo",
            name_tw: "麟洛鄉",
            yzcode: "909",
          },
          {
            code: 713327,
            name_cn: "九如乡",
            name_en: "Jiuru",
            name_tw: "九如鄉",
            yzcode: "904",
          },
          {
            code: 713328,
            name_cn: "里港乡",
            name_en: "Ligang",
            name_tw: "裏港鄉",
            yzcode: "905",
          },
          {
            code: 713329,
            name_cn: "盐埔乡",
            name_en: "Yanpu",
            name_tw: "鹽埔鄉",
            yzcode: "907",
          },
          {
            code: 713330,
            name_cn: "高树乡",
            name_en: "Gaoshu",
            name_tw: "高樹鄉",
            yzcode: "906",
          },
          {
            code: 713331,
            name_cn: "万峦乡",
            name_en: "Wanluan",
            name_tw: "萬巒鄉",
            yzcode: "923",
          },
          {
            code: 713332,
            name_cn: "内埔乡",
            name_en: "Napu",
            name_tw: "內埔鄉",
            yzcode: "912",
          },
          {
            code: 713333,
            name_cn: "竹田乡",
            name_en: "Zhutian",
            name_tw: "竹田鄉",
            yzcode: "911",
          },
          {
            code: 713334,
            name_cn: "新埤乡",
            name_en: "Xinpi",
            name_tw: "新埤鄉",
            yzcode: "925",
          },
          {
            code: 713335,
            name_cn: "枋寮乡",
            name_en: "Fangliao",
            name_tw: "枋寮鄉",
            yzcode: "940",
          },
          {
            code: 713336,
            name_cn: "新园乡",
            name_en: "Xinyuan",
            name_tw: "新園鄉",
            yzcode: "932",
          },
          {
            code: 713337,
            name_cn: "崁顶乡",
            name_en: "Kanding",
            name_tw: "崁頂鄉",
            yzcode: "924",
          },
          {
            code: 713338,
            name_cn: "林边乡",
            name_en: "Linbian",
            name_tw: "林邊鄉",
            yzcode: "927",
          },
          {
            code: 713339,
            name_cn: "南州乡",
            name_en: "Nanzhou",
            name_tw: "南州鄉",
            yzcode: "926",
          },
          {
            code: 713340,
            name_cn: "佳冬乡",
            name_en: "Jiadong",
            name_tw: "佳冬鄉",
            yzcode: "931",
          },
          {
            code: 713341,
            name_cn: "琉球乡",
            name_en: "Liuqiu",
            name_tw: "琉球鄉",
            yzcode: "929",
          },
          {
            code: 713342,
            name_cn: "车城乡",
            name_en: "Checheng",
            name_tw: "車城鄉",
            yzcode: "944",
          },
          {
            code: 713343,
            name_cn: "满州乡",
            name_en: "Manzhou",
            name_tw: "滿州鄉",
            yzcode: "947",
          },
          {
            code: 713344,
            name_cn: "枋山乡",
            name_en: "Fangshan",
            name_tw: "枋山鄉",
            yzcode: "941",
          },
          {
            code: 713345,
            name_cn: "三地门乡",
            name_en: "Sandimen",
            name_tw: "三地門鄉",
            yzcode: "901",
          },
          {
            code: 713346,
            name_cn: "雾台乡",
            name_en: "Wutai",
            name_tw: "霧台鄉",
            yzcode: "902",
          },
          {
            code: 713347,
            name_cn: "玛家乡",
            name_en: "Majia",
            name_tw: "瑪家鄉",
            yzcode: "903",
          },
          {
            code: 713348,
            name_cn: "泰武乡",
            name_en: "Taiwu",
            name_tw: "泰武鄉",
            yzcode: "921",
          },
          {
            code: 713349,
            name_cn: "来义乡",
            name_en: "Laiyi",
            name_tw: "來義鄉",
            yzcode: "922",
          },
          {
            code: 713350,
            name_cn: "春日乡",
            name_en: "Chunri",
            name_tw: "春日鄉",
            yzcode: "942",
          },
          {
            code: 713351,
            name_cn: "狮子乡",
            name_en: "Shizi",
            name_tw: "獅子鄉",
            yzcode: "943",
          },
          {
            code: 713352,
            name_cn: "牡丹乡",
            name_en: "Mudan",
            name_tw: "牡丹鄉",
            yzcode: "945",
          },
        ],
      },
      {
        code: 713400,
        name_cn: "台东县",
        name_en: "Taitung",
        name_tw: "台東縣",
        children: [
          {
            code: 713401,
            name_cn: "台东市",
            name_en: "Taidong",
            name_tw: "台東市",
            yzcode: "950",
          },
          {
            code: 713421,
            name_cn: "成功镇",
            name_en: "Chenggong",
            name_tw: "成功鎮",
            yzcode: "961",
          },
          {
            code: 713422,
            name_cn: "关山镇",
            name_en: "Guanshan",
            name_tw: "關山鎮",
            yzcode: "956",
          },
          {
            code: 713423,
            name_cn: "卑南乡",
            name_en: "Beinan",
            name_tw: "卑南鄉",
            yzcode: "954",
          },
          {
            code: 713424,
            name_cn: "鹿野乡",
            name_en: "Luye",
            name_tw: "鹿野鄉",
            yzcode: "955",
          },
          {
            code: 713425,
            name_cn: "池上乡",
            name_en: "Chishang",
            name_tw: "池上鄉",
            yzcode: "958",
          },
          {
            code: 713426,
            name_cn: "东河乡",
            name_en: "Donghe",
            name_tw: "東河鄉",
            yzcode: "959",
          },
          {
            code: 713427,
            name_cn: "长滨乡",
            name_en: "Changbin",
            name_tw: "長濱鄉",
            yzcode: "962",
          },
          {
            code: 713428,
            name_cn: "太麻里乡",
            name_en: "Taimali",
            name_tw: "太麻裏鄉",
            yzcode: "963",
          },
          {
            code: 713429,
            name_cn: "大武乡",
            name_en: "Dawu",
            name_tw: "大武鄉",
            yzcode: "965",
          },
          {
            code: 713430,
            name_cn: "绿岛乡",
            name_en: "Lvdao",
            name_tw: "綠島鄉",
            yzcode: "951",
          },
          {
            code: 713431,
            name_cn: "海端乡",
            name_en: "Haiduan",
            name_tw: "海端鄉",
            yzcode: "957",
          },
          {
            code: 713432,
            name_cn: "延平乡",
            name_en: "Yanping",
            name_tw: "延平鄉",
            yzcode: "953",
          },
          {
            code: 713433,
            name_cn: "金峰乡",
            name_en: "Jinfeng",
            name_tw: "金峰鄉",
            yzcode: "964",
          },
          {
            code: 713434,
            name_cn: "达仁乡",
            name_en: "Daren",
            name_tw: "達仁鄉",
            yzcode: "966",
          },
          {
            code: 713435,
            name_cn: "兰屿乡",
            name_en: "Lanyu",
            name_tw: "蘭嶼鄉",
            yzcode: "952",
          },
        ],
      },
      {
        code: 713500,
        name_cn: "花莲县",
        name_en: "Hualien",
        name_tw: "花蓮縣",
        children: [
          {
            code: 713501,
            name_cn: "花莲市",
            name_en: "Hualian",
            name_tw: "花蓮市",
            yzcode: "970",
          },
          {
            code: 713521,
            name_cn: "凤林镇",
            name_en: "Fenglin",
            name_tw: "鳳林鎮",
            yzcode: "975",
          },
          {
            code: 713522,
            name_cn: "玉里镇",
            name_en: "Yuli",
            name_tw: "玉裏鎮",
            yzcode: "981",
          },
          {
            code: 713523,
            name_cn: "新城乡",
            name_en: "Xincheng",
            name_tw: "新城鄉",
            yzcode: "971",
          },
          {
            code: 713524,
            name_cn: "吉安乡",
            name_en: "Ji'an",
            name_tw: "吉安鄉",
            yzcode: "973",
          },
          {
            code: 713525,
            name_cn: "寿丰乡",
            name_en: "Shoufeng",
            name_tw: "壽豐鄉",
            yzcode: "974",
          },
          {
            code: 713526,
            name_cn: "光复乡",
            name_en: "Guangfu",
            name_tw: "光復鄉",
            yzcode: "976",
          },
          {
            code: 713527,
            name_cn: "丰滨乡",
            name_en: "Fengbin",
            name_tw: "豐濱鄉",
            yzcode: "977",
          },
          {
            code: 713528,
            name_cn: "瑞穗乡",
            name_en: "Ruisui",
            name_tw: "瑞穗鄉",
            yzcode: "978",
          },
          {
            code: 713529,
            name_cn: "富里乡",
            name_en: "Fuli",
            name_tw: "富裏鄉",
            yzcode: "983",
          },
          {
            code: 713530,
            name_cn: "秀林乡",
            name_en: "Xiulin",
            name_tw: "秀林鄉",
            yzcode: "972",
          },
          {
            code: 713531,
            name_cn: "万荣乡",
            name_en: "Wanrong",
            name_tw: "萬榮鄉",
            yzcode: "979",
          },
          {
            code: 713532,
            name_cn: "卓溪乡",
            name_en: "Zhuoxi",
            name_tw: "卓溪鄉",
            yzcode: "982",
          },
        ],
      },
      {
        code: 713600,
        name_cn: "澎湖县",
        name_en: "Penghu",
        name_tw: "澎湖縣",
        children: [
          {
            code: 713601,
            name_cn: "马公市",
            name_en: "Magong",
            name_tw: "馬公市",
            yzcode: "880",
          },
          {
            code: 713621,
            name_cn: "湖西乡",
            name_en: "Huxi",
            name_tw: "湖西鄉",
            yzcode: "885",
          },
          {
            code: 713622,
            name_cn: "白沙乡",
            name_en: "Baisha",
            name_tw: "白沙鄉",
            yzcode: "884",
          },
          {
            code: 713623,
            name_cn: "西屿乡",
            name_en: "Xiyu",
            name_tw: "西嶼鄉",
            yzcode: "881",
          },
          {
            code: 713624,
            name_cn: "望安乡",
            name_en: "Wang'an",
            name_tw: "望安鄉",
            yzcode: "882",
          },
          {
            code: 713625,
            name_cn: "七美乡",
            name_en: "Qimei",
            name_tw: "七美鄉",
            yzcode: "883",
          },
        ],
      },
      {
        code: 713700,
        name_cn: "金门县",
        name_en: "Jinmen",
        name_tw: "金門縣",
        children: [
          {
            code: 713701,
            name_cn: "金城镇",
            name_en: "Jincheng",
            name_tw: "金城鎮",
            yzcode: "893",
          },
          {
            code: 713702,
            name_cn: "金湖镇",
            name_en: "Jinhu",
            name_tw: "金湖鎮",
            yzcode: "891",
          },
          {
            code: 713703,
            name_cn: "金沙镇",
            name_en: "Jinsha",
            name_tw: "金沙鎮",
            yzcode: "890",
          },
          {
            code: 713704,
            name_cn: "金宁乡",
            name_en: "Jinning",
            name_tw: "金寧鄉",
            yzcode: "892",
          },
          {
            code: 713705,
            name_cn: "烈屿乡",
            name_en: "Lieyu",
            name_tw: "烈嶼鄉",
            yzcode: "894",
          },
          {
            code: 713706,
            name_cn: "乌丘乡",
            name_en: "Wuqiu",
            name_tw: "烏丘鄉",
            yzcode: "896",
          },
        ],
      },
      {
        code: 713800,
        name_cn: "连江县",
        name_en: "Lienchiang",
        name_tw: "連江縣",
        children: [
          {
            code: 713801,
            name_cn: "南竿乡",
            name_en: "Nangan",
            name_tw: "南竿鄉",
            yzcode: "209",
          },
          {
            code: 713802,
            name_cn: "北竿乡",
            name_en: "Beigan",
            name_tw: "北竿鄉",
            yzcode: "210",
          },
          {
            code: 713803,
            name_cn: "莒光乡",
            name_en: "Juguang",
            name_tw: "莒光鄉",
            yzcode: "211",
          },
          {
            code: 713804,
            name_cn: "东引乡",
            name_en: "Dongyin",
            name_tw: "東引鄉",
            yzcode: "212",
          },
        ],
      },
    ],
  },
  {
    code: 110000,
    name_cn: "北京",
    name_en: "Beijing",
    name_tw: "北京",
    children: [
      {
        code: 110100,
        name_cn: "北京市",
        name_en: "Beijing",
        name_tw: "北京市",
        children: [
          {
            code: 110101,
            name_cn: "东城区",
            name_en: "Dongcheng",
            name_tw: "東城區",
            yzcode: "100010",
          },
          {
            code: 110102,
            name_cn: "西城区",
            name_en: "Xicheng",
            name_tw: "西城區",
            yzcode: "100032",
          },
          {
            code: 110105,
            name_cn: "朝阳区",
            name_en: "Chaoyang",
            name_tw: "朝陽區",
            yzcode: "100020",
          },
          {
            code: 110106,
            name_cn: "丰台区",
            name_en: "Fengtai",
            name_tw: "豐台區",
            yzcode: "100071",
          },
          {
            code: 110107,
            name_cn: "石景山区",
            name_en: "Shijingshan",
            name_tw: "石景山區",
            yzcode: "100043",
          },
          {
            code: 110108,
            name_cn: "海淀区",
            name_en: "Haidian",
            name_tw: "海澱區",
            yzcode: "100089",
          },
          {
            code: 110109,
            name_cn: "门头沟区",
            name_en: "Mentougou",
            name_tw: "門頭溝區",
            yzcode: "102300",
          },
          {
            code: 110111,
            name_cn: "房山区",
            name_en: "Fangshan",
            name_tw: "房山區",
            yzcode: "102488",
          },
          {
            code: 110112,
            name_cn: "通州区",
            name_en: "Tongzhou",
            name_tw: "通州區",
            yzcode: "101149",
          },
          {
            code: 110113,
            name_cn: "顺义区",
            name_en: "Shunyi",
            name_tw: "順義區",
            yzcode: "101300",
          },
          {
            code: 110114,
            name_cn: "昌平区",
            name_en: "Changping",
            name_tw: "昌平區",
            yzcode: "102200",
          },
          {
            code: 110115,
            name_cn: "大兴区",
            name_en: "Daxing",
            name_tw: "大興區",
            yzcode: "102600",
          },
          {
            code: 110116,
            name_cn: "怀柔区",
            name_en: "Huairou",
            name_tw: "懷柔區",
            yzcode: "101400",
          },
          {
            code: 110117,
            name_cn: "平谷区",
            name_en: "Pinggu",
            name_tw: "平谷區",
            yzcode: "101200",
          },
          {
            code: 110228,
            name_cn: "密云县",
            name_en: "Miyun",
            name_tw: "密雲縣",
            yzcode: "101500",
          },
          {
            code: 110229,
            name_cn: "延庆县",
            name_en: "Yanqing",
            name_tw: "延慶縣",
            yzcode: "102100",
          },
        ],
      },
    ],
  },
  {
    code: 120000,
    name_cn: "天津",
    name_en: "Tianjin",
    name_tw: "天津",
    children: [
      {
        code: 120100,
        name_cn: "天津市",
        name_en: "Tianjin",
        name_tw: "天津市",
        children: [
          {
            code: 120101,
            name_cn: "和平区",
            name_en: "Heping",
            name_tw: "和平區",
            yzcode: "300041",
          },
          {
            code: 120102,
            name_cn: "河东区",
            name_en: "Hedong",
            name_tw: "河東區",
            yzcode: "300171",
          },
          {
            code: 120103,
            name_cn: "河西区",
            name_en: "Hexi",
            name_tw: "河西區",
            yzcode: "300202",
          },
          {
            code: 120104,
            name_cn: "南开区",
            name_en: "Nankai",
            name_tw: "南開區",
            yzcode: "300110",
          },
          {
            code: 120105,
            name_cn: "河北区",
            name_en: "Hebei",
            name_tw: "河北區",
            yzcode: "300143",
          },
          {
            code: 120106,
            name_cn: "红桥区",
            name_en: "Hongqiao",
            name_tw: "紅橋區",
            yzcode: "300131",
          },
          {
            code: 120110,
            name_cn: "东丽区",
            name_en: "Dongli",
            name_tw: "東麗區",
            yzcode: "300300",
          },
          {
            code: 120111,
            name_cn: "西青区",
            name_en: "Xiqing",
            name_tw: "西青區",
            yzcode: "300380",
          },
          {
            code: 120112,
            name_cn: "津南区",
            name_en: "Jinnan",
            name_tw: "津南區",
            yzcode: "300350",
          },
          {
            code: 120113,
            name_cn: "北辰区",
            name_en: "Beichen",
            name_tw: "北辰區",
            yzcode: "300400",
          },
          {
            code: 120114,
            name_cn: "武清区",
            name_en: "Wuqing",
            name_tw: "武清區",
            yzcode: "301700",
          },
          {
            code: 120115,
            name_cn: "宝坻区",
            name_en: "Baodi",
            name_tw: "寶坻區",
            yzcode: "301800",
          },
          {
            code: 120116,
            name_cn: "滨海新区",
            name_en: "Binhaixinqu",
            name_tw: "濱海新區",
            yzcode: "300451",
          },
          {
            code: 120221,
            name_cn: "宁河县",
            name_en: "Ninghe",
            name_tw: "寧河縣",
            yzcode: "301500",
          },
          {
            code: 120223,
            name_cn: "静海县",
            name_en: "Jinghai",
            name_tw: "靜海縣",
            yzcode: "301600",
          },
          {
            code: 120225,
            name_cn: "蓟县",
            name_en: "Jixian",
            name_tw: "薊縣",
            yzcode: "301900",
          },
        ],
      },
    ],
  },
  {
    code: 130000,
    name_cn: "河北省",
    name_en: "Hebei",
    name_tw: "河北省",
    children: [
      {
        code: 130100,
        name_cn: "石家庄市",
        name_en: "Shijiazhuang",
        name_tw: "石家莊市",
        children: [
          {
            code: 130102,
            name_cn: "长安区",
            name_en: "Chang'an",
            name_tw: "長安區",
            yzcode: "050011",
          },
          {
            code: 130104,
            name_cn: "桥西区",
            name_en: "Qiaoxi",
            name_tw: "橋西區",
            yzcode: "050091",
          },
          {
            code: 130105,
            name_cn: "新华区",
            name_en: "Xinhua",
            name_tw: "新華區",
            yzcode: "050051",
          },
          {
            code: 130107,
            name_cn: "井陉矿区",
            name_en: "Jingxingkuangqu",
            name_tw: "井陘礦區",
            yzcode: "050100",
          },
          {
            code: 130108,
            name_cn: "裕华区",
            name_en: "Yuhua",
            name_tw: "裕華區",
            yzcode: "050031",
          },
          {
            code: 130109,
            name_cn: "藁城区",
            name_en: "Gaocheng",
            name_tw: "槁城區",
            yzcode: "052160",
          },
          {
            code: 130110,
            name_cn: "鹿泉区",
            name_en: "Luquan",
            name_tw: "鹿泉區",
            yzcode: "050200",
          },
          {
            code: 130111,
            name_cn: "栾城区",
            name_en: "Luancheng",
            name_tw: "欒城區",
            yzcode: "051430",
          },
          {
            code: 130121,
            name_cn: "井陉县",
            name_en: "Jingxing",
            name_tw: "井陘縣",
            yzcode: "050300",
          },
          {
            code: 130123,
            name_cn: "正定县",
            name_en: "Zhengding",
            name_tw: "正定縣",
            yzcode: "050800",
          },
          {
            code: 130125,
            name_cn: "行唐县",
            name_en: "Xingtang",
            name_tw: "行唐縣",
            yzcode: "050600",
          },
          {
            code: 130126,
            name_cn: "灵寿县",
            name_en: "Lingshou",
            name_tw: "靈壽縣",
            yzcode: "050500",
          },
          {
            code: 130127,
            name_cn: "高邑县",
            name_en: "Gaoyi",
            name_tw: "高邑縣",
            yzcode: "051330",
          },
          {
            code: 130128,
            name_cn: "深泽县",
            name_en: "Shenze",
            name_tw: "深澤縣",
            yzcode: "052560",
          },
          {
            code: 130129,
            name_cn: "赞皇县",
            name_en: "Zanhuang",
            name_tw: "贊皇縣",
            yzcode: "051230",
          },
          {
            code: 130130,
            name_cn: "无极县",
            name_en: "Wuji",
            name_tw: "無極縣",
            yzcode: "052460",
          },
          {
            code: 130131,
            name_cn: "平山县",
            name_en: "Pingshan",
            name_tw: "平山縣",
            yzcode: "050400",
          },
          {
            code: 130132,
            name_cn: "元氏县",
            name_en: "Yuanshi",
            name_tw: "元氏縣",
            yzcode: "051130",
          },
          {
            code: 130133,
            name_cn: "赵县",
            name_en: "Zhaoxian",
            name_tw: "趙縣",
            yzcode: "051530",
          },
          {
            code: 130181,
            name_cn: "辛集市",
            name_en: "Xinji",
            name_tw: "辛集市",
            yzcode: "052360",
          },
          {
            code: 130183,
            name_cn: "晋州市",
            name_en: "Jinzhou",
            name_tw: "晉州市",
            yzcode: "052260",
          },
          {
            code: 130184,
            name_cn: "新乐市",
            name_en: "Xinle",
            name_tw: "新樂市",
            yzcode: "050700",
          },
        ],
      },
      {
        code: 130200,
        name_cn: "唐山市",
        name_en: "Tangshan",
        name_tw: "唐山市",
        children: [
          {
            code: 130202,
            name_cn: "路南区",
            name_en: "Lunan",
            name_tw: "路南區",
            yzcode: "063000",
          },
          {
            code: 130203,
            name_cn: "路北区",
            name_en: "Lubei",
            name_tw: "路北區",
            yzcode: "063000",
          },
          {
            code: 130204,
            name_cn: "古冶区",
            name_en: "Guye",
            name_tw: "古冶區",
            yzcode: "063100",
          },
          {
            code: 130205,
            name_cn: "开平区",
            name_en: "Kaiping",
            name_tw: "開平區",
            yzcode: "063021",
          },
          {
            code: 130207,
            name_cn: "丰南区",
            name_en: "Fengnan",
            name_tw: "豐南區",
            yzcode: "063300",
          },
          {
            code: 130208,
            name_cn: "丰润区",
            name_en: "Fengrun",
            name_tw: "豐潤區",
            yzcode: "064000",
          },
          {
            code: 130209,
            name_cn: "曹妃甸区",
            name_en: "Caofeidian",
            name_tw: "曹妃甸區",
            yzcode: "063200",
          },
          {
            code: 130223,
            name_cn: "滦县",
            name_en: "Luanxian",
            name_tw: "灤縣",
            yzcode: "063700",
          },
          {
            code: 130224,
            name_cn: "滦南县",
            name_en: "Luannan",
            name_tw: "灤南縣",
            yzcode: "063500",
          },
          {
            code: 130225,
            name_cn: "乐亭县",
            name_en: "Laoting",
            name_tw: "樂亭縣",
            yzcode: "063600",
          },
          {
            code: 130227,
            name_cn: "迁西县",
            name_en: "Qianxi",
            name_tw: "遷西縣",
            yzcode: "064300",
          },
          {
            code: 130229,
            name_cn: "玉田县",
            name_en: "Yutian",
            name_tw: "玉田縣",
            yzcode: "064100",
          },
          {
            code: 130281,
            name_cn: "遵化市",
            name_en: "Zunhua",
            name_tw: "遵化市",
            yzcode: "064200",
          },
          {
            code: 130283,
            name_cn: "迁安市",
            name_en: "Qian'an",
            name_tw: "遷安市",
            yzcode: "064400",
          },
        ],
      },
      {
        code: 130300,
        name_cn: "秦皇岛市",
        name_en: "Qinhuangdao",
        name_tw: "秦皇島市",
        children: [
          {
            code: 130302,
            name_cn: "海港区",
            name_en: "Haigang",
            name_tw: "海港區",
            yzcode: "066000",
          },
          {
            code: 130303,
            name_cn: "山海关区",
            name_en: "Shanhaiguan",
            name_tw: "山海關區",
            yzcode: "066200",
          },
          {
            code: 130304,
            name_cn: "北戴河区",
            name_en: "Beidaihe",
            name_tw: "北戴河區",
            yzcode: "066100",
          },
          {
            code: 130321,
            name_cn: "青龙满族自治县",
            name_en: "Qinglong",
            name_tw: "青龍滿族自治縣",
            yzcode: "066500",
          },
          {
            code: 130322,
            name_cn: "昌黎县",
            name_en: "Changli",
            name_tw: "昌黎縣",
            yzcode: "066600",
          },
          {
            code: 130323,
            name_cn: "抚宁县",
            name_en: "Funing",
            name_tw: "撫寧縣",
            yzcode: "066300",
          },
          {
            code: 130324,
            name_cn: "卢龙县",
            name_en: "Lulong",
            name_tw: "盧龍縣",
            yzcode: "066400",
          },
        ],
      },
      {
        code: 130400,
        name_cn: "邯郸市",
        name_en: "Handan",
        name_tw: "邯鄲市",
        children: [
          {
            code: 130402,
            name_cn: "邯山区",
            name_en: "Hanshan",
            name_tw: "邯山區",
            yzcode: "056001",
          },
          {
            code: 130403,
            name_cn: "丛台区",
            name_en: "Congtai",
            name_tw: "叢台區",
            yzcode: "056002",
          },
          {
            code: 130404,
            name_cn: "复兴区",
            name_en: "Fuxing",
            name_tw: "復興區",
            yzcode: "056003",
          },
          {
            code: 130406,
            name_cn: "峰峰矿区",
            name_en: "Fengfengkuangqu",
            name_tw: "峰峰礦區",
            yzcode: "056200",
          },
          {
            code: 130421,
            name_cn: "邯郸县",
            name_en: "Handan",
            name_tw: "邯鄲縣",
            yzcode: "056101",
          },
          {
            code: 130423,
            name_cn: "临漳县",
            name_en: "Linzhang",
            name_tw: "臨漳縣",
            yzcode: "056600",
          },
          {
            code: 130424,
            name_cn: "成安县",
            name_en: "Cheng'an",
            name_tw: "成安縣",
            yzcode: "056700",
          },
          {
            code: 130425,
            name_cn: "大名县",
            name_en: "Daming",
            name_tw: "大名縣",
            yzcode: "056900",
          },
          {
            code: 130426,
            name_cn: "涉县",
            name_en: "Shexian",
            name_tw: "涉縣",
            yzcode: "056400",
          },
          {
            code: 130427,
            name_cn: "磁县",
            name_en: "Cixian",
            name_tw: "磁縣",
            yzcode: "056500",
          },
          {
            code: 130428,
            name_cn: "肥乡县",
            name_en: "Feixiang",
            name_tw: "肥鄉縣",
            yzcode: "057550",
          },
          {
            code: 130429,
            name_cn: "永年县",
            name_en: "Yongnian",
            name_tw: "永年縣",
            yzcode: "057150",
          },
          {
            code: 130430,
            name_cn: "邱县",
            name_en: "Qiuxian",
            name_tw: "邱縣",
            yzcode: "057450",
          },
          {
            code: 130431,
            name_cn: "鸡泽县",
            name_en: "Jize",
            name_tw: "雞澤縣",
            yzcode: "057350",
          },
          {
            code: 130432,
            name_cn: "广平县",
            name_en: "Guangping",
            name_tw: "廣平縣",
            yzcode: "057650",
          },
          {
            code: 130433,
            name_cn: "馆陶县",
            name_en: "Guantao",
            name_tw: "館陶縣",
            yzcode: "057750",
          },
          {
            code: 130434,
            name_cn: "魏县",
            name_en: "Weixian",
            name_tw: "魏縣",
            yzcode: "056800",
          },
          {
            code: 130435,
            name_cn: "曲周县",
            name_en: "Quzhou",
            name_tw: "曲周縣",
            yzcode: "057250",
          },
          {
            code: 130481,
            name_cn: "武安市",
            name_en: "Wu'an",
            name_tw: "武安市",
            yzcode: "056300",
          },
        ],
      },
      {
        code: 130500,
        name_cn: "邢台市",
        name_en: "Xingtai",
        name_tw: "邢台市",
        children: [
          {
            code: 130502,
            name_cn: "桥东区",
            name_en: "Qiaodong",
            name_tw: "橋東區",
            yzcode: "054001",
          },
          {
            code: 130503,
            name_cn: "桥西区",
            name_en: "Qiaoxi",
            name_tw: "橋西區",
            yzcode: "054000",
          },
          {
            code: 130521,
            name_cn: "邢台县",
            name_en: "Xingtai",
            name_tw: "邢台縣",
            yzcode: "054001",
          },
          {
            code: 130522,
            name_cn: "临城县",
            name_en: "Lincheng",
            name_tw: "臨城縣",
            yzcode: "054300",
          },
          {
            code: 130523,
            name_cn: "内丘县",
            name_en: "Neiqiu",
            name_tw: "內丘縣",
            yzcode: "054200",
          },
          {
            code: 130524,
            name_cn: "柏乡县",
            name_en: "Baixiang",
            name_tw: "柏鄉縣",
            yzcode: "055450",
          },
          {
            code: 130525,
            name_cn: "隆尧县",
            name_en: "Longyao",
            name_tw: "隆堯縣",
            yzcode: "055350",
          },
          {
            code: 130526,
            name_cn: "任县",
            name_en: "Renxian",
            name_tw: "任縣",
            yzcode: "055150",
          },
          {
            code: 130527,
            name_cn: "南和县",
            name_en: "Nanhe",
            name_tw: "南和縣",
            yzcode: "054400",
          },
          {
            code: 130528,
            name_cn: "宁晋县",
            name_en: "Ningjin",
            name_tw: "寧晉縣",
            yzcode: "055550",
          },
          {
            code: 130529,
            name_cn: "巨鹿县",
            name_en: "Julu",
            name_tw: "巨鹿縣",
            yzcode: "055250",
          },
          {
            code: 130530,
            name_cn: "新河县",
            name_en: "Xinhe",
            name_tw: "新河縣",
            yzcode: "055650",
          },
          {
            code: 130531,
            name_cn: "广宗县",
            name_en: "Guangzong",
            name_tw: "廣宗縣",
            yzcode: "054600",
          },
          {
            code: 130532,
            name_cn: "平乡县",
            name_en: "Pingxiang",
            name_tw: "平鄉縣",
            yzcode: "054500",
          },
          {
            code: 130533,
            name_cn: "威县",
            name_en: "Weixian",
            name_tw: "威縣",
            yzcode: "054700",
          },
          {
            code: 130534,
            name_cn: "清河县",
            name_en: "Qinghe",
            name_tw: "清河縣",
            yzcode: "054800",
          },
          {
            code: 130535,
            name_cn: "临西县",
            name_en: "Linxi",
            name_tw: "臨西縣",
            yzcode: "054900",
          },
          {
            code: 130581,
            name_cn: "南宫市",
            name_en: "Nangong",
            name_tw: "南宮市",
            yzcode: "055750",
          },
          {
            code: 130582,
            name_cn: "沙河市",
            name_en: "Shahe",
            name_tw: "沙河市",
            yzcode: "054100",
          },
        ],
      },
      {
        code: 130600,
        name_cn: "保定市",
        name_en: "Baoding",
        name_tw: "保定市",
        children: [
          {
            code: 130602,
            name_cn: "新市区",
            name_en: "Xinshi",
            name_tw: "新市區",
            yzcode: "071051",
          },
          {
            code: 130603,
            name_cn: "北市区",
            name_en: "Beishi",
            name_tw: "北市區",
            yzcode: "071000",
          },
          {
            code: 130604,
            name_cn: "南市区",
            name_en: "Nanshi",
            name_tw: "南市區",
            yzcode: "071001",
          },
          {
            code: 130621,
            name_cn: "满城县",
            name_en: "Mancheng",
            name_tw: "滿城縣",
            yzcode: "072150",
          },
          {
            code: 130622,
            name_cn: "清苑县",
            name_en: "Qingyuan",
            name_tw: "清苑縣",
            yzcode: "071100",
          },
          {
            code: 130623,
            name_cn: "涞水县",
            name_en: "Laishui",
            name_tw: "淶水縣",
            yzcode: "074100",
          },
          {
            code: 130624,
            name_cn: "阜平县",
            name_en: "Fuping",
            name_tw: "阜平縣",
            yzcode: "073200",
          },
          {
            code: 130625,
            name_cn: "徐水县",
            name_en: "Xushui",
            name_tw: "徐水縣",
            yzcode: "072550",
          },
          {
            code: 130626,
            name_cn: "定兴县",
            name_en: "Dingxing",
            name_tw: "定興縣",
            yzcode: "072650",
          },
          {
            code: 130627,
            name_cn: "唐县",
            name_en: "Tangxian",
            name_tw: "唐縣",
            yzcode: "072350",
          },
          {
            code: 130628,
            name_cn: "高阳县",
            name_en: "Gaoyang",
            name_tw: "高陽縣",
            yzcode: "071500",
          },
          {
            code: 130629,
            name_cn: "容城县",
            name_en: "Rongcheng",
            name_tw: "容城縣",
            yzcode: "071700",
          },
          {
            code: 130630,
            name_cn: "涞源县",
            name_en: "Laiyuan",
            name_tw: "淶源縣",
            yzcode: "074300",
          },
          {
            code: 130631,
            name_cn: "望都县",
            name_en: "Wangdu",
            name_tw: "望都縣",
            yzcode: "072450",
          },
          {
            code: 130632,
            name_cn: "安新县",
            name_en: "Anxin",
            name_tw: "安新縣",
            yzcode: "071600",
          },
          {
            code: 130633,
            name_cn: "易县",
            name_en: "Yixian",
            name_tw: "易縣",
            yzcode: "074200",
          },
          {
            code: 130634,
            name_cn: "曲阳县",
            name_en: "Quyang",
            name_tw: "曲陽縣",
            yzcode: "073100",
          },
          {
            code: 130635,
            name_cn: "蠡县",
            name_en: "Lixian",
            name_tw: "蠡縣",
            yzcode: "071400",
          },
          {
            code: 130636,
            name_cn: "顺平县",
            name_en: "Shunping",
            name_tw: "順平縣",
            yzcode: "072250",
          },
          {
            code: 130637,
            name_cn: "博野县",
            name_en: "Boye",
            name_tw: "博野縣",
            yzcode: "071300",
          },
          {
            code: 130638,
            name_cn: "雄县",
            name_en: "Xiongxian",
            name_tw: "雄縣",
            yzcode: "071800",
          },
          {
            code: 130681,
            name_cn: "涿州市",
            name_en: "Zhuozhou",
            name_tw: "涿州市",
            yzcode: "072750",
          },
          {
            code: 130682,
            name_cn: "定州市",
            name_en: "Dingzhou",
            name_tw: "定州市",
            yzcode: "073000",
          },
          {
            code: 130683,
            name_cn: "安国市",
            name_en: "Anguo",
            name_tw: "安國市",
            yzcode: "071200",
          },
          {
            code: 130684,
            name_cn: "高碑店市",
            name_en: "Gaobeidian",
            name_tw: "高碑店市",
            yzcode: "074000",
          },
        ],
      },
      {
        code: 130700,
        name_cn: "张家口市",
        name_en: "Zhangjiakou",
        name_tw: "張家口市",
        children: [
          {
            code: 130702,
            name_cn: "桥东区",
            name_en: "Qiaodong",
            name_tw: "橋東區",
            yzcode: "075000",
          },
          {
            code: 130703,
            name_cn: "桥西区",
            name_en: "Qiaoxi",
            name_tw: "橋西區",
            yzcode: "075061",
          },
          {
            code: 130705,
            name_cn: "宣化区",
            name_en: "Xuanhua",
            name_tw: "宣化區",
            yzcode: "075100",
          },
          {
            code: 130706,
            name_cn: "下花园区",
            name_en: "Xiahuayuan",
            name_tw: "下花園區",
            yzcode: "075300",
          },
          {
            code: 130721,
            name_cn: "宣化县",
            name_en: "Xuanhua",
            name_tw: "宣化縣",
            yzcode: "075100",
          },
          {
            code: 130722,
            name_cn: "张北县",
            name_en: "Zhangbei",
            name_tw: "張北縣",
            yzcode: "076450",
          },
          {
            code: 130723,
            name_cn: "康保县",
            name_en: "Kangbao",
            name_tw: "康保縣",
            yzcode: "076650",
          },
          {
            code: 130724,
            name_cn: "沽源县",
            name_en: "Guyuan",
            name_tw: "沽源縣",
            yzcode: "076550",
          },
          {
            code: 130725,
            name_cn: "尚义县",
            name_en: "Shangyi",
            name_tw: "尚義縣",
            yzcode: "076750",
          },
          {
            code: 130726,
            name_cn: "蔚县",
            name_en: "Yuxian",
            name_tw: "蔚縣",
            yzcode: "075700",
          },
          {
            code: 130727,
            name_cn: "阳原县",
            name_en: "Yangyuan",
            name_tw: "陽原縣",
            yzcode: "075800",
          },
          {
            code: 130728,
            name_cn: "怀安县",
            name_en: "Huai'an",
            name_tw: "懷安縣",
            yzcode: "076150",
          },
          {
            code: 130729,
            name_cn: "万全县",
            name_en: "Wanquan",
            name_tw: "萬全縣",
            yzcode: "076250",
          },
          {
            code: 130730,
            name_cn: "怀来县",
            name_en: "Huailai",
            name_tw: "懷來縣",
            yzcode: "075400",
          },
          {
            code: 130731,
            name_cn: "涿鹿县",
            name_en: "Zhuolu",
            name_tw: "涿鹿縣",
            yzcode: "075600",
          },
          {
            code: 130732,
            name_cn: "赤城县",
            name_en: "Chicheng",
            name_tw: "赤城縣",
            yzcode: "075500",
          },
          {
            code: 130733,
            name_cn: "崇礼县",
            name_en: "Chongli",
            name_tw: "崇禮縣",
            yzcode: "076350",
          },
        ],
      },
      {
        code: 130800,
        name_cn: "承德市",
        name_en: "Chengde",
        name_tw: "承德市",
        children: [
          {
            code: 130802,
            name_cn: "双桥区",
            name_en: "Shuangqiao",
            name_tw: "雙橋區",
            yzcode: "067000",
          },
          {
            code: 130803,
            name_cn: "双滦区",
            name_en: "Shuangluan",
            name_tw: "雙灤區",
            yzcode: "067001",
          },
          {
            code: 130804,
            name_cn: "鹰手营子矿区",
            name_en: "Yingshouyingzikuangqu",
            name_tw: "鷹手營子礦區",
            yzcode: "067200",
          },
          {
            code: 130821,
            name_cn: "承德县",
            name_en: "Chengde",
            name_tw: "承德縣",
            yzcode: "067400",
          },
          {
            code: 130822,
            name_cn: "兴隆县",
            name_en: "Xinglong",
            name_tw: "興隆縣",
            yzcode: "067300",
          },
          {
            code: 130823,
            name_cn: "平泉县",
            name_en: "Pingquan",
            name_tw: "平泉縣",
            yzcode: "067500",
          },
          {
            code: 130824,
            name_cn: "滦平县",
            name_en: "Luanping",
            name_tw: "灤平縣",
            yzcode: "068250",
          },
          {
            code: 130825,
            name_cn: "隆化县",
            name_en: "Longhua",
            name_tw: "隆化縣",
            yzcode: "068150",
          },
          {
            code: 130826,
            name_cn: "丰宁满族自治县",
            name_en: "Fengning",
            name_tw: "豐寧滿族自治縣",
            yzcode: "068350",
          },
          {
            code: 130827,
            name_cn: "宽城满族自治县",
            name_en: "Kuancheng",
            name_tw: "寬城滿族自治縣",
            yzcode: "067600",
          },
          {
            code: 130828,
            name_cn: "围场满族蒙古族自治县",
            name_en: "Weichang",
            name_tw: "圍場滿族蒙古族自治縣",
            yzcode: "068450",
          },
        ],
      },
      {
        code: 130900,
        name_cn: "沧州市",
        name_en: "Cangzhou",
        name_tw: "滄州市",
        children: [
          {
            code: 130902,
            name_cn: "新华区",
            name_en: "Xinhua",
            name_tw: "新華區",
            yzcode: "061000",
          },
          {
            code: 130903,
            name_cn: "运河区",
            name_en: "Yunhe",
            name_tw: "運河區",
            yzcode: "061001",
          },
          {
            code: 130921,
            name_cn: "沧县",
            name_en: "Cangxian",
            name_tw: "滄縣",
            yzcode: "061000",
          },
          {
            code: 130922,
            name_cn: "青县",
            name_en: "Qingxian",
            name_tw: "青縣",
            yzcode: "062650",
          },
          {
            code: 130923,
            name_cn: "东光县",
            name_en: "Dongguang",
            name_tw: "東光縣",
            yzcode: "061600",
          },
          {
            code: 130924,
            name_cn: "海兴县",
            name_en: "Haixing",
            name_tw: "海興縣",
            yzcode: "061200",
          },
          {
            code: 130925,
            name_cn: "盐山县",
            name_en: "Yanshan",
            name_tw: "鹽山縣",
            yzcode: "061300",
          },
          {
            code: 130926,
            name_cn: "肃宁县",
            name_en: "Suning",
            name_tw: "肅寧縣",
            yzcode: "062350",
          },
          {
            code: 130927,
            name_cn: "南皮县",
            name_en: "Nanpi",
            name_tw: "南皮縣",
            yzcode: "061500",
          },
          {
            code: 130928,
            name_cn: "吴桥县",
            name_en: "Wuqiao",
            name_tw: "吳橋縣",
            yzcode: "061800",
          },
          {
            code: 130929,
            name_cn: "献县",
            name_en: "Xianxian",
            name_tw: "獻縣",
            yzcode: "062250",
          },
          {
            code: 130930,
            name_cn: "孟村回族自治县",
            name_en: "Mengcun",
            name_tw: "孟村回族自治縣",
            yzcode: "061400",
          },
          {
            code: 130981,
            name_cn: "泊头市",
            name_en: "Botou",
            name_tw: "泊頭市",
            yzcode: "062150",
          },
          {
            code: 130982,
            name_cn: "任丘市",
            name_en: "Renqiu",
            name_tw: "任丘市",
            yzcode: "062550",
          },
          {
            code: 130983,
            name_cn: "黄骅市",
            name_en: "Huanghua",
            name_tw: "黃驊市",
            yzcode: "061100",
          },
          {
            code: 130984,
            name_cn: "河间市",
            name_en: "Hejian",
            name_tw: "河間市",
            yzcode: "062450",
          },
        ],
      },
      {
        code: 131000,
        name_cn: "廊坊市",
        name_en: "Langfang",
        name_tw: "廊坊市",
        children: [
          {
            code: 131002,
            name_cn: "安次区",
            name_en: "Anci",
            name_tw: "安次區",
            yzcode: "065000",
          },
          {
            code: 131003,
            name_cn: "广阳区",
            name_en: "Guangyang",
            name_tw: "廣陽區",
            yzcode: "065000",
          },
          {
            code: 131022,
            name_cn: "固安县",
            name_en: "Gu'an",
            name_tw: "固安縣",
            yzcode: "065500",
          },
          {
            code: 131023,
            name_cn: "永清县",
            name_en: "Yongqing",
            name_tw: "永清縣",
            yzcode: "065600",
          },
          {
            code: 131024,
            name_cn: "香河县",
            name_en: "Xianghe",
            name_tw: "香河縣",
            yzcode: "065400",
          },
          {
            code: 131025,
            name_cn: "大城县",
            name_en: "Daicheng",
            name_tw: "大城縣",
            yzcode: "065900",
          },
          {
            code: 131026,
            name_cn: "文安县",
            name_en: "Wen'an",
            name_tw: "文安縣",
            yzcode: "065800",
          },
          {
            code: 131028,
            name_cn: "大厂回族自治县",
            name_en: "Dachang",
            name_tw: "大廠回族自治縣",
            yzcode: "065300",
          },
          {
            code: 131081,
            name_cn: "霸州市",
            name_en: "Bazhou",
            name_tw: "霸州市",
            yzcode: "065700",
          },
          {
            code: 131082,
            name_cn: "三河市",
            name_en: "Sanhe",
            name_tw: "三河市",
            yzcode: "065200",
          },
        ],
      },
      {
        code: 131100,
        name_cn: "衡水市",
        name_en: "Hengshui",
        name_tw: "衡水市",
        children: [
          {
            code: 131102,
            name_cn: "桃城区",
            name_en: "Taocheng",
            name_tw: "桃城區",
            yzcode: "053000",
          },
          {
            code: 131121,
            name_cn: "枣强县",
            name_en: "Zaoqiang",
            name_tw: "棗強縣",
            yzcode: "053100",
          },
          {
            code: 131122,
            name_cn: "武邑县",
            name_en: "Wuyi",
            name_tw: "武邑縣",
            yzcode: "053400",
          },
          {
            code: 131123,
            name_cn: "武强县",
            name_en: "Wuqiang",
            name_tw: "武強縣",
            yzcode: "053300",
          },
          {
            code: 131124,
            name_cn: "饶阳县",
            name_en: "Raoyang",
            name_tw: "饒陽縣",
            yzcode: "053900",
          },
          {
            code: 131125,
            name_cn: "安平县",
            name_en: "Anping",
            name_tw: "安平縣",
            yzcode: "053600",
          },
          {
            code: 131126,
            name_cn: "故城县",
            name_en: "Gucheng",
            name_tw: "故城縣",
            yzcode: "053800",
          },
          {
            code: 131127,
            name_cn: "景县",
            name_en: "Jingxian",
            name_tw: "景縣",
            yzcode: "053500",
          },
          {
            code: 131128,
            name_cn: "阜城县",
            name_en: "Fucheng",
            name_tw: "阜城縣",
            yzcode: "053700",
          },
          {
            code: 131181,
            name_cn: "冀州市",
            name_en: "Jizhou",
            name_tw: "冀州市",
            yzcode: "053200",
          },
          {
            code: 131182,
            name_cn: "深州市",
            name_en: "Shenzhou",
            name_tw: "深州市",
            yzcode: "053800",
          },
        ],
      },
    ],
  },
  {
    code: 140000,
    name_cn: "山西省",
    name_en: "Shanxi",
    name_tw: "山西省",
    children: [
      {
        code: 140100,
        name_cn: "太原市",
        name_en: "Taiyuan",
        name_tw: "太原市",
        children: [
          {
            code: 140105,
            name_cn: "小店区",
            name_en: "Xiaodian",
            name_tw: "小店區",
            yzcode: "030032",
          },
          {
            code: 140106,
            name_cn: "迎泽区",
            name_en: "Yingze",
            name_tw: "迎澤區",
            yzcode: "030002",
          },
          {
            code: 140107,
            name_cn: "杏花岭区",
            name_en: "Xinghualing",
            name_tw: "杏花嶺區",
            yzcode: "030009",
          },
          {
            code: 140108,
            name_cn: "尖草坪区",
            name_en: "Jiancaoping",
            name_tw: "尖草坪區",
            yzcode: "030023",
          },
          {
            code: 140109,
            name_cn: "万柏林区",
            name_en: "Wanbailin",
            name_tw: "萬柏林區",
            yzcode: "030024",
          },
          {
            code: 140110,
            name_cn: "晋源区",
            name_en: "Jinyuan",
            name_tw: "晉源區",
            yzcode: "030025",
          },
          {
            code: 140121,
            name_cn: "清徐县",
            name_en: "Qingxu",
            name_tw: "清徐縣",
            yzcode: "030400",
          },
          {
            code: 140122,
            name_cn: "阳曲县",
            name_en: "Yangqu",
            name_tw: "陽曲縣",
            yzcode: "030100",
          },
          {
            code: 140123,
            name_cn: "娄烦县",
            name_en: "Loufan",
            name_tw: "婁煩縣",
            yzcode: "030300",
          },
          {
            code: 140181,
            name_cn: "古交市",
            name_en: "Gujiao",
            name_tw: "古交市",
            yzcode: "030200",
          },
        ],
      },
      {
        code: 140200,
        name_cn: "大同市",
        name_en: "Datong",
        name_tw: "大同市",
        children: [
          {
            code: 140202,
            name_cn: "城区",
            name_en: "Chengqu",
            name_tw: "城區",
            yzcode: "037008",
          },
          {
            code: 140203,
            name_cn: "矿区",
            name_en: "Kuangqu",
            name_tw: "礦區",
            yzcode: "037003",
          },
          {
            code: 140211,
            name_cn: "南郊区",
            name_en: "Nanjiao",
            name_tw: "南郊區",
            yzcode: "037001",
          },
          {
            code: 140212,
            name_cn: "新荣区",
            name_en: "Xinrong",
            name_tw: "新榮區",
            yzcode: "037002",
          },
          {
            code: 140221,
            name_cn: "阳高县",
            name_en: "Yanggao",
            name_tw: "陽高縣",
            yzcode: "038100",
          },
          {
            code: 140222,
            name_cn: "天镇县",
            name_en: "Tianzhen",
            name_tw: "天鎮縣",
            yzcode: "038200",
          },
          {
            code: 140223,
            name_cn: "广灵县",
            name_en: "Guangling",
            name_tw: "廣靈縣",
            yzcode: "037500",
          },
          {
            code: 140224,
            name_cn: "灵丘县",
            name_en: "Lingqiu",
            name_tw: "靈丘縣",
            yzcode: "034400",
          },
          {
            code: 140225,
            name_cn: "浑源县",
            name_en: "Hunyuan",
            name_tw: "渾源縣",
            yzcode: "037400",
          },
          {
            code: 140226,
            name_cn: "左云县",
            name_en: "Zuoyun",
            name_tw: "左雲縣",
            yzcode: "037100",
          },
          {
            code: 140227,
            name_cn: "大同县",
            name_en: "Datong",
            name_tw: "大同縣",
            yzcode: "037300",
          },
        ],
      },
      {
        code: 140300,
        name_cn: "阳泉市",
        name_en: "Yangquan",
        name_tw: "陽泉市",
        children: [
          {
            code: 140302,
            name_cn: "城区",
            name_en: "Chengqu",
            name_tw: "城區",
            yzcode: "045000",
          },
          {
            code: 140303,
            name_cn: "矿区",
            name_en: "Kuangqu",
            name_tw: "礦區",
            yzcode: "045000",
          },
          {
            code: 140311,
            name_cn: "郊区",
            name_en: "Jiaoqu",
            name_tw: "郊區",
            yzcode: "045011",
          },
          {
            code: 140321,
            name_cn: "平定县",
            name_en: "Pingding",
            name_tw: "平定縣",
            yzcode: "045200",
          },
          {
            code: 140322,
            name_cn: "盂县",
            name_en: "Yuxian",
            name_tw: "盂縣",
            yzcode: "045100",
          },
        ],
      },
      {
        code: 140400,
        name_cn: "长治市",
        name_en: "Changzhi",
        name_tw: "長治市",
        children: [
          {
            code: 140402,
            name_cn: "城区",
            name_en: "Chengqu",
            name_tw: "城區",
            yzcode: "046011",
          },
          {
            code: 140411,
            name_cn: "郊区",
            name_en: "Jiaoqu",
            name_tw: "郊區",
            yzcode: "046011",
          },
          {
            code: 140421,
            name_cn: "长治县",
            name_en: "Changzhi",
            name_tw: "長治縣",
            yzcode: "047100",
          },
          {
            code: 140423,
            name_cn: "襄垣县",
            name_en: "Xiangyuan",
            name_tw: "襄垣縣",
            yzcode: "046200",
          },
          {
            code: 140424,
            name_cn: "屯留县",
            name_en: "Tunliu",
            name_tw: "屯留縣",
            yzcode: "046100",
          },
          {
            code: 140425,
            name_cn: "平顺县",
            name_en: "Pingshun",
            name_tw: "平順縣",
            yzcode: "047400",
          },
          {
            code: 140426,
            name_cn: "黎城县",
            name_en: "Licheng",
            name_tw: "黎城縣",
            yzcode: "047600",
          },
          {
            code: 140427,
            name_cn: "壶关县",
            name_en: "Huguan",
            name_tw: "壺關縣",
            yzcode: "047300",
          },
          {
            code: 140428,
            name_cn: "长子县",
            name_en: "Zhangzi",
            name_tw: "長子縣",
            yzcode: "046600",
          },
          {
            code: 140429,
            name_cn: "武乡县",
            name_en: "Wuxiang",
            name_tw: "武鄉縣",
            yzcode: "046300",
          },
          {
            code: 140430,
            name_cn: "沁县",
            name_en: "Qinxian",
            name_tw: "沁縣",
            yzcode: "046400",
          },
          {
            code: 140431,
            name_cn: "沁源县",
            name_en: "Qinyuan",
            name_tw: "沁源縣",
            yzcode: "046500",
          },
          {
            code: 140481,
            name_cn: "潞城市",
            name_en: "Lucheng",
            name_tw: "潞城市",
            yzcode: "047500",
          },
        ],
      },
      {
        code: 140500,
        name_cn: "晋城市",
        name_en: "Jincheng",
        name_tw: "晉城市",
        children: [
          {
            code: 140502,
            name_cn: "城区",
            name_en: "Chengqu",
            name_tw: "城區",
            yzcode: "048000",
          },
          {
            code: 140521,
            name_cn: "沁水县",
            name_en: "Qinshui",
            name_tw: "沁水縣",
            yzcode: "048200",
          },
          {
            code: 140522,
            name_cn: "阳城县",
            name_en: "Yangcheng",
            name_tw: "陽城縣",
            yzcode: "048100",
          },
          {
            code: 140524,
            name_cn: "陵川县",
            name_en: "Lingchuan",
            name_tw: "陵川縣",
            yzcode: "048300",
          },
          {
            code: 140525,
            name_cn: "泽州县",
            name_en: "Zezhou",
            name_tw: "澤州縣",
            yzcode: "048012",
          },
          {
            code: 140581,
            name_cn: "高平市",
            name_en: "Gaoping",
            name_tw: "高平市",
            yzcode: "048400",
          },
        ],
      },
      {
        code: 140600,
        name_cn: "朔州市",
        name_en: "Shuozhou",
        name_tw: "朔州市",
        children: [
          {
            code: 140602,
            name_cn: "朔城区",
            name_en: "Shuocheng",
            name_tw: "朔城區",
            yzcode: "036000",
          },
          {
            code: 140603,
            name_cn: "平鲁区",
            name_en: "Pinglu",
            name_tw: "平魯區",
            yzcode: "038600",
          },
          {
            code: 140621,
            name_cn: "山阴县",
            name_en: "Shanyin",
            name_tw: "山陰縣",
            yzcode: "036900",
          },
          {
            code: 140622,
            name_cn: "应县",
            name_en: "Yingxian",
            name_tw: "應縣",
            yzcode: "037600",
          },
          {
            code: 140623,
            name_cn: "右玉县",
            name_en: "Youyu",
            name_tw: "右玉縣",
            yzcode: "037200",
          },
          {
            code: 140624,
            name_cn: "怀仁县",
            name_en: "Huairen",
            name_tw: "懷仁縣",
            yzcode: "038300",
          },
        ],
      },
      {
        code: 140700,
        name_cn: "晋中市",
        name_en: "Jinzhong",
        name_tw: "晉中市",
        children: [
          {
            code: 140702,
            name_cn: "榆次区",
            name_en: "Yuci",
            name_tw: "榆次區",
            yzcode: "030600",
          },
          {
            code: 140721,
            name_cn: "榆社县",
            name_en: "Yushe",
            name_tw: "榆社縣",
            yzcode: "031800",
          },
          {
            code: 140722,
            name_cn: "左权县",
            name_en: "Zuoquan",
            name_tw: "左權縣",
            yzcode: "032600",
          },
          {
            code: 140723,
            name_cn: "和顺县",
            name_en: "Heshun",
            name_tw: "和順縣",
            yzcode: "032700",
          },
          {
            code: 140724,
            name_cn: "昔阳县",
            name_en: "Xiyang",
            name_tw: "昔陽縣",
            yzcode: "045300",
          },
          {
            code: 140725,
            name_cn: "寿阳县",
            name_en: "Shouyang",
            name_tw: "壽陽縣",
            yzcode: "045400",
          },
          {
            code: 140726,
            name_cn: "太谷县",
            name_en: "Taigu",
            name_tw: "太谷縣",
            yzcode: "030800",
          },
          {
            code: 140727,
            name_cn: "祁县",
            name_en: "Qixian",
            name_tw: "祁縣",
            yzcode: "030900",
          },
          {
            code: 140728,
            name_cn: "平遥县",
            name_en: "Pingyao",
            name_tw: "平遙縣",
            yzcode: "031100",
          },
          {
            code: 140729,
            name_cn: "灵石县",
            name_en: "Lingshi",
            name_tw: "靈石縣",
            yzcode: "031300",
          },
          {
            code: 140781,
            name_cn: "介休市",
            name_en: "Jiexiu",
            name_tw: "介休市",
            yzcode: "032000",
          },
        ],
      },
      {
        code: 140800,
        name_cn: "运城市",
        name_en: "Yuncheng",
        name_tw: "運城市",
        children: [
          {
            code: 140802,
            name_cn: "盐湖区",
            name_en: "Yanhu",
            name_tw: "鹽湖區",
            yzcode: "044000",
          },
          {
            code: 140821,
            name_cn: "临猗县",
            name_en: "Linyi",
            name_tw: "臨猗縣",
            yzcode: "044100",
          },
          {
            code: 140822,
            name_cn: "万荣县",
            name_en: "Wanrong",
            name_tw: "萬榮縣",
            yzcode: "044200",
          },
          {
            code: 140823,
            name_cn: "闻喜县",
            name_en: "Wenxi",
            name_tw: "聞喜縣",
            yzcode: "043800",
          },
          {
            code: 140824,
            name_cn: "稷山县",
            name_en: "Jishan",
            name_tw: "稷山縣",
            yzcode: "043200",
          },
          {
            code: 140825,
            name_cn: "新绛县",
            name_en: "Xinjiang",
            name_tw: "新絳縣",
            yzcode: "043100",
          },
          {
            code: 140826,
            name_cn: "绛县",
            name_en: "Jiangxian",
            name_tw: "絳縣",
            yzcode: "043600",
          },
          {
            code: 140827,
            name_cn: "垣曲县",
            name_en: "Yuanqu",
            name_tw: "垣曲縣",
            yzcode: "043700",
          },
          {
            code: 140828,
            name_cn: "夏县",
            name_en: "Xiaxian",
            name_tw: "夏縣",
            yzcode: "044400",
          },
          {
            code: 140829,
            name_cn: "平陆县",
            name_en: "Pinglu",
            name_tw: "平陸縣",
            yzcode: "044300",
          },
          {
            code: 140830,
            name_cn: "芮城县",
            name_en: "Ruicheng",
            name_tw: "芮城縣",
            yzcode: "044600",
          },
          {
            code: 140881,
            name_cn: "永济市",
            name_en: "Yongji",
            name_tw: "永濟市",
            yzcode: "044500",
          },
          {
            code: 140882,
            name_cn: "河津市",
            name_en: "Hejin",
            name_tw: "河津市",
            yzcode: "043300",
          },
        ],
      },
      {
        code: 140900,
        name_cn: "忻州市",
        name_en: "Xinzhou",
        name_tw: "忻州市",
        children: [
          {
            code: 140902,
            name_cn: "忻府区",
            name_en: "Xinfu",
            name_tw: "忻府區",
            yzcode: "034000",
          },
          {
            code: 140921,
            name_cn: "定襄县",
            name_en: "Dingxiang",
            name_tw: "定襄縣",
            yzcode: "035400",
          },
          {
            code: 140922,
            name_cn: "五台县",
            name_en: "Wutai",
            name_tw: "五台縣",
            yzcode: "035500",
          },
          {
            code: 140923,
            name_cn: "代县",
            name_en: "Daixian",
            name_tw: "代縣",
            yzcode: "034200",
          },
          {
            code: 140924,
            name_cn: "繁峙县",
            name_en: "Fanshi",
            name_tw: "繁峙縣",
            yzcode: "034300",
          },
          {
            code: 140925,
            name_cn: "宁武县",
            name_en: "Ningwu",
            name_tw: "寧武縣",
            yzcode: "036700",
          },
          {
            code: 140926,
            name_cn: "静乐县",
            name_en: "Jingle",
            name_tw: "靜樂縣",
            yzcode: "035100",
          },
          {
            code: 140927,
            name_cn: "神池县",
            name_en: "Shenchi",
            name_tw: "神池縣",
            yzcode: "036100",
          },
          {
            code: 140928,
            name_cn: "五寨县",
            name_en: "Wuzhai",
            name_tw: "五寨縣",
            yzcode: "036200",
          },
          {
            code: 140929,
            name_cn: "岢岚县",
            name_en: "Kelan",
            name_tw: "岢嵐縣",
            yzcode: "036300",
          },
          {
            code: 140930,
            name_cn: "河曲县",
            name_en: "Hequ",
            name_tw: "河曲縣",
            yzcode: "036500",
          },
          {
            code: 140931,
            name_cn: "保德县",
            name_en: "Baode",
            name_tw: "保德縣",
            yzcode: "036600",
          },
          {
            code: 140932,
            name_cn: "偏关县",
            name_en: "Pianguan",
            name_tw: "偏關縣",
            yzcode: "036400",
          },
          {
            code: 140981,
            name_cn: "原平市",
            name_en: "Yuanping",
            name_tw: "原平市",
            yzcode: "034100",
          },
        ],
      },
      {
        code: 141000,
        name_cn: "临汾市",
        name_en: "Linfen",
        name_tw: "臨汾市",
        children: [
          {
            code: 141002,
            name_cn: "尧都区",
            name_en: "Yaodu",
            name_tw: "堯都區",
            yzcode: "041000",
          },
          {
            code: 141021,
            name_cn: "曲沃县",
            name_en: "Quwo",
            name_tw: "曲沃縣",
            yzcode: "043400",
          },
          {
            code: 141022,
            name_cn: "翼城县",
            name_en: "Yicheng",
            name_tw: "翼城縣",
            yzcode: "043500",
          },
          {
            code: 141023,
            name_cn: "襄汾县",
            name_en: "Xiangfen",
            name_tw: "襄汾縣",
            yzcode: "041500",
          },
          {
            code: 141024,
            name_cn: "洪洞县",
            name_en: "Hongtong",
            name_tw: "洪洞縣",
            yzcode: "041600",
          },
          {
            code: 141025,
            name_cn: "古县",
            name_en: "Guxian",
            name_tw: "古縣",
            yzcode: "042400",
          },
          {
            code: 141026,
            name_cn: "安泽县",
            name_en: "Anze",
            name_tw: "安澤縣",
            yzcode: "042500",
          },
          {
            code: 141027,
            name_cn: "浮山县",
            name_en: "Fushan",
            name_tw: "浮山縣",
            yzcode: "042600",
          },
          {
            code: 141028,
            name_cn: "吉县",
            name_en: "Jixian",
            name_tw: "吉縣",
            yzcode: "042200",
          },
          {
            code: 141029,
            name_cn: "乡宁县",
            name_en: "Xiangning",
            name_tw: "鄉寧縣",
            yzcode: "042100",
          },
          {
            code: 141030,
            name_cn: "大宁县",
            name_en: "Daning",
            name_tw: "大寧縣",
            yzcode: "042300",
          },
          {
            code: 141031,
            name_cn: "隰县",
            name_en: "Xixian",
            name_tw: "隰縣",
            yzcode: "041300",
          },
          {
            code: 141032,
            name_cn: "永和县",
            name_en: "Yonghe",
            name_tw: "永和縣",
            yzcode: "041400",
          },
          {
            code: 141033,
            name_cn: "蒲县",
            name_en: "Puxian",
            name_tw: "蒲縣",
            yzcode: "041200",
          },
          {
            code: 141034,
            name_cn: "汾西县",
            name_en: "Fenxi",
            name_tw: "汾西縣",
            yzcode: "031500",
          },
          {
            code: 141081,
            name_cn: "侯马市",
            name_en: "Houma",
            name_tw: "侯馬市",
            yzcode: "043000",
          },
          {
            code: 141082,
            name_cn: "霍州市",
            name_en: "Huozhou",
            name_tw: "霍州市",
            yzcode: "031400",
          },
        ],
      },
      {
        code: 141100,
        name_cn: "吕梁市",
        name_en: "Lvliang",
        name_tw: "呂梁市",
        children: [
          {
            code: 141102,
            name_cn: "离石区",
            name_en: "Lishi",
            name_tw: "離石區",
            yzcode: "033000",
          },
          {
            code: 141121,
            name_cn: "文水县",
            name_en: "Wenshui",
            name_tw: "文水縣",
            yzcode: "032100",
          },
          {
            code: 141122,
            name_cn: "交城县",
            name_en: "Jiaocheng",
            name_tw: "交城縣",
            yzcode: "030500",
          },
          {
            code: 141123,
            name_cn: "兴县",
            name_en: "Xingxian",
            name_tw: "興縣",
            yzcode: "033600",
          },
          {
            code: 141124,
            name_cn: "临县",
            name_en: "Linxian",
            name_tw: "臨縣",
            yzcode: "033200",
          },
          {
            code: 141125,
            name_cn: "柳林县",
            name_en: "Liulin",
            name_tw: "柳林縣",
            yzcode: "033300",
          },
          {
            code: 141126,
            name_cn: "石楼县",
            name_en: "Shilou",
            name_tw: "石樓縣",
            yzcode: "032500",
          },
          {
            code: 141127,
            name_cn: "岚县",
            name_en: "Lanxian",
            name_tw: "嵐縣",
            yzcode: "033500",
          },
          {
            code: 141128,
            name_cn: "方山县",
            name_en: "Fangshan",
            name_tw: "方山縣",
            yzcode: "033100",
          },
          {
            code: 141129,
            name_cn: "中阳县",
            name_en: "Zhongyang",
            name_tw: "中陽縣",
            yzcode: "033400",
          },
          {
            code: 141130,
            name_cn: "交口县",
            name_en: "Jiaokou",
            name_tw: "交口縣",
            yzcode: "032400",
          },
          {
            code: 141181,
            name_cn: "孝义市",
            name_en: "Xiaoyi",
            name_tw: "孝義市",
            yzcode: "032300",
          },
          {
            code: 141182,
            name_cn: "汾阳市",
            name_en: "Fenyang",
            name_tw: "汾陽市",
            yzcode: "032200",
          },
        ],
      },
    ],
  },
  {
    code: 150000,
    name_cn: "内蒙古自治区",
    name_en: "Inner Mongolia",
    name_tw: "內蒙古自治區",
    children: [
      {
        code: 150100,
        name_cn: "呼和浩特市",
        name_en: "Hohhot",
        name_tw: "呼和浩特市",
        children: [
          {
            code: 150102,
            name_cn: "新城区",
            name_en: "Xincheng",
            name_tw: "新城區",
            yzcode: "010050",
          },
          {
            code: 150103,
            name_cn: "回民区",
            name_en: "Huimin",
            name_tw: "回民區",
            yzcode: "010030",
          },
          {
            code: 150104,
            name_cn: "玉泉区",
            name_en: "Yuquan",
            name_tw: "玉泉區",
            yzcode: "010020",
          },
          {
            code: 150105,
            name_cn: "赛罕区",
            name_en: "Saihan",
            name_tw: "賽罕區",
            yzcode: "010020",
          },
          {
            code: 150121,
            name_cn: "土默特左旗",
            name_en: "Tumotezuoqi",
            name_tw: "土默特左旗",
            yzcode: "010100",
          },
          {
            code: 150122,
            name_cn: "托克托县",
            name_en: "Tuoketuo",
            name_tw: "托克托縣",
            yzcode: "010200",
          },
          {
            code: 150123,
            name_cn: "和林格尔县",
            name_en: "Helingeer",
            name_tw: "和林格爾縣",
            yzcode: "011500",
          },
          {
            code: 150124,
            name_cn: "清水河县",
            name_en: "Qingshuihe",
            name_tw: "清水河縣",
            yzcode: "011600",
          },
          {
            code: 150125,
            name_cn: "武川县",
            name_en: "Wuchuan",
            name_tw: "武川縣",
            yzcode: "011700",
          },
        ],
      },
      {
        code: 150200,
        name_cn: "包头市",
        name_en: "Baotou",
        name_tw: "包頭市",
        children: [
          {
            code: 150202,
            name_cn: "东河区",
            name_en: "Donghe",
            name_tw: "東河區",
            yzcode: "014040",
          },
          {
            code: 150203,
            name_cn: "昆都仑区",
            name_en: "Kundulun",
            name_tw: "昆都侖區",
            yzcode: "014010",
          },
          {
            code: 150204,
            name_cn: "青山区",
            name_en: "Qingshan",
            name_tw: "青山區",
            yzcode: "014030",
          },
          {
            code: 150205,
            name_cn: "石拐区",
            name_en: "Shiguai",
            name_tw: "石拐區",
            yzcode: "014070",
          },
          {
            code: 150206,
            name_cn: "白云鄂博矿区",
            name_en: "Baiyunebokuangqu",
            name_tw: "白雲鄂博礦區",
            yzcode: "014080",
          },
          {
            code: 150207,
            name_cn: "九原区",
            name_en: "Jiuyuan",
            name_tw: "九原區",
            yzcode: "014060",
          },
          {
            code: 150221,
            name_cn: "土默特右旗",
            name_en: "Tumoteyouqi",
            name_tw: "土默特右旗",
            yzcode: "014100",
          },
          {
            code: 150222,
            name_cn: "固阳县",
            name_en: "Guyang",
            name_tw: "固陽縣",
            yzcode: "014200",
          },
          {
            code: 150223,
            name_cn: "达尔罕茂明安联合旗",
            name_en: "Damaoqi",
            name_tw: "達爾罕茂明安聯合旗",
            yzcode: "014500",
          },
        ],
      },
      {
        code: 150300,
        name_cn: "乌海市",
        name_en: "Wuhai",
        name_tw: "烏海市",
        children: [
          {
            code: 150302,
            name_cn: "海勃湾区",
            name_en: "Haibowan",
            name_tw: "海勃灣區",
            yzcode: "016000",
          },
          {
            code: 150303,
            name_cn: "海南区",
            name_en: "Hainan",
            name_tw: "海南區",
            yzcode: "016030",
          },
          {
            code: 150304,
            name_cn: "乌达区",
            name_en: "Wuda",
            name_tw: "烏達區",
            yzcode: "016040",
          },
        ],
      },
      {
        code: 150400,
        name_cn: "赤峰市",
        name_en: "Chifeng",
        name_tw: "赤峰市",
        children: [
          {
            code: 150402,
            name_cn: "红山区",
            name_en: "Hongshan",
            name_tw: "紅山區",
            yzcode: "024020",
          },
          {
            code: 150403,
            name_cn: "元宝山区",
            name_en: "Yuanbaoshan",
            name_tw: "元寶山區",
            yzcode: "024076",
          },
          {
            code: 150404,
            name_cn: "松山区",
            name_en: "Songshan",
            name_tw: "松山區",
            yzcode: "024005",
          },
          {
            code: 150421,
            name_cn: "阿鲁科尔沁旗",
            name_en: "Alukeerqinqi",
            name_tw: "阿魯科爾沁旗",
            yzcode: "025550",
          },
          {
            code: 150422,
            name_cn: "巴林左旗",
            name_en: "Balinzuoqi",
            name_tw: "巴林左旗",
            yzcode: "025450",
          },
          {
            code: 150423,
            name_cn: "巴林右旗",
            name_en: "Balinyouqi",
            name_tw: "巴林右旗",
            yzcode: "025150",
          },
          {
            code: 150424,
            name_cn: "林西县",
            name_en: "Linxi",
            name_tw: "林西縣",
            yzcode: "025250",
          },
          {
            code: 150425,
            name_cn: "克什克腾旗",
            name_en: "Keshiketengqi",
            name_tw: "克什克騰旗",
            yzcode: "025350",
          },
          {
            code: 150426,
            name_cn: "翁牛特旗",
            name_en: "Wengniuteqi",
            name_tw: "翁牛特旗",
            yzcode: "024500",
          },
          {
            code: 150428,
            name_cn: "喀喇沁旗",
            name_en: "Kalaqinqi",
            name_tw: "喀喇沁旗",
            yzcode: "024400",
          },
          {
            code: 150429,
            name_cn: "宁城县",
            name_en: "Ningcheng",
            name_tw: "寧城縣",
            yzcode: "024200",
          },
          {
            code: 150430,
            name_cn: "敖汉旗",
            name_en: "Aohanqi",
            name_tw: "敖漢旗",
            yzcode: "024300",
          },
        ],
      },
      {
        code: 150500,
        name_cn: "通辽市",
        name_en: "Tongliao",
        name_tw: "通遼市",
        children: [
          {
            code: 150502,
            name_cn: "科尔沁区",
            name_en: "Keerqin",
            name_tw: "科爾沁區",
            yzcode: "028000",
          },
          {
            code: 150521,
            name_cn: "科尔沁左翼中旗",
            name_en: "Keerqinzuoyizhongqi",
            name_tw: "科爾沁左翼中旗",
            yzcode: "029300",
          },
          {
            code: 150522,
            name_cn: "科尔沁左翼后旗",
            name_en: "Keerqinzuoyihouqi",
            name_tw: "科爾沁左翼後旗",
            yzcode: "028100",
          },
          {
            code: 150523,
            name_cn: "开鲁县",
            name_en: "Kailu",
            name_tw: "開魯縣",
            yzcode: "028400",
          },
          {
            code: 150524,
            name_cn: "库伦旗",
            name_en: "Kulunqi",
            name_tw: "庫倫旗",
            yzcode: "028200",
          },
          {
            code: 150525,
            name_cn: "奈曼旗",
            name_en: "Naimanqi",
            name_tw: "奈曼旗",
            yzcode: "028300",
          },
          {
            code: 150526,
            name_cn: "扎鲁特旗",
            name_en: "Zhaluteqi",
            name_tw: "紮魯特旗",
            yzcode: "029100",
          },
          {
            code: 150581,
            name_cn: "霍林郭勒市",
            name_en: "Huolinguole",
            name_tw: "霍林郭勒市",
            yzcode: "029200",
          },
        ],
      },
      {
        code: 150600,
        name_cn: "鄂尔多斯市",
        name_en: "Ordos",
        name_tw: "鄂爾多斯市",
        children: [
          {
            code: 150602,
            name_cn: "东胜区",
            name_en: "Dongsheng",
            name_tw: "東勝區",
            yzcode: "017000",
          },
          {
            code: 150621,
            name_cn: "达拉特旗",
            name_en: "Dalateqi",
            name_tw: "達拉特旗",
            yzcode: "014300",
          },
          {
            code: 150622,
            name_cn: "准格尔旗",
            name_en: "Zhungeerqi",
            name_tw: "準格爾旗",
            yzcode: "017100",
          },
          {
            code: 150623,
            name_cn: "鄂托克前旗",
            name_en: "Etuokeqianqi",
            name_tw: "鄂托克前旗",
            yzcode: "016200",
          },
          {
            code: 150624,
            name_cn: "鄂托克旗",
            name_en: "Etuokeqi",
            name_tw: "鄂托克旗",
            yzcode: "016100",
          },
          {
            code: 150625,
            name_cn: "杭锦旗",
            name_en: "Hangjinqi",
            name_tw: "杭錦旗",
            yzcode: "017400",
          },
          {
            code: 150626,
            name_cn: "乌审旗",
            name_en: "Wushenqi",
            name_tw: "烏審旗",
            yzcode: "017300",
          },
          {
            code: 150627,
            name_cn: "伊金霍洛旗",
            name_en: "Yijinhuoluoqi",
            name_tw: "伊金霍洛旗",
            yzcode: "017200",
          },
        ],
      },
      {
        code: 150700,
        name_cn: "呼伦贝尔市",
        name_en: "Hulunber",
        name_tw: "呼倫貝爾市",
        children: [
          {
            code: 150702,
            name_cn: "海拉尔区",
            name_en: "Hailaer",
            name_tw: "海拉爾區",
            yzcode: "021000",
          },
          {
            code: 150703,
            name_cn: "扎赉诺尔区",
            name_en: "Zhalainuoer",
            name_tw: "紮賚諾爾區",
            yzcode: "021410",
          },
          {
            code: 150721,
            name_cn: "阿荣旗",
            name_en: "Arongqi",
            name_tw: "阿榮旗",
            yzcode: "162750",
          },
          {
            code: 150722,
            name_cn: "莫力达瓦达斡尔族自治旗",
            name_en: "Moqi",
            name_tw: "莫力達瓦達斡爾族自治旗",
            yzcode: "162850",
          },
          {
            code: 150723,
            name_cn: "鄂伦春自治旗",
            name_en: "Elunchun",
            name_tw: "鄂倫春自治旗",
            yzcode: "165450",
          },
          {
            code: 150724,
            name_cn: "鄂温克族自治旗",
            name_en: "Ewen",
            name_tw: "鄂溫克族自治旗",
            yzcode: "021100",
          },
          {
            code: 150725,
            name_cn: "陈巴尔虎旗",
            name_en: "Chenbaerhuqi",
            name_tw: "陳巴爾虎旗",
            yzcode: "021500",
          },
          {
            code: 150726,
            name_cn: "新巴尔虎左旗",
            name_en: "Xinbaerhuzuoqi",
            name_tw: "新巴爾虎左旗",
            yzcode: "021200",
          },
          {
            code: 150727,
            name_cn: "新巴尔虎右旗",
            name_en: "Xinbaerhuyouqi",
            name_tw: "新巴爾虎右旗",
            yzcode: "021300",
          },
          {
            code: 150781,
            name_cn: "满洲里市",
            name_en: "Manzhouli",
            name_tw: "滿洲裏市",
            yzcode: "021400",
          },
          {
            code: 150782,
            name_cn: "牙克石市",
            name_en: "Yakeshi",
            name_tw: "牙克石市",
            yzcode: "022150",
          },
          {
            code: 150783,
            name_cn: "扎兰屯市",
            name_en: "Zhalantun",
            name_tw: "紮蘭屯市",
            yzcode: "162650",
          },
          {
            code: 150784,
            name_cn: "额尔古纳市",
            name_en: "Eerguna",
            name_tw: "額爾古納市",
            yzcode: "022250",
          },
          {
            code: 150785,
            name_cn: "根河市",
            name_en: "Genhe",
            name_tw: "根河市",
            yzcode: "022350",
          },
        ],
      },
      {
        code: 150800,
        name_cn: "巴彦淖尔市",
        name_en: "Bayan Nur",
        name_tw: "巴彥淖爾市",
        children: [
          {
            code: 150802,
            name_cn: "临河区",
            name_en: "Linhe",
            name_tw: "臨河區",
            yzcode: "015001",
          },
          {
            code: 150821,
            name_cn: "五原县",
            name_en: "Wuyuan",
            name_tw: "五原縣",
            yzcode: "015100",
          },
          {
            code: 150822,
            name_cn: "磴口县",
            name_en: "Dengkou",
            name_tw: "磴口縣",
            yzcode: "015200",
          },
          {
            code: 150823,
            name_cn: "乌拉特前旗",
            name_en: "Wulateqianqi",
            name_tw: "烏拉特前旗",
            yzcode: "014400",
          },
          {
            code: 150824,
            name_cn: "乌拉特中旗",
            name_en: "Wulatezhongqi",
            name_tw: "烏拉特中旗",
            yzcode: "015300",
          },
          {
            code: 150825,
            name_cn: "乌拉特后旗",
            name_en: "Wulatehouqi",
            name_tw: "烏拉特後旗",
            yzcode: "015500",
          },
          {
            code: 150826,
            name_cn: "杭锦后旗",
            name_en: "Hangjinhouqi",
            name_tw: "杭錦後旗",
            yzcode: "015400",
          },
        ],
      },
      {
        code: 150900,
        name_cn: "乌兰察布市",
        name_en: "Ulanqab",
        name_tw: "烏蘭察布市",
        children: [
          {
            code: 150902,
            name_cn: "集宁区",
            name_en: "Jining",
            name_tw: "集寧區",
            yzcode: "012000",
          },
          {
            code: 150921,
            name_cn: "卓资县",
            name_en: "Zhuozi",
            name_tw: "卓資縣",
            yzcode: "012300",
          },
          {
            code: 150922,
            name_cn: "化德县",
            name_en: "Huade",
            name_tw: "化德縣",
            yzcode: "013350",
          },
          {
            code: 150923,
            name_cn: "商都县",
            name_en: "Shangdu",
            name_tw: "商都縣",
            yzcode: "013450",
          },
          {
            code: 150924,
            name_cn: "兴和县",
            name_en: "Xinghe",
            name_tw: "興和縣",
            yzcode: "013650",
          },
          {
            code: 150925,
            name_cn: "凉城县",
            name_en: "Liangcheng",
            name_tw: "涼城縣",
            yzcode: "013750",
          },
          {
            code: 150926,
            name_cn: "察哈尔右翼前旗",
            name_en: "Chayouqianqi",
            name_tw: "察哈爾右翼前旗",
            yzcode: "012200",
          },
          {
            code: 150927,
            name_cn: "察哈尔右翼中旗",
            name_en: "Chayouzhongqi",
            name_tw: "察哈爾右翼中旗",
            yzcode: "013550",
          },
          {
            code: 150928,
            name_cn: "察哈尔右翼后旗",
            name_en: "Chayouhouqi",
            name_tw: "察哈爾右翼後旗",
            yzcode: "012400",
          },
          {
            code: 150929,
            name_cn: "四子王旗",
            name_en: "Siziwangqi",
            name_tw: "四子王旗",
            yzcode: "011800",
          },
          {
            code: 150981,
            name_cn: "丰镇市",
            name_en: "Fengzhen",
            name_tw: "豐鎮市",
            yzcode: "012100",
          },
        ],
      },
      {
        code: 152200,
        name_cn: "兴安盟",
        name_en: "Hinggan",
        name_tw: "興安盟",
        children: [
          {
            code: 152201,
            name_cn: "乌兰浩特市",
            name_en: "Wulanhaote",
            name_tw: "烏蘭浩特市",
            yzcode: "137401",
          },
          {
            code: 152202,
            name_cn: "阿尔山市",
            name_en: "Aershan",
            name_tw: "阿爾山市",
            yzcode: "137800",
          },
          {
            code: 152221,
            name_cn: "科尔沁右翼前旗",
            name_en: "Keyouqianqi",
            name_tw: "科爾沁右翼前旗",
            yzcode: "137423",
          },
          {
            code: 152222,
            name_cn: "科尔沁右翼中旗",
            name_en: "Keyouzhongqi",
            name_tw: "科爾沁右翼中旗",
            yzcode: "029400",
          },
          {
            code: 152223,
            name_cn: "扎赉特旗",
            name_en: "Zhalaiteqi",
            name_tw: "紮賚特旗",
            yzcode: "137600",
          },
          {
            code: 152224,
            name_cn: "突泉县",
            name_en: "Tuquan",
            name_tw: "突泉縣",
            yzcode: "137500",
          },
        ],
      },
      {
        code: 152500,
        name_cn: "锡林郭勒盟",
        name_en: "Xilin Gol",
        name_tw: "錫林郭勒盟",
        children: [
          {
            code: 152501,
            name_cn: "二连浩特市",
            name_en: "Erlianhaote",
            name_tw: "二連浩特市",
            yzcode: "011100",
          },
          {
            code: 152502,
            name_cn: "锡林浩特市",
            name_en: "Xilinhaote",
            name_tw: "錫林浩特市",
            yzcode: "026021",
          },
          {
            code: 152522,
            name_cn: "阿巴嘎旗",
            name_en: "Abagaqi",
            name_tw: "阿巴嘎旗",
            yzcode: "011400",
          },
          {
            code: 152523,
            name_cn: "苏尼特左旗",
            name_en: "Sunitezuoqi",
            name_tw: "蘇尼特左旗",
            yzcode: "011300",
          },
          {
            code: 152524,
            name_cn: "苏尼特右旗",
            name_en: "Suniteyouqi",
            name_tw: "蘇尼特右旗",
            yzcode: "011200",
          },
          {
            code: 152525,
            name_cn: "东乌珠穆沁旗",
            name_en: "Dongwuqi",
            name_tw: "東烏珠穆沁旗",
            yzcode: "026300",
          },
          {
            code: 152526,
            name_cn: "西乌珠穆沁旗",
            name_en: "Xiwuqi",
            name_tw: "西烏珠穆沁旗",
            yzcode: "026200",
          },
          {
            code: 152527,
            name_cn: "太仆寺旗",
            name_en: "Taipusiqi",
            name_tw: "太仆寺旗",
            yzcode: "027000",
          },
          {
            code: 152528,
            name_cn: "镶黄旗",
            name_en: "Xianghuangqi",
            name_tw: "鑲黃旗",
            yzcode: "013250",
          },
          {
            code: 152529,
            name_cn: "正镶白旗",
            name_en: "Zhengxiangbaiqi",
            name_tw: "正鑲白旗",
            yzcode: "013800",
          },
          {
            code: 152530,
            name_cn: "正蓝旗",
            name_en: "Zhenglanqi",
            name_tw: "正藍旗",
            yzcode: "027200",
          },
          {
            code: 152531,
            name_cn: "多伦县",
            name_en: "Duolun",
            name_tw: "多倫縣",
            yzcode: "027300",
          },
        ],
      },
      {
        code: 152900,
        name_cn: "阿拉善盟",
        name_en: "Alxa",
        name_tw: "阿拉善盟",
        children: [
          {
            code: 152921,
            name_cn: "阿拉善左旗",
            name_en: "Alashanzuoqi",
            name_tw: "阿拉善左旗",
            yzcode: "750306",
          },
          {
            code: 152922,
            name_cn: "阿拉善右旗",
            name_en: "Alashanyouqi",
            name_tw: "阿拉善右旗",
            yzcode: "737300",
          },
          {
            code: 152923,
            name_cn: "额济纳旗",
            name_en: "Ejinaqi",
            name_tw: "額濟納旗",
            yzcode: "735400",
          },
        ],
      },
    ],
  },
  {
    code: 210000,
    name_cn: "辽宁省",
    name_en: "Liaoning",
    name_tw: "遼寧省",
    children: [
      {
        code: 210100,
        name_cn: "沈阳市",
        name_en: "Shenyang",
        name_tw: "沈陽市",
        children: [
          {
            code: 210102,
            name_cn: "和平区",
            name_en: "Heping",
            name_tw: "和平區",
            yzcode: "110001",
          },
          {
            code: 210103,
            name_cn: "沈河区",
            name_en: "Shenhe",
            name_tw: "沈河區",
            yzcode: "110011",
          },
          {
            code: 210104,
            name_cn: "大东区",
            name_en: "Dadong",
            name_tw: "大東區",
            yzcode: "110041",
          },
          {
            code: 210105,
            name_cn: "皇姑区",
            name_en: "Huanggu",
            name_tw: "皇姑區",
            yzcode: "110031",
          },
          {
            code: 210106,
            name_cn: "铁西区",
            name_en: "Tiexi",
            name_tw: "鐵西區",
            yzcode: "110021",
          },
          {
            code: 210111,
            name_cn: "苏家屯区",
            name_en: "Sujiatun",
            name_tw: "蘇家屯區",
            yzcode: "110101",
          },
          {
            code: 210112,
            name_cn: "浑南区",
            name_en: "Hunnan",
            name_tw: "渾南區",
            yzcode: "110015",
          },
          {
            code: 210113,
            name_cn: "沈北新区",
            name_en: "Shenbeixinqu",
            name_tw: "沈北新區",
            yzcode: "110121",
          },
          {
            code: 210114,
            name_cn: "于洪区",
            name_en: "Yuhong",
            name_tw: "於洪區",
            yzcode: "110141",
          },
          {
            code: 210122,
            name_cn: "辽中县",
            name_en: "Liaozhong",
            name_tw: "遼中縣",
            yzcode: "110200",
          },
          {
            code: 210123,
            name_cn: "康平县",
            name_en: "Kangping",
            name_tw: "康平縣",
            yzcode: "110500",
          },
          {
            code: 210124,
            name_cn: "法库县",
            name_en: "Faku",
            name_tw: "法庫縣",
            yzcode: "110400",
          },
          {
            code: 210181,
            name_cn: "新民市",
            name_en: "Xinmin",
            name_tw: "新民市",
            yzcode: "110300",
          },
        ],
      },
      {
        code: 210200,
        name_cn: "大连市",
        name_en: "Dalian",
        name_tw: "大連市",
        children: [
          {
            code: 210202,
            name_cn: "中山区",
            name_en: "Zhongshan",
            name_tw: "中山區",
            yzcode: "116001",
          },
          {
            code: 210203,
            name_cn: "西岗区",
            name_en: "Xigang",
            name_tw: "西崗區",
            yzcode: "116011",
          },
          {
            code: 210204,
            name_cn: "沙河口区",
            name_en: "Shahekou",
            name_tw: "沙河口區",
            yzcode: "116021",
          },
          {
            code: 210211,
            name_cn: "甘井子区",
            name_en: "Ganjingzi",
            name_tw: "甘井子區",
            yzcode: "116033",
          },
          {
            code: 210212,
            name_cn: "旅顺口区",
            name_en: "Lvshunkou",
            name_tw: "旅順口區",
            yzcode: "116041",
          },
          {
            code: 210213,
            name_cn: "金州区",
            name_en: "Jinzhou",
            name_tw: "金州區",
            yzcode: "116100",
          },
          {
            code: 210224,
            name_cn: "长海县",
            name_en: "Changhai",
            name_tw: "長海縣",
            yzcode: "116500",
          },
          {
            code: 210281,
            name_cn: "瓦房店市",
            name_en: "Wafangdian",
            name_tw: "瓦房店市",
            yzcode: "116300",
          },
          {
            code: 210282,
            name_cn: "普兰店市",
            name_en: "Pulandian",
            name_tw: "普蘭店市",
            yzcode: "116200",
          },
          {
            code: 210283,
            name_cn: "庄河市",
            name_en: "Zhuanghe",
            name_tw: "莊河市",
            yzcode: "116400",
          },
        ],
      },
      {
        code: 210300,
        name_cn: "鞍山市",
        name_en: "Anshan",
        name_tw: "鞍山市",
        children: [
          {
            code: 210302,
            name_cn: "铁东区",
            name_en: "Tiedong",
            name_tw: "鐵東區",
            yzcode: "114001",
          },
          {
            code: 210303,
            name_cn: "铁西区",
            name_en: "Tiexi",
            name_tw: "鐵西區",
            yzcode: "114013",
          },
          {
            code: 210304,
            name_cn: "立山区",
            name_en: "Lishan",
            name_tw: "立山區",
            yzcode: "114031",
          },
          {
            code: 210311,
            name_cn: "千山区",
            name_en: "Qianshan",
            name_tw: "千山區",
            yzcode: "114041",
          },
          {
            code: 210321,
            name_cn: "台安县",
            name_en: "Tai'an",
            name_tw: "台安縣",
            yzcode: "114100",
          },
          {
            code: 210323,
            name_cn: "岫岩满族自治县",
            name_en: "Xiuyan",
            name_tw: "岫巖滿族自治縣",
            yzcode: "114300",
          },
          {
            code: 210381,
            name_cn: "海城市",
            name_en: "Haicheng",
            name_tw: "海城市",
            yzcode: "114200",
          },
        ],
      },
      {
        code: 210400,
        name_cn: "抚顺市",
        name_en: "Fushun",
        name_tw: "撫順市",
        children: [
          {
            code: 210402,
            name_cn: "新抚区",
            name_en: "Xinfu",
            name_tw: "新撫區",
            yzcode: "113008",
          },
          {
            code: 210403,
            name_cn: "东洲区",
            name_en: "Dongzhou",
            name_tw: "東洲區",
            yzcode: "113003",
          },
          {
            code: 210404,
            name_cn: "望花区",
            name_en: "Wanghua",
            name_tw: "望花區",
            yzcode: "113001",
          },
          {
            code: 210411,
            name_cn: "顺城区",
            name_en: "Shuncheng",
            name_tw: "順城區",
            yzcode: "113006",
          },
          {
            code: 210421,
            name_cn: "抚顺县",
            name_en: "Fushun",
            name_tw: "撫順縣",
            yzcode: "113006",
          },
          {
            code: 210422,
            name_cn: "新宾满族自治县",
            name_en: "Xinbin",
            name_tw: "新賓滿族自治縣",
            yzcode: "113200",
          },
          {
            code: 210423,
            name_cn: "清原满族自治县",
            name_en: "Qingyuan",
            name_tw: "清原滿族自治縣",
            yzcode: "113300",
          },
        ],
      },
      {
        code: 210500,
        name_cn: "本溪市",
        name_en: "Benxi",
        name_tw: "本溪市",
        children: [
          {
            code: 210502,
            name_cn: "平山区",
            name_en: "Pingshan",
            name_tw: "平山區",
            yzcode: "117000",
          },
          {
            code: 210503,
            name_cn: "溪湖区",
            name_en: "Xihu",
            name_tw: "溪湖區",
            yzcode: "117002",
          },
          {
            code: 210504,
            name_cn: "明山区",
            name_en: "Mingshan",
            name_tw: "明山區",
            yzcode: "117021",
          },
          {
            code: 210505,
            name_cn: "南芬区",
            name_en: "Nanfen",
            name_tw: "南芬區",
            yzcode: "117014",
          },
          {
            code: 210521,
            name_cn: "本溪满族自治县",
            name_en: "Benxi",
            name_tw: "本溪滿族自治縣",
            yzcode: "117100",
          },
          {
            code: 210522,
            name_cn: "桓仁满族自治县",
            name_en: "Huanren",
            name_tw: "桓仁滿族自治縣",
            yzcode: "117200",
          },
        ],
      },
      {
        code: 210600,
        name_cn: "丹东市",
        name_en: "Dandong",
        name_tw: "丹東市",
        children: [
          {
            code: 210602,
            name_cn: "元宝区",
            name_en: "Yuanbao",
            name_tw: "元寶區",
            yzcode: "118000",
          },
          {
            code: 210603,
            name_cn: "振兴区",
            name_en: "Zhenxing",
            name_tw: "振興區",
            yzcode: "118002",
          },
          {
            code: 210604,
            name_cn: "振安区",
            name_en: "Zhen'an",
            name_tw: "振安區",
            yzcode: "118001",
          },
          {
            code: 210624,
            name_cn: "宽甸满族自治县",
            name_en: "Kuandian",
            name_tw: "寬甸滿族自治縣",
            yzcode: "118200",
          },
          {
            code: 210681,
            name_cn: "东港市",
            name_en: "Donggang",
            name_tw: "東港市",
            yzcode: "118300",
          },
          {
            code: 210682,
            name_cn: "凤城市",
            name_en: "Fengcheng",
            name_tw: "鳳城市",
            yzcode: "118100",
          },
        ],
      },
      {
        code: 210700,
        name_cn: "锦州市",
        name_en: "Jinzhou",
        name_tw: "錦州市",
        children: [
          {
            code: 210702,
            name_cn: "古塔区",
            name_en: "Guta",
            name_tw: "古塔區",
            yzcode: "121001",
          },
          {
            code: 210703,
            name_cn: "凌河区",
            name_en: "Linghe",
            name_tw: "淩河區",
            yzcode: "121000",
          },
          {
            code: 210711,
            name_cn: "太和区",
            name_en: "Taihe",
            name_tw: "太和區",
            yzcode: "121011",
          },
          {
            code: 210726,
            name_cn: "黑山县",
            name_en: "Heishan",
            name_tw: "黑山縣",
            yzcode: "121400",
          },
          {
            code: 210727,
            name_cn: "义县",
            name_en: "Yixian",
            name_tw: "義縣",
            yzcode: "121100",
          },
          {
            code: 210781,
            name_cn: "凌海市",
            name_en: "Linghai",
            name_tw: "淩海市",
            yzcode: "121200",
          },
          {
            code: 210782,
            name_cn: "北镇市",
            name_en: "Beizhen",
            name_tw: "北鎮市",
            yzcode: "121300",
          },
        ],
      },
      {
        code: 210800,
        name_cn: "营口市",
        name_en: "Yingkou",
        name_tw: "營口市",
        children: [
          {
            code: 210802,
            name_cn: "站前区",
            name_en: "Zhanqian",
            name_tw: "站前區",
            yzcode: "115002",
          },
          {
            code: 210803,
            name_cn: "西市区",
            name_en: "Xishi",
            name_tw: "西市區",
            yzcode: "115004",
          },
          {
            code: 210804,
            name_cn: "鲅鱼圈区",
            name_en: "Bayuquan",
            name_tw: "鮁魚圈區",
            yzcode: "115007",
          },
          {
            code: 210811,
            name_cn: "老边区",
            name_en: "Laobian",
            name_tw: "老邊區",
            yzcode: "115005",
          },
          {
            code: 210881,
            name_cn: "盖州市",
            name_en: "Gaizhou",
            name_tw: "蓋州市",
            yzcode: "115200",
          },
          {
            code: 210882,
            name_cn: "大石桥市",
            name_en: "Dashiqiao",
            name_tw: "大石橋市",
            yzcode: "115100",
          },
        ],
      },
      {
        code: 210900,
        name_cn: "阜新市",
        name_en: "Fuxin",
        name_tw: "阜新市",
        children: [
          {
            code: 210902,
            name_cn: "海州区",
            name_en: "Haizhou",
            name_tw: "海州區",
            yzcode: "123000",
          },
          {
            code: 210903,
            name_cn: "新邱区",
            name_en: "Xinqiu",
            name_tw: "新邱區",
            yzcode: "123005",
          },
          {
            code: 210904,
            name_cn: "太平区",
            name_en: "Taiping",
            name_tw: "太平區",
            yzcode: "123003",
          },
          {
            code: 210905,
            name_cn: "清河门区",
            name_en: "Qinghemen",
            name_tw: "清河門區",
            yzcode: "123006",
          },
          {
            code: 210911,
            name_cn: "细河区",
            name_en: "Xihe",
            name_tw: "細河區",
            yzcode: "123000",
          },
          {
            code: 210921,
            name_cn: "阜新蒙古族自治县",
            name_en: "Fuxin",
            name_tw: "阜新蒙古族自治縣",
            yzcode: "123100",
          },
          {
            code: 210922,
            name_cn: "彰武县",
            name_en: "Zhangwu",
            name_tw: "彰武縣",
            yzcode: "123200",
          },
        ],
      },
      {
        code: 211000,
        name_cn: "辽阳市",
        name_en: "Liaoyang",
        name_tw: "遼陽市",
        children: [
          {
            code: 211002,
            name_cn: "白塔区",
            name_en: "Baita",
            name_tw: "白塔區",
            yzcode: "111000",
          },
          {
            code: 211003,
            name_cn: "文圣区",
            name_en: "Wensheng",
            name_tw: "文聖區",
            yzcode: "111000",
          },
          {
            code: 211004,
            name_cn: "宏伟区",
            name_en: "Hongwei",
            name_tw: "宏偉區",
            yzcode: "111003",
          },
          {
            code: 211005,
            name_cn: "弓长岭区",
            name_en: "Gongchangling",
            name_tw: "弓長嶺區",
            yzcode: "111008",
          },
          {
            code: 211011,
            name_cn: "太子河区",
            name_en: "Taizihe",
            name_tw: "太子河區",
            yzcode: "111000",
          },
          {
            code: 211021,
            name_cn: "辽阳县",
            name_en: "Liaoyang",
            name_tw: "遼陽縣",
            yzcode: "111200",
          },
          {
            code: 211081,
            name_cn: "灯塔市",
            name_en: "Dengta",
            name_tw: "燈塔市",
            yzcode: "111300",
          },
        ],
      },
      {
        code: 211100,
        name_cn: "盘锦市",
        name_en: "Panjin",
        name_tw: "盤錦市",
        children: [
          {
            code: 211102,
            name_cn: "双台子区",
            name_en: "Shuangtaizi",
            name_tw: "雙台子區",
            yzcode: "124000",
          },
          {
            code: 211103,
            name_cn: "兴隆台区",
            name_en: "Xinglongtai",
            name_tw: "興隆台區",
            yzcode: "124010",
          },
          {
            code: 211121,
            name_cn: "大洼县",
            name_en: "Dawa",
            name_tw: "大窪縣",
            yzcode: "124200",
          },
          {
            code: 211122,
            name_cn: "盘山县",
            name_en: "Panshan",
            name_tw: "盤山縣",
            yzcode: "124000",
          },
        ],
      },
      {
        code: 211200,
        name_cn: "铁岭市",
        name_en: "Tieling",
        name_tw: "鐵嶺市",
        children: [
          {
            code: 211202,
            name_cn: "银州区",
            name_en: "Yinzhou",
            name_tw: "銀州區",
            yzcode: "112000",
          },
          {
            code: 211204,
            name_cn: "清河区",
            name_en: "Qinghe",
            name_tw: "清河區",
            yzcode: "112003",
          },
          {
            code: 211221,
            name_cn: "铁岭县",
            name_en: "Tieling",
            name_tw: "鐵嶺縣",
            yzcode: "112000",
          },
          {
            code: 211223,
            name_cn: "西丰县",
            name_en: "Xifeng",
            name_tw: "西豐縣",
            yzcode: "112400",
          },
          {
            code: 211224,
            name_cn: "昌图县",
            name_en: "Changtu",
            name_tw: "昌圖縣",
            yzcode: "112500",
          },
          {
            code: 211281,
            name_cn: "调兵山市",
            name_en: "Diaobingshan",
            name_tw: "調兵山市",
            yzcode: "112700",
          },
          {
            code: 211282,
            name_cn: "开原市",
            name_en: "Kaiyuan",
            name_tw: "開原市",
            yzcode: "112300",
          },
        ],
      },
      {
        code: 211300,
        name_cn: "朝阳市",
        name_en: "Chaoyang",
        name_tw: "朝陽市",
        children: [
          {
            code: 211302,
            name_cn: "双塔区",
            name_en: "Shuangta",
            name_tw: "雙塔區",
            yzcode: "122000",
          },
          {
            code: 211303,
            name_cn: "龙城区",
            name_en: "Longcheng",
            name_tw: "龍城區",
            yzcode: "122000",
          },
          {
            code: 211321,
            name_cn: "朝阳县",
            name_en: "Chaoyang",
            name_tw: "朝陽縣",
            yzcode: "122000",
          },
          {
            code: 211322,
            name_cn: "建平县",
            name_en: "Jianping",
            name_tw: "建平縣",
            yzcode: "122400",
          },
          {
            code: 211324,
            name_cn: "喀喇沁左翼蒙古族自治县",
            name_en: "Kalaqinzuoyi",
            name_tw: "喀喇沁左翼蒙古族自治縣",
            yzcode: "122300",
          },
          {
            code: 211381,
            name_cn: "北票市",
            name_en: "Beipiao",
            name_tw: "北票市",
            yzcode: "122100",
          },
          {
            code: 211382,
            name_cn: "凌源市",
            name_en: "Lingyuan",
            name_tw: "淩源市",
            yzcode: "122500",
          },
        ],
      },
      {
        code: 211400,
        name_cn: "葫芦岛市",
        name_en: "Huludao",
        name_tw: "葫蘆島市",
        children: [
          {
            code: 211402,
            name_cn: "连山区",
            name_en: "Lianshan",
            name_tw: "連山區",
            yzcode: "125001",
          },
          {
            code: 211403,
            name_cn: "龙港区",
            name_en: "Longgang",
            name_tw: "龍港區",
            yzcode: "125003",
          },
          {
            code: 211404,
            name_cn: "南票区",
            name_en: "Nanpiao",
            name_tw: "南票區",
            yzcode: "125027",
          },
          {
            code: 211421,
            name_cn: "绥中县",
            name_en: "Suizhong",
            name_tw: "綏中縣",
            yzcode: "125200",
          },
          {
            code: 211422,
            name_cn: "建昌县",
            name_en: "Jianchang",
            name_tw: "建昌縣",
            yzcode: "125300",
          },
          {
            code: 211481,
            name_cn: "兴城市",
            name_en: "Xingcheng",
            name_tw: "興城市",
            yzcode: "125100",
          },
        ],
      },
      {
        code: 211500,
        name_cn: "金普新区",
        name_en: "Jinpuxinqu",
        name_tw: "金普新區",
        children: [
          {
            code: 211501,
            name_cn: "金州新区",
            name_en: "Jinzhouxinqu",
            name_tw: "金州新區",
            yzcode: "116100",
          },
          {
            code: 211502,
            name_cn: "普湾新区",
            name_en: "Puwanxinqu",
            name_tw: "普灣新區",
            yzcode: "116200",
          },
          {
            code: 211503,
            name_cn: "保税区",
            name_en: "Baoshuiqu",
            name_tw: "保稅區",
            yzcode: "116100",
          },
        ],
      },
    ],
  },
  {
    code: 220000,
    name_cn: "吉林省",
    name_en: "Jilin",
    name_tw: "吉林省",
    children: [
      {
        code: 220100,
        name_cn: "长春市",
        name_en: "Changchun",
        name_tw: "長春市",
        children: [
          {
            code: 220102,
            name_cn: "南关区",
            name_en: "Nanguan",
            name_tw: "南關區",
            yzcode: "130022",
          },
          {
            code: 220103,
            name_cn: "宽城区",
            name_en: "Kuancheng",
            name_tw: "寬城區",
            yzcode: "130051",
          },
          {
            code: 220104,
            name_cn: "朝阳区",
            name_en: "Chaoyang",
            name_tw: "朝陽區",
            yzcode: "130012",
          },
          {
            code: 220105,
            name_cn: "二道区",
            name_en: "Erdao",
            name_tw: "二道區",
            yzcode: "130031",
          },
          {
            code: 220106,
            name_cn: "绿园区",
            name_en: "Lvyuan",
            name_tw: "綠園區",
            yzcode: "130062",
          },
          {
            code: 220112,
            name_cn: "双阳区",
            name_en: "Shuangyang",
            name_tw: "雙陽區",
            yzcode: "130600",
          },
          {
            code: 220113,
            name_cn: "九台区",
            name_en: "Jiutai",
            name_tw: "九台區",
            yzcode: "130500",
          },
          {
            code: 220122,
            name_cn: "农安县",
            name_en: "Nong'an",
            name_tw: "農安縣",
            yzcode: "130200",
          },
          {
            code: 220182,
            name_cn: "榆树市",
            name_en: "Yushu",
            name_tw: "榆樹市",
            yzcode: "130400",
          },
          {
            code: 220183,
            name_cn: "德惠市",
            name_en: "Dehui",
            name_tw: "德惠市",
            yzcode: "130300",
          },
        ],
      },
      {
        code: 220200,
        name_cn: "吉林市",
        name_en: "Jilin",
        name_tw: "吉林市",
        children: [
          {
            code: 220202,
            name_cn: "昌邑区",
            name_en: "Changyi",
            name_tw: "昌邑區",
            yzcode: "132002",
          },
          {
            code: 220203,
            name_cn: "龙潭区",
            name_en: "Longtan",
            name_tw: "龍潭區",
            yzcode: "132021",
          },
          {
            code: 220204,
            name_cn: "船营区",
            name_en: "Chuanying",
            name_tw: "船營區",
            yzcode: "132011",
          },
          {
            code: 220211,
            name_cn: "丰满区",
            name_en: "Fengman",
            name_tw: "豐滿區",
            yzcode: "132013",
          },
          {
            code: 220221,
            name_cn: "永吉县",
            name_en: "Yongji",
            name_tw: "永吉縣",
            yzcode: "132200",
          },
          {
            code: 220281,
            name_cn: "蛟河市",
            name_en: "Jiaohe",
            name_tw: "蛟河市",
            yzcode: "132500",
          },
          {
            code: 220282,
            name_cn: "桦甸市",
            name_en: "Huadian",
            name_tw: "樺甸市",
            yzcode: "132400",
          },
          {
            code: 220283,
            name_cn: "舒兰市",
            name_en: "Shulan",
            name_tw: "舒蘭市",
            yzcode: "132600",
          },
          {
            code: 220284,
            name_cn: "磐石市",
            name_en: "Panshi",
            name_tw: "磐石市",
            yzcode: "132300",
          },
        ],
      },
      {
        code: 220300,
        name_cn: "四平市",
        name_en: "Siping",
        name_tw: "四平市",
        children: [
          {
            code: 220302,
            name_cn: "铁西区",
            name_en: "Tiexi",
            name_tw: "鐵西區",
            yzcode: "136000",
          },
          {
            code: 220303,
            name_cn: "铁东区",
            name_en: "Tiedong",
            name_tw: "鐵東區",
            yzcode: "136001",
          },
          {
            code: 220322,
            name_cn: "梨树县",
            name_en: "Lishu",
            name_tw: "梨樹縣",
            yzcode: "136500",
          },
          {
            code: 220323,
            name_cn: "伊通满族自治县",
            name_en: "Yitong",
            name_tw: "伊通滿族自治縣",
            yzcode: "130700",
          },
          {
            code: 220381,
            name_cn: "公主岭市",
            name_en: "Gongzhuling",
            name_tw: "公主嶺市",
            yzcode: "136100",
          },
          {
            code: 220382,
            name_cn: "双辽市",
            name_en: "Shuangliao",
            name_tw: "雙遼市",
            yzcode: "136400",
          },
        ],
      },
      {
        code: 220400,
        name_cn: "辽源市",
        name_en: "Liaoyuan",
        name_tw: "遼源市",
        children: [
          {
            code: 220402,
            name_cn: "龙山区",
            name_en: "Longshan",
            name_tw: "龍山區",
            yzcode: "136200",
          },
          {
            code: 220403,
            name_cn: "西安区",
            name_en: "Xi'an",
            name_tw: "西安區",
            yzcode: "136201",
          },
          {
            code: 220421,
            name_cn: "东丰县",
            name_en: "Dongfeng",
            name_tw: "東豐縣",
            yzcode: "136300",
          },
          {
            code: 220422,
            name_cn: "东辽县",
            name_en: "Dongliao",
            name_tw: "東遼縣",
            yzcode: "136600",
          },
        ],
      },
      {
        code: 220500,
        name_cn: "通化市",
        name_en: "Tonghua",
        name_tw: "通化市",
        children: [
          {
            code: 220502,
            name_cn: "东昌区",
            name_en: "Dongchang",
            name_tw: "東昌區",
            yzcode: "134001",
          },
          {
            code: 220503,
            name_cn: "二道江区",
            name_en: "Erdaojiang",
            name_tw: "二道江區",
            yzcode: "134003",
          },
          {
            code: 220521,
            name_cn: "通化县",
            name_en: "Tonghua",
            name_tw: "通化縣",
            yzcode: "134100",
          },
          {
            code: 220523,
            name_cn: "辉南县",
            name_en: "Huinan",
            name_tw: "輝南縣",
            yzcode: "135100",
          },
          {
            code: 220524,
            name_cn: "柳河县",
            name_en: "Liuhe",
            name_tw: "柳河縣",
            yzcode: "135300",
          },
          {
            code: 220581,
            name_cn: "梅河口市",
            name_en: "Meihekou",
            name_tw: "梅河口市",
            yzcode: "135000",
          },
          {
            code: 220582,
            name_cn: "集安市",
            name_en: "Ji'an",
            name_tw: "集安市",
            yzcode: "134200",
          },
        ],
      },
      {
        code: 220600,
        name_cn: "白山市",
        name_en: "Baishan",
        name_tw: "白山市",
        children: [
          {
            code: 220602,
            name_cn: "浑江区",
            name_en: "Hunjiang",
            name_tw: "渾江區",
            yzcode: "134300",
          },
          {
            code: 220605,
            name_cn: "江源区",
            name_en: "Jiangyuan",
            name_tw: "江源區",
            yzcode: "134700",
          },
          {
            code: 220621,
            name_cn: "抚松县",
            name_en: "Fusong",
            name_tw: "撫松縣",
            yzcode: "134500",
          },
          {
            code: 220622,
            name_cn: "靖宇县",
            name_en: "Jingyu",
            name_tw: "靖宇縣",
            yzcode: "135200",
          },
          {
            code: 220623,
            name_cn: "长白朝鲜族自治县",
            name_en: "Changbai",
            name_tw: "長白朝鮮族自治縣",
            yzcode: "134400",
          },
          {
            code: 220681,
            name_cn: "临江市",
            name_en: "Linjiang",
            name_tw: "臨江市",
            yzcode: "134600",
          },
        ],
      },
      {
        code: 220700,
        name_cn: "松原市",
        name_en: "Songyuan",
        name_tw: "松原市",
        children: [
          {
            code: 220702,
            name_cn: "宁江区",
            name_en: "Ningjiang",
            name_tw: "寧江區",
            yzcode: "138000",
          },
          {
            code: 220721,
            name_cn: "前郭尔罗斯蒙古族自治县",
            name_en: "Qianguoerluosi",
            name_tw: "前郭爾羅斯蒙古族自治縣",
            yzcode: "138000",
          },
          {
            code: 220722,
            name_cn: "长岭县",
            name_en: "Changling",
            name_tw: "長嶺縣",
            yzcode: "131500",
          },
          {
            code: 220723,
            name_cn: "乾安县",
            name_en: "Qian'an",
            name_tw: "乾安縣",
            yzcode: "131400",
          },
          {
            code: 220781,
            name_cn: "扶余市",
            name_en: "Fuyu",
            name_tw: "扶余市",
            yzcode: "131200",
          },
        ],
      },
      {
        code: 220800,
        name_cn: "白城市",
        name_en: "Baicheng",
        name_tw: "白城市",
        children: [
          {
            code: 220802,
            name_cn: "洮北区",
            name_en: "Taobei",
            name_tw: "洮北區",
            yzcode: "137000",
          },
          {
            code: 220821,
            name_cn: "镇赉县",
            name_en: "Zhenlai",
            name_tw: "鎮賚縣",
            yzcode: "137300",
          },
          {
            code: 220822,
            name_cn: "通榆县",
            name_en: "Tongyu",
            name_tw: "通榆縣",
            yzcode: "137200",
          },
          {
            code: 220881,
            name_cn: "洮南市",
            name_en: "Taonan",
            name_tw: "洮南市",
            yzcode: "137100",
          },
          {
            code: 220882,
            name_cn: "大安市",
            name_en: "Da'an",
            name_tw: "大安市",
            yzcode: "131300",
          },
        ],
      },
      {
        code: 222400,
        name_cn: "延边朝鲜族自治州",
        name_en: "Yanbian",
        name_tw: "延邊朝鮮族自治州",
        children: [
          {
            code: 222401,
            name_cn: "延吉市",
            name_en: "Yanji",
            name_tw: "延吉市",
            yzcode: "133000",
          },
          {
            code: 222402,
            name_cn: "图们市",
            name_en: "Tumen",
            name_tw: "圖們市",
            yzcode: "133100",
          },
          {
            code: 222403,
            name_cn: "敦化市",
            name_en: "Dunhua",
            name_tw: "敦化市",
            yzcode: "133700",
          },
          {
            code: 222404,
            name_cn: "珲春市",
            name_en: "Hunchun",
            name_tw: "琿春市",
            yzcode: "133300",
          },
          {
            code: 222405,
            name_cn: "龙井市",
            name_en: "Longjing",
            name_tw: "龍井市",
            yzcode: "133400",
          },
          {
            code: 222406,
            name_cn: "和龙市",
            name_en: "Helong",
            name_tw: "和龍市",
            yzcode: "133500",
          },
          {
            code: 222424,
            name_cn: "汪清县",
            name_en: "Wangqing",
            name_tw: "汪清縣",
            yzcode: "133200",
          },
          {
            code: 222426,
            name_cn: "安图县",
            name_en: "Antu",
            name_tw: "安圖縣",
            yzcode: "133600",
          },
        ],
      },
    ],
  },
  {
    code: 230000,
    name_cn: "黑龙江省",
    name_en: "Heilongjiang",
    name_tw: "黑龍江省",
    children: [
      {
        code: 230100,
        name_cn: "哈尔滨市",
        name_en: "Harbin",
        name_tw: "哈爾濱市",
        children: [
          {
            code: 230102,
            name_cn: "道里区",
            name_en: "Daoli",
            name_tw: "道裏區",
            yzcode: "150010",
          },
          {
            code: 230103,
            name_cn: "南岗区",
            name_en: "Nangang",
            name_tw: "南崗區",
            yzcode: "150006",
          },
          {
            code: 230104,
            name_cn: "道外区",
            name_en: "Daowai",
            name_tw: "道外區",
            yzcode: "150020",
          },
          {
            code: 230108,
            name_cn: "平房区",
            name_en: "Pingfang",
            name_tw: "平房區",
            yzcode: "150060",
          },
          {
            code: 230109,
            name_cn: "松北区",
            name_en: "Songbei",
            name_tw: "松北區",
            yzcode: "150028",
          },
          {
            code: 230110,
            name_cn: "香坊区",
            name_en: "Xiangfang",
            name_tw: "香坊區",
            yzcode: "150036",
          },
          {
            code: 230111,
            name_cn: "呼兰区",
            name_en: "Hulan",
            name_tw: "呼蘭區",
            yzcode: "150500",
          },
          {
            code: 230112,
            name_cn: "阿城区",
            name_en: "A'cheng",
            name_tw: "阿城區",
            yzcode: "150300",
          },
          {
            code: 230113,
            name_cn: "双城区",
            name_en: "Shuangcheng",
            name_tw: "雙城區",
            yzcode: "150100",
          },
          {
            code: 230123,
            name_cn: "依兰县",
            name_en: "Yilan",
            name_tw: "依蘭縣",
            yzcode: "154800",
          },
          {
            code: 230124,
            name_cn: "方正县",
            name_en: "Fangzheng",
            name_tw: "方正縣",
            yzcode: "150800",
          },
          {
            code: 230125,
            name_cn: "宾县",
            name_en: "Binxian",
            name_tw: "賓縣",
            yzcode: "150400",
          },
          {
            code: 230126,
            name_cn: "巴彦县",
            name_en: "Bayan",
            name_tw: "巴彥縣",
            yzcode: "151800",
          },
          {
            code: 230127,
            name_cn: "木兰县",
            name_en: "Mulan",
            name_tw: "木蘭縣",
            yzcode: "151900",
          },
          {
            code: 230128,
            name_cn: "通河县",
            name_en: "Tonghe",
            name_tw: "通河縣",
            yzcode: "150900",
          },
          {
            code: 230129,
            name_cn: "延寿县",
            name_en: "Yanshou",
            name_tw: "延壽縣",
            yzcode: "150700",
          },
          {
            code: 230183,
            name_cn: "尚志市",
            name_en: "Shangzhi",
            name_tw: "尚誌市",
            yzcode: "150600",
          },
          {
            code: 230184,
            name_cn: "五常市",
            name_en: "Wuchang",
            name_tw: "五常市",
            yzcode: "150200",
          },
        ],
      },
      {
        code: 230200,
        name_cn: "齐齐哈尔市",
        name_en: "Qiqihar",
        name_tw: "齊齊哈爾市",
        children: [
          {
            code: 230202,
            name_cn: "龙沙区",
            name_en: "Longsha",
            name_tw: "龍沙區",
            yzcode: "161000",
          },
          {
            code: 230203,
            name_cn: "建华区",
            name_en: "Jianhua",
            name_tw: "建華區",
            yzcode: "161006",
          },
          {
            code: 230204,
            name_cn: "铁锋区",
            name_en: "Tiefeng",
            name_tw: "鐵鋒區",
            yzcode: "161000",
          },
          {
            code: 230205,
            name_cn: "昂昂溪区",
            name_en: "Angangxi",
            name_tw: "昂昂溪區",
            yzcode: "161031",
          },
          {
            code: 230206,
            name_cn: "富拉尔基区",
            name_en: "Fulaerji",
            name_tw: "富拉爾基區",
            yzcode: "161041",
          },
          {
            code: 230207,
            name_cn: "碾子山区",
            name_en: "Nianzishan",
            name_tw: "碾子山區",
            yzcode: "161046",
          },
          {
            code: 230208,
            name_cn: "梅里斯达斡尔族区",
            name_en: "Meilisi",
            name_tw: "梅裏斯達斡爾族區",
            yzcode: "161021",
          },
          {
            code: 230221,
            name_cn: "龙江县",
            name_en: "Longjiang",
            name_tw: "龍江縣",
            yzcode: "161100",
          },
          {
            code: 230223,
            name_cn: "依安县",
            name_en: "Yi'an",
            name_tw: "依安縣",
            yzcode: "161500",
          },
          {
            code: 230224,
            name_cn: "泰来县",
            name_en: "Tailai",
            name_tw: "泰來縣",
            yzcode: "162400",
          },
          {
            code: 230225,
            name_cn: "甘南县",
            name_en: "Gannan",
            name_tw: "甘南縣",
            yzcode: "162100",
          },
          {
            code: 230227,
            name_cn: "富裕县",
            name_en: "Fuyu",
            name_tw: "富裕縣",
            yzcode: "161200",
          },
          {
            code: 230229,
            name_cn: "克山县",
            name_en: "Keshan",
            name_tw: "克山縣",
            yzcode: "161600",
          },
          {
            code: 230230,
            name_cn: "克东县",
            name_en: "Kedong",
            name_tw: "克東縣",
            yzcode: "164800",
          },
          {
            code: 230231,
            name_cn: "拜泉县",
            name_en: "Baiquan",
            name_tw: "拜泉縣",
            yzcode: "164700",
          },
          {
            code: 230281,
            name_cn: "讷河市",
            name_en: "Nehe",
            name_tw: "訥河市",
            yzcode: "161300",
          },
        ],
      },
      {
        code: 230300,
        name_cn: "鸡西市",
        name_en: "Jixi",
        name_tw: "雞西市",
        children: [
          {
            code: 230302,
            name_cn: "鸡冠区",
            name_en: "Jiguan",
            name_tw: "雞冠區",
            yzcode: "158100",
          },
          {
            code: 230303,
            name_cn: "恒山区",
            name_en: "Hengshan",
            name_tw: "恒山區",
            yzcode: "158130",
          },
          {
            code: 230304,
            name_cn: "滴道区",
            name_en: "Didao",
            name_tw: "滴道區",
            yzcode: "158150",
          },
          {
            code: 230305,
            name_cn: "梨树区",
            name_en: "Lishu",
            name_tw: "梨樹區",
            yzcode: "158160",
          },
          {
            code: 230306,
            name_cn: "城子河区",
            name_en: "Chengzihe",
            name_tw: "城子河區",
            yzcode: "158170",
          },
          {
            code: 230307,
            name_cn: "麻山区",
            name_en: "Mashan",
            name_tw: "麻山區",
            yzcode: "158180",
          },
          {
            code: 230321,
            name_cn: "鸡东县",
            name_en: "Jidong",
            name_tw: "雞東縣",
            yzcode: "158200",
          },
          {
            code: 230381,
            name_cn: "虎林市",
            name_en: "Hulin",
            name_tw: "虎林市",
            yzcode: "158400",
          },
          {
            code: 230382,
            name_cn: "密山市",
            name_en: "Mishan",
            name_tw: "密山市",
            yzcode: "158300",
          },
        ],
      },
      {
        code: 230400,
        name_cn: "鹤岗市",
        name_en: "Hegang",
        name_tw: "鶴崗市",
        children: [
          {
            code: 230402,
            name_cn: "向阳区",
            name_en: "Xiangyang",
            name_tw: "向陽區",
            yzcode: "154100",
          },
          {
            code: 230403,
            name_cn: "工农区",
            name_en: "Gongnong",
            name_tw: "工農區",
            yzcode: "154101",
          },
          {
            code: 230404,
            name_cn: "南山区",
            name_en: "Nanshan",
            name_tw: "南山區",
            yzcode: "154104",
          },
          {
            code: 230405,
            name_cn: "兴安区",
            name_en: "Xing'an",
            name_tw: "興安區",
            yzcode: "154102",
          },
          {
            code: 230406,
            name_cn: "东山区",
            name_en: "Dongshan",
            name_tw: "東山區",
            yzcode: "154106",
          },
          {
            code: 230407,
            name_cn: "兴山区",
            name_en: "Xingshan",
            name_tw: "興山區",
            yzcode: "154105",
          },
          {
            code: 230421,
            name_cn: "萝北县",
            name_en: "Luobei",
            name_tw: "蘿北縣",
            yzcode: "154200",
          },
          {
            code: 230422,
            name_cn: "绥滨县",
            name_en: "Suibin",
            name_tw: "綏濱縣",
            yzcode: "156200",
          },
        ],
      },
      {
        code: 230500,
        name_cn: "双鸭山市",
        name_en: "Shuangyashan",
        name_tw: "雙鴨山市",
        children: [
          {
            code: 230502,
            name_cn: "尖山区",
            name_en: "Jianshan",
            name_tw: "尖山區",
            yzcode: "155100",
          },
          {
            code: 230503,
            name_cn: "岭东区",
            name_en: "Lingdong",
            name_tw: "嶺東區",
            yzcode: "155120",
          },
          {
            code: 230505,
            name_cn: "四方台区",
            name_en: "Sifangtai",
            name_tw: "四方台區",
            yzcode: "155130",
          },
          {
            code: 230506,
            name_cn: "宝山区",
            name_en: "Baoshan",
            name_tw: "寶山區",
            yzcode: "155131",
          },
          {
            code: 230521,
            name_cn: "集贤县",
            name_en: "Jixian",
            name_tw: "集賢縣",
            yzcode: "155900",
          },
          {
            code: 230522,
            name_cn: "友谊县",
            name_en: "Youyi",
            name_tw: "友誼縣",
            yzcode: "155800",
          },
          {
            code: 230523,
            name_cn: "宝清县",
            name_en: "Baoqing",
            name_tw: "寶清縣",
            yzcode: "155600",
          },
          {
            code: 230524,
            name_cn: "饶河县",
            name_en: "Raohe",
            name_tw: "饒河縣",
            yzcode: "155700",
          },
        ],
      },
      {
        code: 230600,
        name_cn: "大庆市",
        name_en: "Daqing",
        name_tw: "大慶市",
        children: [
          {
            code: 230602,
            name_cn: "萨尔图区",
            name_en: "Saertu",
            name_tw: "薩爾圖區",
            yzcode: "163001",
          },
          {
            code: 230603,
            name_cn: "龙凤区",
            name_en: "Longfeng",
            name_tw: "龍鳳區",
            yzcode: "163711",
          },
          {
            code: 230604,
            name_cn: "让胡路区",
            name_en: "Ranghulu",
            name_tw: "讓胡路區",
            yzcode: "163712",
          },
          {
            code: 230605,
            name_cn: "红岗区",
            name_en: "Honggang",
            name_tw: "紅崗區",
            yzcode: "163511",
          },
          {
            code: 230606,
            name_cn: "大同区",
            name_en: "Datong",
            name_tw: "大同區",
            yzcode: "163515",
          },
          {
            code: 230621,
            name_cn: "肇州县",
            name_en: "Zhaozhou",
            name_tw: "肇州縣",
            yzcode: "166400",
          },
          {
            code: 230622,
            name_cn: "肇源县",
            name_en: "Zhaoyuan",
            name_tw: "肇源縣",
            yzcode: "166500",
          },
          {
            code: 230623,
            name_cn: "林甸县",
            name_en: "Lindian",
            name_tw: "林甸縣",
            yzcode: "166300",
          },
          {
            code: 230624,
            name_cn: "杜尔伯特蒙古族自治县",
            name_en: "Duerbote",
            name_tw: "杜爾伯特蒙古族自治縣",
            yzcode: "166200",
          },
        ],
      },
      {
        code: 230700,
        name_cn: "伊春市",
        name_en: "Yichun",
        name_tw: "伊春市",
        children: [
          {
            code: 230702,
            name_cn: "伊春区",
            name_en: "Yichun",
            name_tw: "伊春區",
            yzcode: "153000",
          },
          {
            code: 230703,
            name_cn: "南岔区",
            name_en: "Nancha",
            name_tw: "南岔區",
            yzcode: "153100",
          },
          {
            code: 230704,
            name_cn: "友好区",
            name_en: "Youhao",
            name_tw: "友好區",
            yzcode: "153031",
          },
          {
            code: 230705,
            name_cn: "西林区",
            name_en: "Xilin",
            name_tw: "西林區",
            yzcode: "153025",
          },
          {
            code: 230706,
            name_cn: "翠峦区",
            name_en: "Cuiluan",
            name_tw: "翠巒區",
            yzcode: "153013",
          },
          {
            code: 230707,
            name_cn: "新青区",
            name_en: "Xinqing",
            name_tw: "新青區",
            yzcode: "153036",
          },
          {
            code: 230708,
            name_cn: "美溪区",
            name_en: "Meixi",
            name_tw: "美溪區",
            yzcode: "153021",
          },
          {
            code: 230709,
            name_cn: "金山屯区",
            name_en: "Jinshantun",
            name_tw: "金山屯區",
            yzcode: "153026",
          },
          {
            code: 230710,
            name_cn: "五营区",
            name_en: "Wuying",
            name_tw: "五營區",
            yzcode: "153033",
          },
          {
            code: 230711,
            name_cn: "乌马河区",
            name_en: "Wumahe",
            name_tw: "烏馬河區",
            yzcode: "153011",
          },
          {
            code: 230712,
            name_cn: "汤旺河区",
            name_en: "Tangwanghe",
            name_tw: "湯旺河區",
            yzcode: "153037",
          },
          {
            code: 230713,
            name_cn: "带岭区",
            name_en: "Dailing",
            name_tw: "帶嶺區",
            yzcode: "153106",
          },
          {
            code: 230714,
            name_cn: "乌伊岭区",
            name_en: "Wuyiling",
            name_tw: "烏伊嶺區",
            yzcode: "153038",
          },
          {
            code: 230715,
            name_cn: "红星区",
            name_en: "Hongxing",
            name_tw: "紅星區",
            yzcode: "153035",
          },
          {
            code: 230716,
            name_cn: "上甘岭区",
            name_en: "Shangganling",
            name_tw: "上甘嶺區",
            yzcode: "153032",
          },
          {
            code: 230722,
            name_cn: "嘉荫县",
            name_en: "Jiayin",
            name_tw: "嘉蔭縣",
            yzcode: "153200",
          },
          {
            code: 230781,
            name_cn: "铁力市",
            name_en: "Tieli",
            name_tw: "鐵力市",
            yzcode: "152500",
          },
        ],
      },
      {
        code: 230800,
        name_cn: "佳木斯市",
        name_en: "Jiamusi",
        name_tw: "佳木斯市",
        children: [
          {
            code: 230803,
            name_cn: "向阳区",
            name_en: "Xiangyang",
            name_tw: "向陽區",
            yzcode: "154002",
          },
          {
            code: 230804,
            name_cn: "前进区",
            name_en: "Qianjin",
            name_tw: "前進區",
            yzcode: "154002",
          },
          {
            code: 230805,
            name_cn: "东风区",
            name_en: "Dongfeng",
            name_tw: "東風區",
            yzcode: "154005",
          },
          {
            code: 230811,
            name_cn: "郊区",
            name_en: "Jiaoqu",
            name_tw: "郊區",
            yzcode: "154004",
          },
          {
            code: 230822,
            name_cn: "桦南县",
            name_en: "Huanan",
            name_tw: "樺南縣",
            yzcode: "154400",
          },
          {
            code: 230826,
            name_cn: "桦川县",
            name_en: "Huachuan",
            name_tw: "樺川縣",
            yzcode: "154300",
          },
          {
            code: 230828,
            name_cn: "汤原县",
            name_en: "Tangyuan",
            name_tw: "湯原縣",
            yzcode: "154700",
          },
          {
            code: 230833,
            name_cn: "抚远县",
            name_en: "Fuyuan",
            name_tw: "撫遠縣",
            yzcode: "156500",
          },
          {
            code: 230881,
            name_cn: "同江市",
            name_en: "Tongjiang",
            name_tw: "同江市",
            yzcode: "156400",
          },
          {
            code: 230882,
            name_cn: "富锦市",
            name_en: "Fujin",
            name_tw: "富錦市",
            yzcode: "156100",
          },
        ],
      },
      {
        code: 230900,
        name_cn: "七台河市",
        name_en: "Qitaihe",
        name_tw: "七台河市",
        children: [
          {
            code: 230902,
            name_cn: "新兴区",
            name_en: "Xinxing",
            name_tw: "新興區",
            yzcode: "154604",
          },
          {
            code: 230903,
            name_cn: "桃山区",
            name_en: "Taoshan",
            name_tw: "桃山區",
            yzcode: "154600",
          },
          {
            code: 230904,
            name_cn: "茄子河区",
            name_en: "Qiezihe",
            name_tw: "茄子河區",
            yzcode: "154622",
          },
          {
            code: 230921,
            name_cn: "勃利县",
            name_en: "Boli",
            name_tw: "勃利縣",
            yzcode: "154500",
          },
        ],
      },
      {
        code: 231000,
        name_cn: "牡丹江市",
        name_en: "Mudanjiang",
        name_tw: "牡丹江市",
        children: [
          {
            code: 231002,
            name_cn: "东安区",
            name_en: "Dong'an",
            name_tw: "東安區",
            yzcode: "157000",
          },
          {
            code: 231003,
            name_cn: "阳明区",
            name_en: "Yangming",
            name_tw: "陽明區",
            yzcode: "157013",
          },
          {
            code: 231004,
            name_cn: "爱民区",
            name_en: "Aimin",
            name_tw: "愛民區",
            yzcode: "157009",
          },
          {
            code: 231005,
            name_cn: "西安区",
            name_en: "Xi'an",
            name_tw: "西安區",
            yzcode: "157000",
          },
          {
            code: 231024,
            name_cn: "东宁县",
            name_en: "Dongning",
            name_tw: "東寧縣",
            yzcode: "157200",
          },
          {
            code: 231025,
            name_cn: "林口县",
            name_en: "Linkou",
            name_tw: "林口縣",
            yzcode: "157600",
          },
          {
            code: 231081,
            name_cn: "绥芬河市",
            name_en: "Suifenhe",
            name_tw: "綏芬河市",
            yzcode: "157300",
          },
          {
            code: 231083,
            name_cn: "海林市",
            name_en: "Hailin",
            name_tw: "海林市",
            yzcode: "157100",
          },
          {
            code: 231084,
            name_cn: "宁安市",
            name_en: "Ning'an",
            name_tw: "寧安市",
            yzcode: "157400",
          },
          {
            code: 231085,
            name_cn: "穆棱市",
            name_en: "Muling",
            name_tw: "穆棱市",
            yzcode: "157500",
          },
        ],
      },
      {
        code: 231100,
        name_cn: "黑河市",
        name_en: "Heihe",
        name_tw: "黑河市",
        children: [
          {
            code: 231102,
            name_cn: "爱辉区",
            name_en: "Aihui",
            name_tw: "愛輝區",
            yzcode: "164300",
          },
          {
            code: 231121,
            name_cn: "嫩江县",
            name_en: "Nenjiang",
            name_tw: "嫩江縣",
            yzcode: "161400",
          },
          {
            code: 231123,
            name_cn: "逊克县",
            name_en: "Xunke",
            name_tw: "遜克縣",
            yzcode: "164400",
          },
          {
            code: 231124,
            name_cn: "孙吴县",
            name_en: "Sunwu",
            name_tw: "孫吳縣",
            yzcode: "164200",
          },
          {
            code: 231181,
            name_cn: "北安市",
            name_en: "Bei'an",
            name_tw: "北安市",
            yzcode: "164000",
          },
          {
            code: 231182,
            name_cn: "五大连池市",
            name_en: "Wudalianchi",
            name_tw: "五大連池市",
            yzcode: "164100",
          },
        ],
      },
      {
        code: 231200,
        name_cn: "绥化市",
        name_en: "Suihua",
        name_tw: "綏化市",
        children: [
          {
            code: 231202,
            name_cn: "北林区",
            name_en: "Beilin",
            name_tw: "北林區",
            yzcode: "152000",
          },
          {
            code: 231221,
            name_cn: "望奎县",
            name_en: "Wangkui",
            name_tw: "望奎縣",
            yzcode: "152100",
          },
          {
            code: 231222,
            name_cn: "兰西县",
            name_en: "Lanxi",
            name_tw: "蘭西縣",
            yzcode: "151500",
          },
          {
            code: 231223,
            name_cn: "青冈县",
            name_en: "Qinggang",
            name_tw: "青岡縣",
            yzcode: "151600",
          },
          {
            code: 231224,
            name_cn: "庆安县",
            name_en: "Qing'an",
            name_tw: "慶安縣",
            yzcode: "152400",
          },
          {
            code: 231225,
            name_cn: "明水县",
            name_en: "Mingshui",
            name_tw: "明水縣",
            yzcode: "151700",
          },
          {
            code: 231226,
            name_cn: "绥棱县",
            name_en: "Suileng",
            name_tw: "綏棱縣",
            yzcode: "152200",
          },
          {
            code: 231281,
            name_cn: "安达市",
            name_en: "Anda",
            name_tw: "安達市",
            yzcode: "151400",
          },
          {
            code: 231282,
            name_cn: "肇东市",
            name_en: "Zhaodong",
            name_tw: "肇東市",
            yzcode: "151100",
          },
          {
            code: 231283,
            name_cn: "海伦市",
            name_en: "Hailun",
            name_tw: "海倫市",
            yzcode: "152300",
          },
        ],
      },
      {
        code: 232700,
        name_cn: "大兴安岭地区",
        name_en: "DaXingAnLing",
        name_tw: "大興安嶺地區",
        children: [
          {
            code: 232701,
            name_cn: "加格达奇区",
            name_en: "Jiagedaqi",
            name_tw: "加格達奇區",
            yzcode: "165000",
          },
          {
            code: 232702,
            name_cn: "新林区",
            name_en: "Xinlin",
            name_tw: "新林區",
            yzcode: "165000",
          },
          {
            code: 232703,
            name_cn: "松岭区",
            name_en: "Songling",
            name_tw: "松嶺區",
            yzcode: "165000",
          },
          {
            code: 232704,
            name_cn: "呼中区",
            name_en: "Huzhong",
            name_tw: "呼中區",
            yzcode: "165000",
          },
          {
            code: 232721,
            name_cn: "呼玛县",
            name_en: "Huma",
            name_tw: "呼瑪縣",
            yzcode: "165100",
          },
          {
            code: 232722,
            name_cn: "塔河县",
            name_en: "Tahe",
            name_tw: "塔河縣",
            yzcode: "165200",
          },
          {
            code: 232723,
            name_cn: "漠河县",
            name_en: "Mohe",
            name_tw: "漠河縣",
            yzcode: "165300",
          },
        ],
      },
    ],
  },
  {
    code: 310000,
    name_cn: "上海",
    name_en: "Shanghai",
    name_tw: "上海",
    children: [
      {
        code: 310100,
        name_cn: "上海市",
        name_en: "Shanghai",
        name_tw: "上海市",
        children: [
          {
            code: 310101,
            name_cn: "黄浦区",
            name_en: "Huangpu",
            name_tw: "黃浦區",
            yzcode: "200001",
          },
          {
            code: 310104,
            name_cn: "徐汇区",
            name_en: "Xuhui",
            name_tw: "徐匯區",
            yzcode: "200030",
          },
          {
            code: 310105,
            name_cn: "长宁区",
            name_en: "Changning",
            name_tw: "長寧區",
            yzcode: "200050",
          },
          {
            code: 310106,
            name_cn: "静安区",
            name_en: "Jing'an",
            name_tw: "靜安區",
            yzcode: "200040",
          },
          {
            code: 310107,
            name_cn: "普陀区",
            name_en: "Putuo",
            name_tw: "普陀區",
            yzcode: "200333",
          },
          {
            code: 310108,
            name_cn: "闸北区",
            name_en: "Zhabei",
            name_tw: "閘北區",
            yzcode: "200070",
          },
          {
            code: 310109,
            name_cn: "虹口区",
            name_en: "Hongkou",
            name_tw: "虹口區",
            yzcode: "200086",
          },
          {
            code: 310110,
            name_cn: "杨浦区",
            name_en: "Yangpu",
            name_tw: "楊浦區",
            yzcode: "200082",
          },
          {
            code: 310112,
            name_cn: "闵行区",
            name_en: "Minhang",
            name_tw: "閔行區",
            yzcode: "201100",
          },
          {
            code: 310113,
            name_cn: "宝山区",
            name_en: "Baoshan",
            name_tw: "寶山區",
            yzcode: "201900",
          },
          {
            code: 310114,
            name_cn: "嘉定区",
            name_en: "Jiading",
            name_tw: "嘉定區",
            yzcode: "201800",
          },
          {
            code: 310115,
            name_cn: "浦东新区",
            name_en: "Pudong",
            name_tw: "浦東新區",
            yzcode: "200135",
          },
          {
            code: 310116,
            name_cn: "金山区",
            name_en: "Jinshan",
            name_tw: "金山區",
            yzcode: "200540",
          },
          {
            code: 310117,
            name_cn: "松江区",
            name_en: "Songjiang",
            name_tw: "松江區",
            yzcode: "201600",
          },
          {
            code: 310118,
            name_cn: "青浦区",
            name_en: "Qingpu",
            name_tw: "青浦區",
            yzcode: "201700",
          },
          {
            code: 310120,
            name_cn: "奉贤区",
            name_en: "Fengxian",
            name_tw: "奉賢區",
            yzcode: "201400",
          },
          {
            code: 310230,
            name_cn: "崇明县",
            name_en: "Chongming",
            name_tw: "崇明縣",
            yzcode: "202150",
          },
        ],
      },
    ],
  },
  {
    code: 320000,
    name_cn: "江苏省",
    name_en: "Jiangsu",
    name_tw: "江蘇省",
    children: [
      {
        code: 320100,
        name_cn: "南京市",
        name_en: "Nanjing",
        name_tw: "南京市",
        children: [
          {
            code: 320102,
            name_cn: "玄武区",
            name_en: "Xuanwu",
            name_tw: "玄武區",
            yzcode: "210018",
          },
          {
            code: 320104,
            name_cn: "秦淮区",
            name_en: "Qinhuai",
            name_tw: "秦淮區",
            yzcode: "210001",
          },
          {
            code: 320105,
            name_cn: "建邺区",
            name_en: "Jianye",
            name_tw: "建鄴區",
            yzcode: "210004",
          },
          {
            code: 320106,
            name_cn: "鼓楼区",
            name_en: "Gulou",
            name_tw: "鼓樓區",
            yzcode: "210009",
          },
          {
            code: 320111,
            name_cn: "浦口区",
            name_en: "Pukou",
            name_tw: "浦口區",
            yzcode: "211800",
          },
          {
            code: 320113,
            name_cn: "栖霞区",
            name_en: "Qixia",
            name_tw: "棲霞區",
            yzcode: "210046",
          },
          {
            code: 320114,
            name_cn: "雨花台区",
            name_en: "Yuhuatai",
            name_tw: "雨花台區",
            yzcode: "210012",
          },
          {
            code: 320115,
            name_cn: "江宁区",
            name_en: "Jiangning",
            name_tw: "江寧區",
            yzcode: "211100",
          },
          {
            code: 320116,
            name_cn: "六合区",
            name_en: "Luhe",
            name_tw: "六合區",
            yzcode: "211500",
          },
          {
            code: 320117,
            name_cn: "溧水区",
            name_en: "Lishui",
            name_tw: "溧水區",
            yzcode: "211200",
          },
          {
            code: 320118,
            name_cn: "高淳区",
            name_en: "Gaochun",
            name_tw: "高淳區",
            yzcode: "211300",
          },
        ],
      },
      {
        code: 320200,
        name_cn: "无锡市",
        name_en: "Wuxi",
        name_tw: "無錫市",
        children: [
          {
            code: 320202,
            name_cn: "崇安区",
            name_en: "Chong'an",
            name_tw: "崇安區",
            yzcode: "214001",
          },
          {
            code: 320203,
            name_cn: "南长区",
            name_en: "Nanchang",
            name_tw: "南長區",
            yzcode: "214021",
          },
          {
            code: 320204,
            name_cn: "北塘区",
            name_en: "Beitang",
            name_tw: "北塘區",
            yzcode: "214044",
          },
          {
            code: 320205,
            name_cn: "锡山区",
            name_en: "Xishan",
            name_tw: "錫山區",
            yzcode: "214101",
          },
          {
            code: 320206,
            name_cn: "惠山区",
            name_en: "Huishan",
            name_tw: "惠山區",
            yzcode: "214174",
          },
          {
            code: 320211,
            name_cn: "滨湖区",
            name_en: "Binhu",
            name_tw: "濱湖區",
            yzcode: "214123",
          },
          {
            code: 320281,
            name_cn: "江阴市",
            name_en: "Jiangyin",
            name_tw: "江陰市",
            yzcode: "214431",
          },
          {
            code: 320282,
            name_cn: "宜兴市",
            name_en: "Yixing",
            name_tw: "宜興市",
            yzcode: "214200",
          },
        ],
      },
      {
        code: 320300,
        name_cn: "徐州市",
        name_en: "Xuzhou",
        name_tw: "徐州市",
        children: [
          {
            code: 320302,
            name_cn: "鼓楼区",
            name_en: "Gulou",
            name_tw: "鼓樓區",
            yzcode: "221005",
          },
          {
            code: 320303,
            name_cn: "云龙区",
            name_en: "Yunlong",
            name_tw: "雲龍區",
            yzcode: "221007",
          },
          {
            code: 320305,
            name_cn: "贾汪区",
            name_en: "Jiawang",
            name_tw: "賈汪區",
            yzcode: "221003",
          },
          {
            code: 320311,
            name_cn: "泉山区",
            name_en: "Quanshan",
            name_tw: "泉山區",
            yzcode: "221006",
          },
          {
            code: 320312,
            name_cn: "铜山区",
            name_en: "Tongshan",
            name_tw: "銅山區",
            yzcode: "221106",
          },
          {
            code: 320321,
            name_cn: "丰县",
            name_en: "Fengxian",
            name_tw: "豐縣",
            yzcode: "221700",
          },
          {
            code: 320322,
            name_cn: "沛县",
            name_en: "Peixian",
            name_tw: "沛縣",
            yzcode: "221600",
          },
          {
            code: 320324,
            name_cn: "睢宁县",
            name_en: "Suining",
            name_tw: "睢寧縣",
            yzcode: "221200",
          },
          {
            code: 320381,
            name_cn: "新沂市",
            name_en: "Xinyi",
            name_tw: "新沂市",
            yzcode: "221400",
          },
          {
            code: 320382,
            name_cn: "邳州市",
            name_en: "Pizhou",
            name_tw: "邳州市",
            yzcode: "221300",
          },
        ],
      },
      {
        code: 320400,
        name_cn: "常州市",
        name_en: "Changzhou",
        name_tw: "常州市",
        children: [
          {
            code: 320402,
            name_cn: "天宁区",
            name_en: "Tianning",
            name_tw: "天寧區",
            yzcode: "213000",
          },
          {
            code: 320404,
            name_cn: "钟楼区",
            name_en: "Zhonglou",
            name_tw: "鐘樓區",
            yzcode: "213023",
          },
          {
            code: 320405,
            name_cn: "戚墅堰区",
            name_en: "Qishuyan",
            name_tw: "戚墅堰區",
            yzcode: "213025",
          },
          {
            code: 320411,
            name_cn: "新北区",
            name_en: "Xinbei",
            name_tw: "新北區",
            yzcode: "213022",
          },
          {
            code: 320412,
            name_cn: "武进区",
            name_en: "Wujin",
            name_tw: "武進區",
            yzcode: "213100",
          },
          {
            code: 320481,
            name_cn: "溧阳市",
            name_en: "Liyang",
            name_tw: "溧陽市",
            yzcode: "213300",
          },
          {
            code: 320482,
            name_cn: "金坛市",
            name_en: "Jintan",
            name_tw: "金壇市",
            yzcode: "213200",
          },
        ],
      },
      {
        code: 320500,
        name_cn: "苏州市",
        name_en: "Suzhou",
        name_tw: "蘇州市",
        children: [
          {
            code: 320505,
            name_cn: "虎丘区",
            name_en: "Huqiu",
            name_tw: "虎丘區",
            yzcode: "215004",
          },
          {
            code: 320506,
            name_cn: "吴中区",
            name_en: "Wuzhong",
            name_tw: "吳中區",
            yzcode: "215128",
          },
          {
            code: 320507,
            name_cn: "相城区",
            name_en: "Xiangcheng",
            name_tw: "相城區",
            yzcode: "215131",
          },
          {
            code: 320508,
            name_cn: "姑苏区",
            name_en: "Gusu",
            name_tw: "姑蘇區",
            yzcode: "215031",
          },
          {
            code: 320509,
            name_cn: "吴江区",
            name_en: "Wujiang",
            name_tw: "吳江區",
            yzcode: "215200",
          },
          {
            code: 320581,
            name_cn: "常熟市",
            name_en: "Changshu",
            name_tw: "常熟市",
            yzcode: "215500",
          },
          {
            code: 320582,
            name_cn: "张家港市",
            name_en: "Zhangjiagang",
            name_tw: "張家港市",
            yzcode: "215600",
          },
          {
            code: 320583,
            name_cn: "昆山市",
            name_en: "Kunshan",
            name_tw: "昆山市",
            yzcode: "215300",
          },
          {
            code: 320585,
            name_cn: "太仓市",
            name_en: "Taicang",
            name_tw: "太倉市",
            yzcode: "215400",
          },
        ],
      },
      {
        code: 320600,
        name_cn: "南通市",
        name_en: "Nantong",
        name_tw: "南通市",
        children: [
          {
            code: 320602,
            name_cn: "崇川区",
            name_en: "Chongchuan",
            name_tw: "崇川區",
            yzcode: "226001",
          },
          {
            code: 320611,
            name_cn: "港闸区",
            name_en: "Gangzha",
            name_tw: "港閘區",
            yzcode: "226001",
          },
          {
            code: 320612,
            name_cn: "通州区",
            name_en: "Tongzhou",
            name_tw: "通州區",
            yzcode: "226300",
          },
          {
            code: 320621,
            name_cn: "海安县",
            name_en: "Hai'an",
            name_tw: "海安縣",
            yzcode: "226600",
          },
          {
            code: 320623,
            name_cn: "如东县",
            name_en: "Rudong",
            name_tw: "如東縣",
            yzcode: "226400",
          },
          {
            code: 320681,
            name_cn: "启东市",
            name_en: "Qidong",
            name_tw: "啟東市",
            yzcode: "226200",
          },
          {
            code: 320682,
            name_cn: "如皋市",
            name_en: "Rugao",
            name_tw: "如臯市",
            yzcode: "226500",
          },
          {
            code: 320684,
            name_cn: "海门市",
            name_en: "Haimen",
            name_tw: "海門市",
            yzcode: "226100",
          },
        ],
      },
      {
        code: 320700,
        name_cn: "连云港市",
        name_en: "Lianyungang",
        name_tw: "連雲港市",
        children: [
          {
            code: 320703,
            name_cn: "连云区",
            name_en: "Lianyun",
            name_tw: "連雲區",
            yzcode: "222042",
          },
          {
            code: 320706,
            name_cn: "海州区",
            name_en: "Haizhou",
            name_tw: "海州區",
            yzcode: "222003",
          },
          {
            code: 320707,
            name_cn: "赣榆区",
            name_en: "Ganyu",
            name_tw: "贛榆區",
            yzcode: "222100",
          },
          {
            code: 320722,
            name_cn: "东海县",
            name_en: "Donghai",
            name_tw: "東海縣",
            yzcode: "222300",
          },
          {
            code: 320723,
            name_cn: "灌云县",
            name_en: "Guanyun",
            name_tw: "灌雲縣",
            yzcode: "222200",
          },
          {
            code: 320724,
            name_cn: "灌南县",
            name_en: "Guannan",
            name_tw: "灌南縣",
            yzcode: "222500",
          },
        ],
      },
      {
        code: 320800,
        name_cn: "淮安市",
        name_en: "Huai'an",
        name_tw: "淮安市",
        children: [
          {
            code: 320802,
            name_cn: "清河区",
            name_en: "Qinghe",
            name_tw: "清河區",
            yzcode: "223001",
          },
          {
            code: 320803,
            name_cn: "淮安区",
            name_en: "Huai'an",
            name_tw: "淮安區",
            yzcode: "223200",
          },
          {
            code: 320804,
            name_cn: "淮阴区",
            name_en: "Huaiyin",
            name_tw: "淮陰區",
            yzcode: "223300",
          },
          {
            code: 320811,
            name_cn: "清浦区",
            name_en: "Qingpu",
            name_tw: "清浦區",
            yzcode: "223002",
          },
          {
            code: 320826,
            name_cn: "涟水县",
            name_en: "Lianshui",
            name_tw: "漣水縣",
            yzcode: "223400",
          },
          {
            code: 320829,
            name_cn: "洪泽县",
            name_en: "Hongze",
            name_tw: "洪澤縣",
            yzcode: "223100",
          },
          {
            code: 320830,
            name_cn: "盱眙县",
            name_en: "Xuyi",
            name_tw: "盱眙縣",
            yzcode: "211700",
          },
          {
            code: 320831,
            name_cn: "金湖县",
            name_en: "Jinhu",
            name_tw: "金湖縣",
            yzcode: "211600",
          },
        ],
      },
      {
        code: 320900,
        name_cn: "盐城市",
        name_en: "Yancheng",
        name_tw: "鹽城市",
        children: [
          {
            code: 320902,
            name_cn: "亭湖区",
            name_en: "Tinghu",
            name_tw: "亭湖區",
            yzcode: "224005",
          },
          {
            code: 320903,
            name_cn: "盐都区",
            name_en: "Yandu",
            name_tw: "鹽都區",
            yzcode: "224055",
          },
          {
            code: 320921,
            name_cn: "响水县",
            name_en: "Xiangshui",
            name_tw: "響水縣",
            yzcode: "224600",
          },
          {
            code: 320922,
            name_cn: "滨海县",
            name_en: "Binhai",
            name_tw: "濱海縣",
            yzcode: "224500",
          },
          {
            code: 320923,
            name_cn: "阜宁县",
            name_en: "Funing",
            name_tw: "阜寧縣",
            yzcode: "224400",
          },
          {
            code: 320924,
            name_cn: "射阳县",
            name_en: "Sheyang",
            name_tw: "射陽縣",
            yzcode: "224300",
          },
          {
            code: 320925,
            name_cn: "建湖县",
            name_en: "Jianhu",
            name_tw: "建湖縣",
            yzcode: "224700",
          },
          {
            code: 320981,
            name_cn: "东台市",
            name_en: "Dongtai",
            name_tw: "東台市",
            yzcode: "224200",
          },
          {
            code: 320982,
            name_cn: "大丰市",
            name_en: "Dafeng",
            name_tw: "大豐市",
            yzcode: "224100",
          },
        ],
      },
      {
        code: 321000,
        name_cn: "扬州市",
        name_en: "Yangzhou",
        name_tw: "揚州市",
        children: [
          {
            code: 321002,
            name_cn: "广陵区",
            name_en: "Guangling",
            name_tw: "廣陵區",
            yzcode: "225002",
          },
          {
            code: 321003,
            name_cn: "邗江区",
            name_en: "Hanjiang",
            name_tw: "邗江區",
            yzcode: "225002",
          },
          {
            code: 321012,
            name_cn: "江都区",
            name_en: "Jiangdu",
            name_tw: "江都區",
            yzcode: "225200",
          },
          {
            code: 321023,
            name_cn: "宝应县",
            name_en: "Baoying",
            name_tw: "寶應縣",
            yzcode: "225800",
          },
          {
            code: 321081,
            name_cn: "仪征市",
            name_en: "Yizheng",
            name_tw: "儀征市",
            yzcode: "211400",
          },
          {
            code: 321084,
            name_cn: "高邮市",
            name_en: "Gaoyou",
            name_tw: "高郵市",
            yzcode: "225600",
          },
        ],
      },
      {
        code: 321100,
        name_cn: "镇江市",
        name_en: "Zhenjiang",
        name_tw: "鎮江市",
        children: [
          {
            code: 321102,
            name_cn: "京口区",
            name_en: "Jingkou",
            name_tw: "京口區",
            yzcode: "212003",
          },
          {
            code: 321111,
            name_cn: "润州区",
            name_en: "Runzhou",
            name_tw: "潤州區",
            yzcode: "212005",
          },
          {
            code: 321112,
            name_cn: "丹徒区",
            name_en: "Dantu",
            name_tw: "丹徒區",
            yzcode: "212028",
          },
          {
            code: 321181,
            name_cn: "丹阳市",
            name_en: "Danyang",
            name_tw: "丹陽市",
            yzcode: "212300",
          },
          {
            code: 321182,
            name_cn: "扬中市",
            name_en: "Yangzhong",
            name_tw: "揚中市",
            yzcode: "212200",
          },
          {
            code: 321183,
            name_cn: "句容市",
            name_en: "Jurong",
            name_tw: "句容市",
            yzcode: "212400",
          },
        ],
      },
      {
        code: 321200,
        name_cn: "泰州市",
        name_en: "Taizhou",
        name_tw: "泰州市",
        children: [
          {
            code: 321202,
            name_cn: "海陵区",
            name_en: "Hailing",
            name_tw: "海陵區",
            yzcode: "225300",
          },
          {
            code: 321203,
            name_cn: "高港区",
            name_en: "Gaogang",
            name_tw: "高港區",
            yzcode: "225321",
          },
          {
            code: 321204,
            name_cn: "姜堰区",
            name_en: "Jiangyan",
            name_tw: "姜堰區",
            yzcode: "225500",
          },
          {
            code: 321281,
            name_cn: "兴化市",
            name_en: "Xinghua",
            name_tw: "興化市",
            yzcode: "225700",
          },
          {
            code: 321282,
            name_cn: "靖江市",
            name_en: "Jingjiang",
            name_tw: "靖江市",
            yzcode: "214500",
          },
          {
            code: 321283,
            name_cn: "泰兴市",
            name_en: "Taixing",
            name_tw: "泰興市",
            yzcode: "225400",
          },
        ],
      },
      {
        code: 321300,
        name_cn: "宿迁市",
        name_en: "Suqian",
        name_tw: "宿遷市",
        children: [
          {
            code: 321302,
            name_cn: "宿城区",
            name_en: "Sucheng",
            name_tw: "宿城區",
            yzcode: "223800",
          },
          {
            code: 321311,
            name_cn: "宿豫区",
            name_en: "Suyu",
            name_tw: "宿豫區",
            yzcode: "223800",
          },
          {
            code: 321322,
            name_cn: "沭阳县",
            name_en: "Shuyang",
            name_tw: "沭陽縣",
            yzcode: "223600",
          },
          {
            code: 321323,
            name_cn: "泗阳县",
            name_en: "Siyang",
            name_tw: "泗陽縣",
            yzcode: "223700",
          },
          {
            code: 321324,
            name_cn: "泗洪县",
            name_en: "Sihong",
            name_tw: "泗洪縣",
            yzcode: "223900",
          },
        ],
      },
    ],
  },
  {
    code: 330000,
    name_cn: "浙江省",
    name_en: "Zhejiang",
    name_tw: "浙江省",
    children: [
      {
        code: 330100,
        name_cn: "杭州市",
        name_en: "Hangzhou",
        name_tw: "杭州市",
        children: [
          {
            code: 330102,
            name_cn: "上城区",
            name_en: "Shangcheng",
            name_tw: "上城區",
            yzcode: "310002",
          },
          {
            code: 330103,
            name_cn: "下城区",
            name_en: "Xiacheng",
            name_tw: "下城區",
            yzcode: "310006",
          },
          {
            code: 330104,
            name_cn: "江干区",
            name_en: "Jianggan",
            name_tw: "江幹區",
            yzcode: "310016",
          },
          {
            code: 330105,
            name_cn: "拱墅区",
            name_en: "Gongshu",
            name_tw: "拱墅區",
            yzcode: "310011",
          },
          {
            code: 330106,
            name_cn: "西湖区",
            name_en: "Xihu",
            name_tw: "西湖區",
            yzcode: "310013",
          },
          {
            code: 330108,
            name_cn: "滨江区",
            name_en: "Binjiang",
            name_tw: "濱江區",
            yzcode: "310051",
          },
          {
            code: 330109,
            name_cn: "萧山区",
            name_en: "Xiaoshan",
            name_tw: "蕭山區",
            yzcode: "311200",
          },
          {
            code: 330110,
            name_cn: "余杭区",
            name_en: "Yuhang",
            name_tw: "余杭區",
            yzcode: "311100",
          },
          {
            code: 330122,
            name_cn: "桐庐县",
            name_en: "Tonglu",
            name_tw: "桐廬縣",
            yzcode: "311500",
          },
          {
            code: 330127,
            name_cn: "淳安县",
            name_en: "Chun'an",
            name_tw: "淳安縣",
            yzcode: "311700",
          },
          {
            code: 330182,
            name_cn: "建德市",
            name_en: "Jiande",
            name_tw: "建德市",
            yzcode: "311600",
          },
          {
            code: 330183,
            name_cn: "富阳区",
            name_en: "Fuyang",
            name_tw: "富陽區",
            yzcode: "311400",
          },
          {
            code: 330185,
            name_cn: "临安市",
            name_en: "Lin'an",
            name_tw: "臨安市",
            yzcode: "311300",
          },
        ],
      },
      {
        code: 330200,
        name_cn: "宁波市",
        name_en: "Ningbo",
        name_tw: "寧波市",
        children: [
          {
            code: 330203,
            name_cn: "海曙区",
            name_en: "Haishu",
            name_tw: "海曙區",
            yzcode: "315000",
          },
          {
            code: 330204,
            name_cn: "江东区",
            name_en: "Jiangdong",
            name_tw: "江東區",
            yzcode: "315040",
          },
          {
            code: 330205,
            name_cn: "江北区",
            name_en: "Jiangbei",
            name_tw: "江北區",
            yzcode: "315020",
          },
          {
            code: 330206,
            name_cn: "北仑区",
            name_en: "Beilun",
            name_tw: "北侖區",
            yzcode: "315800",
          },
          {
            code: 330211,
            name_cn: "镇海区",
            name_en: "Zhenhai",
            name_tw: "鎮海區",
            yzcode: "315200",
          },
          {
            code: 330212,
            name_cn: "鄞州区",
            name_en: "Yinzhou",
            name_tw: "鄞州區",
            yzcode: "315100",
          },
          {
            code: 330225,
            name_cn: "象山县",
            name_en: "Xiangshan",
            name_tw: "象山縣",
            yzcode: "315700",
          },
          {
            code: 330226,
            name_cn: "宁海县",
            name_en: "Ninghai",
            name_tw: "寧海縣",
            yzcode: "315600",
          },
          {
            code: 330281,
            name_cn: "余姚市",
            name_en: "Yuyao",
            name_tw: "余姚市",
            yzcode: "315400",
          },
          {
            code: 330282,
            name_cn: "慈溪市",
            name_en: "Cixi",
            name_tw: "慈溪市",
            yzcode: "315300",
          },
          {
            code: 330283,
            name_cn: "奉化市",
            name_en: "Fenghua",
            name_tw: "奉化市",
            yzcode: "315500",
          },
        ],
      },
      {
        code: 330300,
        name_cn: "温州市",
        name_en: "Wenzhou",
        name_tw: "溫州市",
        children: [
          {
            code: 330302,
            name_cn: "鹿城区",
            name_en: "Lucheng",
            name_tw: "鹿城區",
            yzcode: "325000",
          },
          {
            code: 330303,
            name_cn: "龙湾区",
            name_en: "Longwan",
            name_tw: "龍灣區",
            yzcode: "325013",
          },
          {
            code: 330304,
            name_cn: "瓯海区",
            name_en: "Ouhai",
            name_tw: "甌海區",
            yzcode: "325005",
          },
          {
            code: 330322,
            name_cn: "洞头县",
            name_en: "Dongtou",
            name_tw: "洞頭縣",
            yzcode: "325700",
          },
          {
            code: 330324,
            name_cn: "永嘉县",
            name_en: "Yongjia",
            name_tw: "永嘉縣",
            yzcode: "325100",
          },
          {
            code: 330326,
            name_cn: "平阳县",
            name_en: "Pingyang",
            name_tw: "平陽縣",
            yzcode: "325400",
          },
          {
            code: 330327,
            name_cn: "苍南县",
            name_en: "Cangnan",
            name_tw: "蒼南縣",
            yzcode: "325800",
          },
          {
            code: 330328,
            name_cn: "文成县",
            name_en: "Wencheng",
            name_tw: "文成縣",
            yzcode: "325300",
          },
          {
            code: 330329,
            name_cn: "泰顺县",
            name_en: "Taishun",
            name_tw: "泰順縣",
            yzcode: "325500",
          },
          {
            code: 330381,
            name_cn: "瑞安市",
            name_en: "Rui'an",
            name_tw: "瑞安市",
            yzcode: "325200",
          },
          {
            code: 330382,
            name_cn: "乐清市",
            name_en: "Yueqing",
            name_tw: "樂清市",
            yzcode: "325600",
          },
        ],
      },
      {
        code: 330400,
        name_cn: "嘉兴市",
        name_en: "Jiaxing",
        name_tw: "嘉興市",
        children: [
          {
            code: 330402,
            name_cn: "南湖区",
            name_en: "Nanhu",
            name_tw: "南湖區",
            yzcode: "314051",
          },
          {
            code: 330411,
            name_cn: "秀洲区",
            name_en: "Xiuzhou",
            name_tw: "秀洲區",
            yzcode: "314031",
          },
          {
            code: 330421,
            name_cn: "嘉善县",
            name_en: "Jiashan",
            name_tw: "嘉善縣",
            yzcode: "314100",
          },
          {
            code: 330424,
            name_cn: "海盐县",
            name_en: "Haiyan",
            name_tw: "海鹽縣",
            yzcode: "314300",
          },
          {
            code: 330481,
            name_cn: "海宁市",
            name_en: "Haining",
            name_tw: "海寧市",
            yzcode: "314400",
          },
          {
            code: 330482,
            name_cn: "平湖市",
            name_en: "Pinghu",
            name_tw: "平湖市",
            yzcode: "314200",
          },
          {
            code: 330483,
            name_cn: "桐乡市",
            name_en: "Tongxiang",
            name_tw: "桐鄉市",
            yzcode: "314500",
          },
        ],
      },
      {
        code: 330500,
        name_cn: "湖州市",
        name_en: "Huzhou",
        name_tw: "湖州市",
        children: [
          {
            code: 330502,
            name_cn: "吴兴区",
            name_en: "Wuxing",
            name_tw: "吳興區",
            yzcode: "313000",
          },
          {
            code: 330503,
            name_cn: "南浔区",
            name_en: "Nanxun",
            name_tw: "南潯區",
            yzcode: "313009",
          },
          {
            code: 330521,
            name_cn: "德清县",
            name_en: "Deqing",
            name_tw: "德清縣",
            yzcode: "313200",
          },
          {
            code: 330522,
            name_cn: "长兴县",
            name_en: "Changxing",
            name_tw: "長興縣",
            yzcode: "313100",
          },
          {
            code: 330523,
            name_cn: "安吉县",
            name_en: "Anji",
            name_tw: "安吉縣",
            yzcode: "313300",
          },
        ],
      },
      {
        code: 330600,
        name_cn: "绍兴市",
        name_en: "Shaoxing",
        name_tw: "紹興市",
        children: [
          {
            code: 330602,
            name_cn: "越城区",
            name_en: "Yuecheng",
            name_tw: "越城區",
            yzcode: "312000",
          },
          {
            code: 330603,
            name_cn: "柯桥区",
            name_en: "Keqiao ",
            name_tw: "柯橋區",
            yzcode: "312030",
          },
          {
            code: 330604,
            name_cn: "上虞区",
            name_en: "Shangyu",
            name_tw: "上虞區",
            yzcode: "312300",
          },
          {
            code: 330624,
            name_cn: "新昌县",
            name_en: "Xinchang",
            name_tw: "新昌縣",
            yzcode: "312500",
          },
          {
            code: 330681,
            name_cn: "诸暨市",
            name_en: "Zhuji",
            name_tw: "諸暨市",
            yzcode: "311800",
          },
          {
            code: 330683,
            name_cn: "嵊州市",
            name_en: "Shengzhou",
            name_tw: "嵊州市",
            yzcode: "312400",
          },
        ],
      },
      {
        code: 330700,
        name_cn: "金华市",
        name_en: "Jinhua",
        name_tw: "金華市",
        children: [
          {
            code: 330702,
            name_cn: "婺城区",
            name_en: "Wucheng",
            name_tw: "婺城區",
            yzcode: "321000",
          },
          {
            code: 330703,
            name_cn: "金东区",
            name_en: "Jindong",
            name_tw: "金東區",
            yzcode: "321000",
          },
          {
            code: 330723,
            name_cn: "武义县",
            name_en: "Wuyi",
            name_tw: "武義縣",
            yzcode: "321200",
          },
          {
            code: 330726,
            name_cn: "浦江县",
            name_en: "Pujiang",
            name_tw: "浦江縣",
            yzcode: "322200",
          },
          {
            code: 330727,
            name_cn: "磐安县",
            name_en: "Pan'an",
            name_tw: "磐安縣",
            yzcode: "322300",
          },
          {
            code: 330781,
            name_cn: "兰溪市",
            name_en: "Lanxi",
            name_tw: "蘭溪市",
            yzcode: "321100",
          },
          {
            code: 330782,
            name_cn: "义乌市",
            name_en: "Yiwu",
            name_tw: "義烏市",
            yzcode: "322000",
          },
          {
            code: 330783,
            name_cn: "东阳市",
            name_en: "Dongyang",
            name_tw: "東陽市",
            yzcode: "322100",
          },
          {
            code: 330784,
            name_cn: "永康市",
            name_en: "Yongkang",
            name_tw: "永康市",
            yzcode: "321300",
          },
        ],
      },
      {
        code: 330800,
        name_cn: "衢州市",
        name_en: "Quzhou",
        name_tw: "衢州市",
        children: [
          {
            code: 330802,
            name_cn: "柯城区",
            name_en: "Kecheng",
            name_tw: "柯城區",
            yzcode: "324100",
          },
          {
            code: 330803,
            name_cn: "衢江区",
            name_en: "Qujiang",
            name_tw: "衢江區",
            yzcode: "324022",
          },
          {
            code: 330822,
            name_cn: "常山县",
            name_en: "Changshan",
            name_tw: "常山縣",
            yzcode: "324200",
          },
          {
            code: 330824,
            name_cn: "开化县",
            name_en: "Kaihua",
            name_tw: "開化縣",
            yzcode: "324300",
          },
          {
            code: 330825,
            name_cn: "龙游县",
            name_en: "Longyou",
            name_tw: "龍遊縣",
            yzcode: "324400",
          },
          {
            code: 330881,
            name_cn: "江山市",
            name_en: "Jiangshan",
            name_tw: "江山市",
            yzcode: "324100",
          },
        ],
      },
      {
        code: 330900,
        name_cn: "舟山市",
        name_en: "Zhoushan",
        name_tw: "舟山市",
        children: [
          {
            code: 330902,
            name_cn: "定海区",
            name_en: "Dinghai",
            name_tw: "定海區",
            yzcode: "316000",
          },
          {
            code: 330903,
            name_cn: "普陀区",
            name_en: "Putuo",
            name_tw: "普陀區",
            yzcode: "316100",
          },
          {
            code: 330921,
            name_cn: "岱山县",
            name_en: "Daishan",
            name_tw: "岱山縣",
            yzcode: "316200",
          },
          {
            code: 330922,
            name_cn: "嵊泗县",
            name_en: "Shengsi",
            name_tw: "嵊泗縣",
            yzcode: "202450",
          },
        ],
      },
      {
        code: 331000,
        name_cn: "台州市",
        name_en: "Taizhou",
        name_tw: "台州市",
        children: [
          {
            code: 331002,
            name_cn: "椒江区",
            name_en: "Jiaojiang",
            name_tw: "椒江區",
            yzcode: "318000",
          },
          {
            code: 331003,
            name_cn: "黄岩区",
            name_en: "Huangyan",
            name_tw: "黃巖區",
            yzcode: "318020",
          },
          {
            code: 331004,
            name_cn: "路桥区",
            name_en: "Luqiao",
            name_tw: "路橋區",
            yzcode: "318050",
          },
          {
            code: 331021,
            name_cn: "玉环县",
            name_en: "Yuhuan",
            name_tw: "玉環縣",
            yzcode: "317600",
          },
          {
            code: 331022,
            name_cn: "三门县",
            name_en: "Sanmen",
            name_tw: "三門縣",
            yzcode: "317100",
          },
          {
            code: 331023,
            name_cn: "天台县",
            name_en: "Tiantai",
            name_tw: "天台縣",
            yzcode: "317200",
          },
          {
            code: 331024,
            name_cn: "仙居县",
            name_en: "Xianju",
            name_tw: "仙居縣",
            yzcode: "317300",
          },
          {
            code: 331081,
            name_cn: "温岭市",
            name_en: "Wenling",
            name_tw: "溫嶺市",
            yzcode: "317500",
          },
          {
            code: 331082,
            name_cn: "临海市",
            name_en: "Linhai",
            name_tw: "臨海市",
            yzcode: "317000",
          },
        ],
      },
      {
        code: 331100,
        name_cn: "丽水市",
        name_en: "Lishui",
        name_tw: "麗水市",
        children: [
          {
            code: 331102,
            name_cn: "莲都区",
            name_en: "Liandu",
            name_tw: "蓮都區",
            yzcode: "323000",
          },
          {
            code: 331121,
            name_cn: "青田县",
            name_en: "Qingtian",
            name_tw: "青田縣",
            yzcode: "323900",
          },
          {
            code: 331122,
            name_cn: "缙云县",
            name_en: "Jinyun",
            name_tw: "縉雲縣",
            yzcode: "321400",
          },
          {
            code: 331123,
            name_cn: "遂昌县",
            name_en: "Suichang",
            name_tw: "遂昌縣",
            yzcode: "323300",
          },
          {
            code: 331124,
            name_cn: "松阳县",
            name_en: "Songyang",
            name_tw: "松陽縣",
            yzcode: "323400",
          },
          {
            code: 331125,
            name_cn: "云和县",
            name_en: "Yunhe",
            name_tw: "雲和縣",
            yzcode: "323600",
          },
          {
            code: 331126,
            name_cn: "庆元县",
            name_en: "Qingyuan",
            name_tw: "慶元縣",
            yzcode: "323800",
          },
          {
            code: 331127,
            name_cn: "景宁畲族自治县",
            name_en: "Jingning",
            name_tw: "景寧畬族自治縣",
            yzcode: "323500",
          },
          {
            code: 331181,
            name_cn: "龙泉市",
            name_en: "Longquan",
            name_tw: "龍泉市",
            yzcode: "323700",
          },
        ],
      },
      {
        code: 331200,
        name_cn: "舟山群岛新区",
        name_en: "Zhoushan",
        name_tw: "舟山群島新區",
        children: [
          {
            code: 331201,
            name_cn: "金塘岛",
            name_en: "Jintang",
            name_tw: "金塘島",
            yzcode: "316000",
          },
          {
            code: 331202,
            name_cn: "六横岛",
            name_en: "Liuheng",
            name_tw: "六橫島",
            yzcode: "316000",
          },
          {
            code: 331203,
            name_cn: "衢山岛",
            name_en: "Qushan",
            name_tw: "衢山島",
            yzcode: "316000",
          },
          {
            code: 331204,
            name_cn: "舟山本岛西北部",
            name_en: "Zhoushan",
            name_tw: "舟山本島西北部",
            yzcode: "316000",
          },
          {
            code: 331205,
            name_cn: "岱山岛西南部",
            name_en: "Daishan",
            name_tw: "岱山島西南部",
            yzcode: "316000",
          },
          {
            code: 331206,
            name_cn: "泗礁岛",
            name_en: "Sijiao",
            name_tw: "泗礁島",
            yzcode: "316000",
          },
          {
            code: 331207,
            name_cn: "朱家尖岛",
            name_en: "Zhujiajian",
            name_tw: "朱家尖島",
            yzcode: "316000",
          },
          {
            code: 331208,
            name_cn: "洋山岛",
            name_en: "Yangshan",
            name_tw: "洋山島",
            yzcode: "316000",
          },
          {
            code: 331209,
            name_cn: "长涂岛",
            name_en: "Changtu",
            name_tw: "長塗島",
            yzcode: "316000",
          },
          {
            code: 331210,
            name_cn: "虾峙岛",
            name_en: "Xiazhi",
            name_tw: "蝦峙島",
            yzcode: "316000",
          },
        ],
      },
    ],
  },
  {
    code: 340000,
    name_cn: "安徽省",
    name_en: "Anhui",
    name_tw: "安徽省",
    children: [
      {
        code: 340100,
        name_cn: "合肥市",
        name_en: "Hefei",
        name_tw: "合肥市",
        children: [
          {
            code: 340102,
            name_cn: "瑶海区",
            name_en: "Yaohai",
            name_tw: "瑤海區",
            yzcode: "230011",
          },
          {
            code: 340103,
            name_cn: "庐阳区",
            name_en: "Luyang",
            name_tw: "廬陽區",
            yzcode: "230001",
          },
          {
            code: 340104,
            name_cn: "蜀山区",
            name_en: "Shushan",
            name_tw: "蜀山區",
            yzcode: "230031",
          },
          {
            code: 340111,
            name_cn: "包河区",
            name_en: "Baohe",
            name_tw: "包河區",
            yzcode: "230041",
          },
          {
            code: 340121,
            name_cn: "长丰县",
            name_en: "Changfeng",
            name_tw: "長豐縣",
            yzcode: "231100",
          },
          {
            code: 340122,
            name_cn: "肥东县",
            name_en: "Feidong",
            name_tw: "肥東縣",
            yzcode: "231600",
          },
          {
            code: 340123,
            name_cn: "肥西县",
            name_en: "Feixi",
            name_tw: "肥西縣",
            yzcode: "231200",
          },
          {
            code: 340124,
            name_cn: "庐江县",
            name_en: "Lujiang",
            name_tw: "廬江縣",
            yzcode: "231500",
          },
          {
            code: 340181,
            name_cn: "巢湖市",
            name_en: "Chaohu",
            name_tw: "巢湖市",
            yzcode: "238000",
          },
        ],
      },
      {
        code: 340200,
        name_cn: "芜湖市",
        name_en: "Wuhu",
        name_tw: "蕪湖市",
        children: [
          {
            code: 340202,
            name_cn: "镜湖区",
            name_en: "Jinghu",
            name_tw: "鏡湖區",
            yzcode: "241000",
          },
          {
            code: 340203,
            name_cn: "弋江区",
            name_en: "Yijiang",
            name_tw: "弋江區",
            yzcode: "241000",
          },
          {
            code: 340207,
            name_cn: "鸠江区",
            name_en: "Jiujiang",
            name_tw: "鳩江區",
            yzcode: "241000",
          },
          {
            code: 340208,
            name_cn: "三山区",
            name_en: "Sanshan",
            name_tw: "三山區",
            yzcode: "241000",
          },
          {
            code: 340221,
            name_cn: "芜湖县",
            name_en: "Wuhu",
            name_tw: "蕪湖縣",
            yzcode: "241100",
          },
          {
            code: 340222,
            name_cn: "繁昌县",
            name_en: "Fanchang",
            name_tw: "繁昌縣",
            yzcode: "241200",
          },
          {
            code: 340223,
            name_cn: "南陵县",
            name_en: "Nanling",
            name_tw: "南陵縣",
            yzcode: "242400",
          },
          {
            code: 340225,
            name_cn: "无为县",
            name_en: "Wuwei",
            name_tw: "無為縣",
            yzcode: "238300",
          },
        ],
      },
      {
        code: 340300,
        name_cn: "蚌埠市",
        name_en: "Bengbu",
        name_tw: "蚌埠市",
        children: [
          {
            code: 340302,
            name_cn: "龙子湖区",
            name_en: "Longzihu",
            name_tw: "龍子湖區",
            yzcode: "233000",
          },
          {
            code: 340303,
            name_cn: "蚌山区",
            name_en: "Bengshan",
            name_tw: "蚌山區",
            yzcode: "233000",
          },
          {
            code: 340304,
            name_cn: "禹会区",
            name_en: "Yuhui",
            name_tw: "禹會區",
            yzcode: "233010",
          },
          {
            code: 340311,
            name_cn: "淮上区",
            name_en: "Huaishang",
            name_tw: "淮上區",
            yzcode: "233002",
          },
          {
            code: 340321,
            name_cn: "怀远县",
            name_en: "Huaiyuan",
            name_tw: "懷遠縣",
            yzcode: "233400",
          },
          {
            code: 340322,
            name_cn: "五河县",
            name_en: "Wuhe",
            name_tw: "五河縣",
            yzcode: "233300",
          },
          {
            code: 340323,
            name_cn: "固镇县",
            name_en: "Guzhen",
            name_tw: "固鎮縣",
            yzcode: "233700",
          },
        ],
      },
      {
        code: 340400,
        name_cn: "淮南市",
        name_en: "Huainan",
        name_tw: "淮南市",
        children: [
          {
            code: 340402,
            name_cn: "大通区",
            name_en: "Datong",
            name_tw: "大通區",
            yzcode: "232033",
          },
          {
            code: 340403,
            name_cn: "田家庵区",
            name_en: "Tianjiaan",
            name_tw: "田家庵區",
            yzcode: "232000",
          },
          {
            code: 340404,
            name_cn: "谢家集区",
            name_en: "Xiejiaji",
            name_tw: "謝家集區",
            yzcode: "232052",
          },
          {
            code: 340405,
            name_cn: "八公山区",
            name_en: "Bagongshan",
            name_tw: "八公山區",
            yzcode: "232072",
          },
          {
            code: 340406,
            name_cn: "潘集区",
            name_en: "Panji",
            name_tw: "潘集區",
            yzcode: "232082",
          },
          {
            code: 340421,
            name_cn: "凤台县",
            name_en: "Fengtai",
            name_tw: "鳳台縣",
            yzcode: "232100",
          },
        ],
      },
      {
        code: 340500,
        name_cn: "马鞍山市",
        name_en: "Ma'anshan",
        name_tw: "馬鞍山市",
        children: [
          {
            code: 340503,
            name_cn: "花山区",
            name_en: "Huashan",
            name_tw: "花山區",
            yzcode: "243000",
          },
          {
            code: 340504,
            name_cn: "雨山区",
            name_en: "Yushan",
            name_tw: "雨山區",
            yzcode: "243071",
          },
          {
            code: 340506,
            name_cn: "博望区",
            name_en: "Bowang",
            name_tw: "博望區",
            yzcode: "243131",
          },
          {
            code: 340521,
            name_cn: "当涂县",
            name_en: "Dangtu",
            name_tw: "當塗縣",
            yzcode: "243100",
          },
          {
            code: 340522,
            name_cn: "含山县",
            name_en: "Hanshan ",
            name_tw: "含山縣",
            yzcode: "238100",
          },
          {
            code: 340523,
            name_cn: "和县",
            name_en: "Hexian",
            name_tw: "和縣",
            yzcode: "238200",
          },
        ],
      },
      {
        code: 340600,
        name_cn: "淮北市",
        name_en: "Huaibei",
        name_tw: "淮北市",
        children: [
          {
            code: 340602,
            name_cn: "杜集区",
            name_en: "Duji",
            name_tw: "杜集區",
            yzcode: "235000",
          },
          {
            code: 340603,
            name_cn: "相山区",
            name_en: "Xiangshan",
            name_tw: "相山區",
            yzcode: "235000",
          },
          {
            code: 340604,
            name_cn: "烈山区",
            name_en: "Lieshan",
            name_tw: "烈山區",
            yzcode: "235000",
          },
          {
            code: 340621,
            name_cn: "濉溪县",
            name_en: "Suixi",
            name_tw: "濉溪縣",
            yzcode: "235100",
          },
        ],
      },
      {
        code: 340700,
        name_cn: "铜陵市",
        name_en: "Tongling",
        name_tw: "銅陵市",
        children: [
          {
            code: 340702,
            name_cn: "铜官山区",
            name_en: "Tongguanshan",
            name_tw: "銅官山區",
            yzcode: "244000",
          },
          {
            code: 340703,
            name_cn: "狮子山区",
            name_en: "Shizishan",
            name_tw: "獅子山區",
            yzcode: "244000",
          },
          {
            code: 340711,
            name_cn: "郊区",
            name_en: "Jiaoqu",
            name_tw: "郊區",
            yzcode: "244000",
          },
          {
            code: 340721,
            name_cn: "铜陵县",
            name_en: "Tongling",
            name_tw: "銅陵縣",
            yzcode: "244100",
          },
        ],
      },
      {
        code: 340800,
        name_cn: "安庆市",
        name_en: "Anqing",
        name_tw: "安慶市",
        children: [
          {
            code: 340802,
            name_cn: "迎江区",
            name_en: "Yingjiang",
            name_tw: "迎江區",
            yzcode: "246001",
          },
          {
            code: 340803,
            name_cn: "大观区",
            name_en: "Daguan",
            name_tw: "大觀區",
            yzcode: "246002",
          },
          {
            code: 340811,
            name_cn: "宜秀区",
            name_en: "Yixiu",
            name_tw: "宜秀區",
            yzcode: "246003",
          },
          {
            code: 340822,
            name_cn: "怀宁县",
            name_en: "Huaining",
            name_tw: "懷寧縣",
            yzcode: "246100",
          },
          {
            code: 340823,
            name_cn: "枞阳县",
            name_en: "Zongyang",
            name_tw: "樅陽縣",
            yzcode: "246700",
          },
          {
            code: 340824,
            name_cn: "潜山县",
            name_en: "Qianshan",
            name_tw: "潛山縣",
            yzcode: "246300",
          },
          {
            code: 340825,
            name_cn: "太湖县",
            name_en: "Taihu",
            name_tw: "太湖縣",
            yzcode: "246400",
          },
          {
            code: 340826,
            name_cn: "宿松县",
            name_en: "Susong",
            name_tw: "宿松縣",
            yzcode: "246500",
          },
          {
            code: 340827,
            name_cn: "望江县",
            name_en: "Wangjiang",
            name_tw: "望江縣",
            yzcode: "246200",
          },
          {
            code: 340828,
            name_cn: "岳西县",
            name_en: "Yuexi",
            name_tw: "嶽西縣",
            yzcode: "246600",
          },
          {
            code: 340881,
            name_cn: "桐城市",
            name_en: "Tongcheng",
            name_tw: "桐城市",
            yzcode: "231400",
          },
        ],
      },
      {
        code: 341000,
        name_cn: "黄山市",
        name_en: "Huangshan",
        name_tw: "黃山市",
        children: [
          {
            code: 341002,
            name_cn: "屯溪区",
            name_en: "Tunxi",
            name_tw: "屯溪區",
            yzcode: "245000",
          },
          {
            code: 341003,
            name_cn: "黄山区",
            name_en: "Huangshan",
            name_tw: "黃山區",
            yzcode: "242700",
          },
          {
            code: 341004,
            name_cn: "徽州区",
            name_en: "Huizhou",
            name_tw: "徽州區",
            yzcode: "245061",
          },
          {
            code: 341021,
            name_cn: "歙县",
            name_en: "Shexian",
            name_tw: "歙縣",
            yzcode: "245200",
          },
          {
            code: 341022,
            name_cn: "休宁县",
            name_en: "Xiuning",
            name_tw: "休寧縣",
            yzcode: "245400",
          },
          {
            code: 341023,
            name_cn: "黟县",
            name_en: "Yixian",
            name_tw: "黟縣",
            yzcode: "245500",
          },
          {
            code: 341024,
            name_cn: "祁门县",
            name_en: "Qimen",
            name_tw: "祁門縣",
            yzcode: "245600",
          },
        ],
      },
      {
        code: 341100,
        name_cn: "滁州市",
        name_en: "Chuzhou",
        name_tw: "滁州市",
        children: [
          {
            code: 341102,
            name_cn: "琅琊区",
            name_en: "Langya",
            name_tw: "瑯琊區",
            yzcode: "239000",
          },
          {
            code: 341103,
            name_cn: "南谯区",
            name_en: "Nanqiao",
            name_tw: "南譙區",
            yzcode: "239000",
          },
          {
            code: 341122,
            name_cn: "来安县",
            name_en: "Lai'an",
            name_tw: "來安縣",
            yzcode: "239200",
          },
          {
            code: 341124,
            name_cn: "全椒县",
            name_en: "Quanjiao",
            name_tw: "全椒縣",
            yzcode: "239500",
          },
          {
            code: 341125,
            name_cn: "定远县",
            name_en: "Dingyuan",
            name_tw: "定遠縣",
            yzcode: "233200",
          },
          {
            code: 341126,
            name_cn: "凤阳县",
            name_en: "Fengyang",
            name_tw: "鳳陽縣",
            yzcode: "233100",
          },
          {
            code: 341181,
            name_cn: "天长市",
            name_en: "Tianchang",
            name_tw: "天長市",
            yzcode: "239300",
          },
          {
            code: 341182,
            name_cn: "明光市",
            name_en: "Mingguang",
            name_tw: "明光市",
            yzcode: "239400",
          },
        ],
      },
      {
        code: 341200,
        name_cn: "阜阳市",
        name_en: "Fuyang",
        name_tw: "阜陽市",
        children: [
          {
            code: 341202,
            name_cn: "颍州区",
            name_en: "Yingzhou",
            name_tw: "潁州區",
            yzcode: "236001",
          },
          {
            code: 341203,
            name_cn: "颍东区",
            name_en: "Yingdong",
            name_tw: "潁東區",
            yzcode: "236058",
          },
          {
            code: 341204,
            name_cn: "颍泉区",
            name_en: "Yingquan",
            name_tw: "潁泉區",
            yzcode: "236045",
          },
          {
            code: 341221,
            name_cn: "临泉县",
            name_en: "Linquan",
            name_tw: "臨泉縣",
            yzcode: "236400",
          },
          {
            code: 341222,
            name_cn: "太和县",
            name_en: "Taihe",
            name_tw: "太和縣",
            yzcode: "236600",
          },
          {
            code: 341225,
            name_cn: "阜南县",
            name_en: "Funan",
            name_tw: "阜南縣",
            yzcode: "236300",
          },
          {
            code: 341226,
            name_cn: "颍上县",
            name_en: "Yingshang",
            name_tw: "潁上縣",
            yzcode: "236200",
          },
          {
            code: 341282,
            name_cn: "界首市",
            name_en: "Jieshou",
            name_tw: "界首市",
            yzcode: "236500",
          },
        ],
      },
      {
        code: 341300,
        name_cn: "宿州市",
        name_en: "Suzhou",
        name_tw: "宿州市",
        children: [
          {
            code: 341302,
            name_cn: "埇桥区",
            name_en: "Yongqiao",
            name_tw: "埇橋區",
            yzcode: "234000",
          },
          {
            code: 341321,
            name_cn: "砀山县",
            name_en: "Dangshan",
            name_tw: "碭山縣",
            yzcode: "235300",
          },
          {
            code: 341322,
            name_cn: "萧县",
            name_en: "Xiaoxian",
            name_tw: "蕭縣",
            yzcode: "235200",
          },
          {
            code: 341323,
            name_cn: "灵璧县",
            name_en: "Lingbi",
            name_tw: "靈璧縣",
            yzcode: "234200",
          },
          {
            code: 341324,
            name_cn: "泗县",
            name_en: "Sixian",
            name_tw: "泗縣",
            yzcode: "234300",
          },
        ],
      },
      {
        code: 341500,
        name_cn: "六安市",
        name_en: "Lu'an",
        name_tw: "六安市",
        children: [
          {
            code: 341502,
            name_cn: "金安区",
            name_en: "Jin'an",
            name_tw: "金安區",
            yzcode: "237005",
          },
          {
            code: 341503,
            name_cn: "裕安区",
            name_en: "Yu'an",
            name_tw: "裕安區",
            yzcode: "237010",
          },
          {
            code: 341521,
            name_cn: "寿县",
            name_en: "Shouxian",
            name_tw: "壽縣",
            yzcode: "232200",
          },
          {
            code: 341522,
            name_cn: "霍邱县",
            name_en: "Huoqiu",
            name_tw: "霍邱縣",
            yzcode: "237400",
          },
          {
            code: 341523,
            name_cn: "舒城县",
            name_en: "Shucheng",
            name_tw: "舒城縣",
            yzcode: "231300",
          },
          {
            code: 341524,
            name_cn: "金寨县",
            name_en: "Jinzhai",
            name_tw: "金寨縣",
            yzcode: "237300",
          },
          {
            code: 341525,
            name_cn: "霍山县",
            name_en: "Huoshan",
            name_tw: "霍山縣",
            yzcode: "237200",
          },
        ],
      },
      {
        code: 341600,
        name_cn: "亳州市",
        name_en: "Bozhou",
        name_tw: "亳州市",
        children: [
          {
            code: 341602,
            name_cn: "谯城区",
            name_en: "Qiaocheng",
            name_tw: "譙城區",
            yzcode: "236800",
          },
          {
            code: 341621,
            name_cn: "涡阳县",
            name_en: "Guoyang",
            name_tw: "渦陽縣",
            yzcode: "233600",
          },
          {
            code: 341622,
            name_cn: "蒙城县",
            name_en: "Mengcheng",
            name_tw: "蒙城縣",
            yzcode: "233500",
          },
          {
            code: 341623,
            name_cn: "利辛县",
            name_en: "Lixin",
            name_tw: "利辛縣",
            yzcode: "236700",
          },
        ],
      },
      {
        code: 341700,
        name_cn: "池州市",
        name_en: "Chizhou",
        name_tw: "池州市",
        children: [
          {
            code: 341702,
            name_cn: "贵池区",
            name_en: "Guichi",
            name_tw: "貴池區",
            yzcode: "247100",
          },
          {
            code: 341721,
            name_cn: "东至县",
            name_en: "Dongzhi",
            name_tw: "東至縣",
            yzcode: "247200",
          },
          {
            code: 341722,
            name_cn: "石台县",
            name_en: "Shitai",
            name_tw: "石台縣",
            yzcode: "245100",
          },
          {
            code: 341723,
            name_cn: "青阳县",
            name_en: "Qingyang",
            name_tw: "青陽縣",
            yzcode: "242800",
          },
        ],
      },
      {
        code: 341800,
        name_cn: "宣城市",
        name_en: "Xuancheng",
        name_tw: "宣城市",
        children: [
          {
            code: 341802,
            name_cn: "宣州区",
            name_en: "Xuanzhou",
            name_tw: "宣州區",
            yzcode: "242000",
          },
          {
            code: 341821,
            name_cn: "郎溪县",
            name_en: "Langxi",
            name_tw: "郎溪縣",
            yzcode: "242100",
          },
          {
            code: 341822,
            name_cn: "广德县",
            name_en: "Guangde",
            name_tw: "廣德縣",
            yzcode: "242200",
          },
          {
            code: 341823,
            name_cn: "泾县",
            name_en: "Jingxian",
            name_tw: "涇縣",
            yzcode: "242500",
          },
          {
            code: 341824,
            name_cn: "绩溪县",
            name_en: "Jixi",
            name_tw: "績溪縣",
            yzcode: "245300",
          },
          {
            code: 341825,
            name_cn: "旌德县",
            name_en: "Jingde",
            name_tw: "旌德縣",
            yzcode: "242600",
          },
          {
            code: 341881,
            name_cn: "宁国市",
            name_en: "Ningguo",
            name_tw: "寧國市",
            yzcode: "242300",
          },
        ],
      },
    ],
  },
  {
    code: 350000,
    name_cn: "福建省",
    name_en: "Fujian",
    name_tw: "福建省",
    children: [
      {
        code: 350100,
        name_cn: "福州市",
        name_en: "Fuzhou",
        name_tw: "福州市",
        children: [
          {
            code: 350102,
            name_cn: "鼓楼区",
            name_en: "Gulou",
            name_tw: "鼓樓區",
            yzcode: "350001",
          },
          {
            code: 350103,
            name_cn: "台江区",
            name_en: "Taijiang",
            name_tw: "台江區",
            yzcode: "350004",
          },
          {
            code: 350104,
            name_cn: "仓山区",
            name_en: "Cangshan",
            name_tw: "倉山區",
            yzcode: "350007",
          },
          {
            code: 350105,
            name_cn: "马尾区",
            name_en: "Mawei",
            name_tw: "馬尾區",
            yzcode: "350015",
          },
          {
            code: 350111,
            name_cn: "晋安区",
            name_en: "Jin'an",
            name_tw: "晉安區",
            yzcode: "350011",
          },
          {
            code: 350121,
            name_cn: "闽侯县",
            name_en: "Minhou",
            name_tw: "閩侯縣",
            yzcode: "350100",
          },
          {
            code: 350122,
            name_cn: "连江县",
            name_en: "Lianjiang",
            name_tw: "連江縣",
            yzcode: "350500",
          },
          {
            code: 350123,
            name_cn: "罗源县",
            name_en: "Luoyuan",
            name_tw: "羅源縣",
            yzcode: "350600",
          },
          {
            code: 350124,
            name_cn: "闽清县",
            name_en: "Minqing",
            name_tw: "閩清縣",
            yzcode: "350800",
          },
          {
            code: 350125,
            name_cn: "永泰县",
            name_en: "Yongtai",
            name_tw: "永泰縣",
            yzcode: "350700",
          },
          {
            code: 350128,
            name_cn: "平潭县",
            name_en: "Pingtan",
            name_tw: "平潭縣",
            yzcode: "350400",
          },
          {
            code: 350181,
            name_cn: "福清市",
            name_en: "Fuqing",
            name_tw: "福清市",
            yzcode: "350300",
          },
          {
            code: 350182,
            name_cn: "长乐市",
            name_en: "Changle",
            name_tw: "長樂市",
            yzcode: "350200",
          },
        ],
      },
      {
        code: 350200,
        name_cn: "厦门市",
        name_en: "Xiamen",
        name_tw: "廈門市",
        children: [
          {
            code: 350203,
            name_cn: "思明区",
            name_en: "Siming",
            name_tw: "思明區",
            yzcode: "361001",
          },
          {
            code: 350205,
            name_cn: "海沧区",
            name_en: "Haicang",
            name_tw: "海滄區",
            yzcode: "361026",
          },
          {
            code: 350206,
            name_cn: "湖里区",
            name_en: "Huli",
            name_tw: "湖裏區",
            yzcode: "361006",
          },
          {
            code: 350211,
            name_cn: "集美区",
            name_en: "Jimei",
            name_tw: "集美區",
            yzcode: "361021",
          },
          {
            code: 350212,
            name_cn: "同安区",
            name_en: "Tong'an",
            name_tw: "同安區",
            yzcode: "361100",
          },
          {
            code: 350213,
            name_cn: "翔安区",
            name_en: "Xiang'an",
            name_tw: "翔安區",
            yzcode: "361101",
          },
        ],
      },
      {
        code: 350300,
        name_cn: "莆田市",
        name_en: "Putian",
        name_tw: "莆田市",
        children: [
          {
            code: 350302,
            name_cn: "城厢区",
            name_en: "Chengxiang",
            name_tw: "城廂區",
            yzcode: "351100",
          },
          {
            code: 350303,
            name_cn: "涵江区",
            name_en: "Hanjiang",
            name_tw: "涵江區",
            yzcode: "351111",
          },
          {
            code: 350304,
            name_cn: "荔城区",
            name_en: "Licheng",
            name_tw: "荔城區",
            yzcode: "351100",
          },
          {
            code: 350305,
            name_cn: "秀屿区",
            name_en: "Xiuyu",
            name_tw: "秀嶼區",
            yzcode: "351152",
          },
          {
            code: 350322,
            name_cn: "仙游县",
            name_en: "Xianyou",
            name_tw: "仙遊縣",
            yzcode: "351200",
          },
        ],
      },
      {
        code: 350400,
        name_cn: "三明市",
        name_en: "Sanming",
        name_tw: "三明市",
        children: [
          {
            code: 350402,
            name_cn: "梅列区",
            name_en: "Meilie",
            name_tw: "梅列區",
            yzcode: "365000",
          },
          {
            code: 350403,
            name_cn: "三元区",
            name_en: "Sanyuan",
            name_tw: "三元區",
            yzcode: "365001",
          },
          {
            code: 350421,
            name_cn: "明溪县",
            name_en: "Mingxi",
            name_tw: "明溪縣",
            yzcode: "365200",
          },
          {
            code: 350423,
            name_cn: "清流县",
            name_en: "Qingliu",
            name_tw: "清流縣",
            yzcode: "365300",
          },
          {
            code: 350424,
            name_cn: "宁化县",
            name_en: "Ninghua",
            name_tw: "寧化縣",
            yzcode: "365400",
          },
          {
            code: 350425,
            name_cn: "大田县",
            name_en: "Datian",
            name_tw: "大田縣",
            yzcode: "366100",
          },
          {
            code: 350426,
            name_cn: "尤溪县",
            name_en: "Youxi",
            name_tw: "尤溪縣",
            yzcode: "365100",
          },
          {
            code: 350427,
            name_cn: "沙县",
            name_en: "Shaxian",
            name_tw: "沙縣",
            yzcode: "365500",
          },
          {
            code: 350428,
            name_cn: "将乐县",
            name_en: "Jiangle",
            name_tw: "將樂縣",
            yzcode: "353300",
          },
          {
            code: 350429,
            name_cn: "泰宁县",
            name_en: "Taining",
            name_tw: "泰寧縣",
            yzcode: "354400",
          },
          {
            code: 350430,
            name_cn: "建宁县",
            name_en: "Jianning",
            name_tw: "建寧縣",
            yzcode: "354500",
          },
          {
            code: 350481,
            name_cn: "永安市",
            name_en: "Yong'an",
            name_tw: "永安市",
            yzcode: "366000",
          },
        ],
      },
      {
        code: 350500,
        name_cn: "泉州市",
        name_en: "Quanzhou",
        name_tw: "泉州市",
        children: [
          {
            code: 350502,
            name_cn: "鲤城区",
            name_en: "Licheng",
            name_tw: "鯉城區",
            yzcode: "362000",
          },
          {
            code: 350503,
            name_cn: "丰泽区",
            name_en: "Fengze",
            name_tw: "豐澤區",
            yzcode: "362000",
          },
          {
            code: 350504,
            name_cn: "洛江区",
            name_en: "Luojiang",
            name_tw: "洛江區",
            yzcode: "362011",
          },
          {
            code: 350505,
            name_cn: "泉港区",
            name_en: "Quangang",
            name_tw: "泉港區",
            yzcode: "362114",
          },
          {
            code: 350521,
            name_cn: "惠安县",
            name_en: "Hui'an",
            name_tw: "惠安縣",
            yzcode: "362100",
          },
          {
            code: 350524,
            name_cn: "安溪县",
            name_en: "Anxi",
            name_tw: "安溪縣",
            yzcode: "362400",
          },
          {
            code: 350525,
            name_cn: "永春县",
            name_en: "Yongchun",
            name_tw: "永春縣",
            yzcode: "362600",
          },
          {
            code: 350526,
            name_cn: "德化县",
            name_en: "Dehua",
            name_tw: "德化縣",
            yzcode: "362500",
          },
          {
            code: 350527,
            name_cn: "金门县",
            name_en: "Jinmen",
            name_tw: "金門縣",
            yzcode: "",
          },
          {
            code: 350581,
            name_cn: "石狮市",
            name_en: "Shishi",
            name_tw: "石獅市",
            yzcode: "362700",
          },
          {
            code: 350582,
            name_cn: "晋江市",
            name_en: "Jinjiang",
            name_tw: "晉江市",
            yzcode: "362200",
          },
          {
            code: 350583,
            name_cn: "南安市",
            name_en: "Nan'an",
            name_tw: "南安市",
            yzcode: "362300",
          },
        ],
      },
      {
        code: 350600,
        name_cn: "漳州市",
        name_en: "Zhangzhou",
        name_tw: "漳州市",
        children: [
          {
            code: 350602,
            name_cn: "芗城区",
            name_en: "Xiangcheng",
            name_tw: "薌城區",
            yzcode: "363000",
          },
          {
            code: 350603,
            name_cn: "龙文区",
            name_en: "Longwen",
            name_tw: "龍文區",
            yzcode: "363005",
          },
          {
            code: 350622,
            name_cn: "云霄县",
            name_en: "Yunxiao",
            name_tw: "雲霄縣",
            yzcode: "363300",
          },
          {
            code: 350623,
            name_cn: "漳浦县",
            name_en: "Zhangpu",
            name_tw: "漳浦縣",
            yzcode: "363200",
          },
          {
            code: 350624,
            name_cn: "诏安县",
            name_en: "Zhao'an",
            name_tw: "詔安縣",
            yzcode: "363500",
          },
          {
            code: 350625,
            name_cn: "长泰县",
            name_en: "Changtai",
            name_tw: "長泰縣",
            yzcode: "363900",
          },
          {
            code: 350626,
            name_cn: "东山县",
            name_en: "Dongshan",
            name_tw: "東山縣",
            yzcode: "363400",
          },
          {
            code: 350627,
            name_cn: "南靖县",
            name_en: "Nanjing",
            name_tw: "南靖縣",
            yzcode: "363600",
          },
          {
            code: 350628,
            name_cn: "平和县",
            name_en: "Pinghe",
            name_tw: "平和縣",
            yzcode: "363700",
          },
          {
            code: 350629,
            name_cn: "华安县",
            name_en: "Hua'an",
            name_tw: "華安縣",
            yzcode: "363800",
          },
          {
            code: 350681,
            name_cn: "龙海市",
            name_en: "Longhai",
            name_tw: "龍海市",
            yzcode: "363100",
          },
        ],
      },
      {
        code: 350700,
        name_cn: "南平市",
        name_en: "Nanping",
        name_tw: "南平市",
        children: [
          {
            code: 350702,
            name_cn: "延平区",
            name_en: "Yanping",
            name_tw: "延平區",
            yzcode: "353000",
          },
          {
            code: 350703,
            name_cn: "建阳区",
            name_en: "Jianyang",
            name_tw: "建陽區",
            yzcode: "354200",
          },
          {
            code: 350721,
            name_cn: "顺昌县",
            name_en: "Shunchang",
            name_tw: "順昌縣",
            yzcode: "353200",
          },
          {
            code: 350722,
            name_cn: "浦城县",
            name_en: "Pucheng",
            name_tw: "浦城縣",
            yzcode: "353400",
          },
          {
            code: 350723,
            name_cn: "光泽县",
            name_en: "Guangze",
            name_tw: "光澤縣",
            yzcode: "354100",
          },
          {
            code: 350724,
            name_cn: "松溪县",
            name_en: "Songxi",
            name_tw: "松溪縣",
            yzcode: "353500",
          },
          {
            code: 350725,
            name_cn: "政和县",
            name_en: "Zhenghe",
            name_tw: "政和縣",
            yzcode: "353600",
          },
          {
            code: 350781,
            name_cn: "邵武市",
            name_en: "Shaowu",
            name_tw: "邵武市",
            yzcode: "354000",
          },
          {
            code: 350782,
            name_cn: "武夷山市",
            name_en: "Wuyishan",
            name_tw: "武夷山市",
            yzcode: "354300",
          },
          {
            code: 350783,
            name_cn: "建瓯市",
            name_en: "Jianou",
            name_tw: "建甌市",
            yzcode: "353100",
          },
        ],
      },
      {
        code: 350800,
        name_cn: "龙岩市",
        name_en: "Longyan",
        name_tw: "龍巖市",
        children: [
          {
            code: 350802,
            name_cn: "新罗区",
            name_en: "Xinluo",
            name_tw: "新羅區",
            yzcode: "364000",
          },
          {
            code: 350821,
            name_cn: "长汀县",
            name_en: "Changting",
            name_tw: "長汀縣",
            yzcode: "366300",
          },
          {
            code: 350822,
            name_cn: "永定区",
            name_en: "Yongding",
            name_tw: "永定區",
            yzcode: "364100",
          },
          {
            code: 350823,
            name_cn: "上杭县",
            name_en: "Shanghang",
            name_tw: "上杭縣",
            yzcode: "364200",
          },
          {
            code: 350824,
            name_cn: "武平县",
            name_en: "Wuping",
            name_tw: "武平縣",
            yzcode: "364300",
          },
          {
            code: 350825,
            name_cn: "连城县",
            name_en: "Liancheng",
            name_tw: "連城縣",
            yzcode: "366200",
          },
          {
            code: 350881,
            name_cn: "漳平市",
            name_en: "Zhangping",
            name_tw: "漳平市",
            yzcode: "364400",
          },
        ],
      },
      {
        code: 350900,
        name_cn: "宁德市",
        name_en: "Ningde",
        name_tw: "寧德市",
        children: [
          {
            code: 350902,
            name_cn: "蕉城区",
            name_en: "Jiaocheng",
            name_tw: "蕉城區",
            yzcode: "352100",
          },
          {
            code: 350921,
            name_cn: "霞浦县",
            name_en: "Xiapu",
            name_tw: "霞浦縣",
            yzcode: "355100",
          },
          {
            code: 350922,
            name_cn: "古田县",
            name_en: "Gutian",
            name_tw: "古田縣",
            yzcode: "352200",
          },
          {
            code: 350923,
            name_cn: "屏南县",
            name_en: "Pingnan",
            name_tw: "屏南縣",
            yzcode: "352300",
          },
          {
            code: 350924,
            name_cn: "寿宁县",
            name_en: "Shouning",
            name_tw: "壽寧縣",
            yzcode: "355500",
          },
          {
            code: 350925,
            name_cn: "周宁县",
            name_en: "Zhouning",
            name_tw: "周寧縣",
            yzcode: "355400",
          },
          {
            code: 350926,
            name_cn: "柘荣县",
            name_en: "Zherong",
            name_tw: "柘榮縣",
            yzcode: "355300",
          },
          {
            code: 350981,
            name_cn: "福安市",
            name_en: "Fu'an",
            name_tw: "福安市",
            yzcode: "355000",
          },
          {
            code: 350982,
            name_cn: "福鼎市",
            name_en: "Fuding",
            name_tw: "福鼎市",
            yzcode: "355200",
          },
        ],
      },
    ],
  },
  {
    code: 360000,
    name_cn: "江西省",
    name_en: "Jiangxi",
    name_tw: "江西省",
    children: [
      {
        code: 360100,
        name_cn: "南昌市",
        name_en: "Nanchang",
        name_tw: "南昌市",
        children: [
          {
            code: 360102,
            name_cn: "东湖区",
            name_en: "Donghu",
            name_tw: "東湖區",
            yzcode: "330006",
          },
          {
            code: 360103,
            name_cn: "西湖区",
            name_en: "Xihu",
            name_tw: "西湖區",
            yzcode: "330009",
          },
          {
            code: 360104,
            name_cn: "青云谱区",
            name_en: "Qingyunpu",
            name_tw: "青雲譜區",
            yzcode: "330001",
          },
          {
            code: 360105,
            name_cn: "湾里区",
            name_en: "Wanli",
            name_tw: "灣裏區",
            yzcode: "330004",
          },
          {
            code: 360111,
            name_cn: "青山湖区",
            name_en: "Qingshanhu",
            name_tw: "青山湖區",
            yzcode: "330029",
          },
          {
            code: 360121,
            name_cn: "南昌县",
            name_en: "Nanchang",
            name_tw: "南昌縣",
            yzcode: "330200",
          },
          {
            code: 360122,
            name_cn: "新建县",
            name_en: "Xinjian",
            name_tw: "新建縣",
            yzcode: "330100",
          },
          {
            code: 360123,
            name_cn: "安义县",
            name_en: "Anyi",
            name_tw: "安義縣",
            yzcode: "330500",
          },
          {
            code: 360124,
            name_cn: "进贤县",
            name_en: "Jinxian",
            name_tw: "進賢縣",
            yzcode: "331700",
          },
        ],
      },
      {
        code: 360200,
        name_cn: "景德镇市",
        name_en: "Jingdezhen",
        name_tw: "景德鎮市",
        children: [
          {
            code: 360202,
            name_cn: "昌江区",
            name_en: "Changjiang",
            name_tw: "昌江區",
            yzcode: "333000",
          },
          {
            code: 360203,
            name_cn: "珠山区",
            name_en: "Zhushan",
            name_tw: "珠山區",
            yzcode: "333000",
          },
          {
            code: 360222,
            name_cn: "浮梁县",
            name_en: "Fuliang",
            name_tw: "浮梁縣",
            yzcode: "333400",
          },
          {
            code: 360281,
            name_cn: "乐平市",
            name_en: "Leping",
            name_tw: "樂平市",
            yzcode: "333300",
          },
        ],
      },
      {
        code: 360300,
        name_cn: "萍乡市",
        name_en: "Pingxiang",
        name_tw: "萍鄉市",
        children: [
          {
            code: 360302,
            name_cn: "安源区",
            name_en: "Anyuan",
            name_tw: "安源區",
            yzcode: "337000",
          },
          {
            code: 360313,
            name_cn: "湘东区",
            name_en: "Xiangdong",
            name_tw: "湘東區",
            yzcode: "337016",
          },
          {
            code: 360321,
            name_cn: "莲花县",
            name_en: "Lianhua",
            name_tw: "蓮花縣",
            yzcode: "337100",
          },
          {
            code: 360322,
            name_cn: "上栗县",
            name_en: "Shangli",
            name_tw: "上栗縣",
            yzcode: "337009",
          },
          {
            code: 360323,
            name_cn: "芦溪县",
            name_en: "Luxi",
            name_tw: "蘆溪縣",
            yzcode: "337053",
          },
        ],
      },
      {
        code: 360400,
        name_cn: "九江市",
        name_en: "Jiujiang",
        name_tw: "九江市",
        children: [
          {
            code: 360402,
            name_cn: "庐山区",
            name_en: "Lushan",
            name_tw: "廬山區",
            yzcode: "332005",
          },
          {
            code: 360403,
            name_cn: "浔阳区",
            name_en: "Xunyang",
            name_tw: "潯陽區",
            yzcode: "332000",
          },
          {
            code: 360421,
            name_cn: "九江县",
            name_en: "Jiujiang",
            name_tw: "九江縣",
            yzcode: "332100",
          },
          {
            code: 360423,
            name_cn: "武宁县",
            name_en: "Wuning",
            name_tw: "武寧縣",
            yzcode: "332300",
          },
          {
            code: 360424,
            name_cn: "修水县",
            name_en: "Xiushui",
            name_tw: "修水縣",
            yzcode: "332400",
          },
          {
            code: 360425,
            name_cn: "永修县",
            name_en: "Yongxiu",
            name_tw: "永修縣",
            yzcode: "330300",
          },
          {
            code: 360426,
            name_cn: "德安县",
            name_en: "De'an",
            name_tw: "德安縣",
            yzcode: "330400",
          },
          {
            code: 360427,
            name_cn: "星子县",
            name_en: "Xingzi",
            name_tw: "星子縣",
            yzcode: "332800",
          },
          {
            code: 360428,
            name_cn: "都昌县",
            name_en: "Duchang",
            name_tw: "都昌縣",
            yzcode: "332600",
          },
          {
            code: 360429,
            name_cn: "湖口县",
            name_en: "Hukou",
            name_tw: "湖口縣",
            yzcode: "332500",
          },
          {
            code: 360430,
            name_cn: "彭泽县",
            name_en: "Pengze",
            name_tw: "彭澤縣",
            yzcode: "332700",
          },
          {
            code: 360481,
            name_cn: "瑞昌市",
            name_en: "Ruichang",
            name_tw: "瑞昌市",
            yzcode: "332200",
          },
          {
            code: 360482,
            name_cn: "共青城市",
            name_en: "Gongqingcheng",
            name_tw: "共青城市",
            yzcode: "332020",
          },
        ],
      },
      {
        code: 360500,
        name_cn: "新余市",
        name_en: "Xinyu",
        name_tw: "新余市",
        children: [
          {
            code: 360502,
            name_cn: "渝水区",
            name_en: "Yushui",
            name_tw: "渝水區",
            yzcode: "338025",
          },
          {
            code: 360521,
            name_cn: "分宜县",
            name_en: "Fenyi",
            name_tw: "分宜縣",
            yzcode: "336600",
          },
        ],
      },
      {
        code: 360600,
        name_cn: "鹰潭市",
        name_en: "Yingtan",
        name_tw: "鷹潭市",
        children: [
          {
            code: 360602,
            name_cn: "月湖区",
            name_en: "Yuehu",
            name_tw: "月湖區",
            yzcode: "335000",
          },
          {
            code: 360622,
            name_cn: "余江县",
            name_en: "Yujiang",
            name_tw: "余江縣",
            yzcode: "335200",
          },
          {
            code: 360681,
            name_cn: "贵溪市",
            name_en: "Guixi",
            name_tw: "貴溪市",
            yzcode: "335400",
          },
        ],
      },
      {
        code: 360700,
        name_cn: "赣州市",
        name_en: "Ganzhou",
        name_tw: "贛州市",
        children: [
          {
            code: 360702,
            name_cn: "章贡区",
            name_en: "Zhanggong",
            name_tw: "章貢區",
            yzcode: "341000",
          },
          {
            code: 360703,
            name_cn: "南康区",
            name_en: "Nankang",
            name_tw: "南康區",
            yzcode: "341400",
          },
          {
            code: 360721,
            name_cn: "赣县",
            name_en: "Ganxian",
            name_tw: "贛縣",
            yzcode: "341100",
          },
          {
            code: 360722,
            name_cn: "信丰县",
            name_en: "Xinfeng",
            name_tw: "信豐縣",
            yzcode: "341600",
          },
          {
            code: 360723,
            name_cn: "大余县",
            name_en: "Dayu",
            name_tw: "大余縣",
            yzcode: "341500",
          },
          {
            code: 360724,
            name_cn: "上犹县",
            name_en: "Shangyou",
            name_tw: "上猶縣",
            yzcode: "341200",
          },
          {
            code: 360725,
            name_cn: "崇义县",
            name_en: "Chongyi",
            name_tw: "崇義縣",
            yzcode: "341300",
          },
          {
            code: 360726,
            name_cn: "安远县",
            name_en: "Anyuan",
            name_tw: "安遠縣",
            yzcode: "342100",
          },
          {
            code: 360727,
            name_cn: "龙南县",
            name_en: "Longnan",
            name_tw: "龍南縣",
            yzcode: "341700",
          },
          {
            code: 360728,
            name_cn: "定南县",
            name_en: "Dingnan",
            name_tw: "定南縣",
            yzcode: "341900",
          },
          {
            code: 360729,
            name_cn: "全南县",
            name_en: "Quannan",
            name_tw: "全南縣",
            yzcode: "341800",
          },
          {
            code: 360730,
            name_cn: "宁都县",
            name_en: "Ningdu",
            name_tw: "寧都縣",
            yzcode: "342800",
          },
          {
            code: 360731,
            name_cn: "于都县",
            name_en: "Yudu",
            name_tw: "於都縣",
            yzcode: "342300",
          },
          {
            code: 360732,
            name_cn: "兴国县",
            name_en: "Xingguo",
            name_tw: "興國縣",
            yzcode: "342400",
          },
          {
            code: 360733,
            name_cn: "会昌县",
            name_en: "Huichang",
            name_tw: "會昌縣",
            yzcode: "342600",
          },
          {
            code: 360734,
            name_cn: "寻乌县",
            name_en: "Xunwu",
            name_tw: "尋烏縣",
            yzcode: "342200",
          },
          {
            code: 360735,
            name_cn: "石城县",
            name_en: "Shicheng",
            name_tw: "石城縣",
            yzcode: "342700",
          },
          {
            code: 360781,
            name_cn: "瑞金市",
            name_en: "Ruijin",
            name_tw: "瑞金市",
            yzcode: "342500",
          },
        ],
      },
      {
        code: 360800,
        name_cn: "吉安市",
        name_en: "Ji'an",
        name_tw: "吉安市",
        children: [
          {
            code: 360802,
            name_cn: "吉州区",
            name_en: "Jizhou",
            name_tw: "吉州區",
            yzcode: "343000",
          },
          {
            code: 360803,
            name_cn: "青原区",
            name_en: "Qingyuan",
            name_tw: "青原區",
            yzcode: "343009",
          },
          {
            code: 360821,
            name_cn: "吉安县",
            name_en: "Ji'an",
            name_tw: "吉安縣",
            yzcode: "343100",
          },
          {
            code: 360822,
            name_cn: "吉水县",
            name_en: "Jishui",
            name_tw: "吉水縣",
            yzcode: "331600",
          },
          {
            code: 360823,
            name_cn: "峡江县",
            name_en: "Xiajiang",
            name_tw: "峽江縣",
            yzcode: "331409",
          },
          {
            code: 360824,
            name_cn: "新干县",
            name_en: "Xingan",
            name_tw: "新幹縣",
            yzcode: "331300",
          },
          {
            code: 360825,
            name_cn: "永丰县",
            name_en: "Yongfeng",
            name_tw: "永豐縣",
            yzcode: "331500",
          },
          {
            code: 360826,
            name_cn: "泰和县",
            name_en: "Taihe",
            name_tw: "泰和縣",
            yzcode: "343700",
          },
          {
            code: 360827,
            name_cn: "遂川县",
            name_en: "Suichuan",
            name_tw: "遂川縣",
            yzcode: "343900",
          },
          {
            code: 360828,
            name_cn: "万安县",
            name_en: "Wan'an",
            name_tw: "萬安縣",
            yzcode: "343800",
          },
          {
            code: 360829,
            name_cn: "安福县",
            name_en: "Anfu",
            name_tw: "安福縣",
            yzcode: "343200",
          },
          {
            code: 360830,
            name_cn: "永新县",
            name_en: "Yongxin",
            name_tw: "永新縣",
            yzcode: "343400",
          },
          {
            code: 360881,
            name_cn: "井冈山市",
            name_en: "Jinggangshan",
            name_tw: "井岡山市",
            yzcode: "343600",
          },
        ],
      },
      {
        code: 360900,
        name_cn: "宜春市",
        name_en: "Yichun",
        name_tw: "宜春市",
        children: [
          {
            code: 360902,
            name_cn: "袁州区",
            name_en: "Yuanzhou",
            name_tw: "袁州區",
            yzcode: "336000",
          },
          {
            code: 360921,
            name_cn: "奉新县",
            name_en: "Fengxin",
            name_tw: "奉新縣",
            yzcode: "330700",
          },
          {
            code: 360922,
            name_cn: "万载县",
            name_en: "Wanzai",
            name_tw: "萬載縣",
            yzcode: "336100",
          },
          {
            code: 360923,
            name_cn: "上高县",
            name_en: "Shanggao",
            name_tw: "上高縣",
            yzcode: "336400",
          },
          {
            code: 360924,
            name_cn: "宜丰县",
            name_en: "Yifeng",
            name_tw: "宜豐縣",
            yzcode: "336300",
          },
          {
            code: 360925,
            name_cn: "靖安县",
            name_en: "Jing'an",
            name_tw: "靖安縣",
            yzcode: "330600",
          },
          {
            code: 360926,
            name_cn: "铜鼓县",
            name_en: "Tonggu",
            name_tw: "銅鼓縣",
            yzcode: "336200",
          },
          {
            code: 360981,
            name_cn: "丰城市",
            name_en: "Fengcheng",
            name_tw: "豐城市",
            yzcode: "331100",
          },
          {
            code: 360982,
            name_cn: "樟树市",
            name_en: "Zhangshu",
            name_tw: "樟樹市",
            yzcode: "331200",
          },
          {
            code: 360983,
            name_cn: "高安市",
            name_en: "Gao'an",
            name_tw: "高安市",
            yzcode: "330800",
          },
        ],
      },
      {
        code: 361000,
        name_cn: "抚州市",
        name_en: "Fuzhou",
        name_tw: "撫州市",
        children: [
          {
            code: 361002,
            name_cn: "临川区",
            name_en: "Linchuan",
            name_tw: "臨川區",
            yzcode: "344000",
          },
          {
            code: 361021,
            name_cn: "南城县",
            name_en: "Nancheng",
            name_tw: "南城縣",
            yzcode: "344700",
          },
          {
            code: 361022,
            name_cn: "黎川县",
            name_en: "Lichuan",
            name_tw: "黎川縣",
            yzcode: "344600",
          },
          {
            code: 361023,
            name_cn: "南丰县",
            name_en: "Nanfeng",
            name_tw: "南豐縣",
            yzcode: "344500",
          },
          {
            code: 361024,
            name_cn: "崇仁县",
            name_en: "Chongren",
            name_tw: "崇仁縣",
            yzcode: "344200",
          },
          {
            code: 361025,
            name_cn: "乐安县",
            name_en: "Le'an",
            name_tw: "樂安縣",
            yzcode: "344300",
          },
          {
            code: 361026,
            name_cn: "宜黄县",
            name_en: "Yihuang",
            name_tw: "宜黃縣",
            yzcode: "344400",
          },
          {
            code: 361027,
            name_cn: "金溪县",
            name_en: "Jinxi",
            name_tw: "金溪縣",
            yzcode: "344800",
          },
          {
            code: 361028,
            name_cn: "资溪县",
            name_en: "Zixi",
            name_tw: "資溪縣",
            yzcode: "335300",
          },
          {
            code: 361029,
            name_cn: "东乡县",
            name_en: "Dongxiang",
            name_tw: "東鄉縣",
            yzcode: "331800",
          },
          {
            code: 361030,
            name_cn: "广昌县",
            name_en: "Guangchang",
            name_tw: "廣昌縣",
            yzcode: "344900",
          },
        ],
      },
      {
        code: 361100,
        name_cn: "上饶市",
        name_en: "Shangrao",
        name_tw: "上饒市",
        children: [
          {
            code: 361102,
            name_cn: "信州区",
            name_en: "Xinzhou",
            name_tw: "信州區",
            yzcode: "334000",
          },
          {
            code: 361121,
            name_cn: "上饶县",
            name_en: "Shangrao",
            name_tw: "上饒縣",
            yzcode: "334100",
          },
          {
            code: 361122,
            name_cn: "广丰县",
            name_en: "Guangfeng",
            name_tw: "廣豐縣",
            yzcode: "334600",
          },
          {
            code: 361123,
            name_cn: "玉山县",
            name_en: "Yushan",
            name_tw: "玉山縣",
            yzcode: "334700",
          },
          {
            code: 361124,
            name_cn: "铅山县",
            name_en: "Yanshan",
            name_tw: "鉛山縣",
            yzcode: "334500",
          },
          {
            code: 361125,
            name_cn: "横峰县",
            name_en: "Hengfeng",
            name_tw: "橫峰縣",
            yzcode: "334300",
          },
          {
            code: 361126,
            name_cn: "弋阳县",
            name_en: "Yiyang",
            name_tw: "弋陽縣",
            yzcode: "334400",
          },
          {
            code: 361127,
            name_cn: "余干县",
            name_en: "Yugan",
            name_tw: "余幹縣",
            yzcode: "335100",
          },
          {
            code: 361128,
            name_cn: "鄱阳县",
            name_en: "Poyang",
            name_tw: "鄱陽縣",
            yzcode: "333100",
          },
          {
            code: 361129,
            name_cn: "万年县",
            name_en: "Wannian",
            name_tw: "萬年縣",
            yzcode: "335500",
          },
          {
            code: 361130,
            name_cn: "婺源县",
            name_en: "Wuyuan",
            name_tw: "婺源縣",
            yzcode: "333200",
          },
          {
            code: 361181,
            name_cn: "德兴市",
            name_en: "Dexing",
            name_tw: "德興市",
            yzcode: "334200",
          },
        ],
      },
    ],
  },
  {
    code: 370000,
    name_cn: "山东省",
    name_en: "Shandong",
    name_tw: "山東省",
    children: [
      {
        code: 370100,
        name_cn: "济南市",
        name_en: "Jinan",
        name_tw: "濟南市",
        children: [
          {
            code: 370102,
            name_cn: "历下区",
            name_en: "Lixia",
            name_tw: "歷下區",
            yzcode: "250014",
          },
          {
            code: 370103,
            name_cn: "市中区",
            name_en: "Shizhongqu",
            name_tw: "市中區",
            yzcode: "250001",
          },
          {
            code: 370104,
            name_cn: "槐荫区",
            name_en: "Huaiyin",
            name_tw: "槐蔭區",
            yzcode: "250117",
          },
          {
            code: 370105,
            name_cn: "天桥区",
            name_en: "Tianqiao",
            name_tw: "天橋區",
            yzcode: "250031",
          },
          {
            code: 370112,
            name_cn: "历城区",
            name_en: "Licheng",
            name_tw: "歷城區",
            yzcode: "250100",
          },
          {
            code: 370113,
            name_cn: "长清区",
            name_en: "Changqing",
            name_tw: "長清區",
            yzcode: "250300",
          },
          {
            code: 370124,
            name_cn: "平阴县",
            name_en: "Pingyin",
            name_tw: "平陰縣",
            yzcode: "250400",
          },
          {
            code: 370125,
            name_cn: "济阳县",
            name_en: "Jiyang",
            name_tw: "濟陽縣",
            yzcode: "251400",
          },
          {
            code: 370126,
            name_cn: "商河县",
            name_en: "Shanghe",
            name_tw: "商河縣",
            yzcode: "251600",
          },
          {
            code: 370181,
            name_cn: "章丘市",
            name_en: "Zhangqiu",
            name_tw: "章丘市",
            yzcode: "250200",
          },
        ],
      },
      {
        code: 370200,
        name_cn: "青岛市",
        name_en: "Qingdao",
        name_tw: "青島市",
        children: [
          {
            code: 370202,
            name_cn: "市南区",
            name_en: "Shinan",
            name_tw: "市南區",
            yzcode: "266001",
          },
          {
            code: 370203,
            name_cn: "市北区",
            name_en: "Shibei",
            name_tw: "市北區",
            yzcode: "266011",
          },
          {
            code: 370211,
            name_cn: "黄岛区",
            name_en: "Huangdao",
            name_tw: "黃島區",
            yzcode: "266500",
          },
          {
            code: 370212,
            name_cn: "崂山区",
            name_en: "Laoshan",
            name_tw: "嶗山區",
            yzcode: "266100",
          },
          {
            code: 370213,
            name_cn: "李沧区",
            name_en: "Licang",
            name_tw: "李滄區",
            yzcode: "266021",
          },
          {
            code: 370214,
            name_cn: "城阳区",
            name_en: "Chengyang",
            name_tw: "城陽區",
            yzcode: "266041",
          },
          {
            code: 370281,
            name_cn: "胶州市",
            name_en: "Jiaozhou",
            name_tw: "膠州市",
            yzcode: "266300",
          },
          {
            code: 370282,
            name_cn: "即墨市",
            name_en: "Jimo",
            name_tw: "即墨市",
            yzcode: "266200",
          },
          {
            code: 370283,
            name_cn: "平度市",
            name_en: "Pingdu",
            name_tw: "平度市",
            yzcode: "266700",
          },
          {
            code: 370285,
            name_cn: "莱西市",
            name_en: "Laixi",
            name_tw: "萊西市",
            yzcode: "266600",
          },
          {
            code: 370286,
            name_cn: "西海岸新区",
            name_en: "Xihai'an",
            name_tw: "西海岸新區",
            yzcode: "266500",
          },
        ],
      },
      {
        code: 370300,
        name_cn: "淄博市",
        name_en: "Zibo",
        name_tw: "淄博市",
        children: [
          {
            code: 370302,
            name_cn: "淄川区",
            name_en: "Zichuan",
            name_tw: "淄川區",
            yzcode: "255100",
          },
          {
            code: 370303,
            name_cn: "张店区",
            name_en: "Zhangdian",
            name_tw: "張店區",
            yzcode: "255022",
          },
          {
            code: 370304,
            name_cn: "博山区",
            name_en: "Boshan",
            name_tw: "博山區",
            yzcode: "255200",
          },
          {
            code: 370305,
            name_cn: "临淄区",
            name_en: "Linzi",
            name_tw: "臨淄區",
            yzcode: "255400",
          },
          {
            code: 370306,
            name_cn: "周村区",
            name_en: "Zhoucun",
            name_tw: "周村區",
            yzcode: "255300",
          },
          {
            code: 370321,
            name_cn: "桓台县",
            name_en: "Huantai",
            name_tw: "桓台縣",
            yzcode: "256400",
          },
          {
            code: 370322,
            name_cn: "高青县",
            name_en: "Gaoqing",
            name_tw: "高青縣",
            yzcode: "256300",
          },
          {
            code: 370323,
            name_cn: "沂源县",
            name_en: "Yiyuan",
            name_tw: "沂源縣",
            yzcode: "256100",
          },
        ],
      },
      {
        code: 370400,
        name_cn: "枣庄市",
        name_en: "Zaozhuang",
        name_tw: "棗莊市",
        children: [
          {
            code: 370402,
            name_cn: "市中区",
            name_en: "Shizhongqu",
            name_tw: "市中區",
            yzcode: "277101",
          },
          {
            code: 370403,
            name_cn: "薛城区",
            name_en: "Xuecheng",
            name_tw: "薛城區",
            yzcode: "277000",
          },
          {
            code: 370404,
            name_cn: "峄城区",
            name_en: "Yicheng",
            name_tw: "嶧城區",
            yzcode: "277300",
          },
          {
            code: 370405,
            name_cn: "台儿庄区",
            name_en: "Taierzhuang",
            name_tw: "台兒莊區",
            yzcode: "277400",
          },
          {
            code: 370406,
            name_cn: "山亭区",
            name_en: "Shanting",
            name_tw: "山亭區",
            yzcode: "277200",
          },
          {
            code: 370481,
            name_cn: "滕州市",
            name_en: "Tengzhou",
            name_tw: "滕州市",
            yzcode: "277500",
          },
        ],
      },
      {
        code: 370500,
        name_cn: "东营市",
        name_en: "Dongying",
        name_tw: "東營市",
        children: [
          {
            code: 370502,
            name_cn: "东营区",
            name_en: "Dongying",
            name_tw: "東營區",
            yzcode: "257029",
          },
          {
            code: 370503,
            name_cn: "河口区",
            name_en: "Hekou",
            name_tw: "河口區",
            yzcode: "257200",
          },
          {
            code: 370521,
            name_cn: "垦利县",
            name_en: "Kenli",
            name_tw: "墾利縣",
            yzcode: "257500",
          },
          {
            code: 370522,
            name_cn: "利津县",
            name_en: "Lijin",
            name_tw: "利津縣",
            yzcode: "257400",
          },
          {
            code: 370523,
            name_cn: "广饶县",
            name_en: "Guangrao",
            name_tw: "廣饒縣",
            yzcode: "257300",
          },
        ],
      },
      {
        code: 370600,
        name_cn: "烟台市",
        name_en: "Yantai",
        name_tw: "煙台市",
        children: [
          {
            code: 370602,
            name_cn: "芝罘区",
            name_en: "Zhifu",
            name_tw: "芝罘區",
            yzcode: "264001",
          },
          {
            code: 370611,
            name_cn: "福山区",
            name_en: "Fushan",
            name_tw: "福山區",
            yzcode: "265500",
          },
          {
            code: 370612,
            name_cn: "牟平区",
            name_en: "Muping",
            name_tw: "牟平區",
            yzcode: "264100",
          },
          {
            code: 370613,
            name_cn: "莱山区",
            name_en: "Laishan",
            name_tw: "萊山區",
            yzcode: "264600",
          },
          {
            code: 370634,
            name_cn: "长岛县",
            name_en: "Changdao",
            name_tw: "長島縣",
            yzcode: "265800",
          },
          {
            code: 370681,
            name_cn: "龙口市",
            name_en: "Longkou",
            name_tw: "龍口市",
            yzcode: "265700",
          },
          {
            code: 370682,
            name_cn: "莱阳市",
            name_en: "Laiyang",
            name_tw: "萊陽市",
            yzcode: "265200",
          },
          {
            code: 370683,
            name_cn: "莱州市",
            name_en: "Laizhou",
            name_tw: "萊州市",
            yzcode: "261400",
          },
          {
            code: 370684,
            name_cn: "蓬莱市",
            name_en: "Penglai",
            name_tw: "蓬萊市",
            yzcode: "265600",
          },
          {
            code: 370685,
            name_cn: "招远市",
            name_en: "Zhaoyuan",
            name_tw: "招遠市",
            yzcode: "265400",
          },
          {
            code: 370686,
            name_cn: "栖霞市",
            name_en: "Qixia",
            name_tw: "棲霞市",
            yzcode: "265300",
          },
          {
            code: 370687,
            name_cn: "海阳市",
            name_en: "Haiyang",
            name_tw: "海陽市",
            yzcode: "265100",
          },
        ],
      },
      {
        code: 370700,
        name_cn: "潍坊市",
        name_en: "Weifang",
        name_tw: "濰坊市",
        children: [
          {
            code: 370702,
            name_cn: "潍城区",
            name_en: "Weicheng",
            name_tw: "濰城區",
            yzcode: "261021",
          },
          {
            code: 370703,
            name_cn: "寒亭区",
            name_en: "Hanting",
            name_tw: "寒亭區",
            yzcode: "261100",
          },
          {
            code: 370704,
            name_cn: "坊子区",
            name_en: "Fangzi",
            name_tw: "坊子區",
            yzcode: "261200",
          },
          {
            code: 370705,
            name_cn: "奎文区",
            name_en: "Kuiwen",
            name_tw: "奎文區",
            yzcode: "261031",
          },
          {
            code: 370724,
            name_cn: "临朐县",
            name_en: "Linqu",
            name_tw: "臨朐縣",
            yzcode: "262600",
          },
          {
            code: 370725,
            name_cn: "昌乐县",
            name_en: "Changle",
            name_tw: "昌樂縣",
            yzcode: "262400",
          },
          {
            code: 370781,
            name_cn: "青州市",
            name_en: "Qingzhou",
            name_tw: "青州市",
            yzcode: "262500",
          },
          {
            code: 370782,
            name_cn: "诸城市",
            name_en: "Zhucheng",
            name_tw: "諸城市",
            yzcode: "262200",
          },
          {
            code: 370783,
            name_cn: "寿光市",
            name_en: "Shouguang",
            name_tw: "壽光市",
            yzcode: "262700",
          },
          {
            code: 370784,
            name_cn: "安丘市",
            name_en: "Anqiu",
            name_tw: "安丘市",
            yzcode: "262100",
          },
          {
            code: 370785,
            name_cn: "高密市",
            name_en: "Gaomi",
            name_tw: "高密市",
            yzcode: "261500",
          },
          {
            code: 370786,
            name_cn: "昌邑市",
            name_en: "Changyi",
            name_tw: "昌邑市",
            yzcode: "261300",
          },
        ],
      },
      {
        code: 370800,
        name_cn: "济宁市",
        name_en: "Jining",
        name_tw: "濟寧市",
        children: [
          {
            code: 370811,
            name_cn: "任城区",
            name_en: "Rencheng",
            name_tw: "任城區",
            yzcode: "272113",
          },
          {
            code: 370812,
            name_cn: "兖州区",
            name_en: "Yanzhou ",
            name_tw: "兗州區",
            yzcode: "272000",
          },
          {
            code: 370826,
            name_cn: "微山县",
            name_en: "Weishan",
            name_tw: "微山縣",
            yzcode: "277600",
          },
          {
            code: 370827,
            name_cn: "鱼台县",
            name_en: "Yutai",
            name_tw: "魚台縣",
            yzcode: "272300",
          },
          {
            code: 370828,
            name_cn: "金乡县",
            name_en: "Jinxiang",
            name_tw: "金鄉縣",
            yzcode: "272200",
          },
          {
            code: 370829,
            name_cn: "嘉祥县",
            name_en: "Jiaxiang",
            name_tw: "嘉祥縣",
            yzcode: "272400",
          },
          {
            code: 370830,
            name_cn: "汶上县",
            name_en: "Wenshang",
            name_tw: "汶上縣",
            yzcode: "272501",
          },
          {
            code: 370831,
            name_cn: "泗水县",
            name_en: "Sishui",
            name_tw: "泗水縣",
            yzcode: "273200",
          },
          {
            code: 370832,
            name_cn: "梁山县",
            name_en: "Liangshan",
            name_tw: "梁山縣",
            yzcode: "272600",
          },
          {
            code: 370881,
            name_cn: "曲阜市",
            name_en: "Qufu",
            name_tw: "曲阜市",
            yzcode: "273100",
          },
          {
            code: 370883,
            name_cn: "邹城市",
            name_en: "Zoucheng",
            name_tw: "鄒城市",
            yzcode: "273500",
          },
        ],
      },
      {
        code: 370900,
        name_cn: "泰安市",
        name_en: "Tai'an",
        name_tw: "泰安市",
        children: [
          {
            code: 370902,
            name_cn: "泰山区",
            name_en: "Taishan",
            name_tw: "泰山區",
            yzcode: "271000",
          },
          {
            code: 370911,
            name_cn: "岱岳区",
            name_en: "Daiyue",
            name_tw: "岱嶽區",
            yzcode: "271000",
          },
          {
            code: 370921,
            name_cn: "宁阳县",
            name_en: "Ningyang",
            name_tw: "寧陽縣",
            yzcode: "271400",
          },
          {
            code: 370923,
            name_cn: "东平县",
            name_en: "Dongping",
            name_tw: "東平縣",
            yzcode: "271500",
          },
          {
            code: 370982,
            name_cn: "新泰市",
            name_en: "Xintai",
            name_tw: "新泰市",
            yzcode: "271200",
          },
          {
            code: 370983,
            name_cn: "肥城市",
            name_en: "Feicheng",
            name_tw: "肥城市",
            yzcode: "271600",
          },
        ],
      },
      {
        code: 371000,
        name_cn: "威海市",
        name_en: "Weihai",
        name_tw: "威海市",
        children: [
          {
            code: 371002,
            name_cn: "环翠区",
            name_en: "Huancui",
            name_tw: "環翠區",
            yzcode: "264200",
          },
          {
            code: 371003,
            name_cn: "文登区",
            name_en: "Wendeng",
            name_tw: "文登區",
            yzcode: "266440",
          },
          {
            code: 371082,
            name_cn: "荣成市",
            name_en: "Rongcheng",
            name_tw: "榮成市",
            yzcode: "264300",
          },
          {
            code: 371083,
            name_cn: "乳山市",
            name_en: "Rushan",
            name_tw: "乳山市",
            yzcode: "264500",
          },
        ],
      },
      {
        code: 371100,
        name_cn: "日照市",
        name_en: "Rizhao",
        name_tw: "日照市",
        children: [
          {
            code: 371102,
            name_cn: "东港区",
            name_en: "Donggang",
            name_tw: "東港區",
            yzcode: "276800",
          },
          {
            code: 371103,
            name_cn: "岚山区",
            name_en: "Lanshan",
            name_tw: "嵐山區",
            yzcode: "276808",
          },
          {
            code: 371121,
            name_cn: "五莲县",
            name_en: "Wulian",
            name_tw: "五蓮縣",
            yzcode: "262300",
          },
          {
            code: 371122,
            name_cn: "莒县",
            name_en: "Juxian",
            name_tw: "莒縣",
            yzcode: "276500",
          },
        ],
      },
      {
        code: 371200,
        name_cn: "莱芜市",
        name_en: "Laiwu",
        name_tw: "萊蕪市",
        children: [
          {
            code: 371202,
            name_cn: "莱城区",
            name_en: "Laicheng",
            name_tw: "萊城區",
            yzcode: "271199",
          },
          {
            code: 371203,
            name_cn: "钢城区",
            name_en: "Gangcheng",
            name_tw: "鋼城區",
            yzcode: "271100",
          },
        ],
      },
      {
        code: 371300,
        name_cn: "临沂市",
        name_en: "Linyi",
        name_tw: "臨沂市",
        children: [
          {
            code: 371302,
            name_cn: "兰山区",
            name_en: "Lanshan",
            name_tw: "蘭山區",
            yzcode: "276002",
          },
          {
            code: 371311,
            name_cn: "罗庄区",
            name_en: "Luozhuang",
            name_tw: "羅莊區",
            yzcode: "276022",
          },
          {
            code: 371312,
            name_cn: "河东区",
            name_en: "Hedong",
            name_tw: "河東區",
            yzcode: "276034",
          },
          {
            code: 371321,
            name_cn: "沂南县",
            name_en: "Yinan",
            name_tw: "沂南縣",
            yzcode: "276300",
          },
          {
            code: 371322,
            name_cn: "郯城县",
            name_en: "Tancheng",
            name_tw: "郯城縣",
            yzcode: "276100",
          },
          {
            code: 371323,
            name_cn: "沂水县",
            name_en: "Yishui",
            name_tw: "沂水縣",
            yzcode: "276400",
          },
          {
            code: 371324,
            name_cn: "兰陵县",
            name_en: "Lanling",
            name_tw: "蘭陵縣",
            yzcode: "277700",
          },
          {
            code: 371325,
            name_cn: "费县",
            name_en: "Feixian",
            name_tw: "費縣",
            yzcode: "273400",
          },
          {
            code: 371326,
            name_cn: "平邑县",
            name_en: "Pingyi",
            name_tw: "平邑縣",
            yzcode: "273300",
          },
          {
            code: 371327,
            name_cn: "莒南县",
            name_en: "Junan",
            name_tw: "莒南縣",
            yzcode: "276600",
          },
          {
            code: 371328,
            name_cn: "蒙阴县",
            name_en: "Mengyin",
            name_tw: "蒙陰縣",
            yzcode: "276200",
          },
          {
            code: 371329,
            name_cn: "临沭县",
            name_en: "Linshu",
            name_tw: "臨沭縣",
            yzcode: "276700",
          },
        ],
      },
      {
        code: 371400,
        name_cn: "德州市",
        name_en: "Dezhou",
        name_tw: "德州市",
        children: [
          {
            code: 371402,
            name_cn: "德城区",
            name_en: "Decheng",
            name_tw: "德城區",
            yzcode: "253012",
          },
          {
            code: 371403,
            name_cn: "陵城区",
            name_en: "Lingcheng",
            name_tw: "陵城區",
            yzcode: "253500",
          },
          {
            code: 371422,
            name_cn: "宁津县",
            name_en: "Ningjin",
            name_tw: "寧津縣",
            yzcode: "253400",
          },
          {
            code: 371423,
            name_cn: "庆云县",
            name_en: "Qingyun",
            name_tw: "慶雲縣",
            yzcode: "253700",
          },
          {
            code: 371424,
            name_cn: "临邑县",
            name_en: "Linyi",
            name_tw: "臨邑縣",
            yzcode: "251500",
          },
          {
            code: 371425,
            name_cn: "齐河县",
            name_en: "Qihe",
            name_tw: "齊河縣",
            yzcode: "251100",
          },
          {
            code: 371426,
            name_cn: "平原县",
            name_en: "Pingyuan",
            name_tw: "平原縣",
            yzcode: "253100",
          },
          {
            code: 371427,
            name_cn: "夏津县",
            name_en: "Xiajin",
            name_tw: "夏津縣",
            yzcode: "253200",
          },
          {
            code: 371428,
            name_cn: "武城县",
            name_en: "Wucheng",
            name_tw: "武城縣",
            yzcode: "253300",
          },
          {
            code: 371481,
            name_cn: "乐陵市",
            name_en: "Leling",
            name_tw: "樂陵市",
            yzcode: "253600",
          },
          {
            code: 371482,
            name_cn: "禹城市",
            name_en: "Yucheng",
            name_tw: "禹城市",
            yzcode: "251200",
          },
        ],
      },
      {
        code: 371500,
        name_cn: "聊城市",
        name_en: "Liaocheng",
        name_tw: "聊城市",
        children: [
          {
            code: 371502,
            name_cn: "东昌府区",
            name_en: "Dongchangfu",
            name_tw: "東昌府區",
            yzcode: "252000",
          },
          {
            code: 371521,
            name_cn: "阳谷县",
            name_en: "Yanggu",
            name_tw: "陽谷縣",
            yzcode: "252300",
          },
          {
            code: 371522,
            name_cn: "莘县",
            name_en: "Shenxian",
            name_tw: "莘縣",
            yzcode: "252400",
          },
          {
            code: 371523,
            name_cn: "茌平县",
            name_en: "Chiping",
            name_tw: "茌平縣",
            yzcode: "252100",
          },
          {
            code: 371524,
            name_cn: "东阿县",
            name_en: "Dong'e",
            name_tw: "東阿縣",
            yzcode: "252200",
          },
          {
            code: 371525,
            name_cn: "冠县",
            name_en: "Guanxian",
            name_tw: "冠縣",
            yzcode: "252500",
          },
          {
            code: 371526,
            name_cn: "高唐县",
            name_en: "Gaotang",
            name_tw: "高唐縣",
            yzcode: "252800",
          },
          {
            code: 371581,
            name_cn: "临清市",
            name_en: "Linqing",
            name_tw: "臨清市",
            yzcode: "252600",
          },
        ],
      },
      {
        code: 371600,
        name_cn: "滨州市",
        name_en: "Binzhou",
        name_tw: "濱州市",
        children: [
          {
            code: 371602,
            name_cn: "滨城区",
            name_en: "Bincheng",
            name_tw: "濱城區",
            yzcode: "256613",
          },
          {
            code: 371603,
            name_cn: "沾化区",
            name_en: "Zhanhua",
            name_tw: "沾化區",
            yzcode: "256800",
          },
          {
            code: 371621,
            name_cn: "惠民县",
            name_en: "Huimin",
            name_tw: "惠民縣",
            yzcode: "251700",
          },
          {
            code: 371622,
            name_cn: "阳信县",
            name_en: "Yangxin",
            name_tw: "陽信縣",
            yzcode: "251800",
          },
          {
            code: 371623,
            name_cn: "无棣县",
            name_en: "Wudi",
            name_tw: "無棣縣",
            yzcode: "251900",
          },
          {
            code: 371625,
            name_cn: "博兴县",
            name_en: "Boxing",
            name_tw: "博興縣",
            yzcode: "256500",
          },
          {
            code: 371626,
            name_cn: "邹平县",
            name_en: "Zouping",
            name_tw: "鄒平縣",
            yzcode: "256200",
          },
          {
            code: 371627,
            name_cn: "北海新区",
            name_en: "Beihaixinqu",
            name_tw: "北海新區",
            yzcode: "256200",
          },
        ],
      },
      {
        code: 371700,
        name_cn: "菏泽市",
        name_en: "Heze",
        name_tw: "菏澤市",
        children: [
          {
            code: 371702,
            name_cn: "牡丹区",
            name_en: "Mudan",
            name_tw: "牡丹區",
            yzcode: "274009",
          },
          {
            code: 371721,
            name_cn: "曹县",
            name_en: "Caoxian",
            name_tw: "曹縣",
            yzcode: "274400",
          },
          {
            code: 371722,
            name_cn: "单县",
            name_en: "Shanxian",
            name_tw: "單縣",
            yzcode: "273700",
          },
          {
            code: 371723,
            name_cn: "成武县",
            name_en: "Chengwu",
            name_tw: "成武縣",
            yzcode: "274200",
          },
          {
            code: 371724,
            name_cn: "巨野县",
            name_en: "Juye",
            name_tw: "巨野縣",
            yzcode: "274900",
          },
          {
            code: 371725,
            name_cn: "郓城县",
            name_en: "Yuncheng",
            name_tw: "鄆城縣",
            yzcode: "274700",
          },
          {
            code: 371726,
            name_cn: "鄄城县",
            name_en: "Juancheng",
            name_tw: "鄄城縣",
            yzcode: "274600",
          },
          {
            code: 371727,
            name_cn: "定陶县",
            name_en: "Dingtao",
            name_tw: "定陶縣",
            yzcode: "274100",
          },
          {
            code: 371728,
            name_cn: "东明县",
            name_en: "Dongming",
            name_tw: "東明縣",
            yzcode: "274500",
          },
        ],
      },
    ],
  },
  {
    code: 410000,
    name_cn: "河南省",
    name_en: "Henan",
    name_tw: "河南省",
    children: [
      {
        code: 410100,
        name_cn: "郑州市",
        name_en: "Zhengzhou",
        name_tw: "鄭州市",
        children: [
          {
            code: 410102,
            name_cn: "中原区",
            name_en: "Zhongyuan",
            name_tw: "中原區",
            yzcode: "450007",
          },
          {
            code: 410103,
            name_cn: "二七区",
            name_en: "Erqi",
            name_tw: "二七區",
            yzcode: "450052",
          },
          {
            code: 410104,
            name_cn: "管城回族区",
            name_en: "Guancheng",
            name_tw: "管城回族區",
            yzcode: "450000",
          },
          {
            code: 410105,
            name_cn: "金水区",
            name_en: "Jinshui",
            name_tw: "金水區",
            yzcode: "450003",
          },
          {
            code: 410106,
            name_cn: "上街区",
            name_en: "Shangjie",
            name_tw: "上街區",
            yzcode: "450041",
          },
          {
            code: 410108,
            name_cn: "惠济区",
            name_en: "Huiji",
            name_tw: "惠濟區",
            yzcode: "450053",
          },
          {
            code: 410122,
            name_cn: "中牟县",
            name_en: "Zhongmu",
            name_tw: "中牟縣",
            yzcode: "451450",
          },
          {
            code: 410181,
            name_cn: "巩义市",
            name_en: "Gongyi",
            name_tw: "鞏義市",
            yzcode: "451200",
          },
          {
            code: 410182,
            name_cn: "荥阳市",
            name_en: "Xingyang",
            name_tw: "滎陽市",
            yzcode: "450100",
          },
          {
            code: 410183,
            name_cn: "新密市",
            name_en: "Xinmi",
            name_tw: "新密市",
            yzcode: "452300",
          },
          {
            code: 410184,
            name_cn: "新郑市",
            name_en: "Xinzheng",
            name_tw: "新鄭市",
            yzcode: "451100",
          },
          {
            code: 410185,
            name_cn: "登封市",
            name_en: "Dengfeng",
            name_tw: "登封市",
            yzcode: "452470",
          },
        ],
      },
      {
        code: 410200,
        name_cn: "开封市",
        name_en: "Kaifeng",
        name_tw: "開封市",
        children: [
          {
            code: 410202,
            name_cn: "龙亭区",
            name_en: "Longting",
            name_tw: "龍亭區",
            yzcode: "475100",
          },
          {
            code: 410203,
            name_cn: "顺河回族区",
            name_en: "Shunhe",
            name_tw: "順河回族區",
            yzcode: "475000",
          },
          {
            code: 410204,
            name_cn: "鼓楼区",
            name_en: "Gulou",
            name_tw: "鼓樓區",
            yzcode: "475000",
          },
          {
            code: 410205,
            name_cn: "禹王台区",
            name_en: "Yuwangtai",
            name_tw: "禹王台區",
            yzcode: "475003",
          },
          {
            code: 410212,
            name_cn: "祥符区",
            name_en: "Xiangfu",
            name_tw: "祥符區",
            yzcode: "475100",
          },
          {
            code: 410221,
            name_cn: "杞县",
            name_en: "Qixian",
            name_tw: "杞縣",
            yzcode: "475200",
          },
          {
            code: 410222,
            name_cn: "通许县",
            name_en: "Tongxu",
            name_tw: "通許縣",
            yzcode: "475400",
          },
          {
            code: 410223,
            name_cn: "尉氏县",
            name_en: "Weishi",
            name_tw: "尉氏縣",
            yzcode: "475500",
          },
          {
            code: 410225,
            name_cn: "兰考县",
            name_en: "Lankao",
            name_tw: "蘭考縣",
            yzcode: "475300",
          },
        ],
      },
      {
        code: 410300,
        name_cn: "洛阳市",
        name_en: "Luoyang",
        name_tw: "洛陽市",
        children: [
          {
            code: 410302,
            name_cn: "老城区",
            name_en: "Laocheng",
            name_tw: "老城區",
            yzcode: "471002",
          },
          {
            code: 410303,
            name_cn: "西工区",
            name_en: "Xigong",
            name_tw: "西工區",
            yzcode: "471000",
          },
          {
            code: 410304,
            name_cn: "瀍河回族区",
            name_en: "Chanhe",
            name_tw: "瀍河回族區",
            yzcode: "471002",
          },
          {
            code: 410305,
            name_cn: "涧西区",
            name_en: "Jianxi",
            name_tw: "澗西區",
            yzcode: "471003",
          },
          {
            code: 410306,
            name_cn: "吉利区",
            name_en: "Jili",
            name_tw: "吉利區",
            yzcode: "471012",
          },
          {
            code: 410311,
            name_cn: "洛龙区",
            name_en: "Luolong",
            name_tw: "洛龍區",
            yzcode: "471000",
          },
          {
            code: 410322,
            name_cn: "孟津县",
            name_en: "Mengjin",
            name_tw: "孟津縣",
            yzcode: "471100",
          },
          {
            code: 410323,
            name_cn: "新安县",
            name_en: "Xin'an",
            name_tw: "新安縣",
            yzcode: "471800",
          },
          {
            code: 410324,
            name_cn: "栾川县",
            name_en: "Luanchuan",
            name_tw: "欒川縣",
            yzcode: "471500",
          },
          {
            code: 410325,
            name_cn: "嵩县",
            name_en: "Songxian",
            name_tw: "嵩縣",
            yzcode: "471400",
          },
          {
            code: 410326,
            name_cn: "汝阳县",
            name_en: "Ruyang",
            name_tw: "汝陽縣",
            yzcode: "471200",
          },
          {
            code: 410327,
            name_cn: "宜阳县",
            name_en: "Yiyang",
            name_tw: "宜陽縣",
            yzcode: "471600",
          },
          {
            code: 410328,
            name_cn: "洛宁县",
            name_en: "Luoning",
            name_tw: "洛寧縣",
            yzcode: "471700",
          },
          {
            code: 410329,
            name_cn: "伊川县",
            name_en: "Yichuan",
            name_tw: "伊川縣",
            yzcode: "471300",
          },
          {
            code: 410381,
            name_cn: "偃师市",
            name_en: "Yanshi",
            name_tw: "偃師市",
            yzcode: "471900",
          },
        ],
      },
      {
        code: 410400,
        name_cn: "平顶山市",
        name_en: "Pingdingshan",
        name_tw: "平頂山市",
        children: [
          {
            code: 410402,
            name_cn: "新华区",
            name_en: "Xinhua",
            name_tw: "新華區",
            yzcode: "467002",
          },
          {
            code: 410403,
            name_cn: "卫东区",
            name_en: "Weidong",
            name_tw: "衛東區",
            yzcode: "467021",
          },
          {
            code: 410404,
            name_cn: "石龙区",
            name_en: "Shilong",
            name_tw: "石龍區",
            yzcode: "467045",
          },
          {
            code: 410411,
            name_cn: "湛河区",
            name_en: "Zhanhe",
            name_tw: "湛河區",
            yzcode: "467000",
          },
          {
            code: 410421,
            name_cn: "宝丰县",
            name_en: "Baofeng",
            name_tw: "寶豐縣",
            yzcode: "467400",
          },
          {
            code: 410422,
            name_cn: "叶县",
            name_en: "Yexian",
            name_tw: "葉縣",
            yzcode: "467200",
          },
          {
            code: 410423,
            name_cn: "鲁山县",
            name_en: "Lushan",
            name_tw: "魯山縣",
            yzcode: "467300",
          },
          {
            code: 410425,
            name_cn: "郏县",
            name_en: "Jiaxian",
            name_tw: "郟縣",
            yzcode: "467100",
          },
          {
            code: 410481,
            name_cn: "舞钢市",
            name_en: "Wugang",
            name_tw: "舞鋼市",
            yzcode: "462500",
          },
          {
            code: 410482,
            name_cn: "汝州市",
            name_en: "Ruzhou",
            name_tw: "汝州市",
            yzcode: "467500",
          },
        ],
      },
      {
        code: 410500,
        name_cn: "安阳市",
        name_en: "Anyang",
        name_tw: "安陽市",
        children: [
          {
            code: 410502,
            name_cn: "文峰区",
            name_en: "Wenfeng",
            name_tw: "文峰區",
            yzcode: "455000",
          },
          {
            code: 410503,
            name_cn: "北关区",
            name_en: "Beiguan",
            name_tw: "北關區",
            yzcode: "455001",
          },
          {
            code: 410505,
            name_cn: "殷都区",
            name_en: "Yindu",
            name_tw: "殷都區",
            yzcode: "455004",
          },
          {
            code: 410506,
            name_cn: "龙安区",
            name_en: "Long'an",
            name_tw: "龍安區",
            yzcode: "455001",
          },
          {
            code: 410522,
            name_cn: "安阳县",
            name_en: "Anyang",
            name_tw: "安陽縣",
            yzcode: "455000",
          },
          {
            code: 410523,
            name_cn: "汤阴县",
            name_en: "Tangyin",
            name_tw: "湯陰縣",
            yzcode: "456150",
          },
          {
            code: 410526,
            name_cn: "滑县",
            name_en: "Huaxian",
            name_tw: "滑縣",
            yzcode: "456400",
          },
          {
            code: 410527,
            name_cn: "内黄县",
            name_en: "Neihuang",
            name_tw: "內黃縣",
            yzcode: "456350",
          },
          {
            code: 410581,
            name_cn: "林州市",
            name_en: "Linzhou",
            name_tw: "林州市",
            yzcode: "456550",
          },
        ],
      },
      {
        code: 410600,
        name_cn: "鹤壁市",
        name_en: "Hebi",
        name_tw: "鶴壁市",
        children: [
          {
            code: 410602,
            name_cn: "鹤山区",
            name_en: "Heshan",
            name_tw: "鶴山區",
            yzcode: "458010",
          },
          {
            code: 410603,
            name_cn: "山城区",
            name_en: "Shancheng",
            name_tw: "山城區",
            yzcode: "458000",
          },
          {
            code: 410611,
            name_cn: "淇滨区",
            name_en: "Qibin",
            name_tw: "淇濱區",
            yzcode: "458000",
          },
          {
            code: 410621,
            name_cn: "浚县",
            name_en: "Xunxian",
            name_tw: "浚縣",
            yzcode: "456250",
          },
          {
            code: 410622,
            name_cn: "淇县",
            name_en: "Qixian",
            name_tw: "淇縣",
            yzcode: "456750",
          },
        ],
      },
      {
        code: 410700,
        name_cn: "新乡市",
        name_en: "Xinxiang",
        name_tw: "新鄉市",
        children: [
          {
            code: 410702,
            name_cn: "红旗区",
            name_en: "Hongqi",
            name_tw: "紅旗區",
            yzcode: "453000",
          },
          {
            code: 410703,
            name_cn: "卫滨区",
            name_en: "Weibin",
            name_tw: "衛濱區",
            yzcode: "453000",
          },
          {
            code: 410704,
            name_cn: "凤泉区",
            name_en: "Fengquan",
            name_tw: "鳳泉區",
            yzcode: "453011",
          },
          {
            code: 410711,
            name_cn: "牧野区",
            name_en: "Muye",
            name_tw: "牧野區",
            yzcode: "453002",
          },
          {
            code: 410721,
            name_cn: "新乡县",
            name_en: "Xinxiang",
            name_tw: "新鄉縣",
            yzcode: "453700",
          },
          {
            code: 410724,
            name_cn: "获嘉县",
            name_en: "Huojia",
            name_tw: "獲嘉縣",
            yzcode: "453800",
          },
          {
            code: 410725,
            name_cn: "原阳县",
            name_en: "Yuanyang",
            name_tw: "原陽縣",
            yzcode: "453500",
          },
          {
            code: 410726,
            name_cn: "延津县",
            name_en: "Yanjin",
            name_tw: "延津縣",
            yzcode: "453200",
          },
          {
            code: 410727,
            name_cn: "封丘县",
            name_en: "Fengqiu",
            name_tw: "封丘縣",
            yzcode: "453300",
          },
          {
            code: 410728,
            name_cn: "长垣县",
            name_en: "Changyuan",
            name_tw: "長垣縣",
            yzcode: "453400",
          },
          {
            code: 410781,
            name_cn: "卫辉市",
            name_en: "Weihui",
            name_tw: "衛輝市",
            yzcode: "453100",
          },
          {
            code: 410782,
            name_cn: "辉县市",
            name_en: "Huixian",
            name_tw: "輝縣市",
            yzcode: "453600",
          },
        ],
      },
      {
        code: 410800,
        name_cn: "焦作市",
        name_en: "Jiaozuo",
        name_tw: "焦作市",
        children: [
          {
            code: 410802,
            name_cn: "解放区",
            name_en: "Jiefang",
            name_tw: "解放區",
            yzcode: "454000",
          },
          {
            code: 410803,
            name_cn: "中站区",
            name_en: "Zhongzhan",
            name_tw: "中站區",
            yzcode: "454191",
          },
          {
            code: 410804,
            name_cn: "马村区",
            name_en: "Macun",
            name_tw: "馬村區",
            yzcode: "454171",
          },
          {
            code: 410811,
            name_cn: "山阳区",
            name_en: "Shanyang",
            name_tw: "山陽區",
            yzcode: "454002",
          },
          {
            code: 410821,
            name_cn: "修武县",
            name_en: "Xiuwu",
            name_tw: "修武縣",
            yzcode: "454350",
          },
          {
            code: 410822,
            name_cn: "博爱县",
            name_en: "Boai",
            name_tw: "博愛縣",
            yzcode: "454450",
          },
          {
            code: 410823,
            name_cn: "武陟县",
            name_en: "Wuzhi",
            name_tw: "武陟縣",
            yzcode: "454950",
          },
          {
            code: 410825,
            name_cn: "温县",
            name_en: "Wenxian",
            name_tw: "溫縣",
            yzcode: "454850",
          },
          {
            code: 410882,
            name_cn: "沁阳市",
            name_en: "Qinyang",
            name_tw: "沁陽市",
            yzcode: "454550",
          },
          {
            code: 410883,
            name_cn: "孟州市",
            name_en: "Mengzhou",
            name_tw: "孟州市",
            yzcode: "454750",
          },
        ],
      },
      {
        code: 410900,
        name_cn: "濮阳市",
        name_en: "Puyang",
        name_tw: "濮陽市",
        children: [
          {
            code: 410902,
            name_cn: "华龙区",
            name_en: "Hualong",
            name_tw: "華龍區",
            yzcode: "457001",
          },
          {
            code: 410922,
            name_cn: "清丰县",
            name_en: "Qingfeng",
            name_tw: "清豐縣",
            yzcode: "457300",
          },
          {
            code: 410923,
            name_cn: "南乐县",
            name_en: "Nanle",
            name_tw: "南樂縣",
            yzcode: "457400",
          },
          {
            code: 410926,
            name_cn: "范县",
            name_en: "Fanxian",
            name_tw: "範縣",
            yzcode: "457500",
          },
          {
            code: 410927,
            name_cn: "台前县",
            name_en: "Taiqian",
            name_tw: "台前縣",
            yzcode: "457600",
          },
          {
            code: 410928,
            name_cn: "濮阳县",
            name_en: "Puyang",
            name_tw: "濮陽縣",
            yzcode: "457100",
          },
        ],
      },
      {
        code: 411000,
        name_cn: "许昌市",
        name_en: "Xuchang",
        name_tw: "許昌市",
        children: [
          {
            code: 411002,
            name_cn: "魏都区",
            name_en: "Weidu",
            name_tw: "魏都區",
            yzcode: "461000",
          },
          {
            code: 411023,
            name_cn: "许昌县",
            name_en: "Xuchang",
            name_tw: "許昌縣",
            yzcode: "461100",
          },
          {
            code: 411024,
            name_cn: "鄢陵县",
            name_en: "Yanling",
            name_tw: "鄢陵縣",
            yzcode: "461200",
          },
          {
            code: 411025,
            name_cn: "襄城县",
            name_en: "Xiangcheng",
            name_tw: "襄城縣",
            yzcode: "461700",
          },
          {
            code: 411081,
            name_cn: "禹州市",
            name_en: "Yuzhou",
            name_tw: "禹州市",
            yzcode: "461670",
          },
          {
            code: 411082,
            name_cn: "长葛市",
            name_en: "Changge",
            name_tw: "長葛市",
            yzcode: "461500",
          },
        ],
      },
      {
        code: 411100,
        name_cn: "漯河市",
        name_en: "Luohe",
        name_tw: "漯河市",
        children: [
          {
            code: 411102,
            name_cn: "源汇区",
            name_en: "Yuanhui",
            name_tw: "源匯區",
            yzcode: "462000",
          },
          {
            code: 411103,
            name_cn: "郾城区",
            name_en: "Yancheng",
            name_tw: "郾城區",
            yzcode: "462300",
          },
          {
            code: 411104,
            name_cn: "召陵区",
            name_en: "Zhaoling",
            name_tw: "召陵區",
            yzcode: "462300",
          },
          {
            code: 411121,
            name_cn: "舞阳县",
            name_en: "Wuyang",
            name_tw: "舞陽縣",
            yzcode: "462400",
          },
          {
            code: 411122,
            name_cn: "临颍县",
            name_en: "Linying",
            name_tw: "臨潁縣",
            yzcode: "462600",
          },
        ],
      },
      {
        code: 411200,
        name_cn: "三门峡市",
        name_en: "Sanmenxia",
        name_tw: "三門峽市",
        children: [
          {
            code: 411202,
            name_cn: "湖滨区",
            name_en: "Hubin",
            name_tw: "湖濱區",
            yzcode: "472000",
          },
          {
            code: 411221,
            name_cn: "渑池县",
            name_en: "Mianchi",
            name_tw: "澠池縣",
            yzcode: "472400",
          },
          {
            code: 411222,
            name_cn: "陕县",
            name_en: "Shanxian",
            name_tw: "陜縣",
            yzcode: "472100",
          },
          {
            code: 411224,
            name_cn: "卢氏县",
            name_en: "Lushi",
            name_tw: "盧氏縣",
            yzcode: "472200",
          },
          {
            code: 411281,
            name_cn: "义马市",
            name_en: "Yima",
            name_tw: "義馬市",
            yzcode: "472300",
          },
          {
            code: 411282,
            name_cn: "灵宝市",
            name_en: "Lingbao",
            name_tw: "靈寶市",
            yzcode: "472500",
          },
        ],
      },
      {
        code: 411300,
        name_cn: "南阳市",
        name_en: "Nanyang",
        name_tw: "南陽市",
        children: [
          {
            code: 411302,
            name_cn: "宛城区",
            name_en: "Wancheng",
            name_tw: "宛城區",
            yzcode: "473001",
          },
          {
            code: 411303,
            name_cn: "卧龙区",
            name_en: "Wolong",
            name_tw: "臥龍區",
            yzcode: "473003",
          },
          {
            code: 411321,
            name_cn: "南召县",
            name_en: "Nanzhao",
            name_tw: "南召縣",
            yzcode: "474650",
          },
          {
            code: 411322,
            name_cn: "方城县",
            name_en: "Fangcheng",
            name_tw: "方城縣",
            yzcode: "473200",
          },
          {
            code: 411323,
            name_cn: "西峡县",
            name_en: "Xixia",
            name_tw: "西峽縣",
            yzcode: "474550",
          },
          {
            code: 411324,
            name_cn: "镇平县",
            name_en: "Zhenping",
            name_tw: "鎮平縣",
            yzcode: "474250",
          },
          {
            code: 411325,
            name_cn: "内乡县",
            name_en: "Neixiang",
            name_tw: "內鄉縣",
            yzcode: "474350",
          },
          {
            code: 411326,
            name_cn: "淅川县",
            name_en: "Xichuan",
            name_tw: "淅川縣",
            yzcode: "474450",
          },
          {
            code: 411327,
            name_cn: "社旗县",
            name_en: "Sheqi",
            name_tw: "社旗縣",
            yzcode: "473300",
          },
          {
            code: 411328,
            name_cn: "唐河县",
            name_en: "Tanghe",
            name_tw: "唐河縣",
            yzcode: "473400",
          },
          {
            code: 411329,
            name_cn: "新野县",
            name_en: "Xinye",
            name_tw: "新野縣",
            yzcode: "473500",
          },
          {
            code: 411330,
            name_cn: "桐柏县",
            name_en: "Tongbai",
            name_tw: "桐柏縣",
            yzcode: "474750",
          },
          {
            code: 411381,
            name_cn: "邓州市",
            name_en: "Dengzhou",
            name_tw: "鄧州市",
            yzcode: "474150",
          },
        ],
      },
      {
        code: 411400,
        name_cn: "商丘市",
        name_en: "Shangqiu",
        name_tw: "商丘市",
        children: [
          {
            code: 411402,
            name_cn: "梁园区",
            name_en: "Liangyuan",
            name_tw: "梁園區",
            yzcode: "476000",
          },
          {
            code: 411403,
            name_cn: "睢阳区",
            name_en: "Suiyang",
            name_tw: "睢陽區",
            yzcode: "476100",
          },
          {
            code: 411421,
            name_cn: "民权县",
            name_en: "Minquan",
            name_tw: "民權縣",
            yzcode: "476800",
          },
          {
            code: 411422,
            name_cn: "睢县",
            name_en: "Suixian",
            name_tw: "睢縣",
            yzcode: "476900",
          },
          {
            code: 411423,
            name_cn: "宁陵县",
            name_en: "Ningling",
            name_tw: "寧陵縣",
            yzcode: "476700",
          },
          {
            code: 411424,
            name_cn: "柘城县",
            name_en: "Zhecheng",
            name_tw: "柘城縣",
            yzcode: "476200",
          },
          {
            code: 411425,
            name_cn: "虞城县",
            name_en: "Yucheng",
            name_tw: "虞城縣",
            yzcode: "476300",
          },
          {
            code: 411426,
            name_cn: "夏邑县",
            name_en: "Xiayi",
            name_tw: "夏邑縣",
            yzcode: "476400",
          },
          {
            code: 411481,
            name_cn: "永城市",
            name_en: "Yongcheng",
            name_tw: "永城市",
            yzcode: "476600",
          },
        ],
      },
      {
        code: 411500,
        name_cn: "信阳市",
        name_en: "Xinyang",
        name_tw: "信陽市",
        children: [
          {
            code: 411502,
            name_cn: "浉河区",
            name_en: "Shihe",
            name_tw: "浉河區",
            yzcode: "464000",
          },
          {
            code: 411503,
            name_cn: "平桥区",
            name_en: "Pingqiao",
            name_tw: "平橋區",
            yzcode: "464100",
          },
          {
            code: 411521,
            name_cn: "罗山县",
            name_en: "Luoshan",
            name_tw: "羅山縣",
            yzcode: "464200",
          },
          {
            code: 411522,
            name_cn: "光山县",
            name_en: "Guangshan",
            name_tw: "光山縣",
            yzcode: "465450",
          },
          {
            code: 411523,
            name_cn: "新县",
            name_en: "Xinxian",
            name_tw: "新縣",
            yzcode: "465550",
          },
          {
            code: 411524,
            name_cn: "商城县",
            name_en: "Shangcheng",
            name_tw: "商城縣",
            yzcode: "465350",
          },
          {
            code: 411525,
            name_cn: "固始县",
            name_en: "Gushi",
            name_tw: "固始縣",
            yzcode: "465250",
          },
          {
            code: 411526,
            name_cn: "潢川县",
            name_en: "Huangchuan",
            name_tw: "潢川縣",
            yzcode: "465150",
          },
          {
            code: 411527,
            name_cn: "淮滨县",
            name_en: "Huaibin",
            name_tw: "淮濱縣",
            yzcode: "464400",
          },
          {
            code: 411528,
            name_cn: "息县",
            name_en: "Xixian",
            name_tw: "息縣",
            yzcode: "464300",
          },
        ],
      },
      {
        code: 411600,
        name_cn: "周口市",
        name_en: "Zhoukou",
        name_tw: "周口市",
        children: [
          {
            code: 411602,
            name_cn: "川汇区",
            name_en: "Chuanhui",
            name_tw: "川匯區",
            yzcode: "466000",
          },
          {
            code: 411621,
            name_cn: "扶沟县",
            name_en: "Fugou",
            name_tw: "扶溝縣",
            yzcode: "461300",
          },
          {
            code: 411622,
            name_cn: "西华县",
            name_en: "Xihua",
            name_tw: "西華縣",
            yzcode: "466600",
          },
          {
            code: 411623,
            name_cn: "商水县",
            name_en: "Shangshui",
            name_tw: "商水縣",
            yzcode: "466100",
          },
          {
            code: 411624,
            name_cn: "沈丘县",
            name_en: "Shenqiu",
            name_tw: "沈丘縣",
            yzcode: "466300",
          },
          {
            code: 411625,
            name_cn: "郸城县",
            name_en: "Dancheng",
            name_tw: "鄲城縣",
            yzcode: "477150",
          },
          {
            code: 411626,
            name_cn: "淮阳县",
            name_en: "Huaiyang",
            name_tw: "淮陽縣",
            yzcode: "466700",
          },
          {
            code: 411627,
            name_cn: "太康县",
            name_en: "Taikang",
            name_tw: "太康縣",
            yzcode: "461400",
          },
          {
            code: 411628,
            name_cn: "鹿邑县",
            name_en: "Luyi",
            name_tw: "鹿邑縣",
            yzcode: "477200",
          },
          {
            code: 411681,
            name_cn: "项城市",
            name_en: "Xiangcheng",
            name_tw: "項城市",
            yzcode: "466200",
          },
        ],
      },
      {
        code: 411700,
        name_cn: "驻马店市",
        name_en: "Zhumadian",
        name_tw: "駐馬店市",
        children: [
          {
            code: 411702,
            name_cn: "驿城区",
            name_en: "Yicheng",
            name_tw: "驛城區",
            yzcode: "463000",
          },
          {
            code: 411721,
            name_cn: "西平县",
            name_en: "Xiping",
            name_tw: "西平縣",
            yzcode: "463900",
          },
          {
            code: 411722,
            name_cn: "上蔡县",
            name_en: "Shangcai",
            name_tw: "上蔡縣",
            yzcode: "463800",
          },
          {
            code: 411723,
            name_cn: "平舆县",
            name_en: "Pingyu",
            name_tw: "平輿縣",
            yzcode: "463400",
          },
          {
            code: 411724,
            name_cn: "正阳县",
            name_en: "Zhengyang",
            name_tw: "正陽縣",
            yzcode: "463600",
          },
          {
            code: 411725,
            name_cn: "确山县",
            name_en: "Queshan",
            name_tw: "確山縣",
            yzcode: "463200",
          },
          {
            code: 411726,
            name_cn: "泌阳县",
            name_en: "Biyang",
            name_tw: "泌陽縣",
            yzcode: "463700",
          },
          {
            code: 411727,
            name_cn: "汝南县",
            name_en: "Runan",
            name_tw: "汝南縣",
            yzcode: "463300",
          },
          {
            code: 411728,
            name_cn: "遂平县",
            name_en: "Suiping",
            name_tw: "遂平縣",
            yzcode: "463100",
          },
          {
            code: 411729,
            name_cn: "新蔡县",
            name_en: "Xincai",
            name_tw: "新蔡縣",
            yzcode: "463500",
          },
        ],
      },
      {
        code: 419000,
        name_cn: "直辖县级",
        name_en: "",
        name_tw: "直轄縣級",
        children: [
          {
            code: 419001,
            name_cn: "济源市",
            name_en: "Jiyuan",
            name_tw: "濟源市",
            yzcode: "454650",
          },
        ],
      },
    ],
  },
  {
    code: 420000,
    name_cn: "湖北省",
    name_en: "Hubei",
    name_tw: "湖北省",
    children: [
      {
        code: 420100,
        name_cn: "武汉市",
        name_en: "Wuhan",
        name_tw: "武漢市",
        children: [
          {
            code: 420102,
            name_cn: "江岸区",
            name_en: "Jiang'an",
            name_tw: "江岸區",
            yzcode: "430014",
          },
          {
            code: 420103,
            name_cn: "江汉区",
            name_en: "Jianghan",
            name_tw: "江漢區",
            yzcode: "430021",
          },
          {
            code: 420104,
            name_cn: "硚口区",
            name_en: "Qiaokou",
            name_tw: "硚口區",
            yzcode: "430033",
          },
          {
            code: 420105,
            name_cn: "汉阳区",
            name_en: "Hanyang",
            name_tw: "漢陽區",
            yzcode: "430050",
          },
          {
            code: 420106,
            name_cn: "武昌区",
            name_en: "Wuchang",
            name_tw: "武昌區",
            yzcode: "430061",
          },
          {
            code: 420107,
            name_cn: "青山区",
            name_en: "Qingshan",
            name_tw: "青山區",
            yzcode: "430080",
          },
          {
            code: 420111,
            name_cn: "洪山区",
            name_en: "Hongshan",
            name_tw: "洪山區",
            yzcode: "430070",
          },
          {
            code: 420112,
            name_cn: "东西湖区",
            name_en: "Dongxihu",
            name_tw: "東西湖區",
            yzcode: "430040",
          },
          {
            code: 420113,
            name_cn: "汉南区",
            name_en: "Hannan",
            name_tw: "漢南區",
            yzcode: "430090",
          },
          {
            code: 420114,
            name_cn: "蔡甸区",
            name_en: "Caidian",
            name_tw: "蔡甸區",
            yzcode: "430100",
          },
          {
            code: 420115,
            name_cn: "江夏区",
            name_en: "Jiangxia",
            name_tw: "江夏區",
            yzcode: "430200",
          },
          {
            code: 420116,
            name_cn: "黄陂区",
            name_en: "Huangpi",
            name_tw: "黃陂區",
            yzcode: "432200",
          },
          {
            code: 420117,
            name_cn: "新洲区",
            name_en: "Xinzhou",
            name_tw: "新洲區",
            yzcode: "431400",
          },
        ],
      },
      {
        code: 420200,
        name_cn: "黄石市",
        name_en: "Huangshi",
        name_tw: "黃石市",
        children: [
          {
            code: 420202,
            name_cn: "黄石港区",
            name_en: "Huangshigang",
            name_tw: "黃石港區",
            yzcode: "435000",
          },
          {
            code: 420203,
            name_cn: "西塞山区",
            name_en: "Xisaishan",
            name_tw: "西塞山區",
            yzcode: "435001",
          },
          {
            code: 420204,
            name_cn: "下陆区",
            name_en: "Xialu",
            name_tw: "下陸區",
            yzcode: "435005",
          },
          {
            code: 420205,
            name_cn: "铁山区",
            name_en: "Tieshan",
            name_tw: "鐵山區",
            yzcode: "435006",
          },
          {
            code: 420222,
            name_cn: "阳新县",
            name_en: "Yangxin",
            name_tw: "陽新縣",
            yzcode: "435200",
          },
          {
            code: 420281,
            name_cn: "大冶市",
            name_en: "Daye",
            name_tw: "大冶市",
            yzcode: "435100",
          },
        ],
      },
      {
        code: 420300,
        name_cn: "十堰市",
        name_en: "Shiyan",
        name_tw: "十堰市",
        children: [
          {
            code: 420302,
            name_cn: "茅箭区",
            name_en: "Maojian",
            name_tw: "茅箭區",
            yzcode: "442012",
          },
          {
            code: 420303,
            name_cn: "张湾区",
            name_en: "Zhangwan",
            name_tw: "張灣區",
            yzcode: "442001",
          },
          {
            code: 420304,
            name_cn: "郧阳区",
            name_en: "Yunyang",
            name_tw: "鄖陽區",
            yzcode: "442500",
          },
          {
            code: 420322,
            name_cn: "郧西县",
            name_en: "Yunxi",
            name_tw: "鄖西縣",
            yzcode: "442600",
          },
          {
            code: 420323,
            name_cn: "竹山县",
            name_en: "Zhushan",
            name_tw: "竹山縣",
            yzcode: "442200",
          },
          {
            code: 420324,
            name_cn: "竹溪县",
            name_en: "Zhuxi",
            name_tw: "竹溪縣",
            yzcode: "442300",
          },
          {
            code: 420325,
            name_cn: "房县",
            name_en: "Fangxian",
            name_tw: "房縣",
            yzcode: "442100",
          },
          {
            code: 420381,
            name_cn: "丹江口市",
            name_en: "Danjiangkou",
            name_tw: "丹江口市",
            yzcode: "442700",
          },
        ],
      },
      {
        code: 420500,
        name_cn: "宜昌市",
        name_en: "Yichang",
        name_tw: "宜昌市",
        children: [
          {
            code: 420502,
            name_cn: "西陵区",
            name_en: "Xiling",
            name_tw: "西陵區",
            yzcode: "443000",
          },
          {
            code: 420503,
            name_cn: "伍家岗区",
            name_en: "Wujiagang",
            name_tw: "伍家崗區",
            yzcode: "443001",
          },
          {
            code: 420504,
            name_cn: "点军区",
            name_en: "Dianjun",
            name_tw: "點軍區",
            yzcode: "443006",
          },
          {
            code: 420505,
            name_cn: "猇亭区",
            name_en: "Xiaoting",
            name_tw: "猇亭區",
            yzcode: "443007",
          },
          {
            code: 420506,
            name_cn: "夷陵区",
            name_en: "Yiling",
            name_tw: "夷陵區",
            yzcode: "443100",
          },
          {
            code: 420525,
            name_cn: "远安县",
            name_en: "Yuan'an",
            name_tw: "遠安縣",
            yzcode: "444200",
          },
          {
            code: 420526,
            name_cn: "兴山县",
            name_en: "Xingshan",
            name_tw: "興山縣",
            yzcode: "443711",
          },
          {
            code: 420527,
            name_cn: "秭归县",
            name_en: "Zigui",
            name_tw: "秭歸縣",
            yzcode: "443600",
          },
          {
            code: 420528,
            name_cn: "长阳土家族自治县",
            name_en: "Changyang",
            name_tw: "長陽土家族自治縣",
            yzcode: "443500",
          },
          {
            code: 420529,
            name_cn: "五峰土家族自治县",
            name_en: "Wufeng",
            name_tw: "五峰土家族自治縣",
            yzcode: "443413",
          },
          {
            code: 420581,
            name_cn: "宜都市",
            name_en: "Yidu",
            name_tw: "宜都市",
            yzcode: "443300",
          },
          {
            code: 420582,
            name_cn: "当阳市",
            name_en: "Dangyang",
            name_tw: "當陽市",
            yzcode: "444100",
          },
          {
            code: 420583,
            name_cn: "枝江市",
            name_en: "Zhijiang",
            name_tw: "枝江市",
            yzcode: "443200",
          },
        ],
      },
      {
        code: 420600,
        name_cn: "襄阳市",
        name_en: "Xiangyang",
        name_tw: "襄陽市",
        children: [
          {
            code: 420602,
            name_cn: "襄城区",
            name_en: "Xiangcheng",
            name_tw: "襄城區",
            yzcode: "441021",
          },
          {
            code: 420606,
            name_cn: "樊城区",
            name_en: "Fancheng",
            name_tw: "樊城區",
            yzcode: "441001",
          },
          {
            code: 420607,
            name_cn: "襄州区",
            name_en: "Xiangzhou",
            name_tw: "襄州區",
            yzcode: "441100",
          },
          {
            code: 420624,
            name_cn: "南漳县",
            name_en: "Nanzhang",
            name_tw: "南漳縣",
            yzcode: "441500",
          },
          {
            code: 420625,
            name_cn: "谷城县",
            name_en: "Gucheng",
            name_tw: "谷城縣",
            yzcode: "441700",
          },
          {
            code: 420626,
            name_cn: "保康县",
            name_en: "Baokang",
            name_tw: "保康縣",
            yzcode: "441600",
          },
          {
            code: 420682,
            name_cn: "老河口市",
            name_en: "Laohekou",
            name_tw: "老河口市",
            yzcode: "441800",
          },
          {
            code: 420683,
            name_cn: "枣阳市",
            name_en: "Zaoyang",
            name_tw: "棗陽市",
            yzcode: "441200",
          },
          {
            code: 420684,
            name_cn: "宜城市",
            name_en: "Yicheng",
            name_tw: "宜城市",
            yzcode: "441400",
          },
        ],
      },
      {
        code: 420700,
        name_cn: "鄂州市",
        name_en: "Ezhou",
        name_tw: "鄂州市",
        children: [
          {
            code: 420702,
            name_cn: "梁子湖区",
            name_en: "Liangzihu",
            name_tw: "梁子湖區",
            yzcode: "436064",
          },
          {
            code: 420703,
            name_cn: "华容区",
            name_en: "Huarong",
            name_tw: "華容區",
            yzcode: "436030",
          },
          {
            code: 420704,
            name_cn: "鄂城区",
            name_en: "Echeng",
            name_tw: "鄂城區",
            yzcode: "436000",
          },
        ],
      },
      {
        code: 420800,
        name_cn: "荆门市",
        name_en: "Jingmen",
        name_tw: "荊門市",
        children: [
          {
            code: 420802,
            name_cn: "东宝区",
            name_en: "Dongbao",
            name_tw: "東寶區",
            yzcode: "448004",
          },
          {
            code: 420804,
            name_cn: "掇刀区",
            name_en: "Duodao",
            name_tw: "掇刀區",
            yzcode: "448124",
          },
          {
            code: 420821,
            name_cn: "京山县",
            name_en: "Jingshan",
            name_tw: "京山縣",
            yzcode: "431800",
          },
          {
            code: 420822,
            name_cn: "沙洋县",
            name_en: "Shayang",
            name_tw: "沙洋縣",
            yzcode: "448200",
          },
          {
            code: 420881,
            name_cn: "钟祥市",
            name_en: "Zhongxiang",
            name_tw: "鐘祥市",
            yzcode: "431900",
          },
        ],
      },
      {
        code: 420900,
        name_cn: "孝感市",
        name_en: "Xiaogan",
        name_tw: "孝感市",
        children: [
          {
            code: 420902,
            name_cn: "孝南区",
            name_en: "Xiaonan",
            name_tw: "孝南區",
            yzcode: "432100",
          },
          {
            code: 420921,
            name_cn: "孝昌县",
            name_en: "Xiaochang",
            name_tw: "孝昌縣",
            yzcode: "432900",
          },
          {
            code: 420922,
            name_cn: "大悟县",
            name_en: "Dawu",
            name_tw: "大悟縣",
            yzcode: "432800",
          },
          {
            code: 420923,
            name_cn: "云梦县",
            name_en: "Yunmeng",
            name_tw: "雲夢縣",
            yzcode: "432500",
          },
          {
            code: 420981,
            name_cn: "应城市",
            name_en: "Yingcheng",
            name_tw: "應城市",
            yzcode: "432400",
          },
          {
            code: 420982,
            name_cn: "安陆市",
            name_en: "Anlu",
            name_tw: "安陸市",
            yzcode: "432600",
          },
          {
            code: 420984,
            name_cn: "汉川市",
            name_en: "Hanchuan",
            name_tw: "漢川市",
            yzcode: "432300",
          },
        ],
      },
      {
        code: 421000,
        name_cn: "荆州市",
        name_en: "Jingzhou",
        name_tw: "荊州市",
        children: [
          {
            code: 421002,
            name_cn: "沙市区",
            name_en: "Shashi",
            name_tw: "沙市區",
            yzcode: "434000",
          },
          {
            code: 421003,
            name_cn: "荆州区",
            name_en: "Jingzhou",
            name_tw: "荊州區",
            yzcode: "434020",
          },
          {
            code: 421022,
            name_cn: "公安县",
            name_en: "Gong'an",
            name_tw: "公安縣",
            yzcode: "434300",
          },
          {
            code: 421023,
            name_cn: "监利县",
            name_en: "Jianli",
            name_tw: "監利縣",
            yzcode: "433300",
          },
          {
            code: 421024,
            name_cn: "江陵县",
            name_en: "Jiangling",
            name_tw: "江陵縣",
            yzcode: "434101",
          },
          {
            code: 421081,
            name_cn: "石首市",
            name_en: "Shishou",
            name_tw: "石首市",
            yzcode: "434400",
          },
          {
            code: 421083,
            name_cn: "洪湖市",
            name_en: "Honghu",
            name_tw: "洪湖市",
            yzcode: "433200",
          },
          {
            code: 421087,
            name_cn: "松滋市",
            name_en: "Songzi",
            name_tw: "松滋市",
            yzcode: "434200",
          },
        ],
      },
      {
        code: 421100,
        name_cn: "黄冈市",
        name_en: "Huanggang",
        name_tw: "黃岡市",
        children: [
          {
            code: 421102,
            name_cn: "黄州区",
            name_en: "Huangzhou",
            name_tw: "黃州區",
            yzcode: "438000",
          },
          {
            code: 421121,
            name_cn: "团风县",
            name_en: "Tuanfeng",
            name_tw: "團風縣",
            yzcode: "438800",
          },
          {
            code: 421122,
            name_cn: "红安县",
            name_en: "Hong'an",
            name_tw: "紅安縣",
            yzcode: "438401",
          },
          {
            code: 421123,
            name_cn: "罗田县",
            name_en: "Luotian",
            name_tw: "羅田縣",
            yzcode: "438600",
          },
          {
            code: 421124,
            name_cn: "英山县",
            name_en: "Yingshan",
            name_tw: "英山縣",
            yzcode: "438700",
          },
          {
            code: 421125,
            name_cn: "浠水县",
            name_en: "Xishui",
            name_tw: "浠水縣",
            yzcode: "438200",
          },
          {
            code: 421126,
            name_cn: "蕲春县",
            name_en: "Qichun",
            name_tw: "蘄春縣",
            yzcode: "435300",
          },
          {
            code: 421127,
            name_cn: "黄梅县",
            name_en: "Huangmei",
            name_tw: "黃梅縣",
            yzcode: "435500",
          },
          {
            code: 421181,
            name_cn: "麻城市",
            name_en: "Macheng",
            name_tw: "麻城市",
            yzcode: "438300",
          },
          {
            code: 421182,
            name_cn: "武穴市",
            name_en: "Wuxue",
            name_tw: "武穴市",
            yzcode: "435400",
          },
        ],
      },
      {
        code: 421200,
        name_cn: "咸宁市",
        name_en: "Xianning",
        name_tw: "鹹寧市",
        children: [
          {
            code: 421202,
            name_cn: "咸安区",
            name_en: "Xian'an",
            name_tw: "鹹安區",
            yzcode: "437000",
          },
          {
            code: 421221,
            name_cn: "嘉鱼县",
            name_en: "Jiayu",
            name_tw: "嘉魚縣",
            yzcode: "437200",
          },
          {
            code: 421222,
            name_cn: "通城县",
            name_en: "Tongcheng",
            name_tw: "通城縣",
            yzcode: "437400",
          },
          {
            code: 421223,
            name_cn: "崇阳县",
            name_en: "Chongyang",
            name_tw: "崇陽縣",
            yzcode: "437500",
          },
          {
            code: 421224,
            name_cn: "通山县",
            name_en: "Tongshan",
            name_tw: "通山縣",
            yzcode: "437600",
          },
          {
            code: 421281,
            name_cn: "赤壁市",
            name_en: "Chibi",
            name_tw: "赤壁市",
            yzcode: "437300",
          },
        ],
      },
      {
        code: 421300,
        name_cn: "随州市",
        name_en: "Suizhou",
        name_tw: "隨州市",
        children: [
          {
            code: 421303,
            name_cn: "曾都区",
            name_en: "Zengdu",
            name_tw: "曾都區",
            yzcode: "441300",
          },
          {
            code: 421321,
            name_cn: "随县",
            name_en: "Suixian",
            name_tw: "隨縣",
            yzcode: "441309",
          },
          {
            code: 421381,
            name_cn: "广水市",
            name_en: "Guangshui",
            name_tw: "廣水市",
            yzcode: "432700",
          },
        ],
      },
      {
        code: 422800,
        name_cn: "恩施土家族苗族自治州",
        name_en: "Enshi",
        name_tw: "恩施土家族苗族自治州",
        children: [
          {
            code: 422801,
            name_cn: "恩施市",
            name_en: "Enshi",
            name_tw: "恩施市",
            yzcode: "445000",
          },
          {
            code: 422802,
            name_cn: "利川市",
            name_en: "Lichuan",
            name_tw: "利川市",
            yzcode: "445400",
          },
          {
            code: 422822,
            name_cn: "建始县",
            name_en: "Jianshi",
            name_tw: "建始縣",
            yzcode: "445300",
          },
          {
            code: 422823,
            name_cn: "巴东县",
            name_en: "Badong",
            name_tw: "巴東縣",
            yzcode: "444300",
          },
          {
            code: 422825,
            name_cn: "宣恩县",
            name_en: "Xuanen",
            name_tw: "宣恩縣",
            yzcode: "445500",
          },
          {
            code: 422826,
            name_cn: "咸丰县",
            name_en: "Xianfeng",
            name_tw: "鹹豐縣",
            yzcode: "445600",
          },
          {
            code: 422827,
            name_cn: "来凤县",
            name_en: "Laifeng",
            name_tw: "來鳳縣",
            yzcode: "445700",
          },
          {
            code: 422828,
            name_cn: "鹤峰县",
            name_en: "Hefeng",
            name_tw: "鶴峰縣",
            yzcode: "445800",
          },
        ],
      },
      {
        code: 429000,
        name_cn: "直辖县级",
        name_en: "",
        name_tw: "直轄縣級",
        children: [
          {
            code: 429004,
            name_cn: "仙桃市",
            name_en: "Xiantao",
            name_tw: "仙桃市",
            yzcode: "433000",
          },
          {
            code: 429005,
            name_cn: "潜江市",
            name_en: "Qianjiang",
            name_tw: "潛江市",
            yzcode: "433100",
          },
          {
            code: 429006,
            name_cn: "天门市",
            name_en: "Tianmen",
            name_tw: "天門市",
            yzcode: "431700",
          },
          {
            code: 429021,
            name_cn: "神农架林区",
            name_en: "Shennongjia",
            name_tw: "神農架林區",
            yzcode: "442400",
          },
        ],
      },
    ],
  },
  {
    code: 430000,
    name_cn: "湖南省",
    name_en: "Hunan",
    name_tw: "湖南省",
    children: [
      {
        code: 430100,
        name_cn: "长沙市",
        name_en: "Changsha",
        name_tw: "長沙市",
        children: [
          {
            code: 430102,
            name_cn: "芙蓉区",
            name_en: "Furong",
            name_tw: "芙蓉區",
            yzcode: "410011",
          },
          {
            code: 430103,
            name_cn: "天心区",
            name_en: "Tianxin",
            name_tw: "天心區",
            yzcode: "410004",
          },
          {
            code: 430104,
            name_cn: "岳麓区",
            name_en: "Yuelu",
            name_tw: "嶽麓區",
            yzcode: "410013",
          },
          {
            code: 430105,
            name_cn: "开福区",
            name_en: "Kaifu",
            name_tw: "開福區",
            yzcode: "410008",
          },
          {
            code: 430111,
            name_cn: "雨花区",
            name_en: "Yuhua",
            name_tw: "雨花區",
            yzcode: "410011",
          },
          {
            code: 430112,
            name_cn: "望城区",
            name_en: "Wangcheng",
            name_tw: "望城區",
            yzcode: "410200",
          },
          {
            code: 430121,
            name_cn: "长沙县",
            name_en: "Changsha",
            name_tw: "長沙縣",
            yzcode: "410100",
          },
          {
            code: 430124,
            name_cn: "宁乡县",
            name_en: "Ningxiang",
            name_tw: "寧鄉縣",
            yzcode: "410600",
          },
          {
            code: 430181,
            name_cn: "浏阳市",
            name_en: "Liuyang",
            name_tw: "瀏陽市",
            yzcode: "410300",
          },
        ],
      },
      {
        code: 430200,
        name_cn: "株洲市",
        name_en: "Zhuzhou",
        name_tw: "株洲市",
        children: [
          {
            code: 430202,
            name_cn: "荷塘区",
            name_en: "Hetang",
            name_tw: "荷塘區",
            yzcode: "412000",
          },
          {
            code: 430203,
            name_cn: "芦淞区",
            name_en: "Lusong",
            name_tw: "蘆淞區",
            yzcode: "412000",
          },
          {
            code: 430204,
            name_cn: "石峰区",
            name_en: "Shifeng",
            name_tw: "石峰區",
            yzcode: "412005",
          },
          {
            code: 430211,
            name_cn: "天元区",
            name_en: "Tianyuan",
            name_tw: "天元區",
            yzcode: "412007",
          },
          {
            code: 430221,
            name_cn: "株洲县",
            name_en: "Zhuzhou",
            name_tw: "株洲縣",
            yzcode: "412100",
          },
          {
            code: 430223,
            name_cn: "攸县",
            name_en: "Youxian",
            name_tw: "攸縣",
            yzcode: "412300",
          },
          {
            code: 430224,
            name_cn: "茶陵县",
            name_en: "Chaling",
            name_tw: "茶陵縣",
            yzcode: "412400",
          },
          {
            code: 430225,
            name_cn: "炎陵县",
            name_en: "Yanling",
            name_tw: "炎陵縣",
            yzcode: "412500",
          },
          {
            code: 430281,
            name_cn: "醴陵市",
            name_en: "Liling",
            name_tw: "醴陵市",
            yzcode: "412200",
          },
        ],
      },
      {
        code: 430300,
        name_cn: "湘潭市",
        name_en: "Xiangtan",
        name_tw: "湘潭市",
        children: [
          {
            code: 430302,
            name_cn: "雨湖区",
            name_en: "Yuhu",
            name_tw: "雨湖區",
            yzcode: "411100",
          },
          {
            code: 430304,
            name_cn: "岳塘区",
            name_en: "Yuetang",
            name_tw: "嶽塘區",
            yzcode: "411101",
          },
          {
            code: 430321,
            name_cn: "湘潭县",
            name_en: "Xiangtan",
            name_tw: "湘潭縣",
            yzcode: "411228",
          },
          {
            code: 430381,
            name_cn: "湘乡市",
            name_en: "Xiangxiang",
            name_tw: "湘鄉市",
            yzcode: "411400",
          },
          {
            code: 430382,
            name_cn: "韶山市",
            name_en: "Shaoshan",
            name_tw: "韶山市",
            yzcode: "411300",
          },
        ],
      },
      {
        code: 430400,
        name_cn: "衡阳市",
        name_en: "Hengyang",
        name_tw: "衡陽市",
        children: [
          {
            code: 430405,
            name_cn: "珠晖区",
            name_en: "Zhuhui",
            name_tw: "珠暉區",
            yzcode: "421002",
          },
          {
            code: 430406,
            name_cn: "雁峰区",
            name_en: "Yanfeng",
            name_tw: "雁峰區",
            yzcode: "421001",
          },
          {
            code: 430407,
            name_cn: "石鼓区",
            name_en: "Shigu",
            name_tw: "石鼓區",
            yzcode: "421005",
          },
          {
            code: 430408,
            name_cn: "蒸湘区",
            name_en: "Zhengxiang",
            name_tw: "蒸湘區",
            yzcode: "421001",
          },
          {
            code: 430412,
            name_cn: "南岳区",
            name_en: "Nanyue",
            name_tw: "南嶽區",
            yzcode: "421900",
          },
          {
            code: 430421,
            name_cn: "衡阳县",
            name_en: "Hengyang",
            name_tw: "衡陽縣",
            yzcode: "421200",
          },
          {
            code: 430422,
            name_cn: "衡南县",
            name_en: "Hengnan",
            name_tw: "衡南縣",
            yzcode: "421131",
          },
          {
            code: 430423,
            name_cn: "衡山县",
            name_en: "Hengshan",
            name_tw: "衡山縣",
            yzcode: "421300",
          },
          {
            code: 430424,
            name_cn: "衡东县",
            name_en: "Hengdong",
            name_tw: "衡東縣",
            yzcode: "421400",
          },
          {
            code: 430426,
            name_cn: "祁东县",
            name_en: "Qidong",
            name_tw: "祁東縣",
            yzcode: "421600",
          },
          {
            code: 430481,
            name_cn: "耒阳市",
            name_en: "Leiyang",
            name_tw: "耒陽市",
            yzcode: "421800",
          },
          {
            code: 430482,
            name_cn: "常宁市",
            name_en: "Changning",
            name_tw: "常寧市",
            yzcode: "421500",
          },
        ],
      },
      {
        code: 430500,
        name_cn: "邵阳市",
        name_en: "Shaoyang",
        name_tw: "邵陽市",
        children: [
          {
            code: 430502,
            name_cn: "双清区",
            name_en: "Shuangqing",
            name_tw: "雙清區",
            yzcode: "422001",
          },
          {
            code: 430503,
            name_cn: "大祥区",
            name_en: "Daxiang",
            name_tw: "大祥區",
            yzcode: "422000",
          },
          {
            code: 430511,
            name_cn: "北塔区",
            name_en: "Beita",
            name_tw: "北塔區",
            yzcode: "422007",
          },
          {
            code: 430521,
            name_cn: "邵东县",
            name_en: "Shaodong",
            name_tw: "邵東縣",
            yzcode: "422800",
          },
          {
            code: 430522,
            name_cn: "新邵县",
            name_en: "Xinshao",
            name_tw: "新邵縣",
            yzcode: "422900",
          },
          {
            code: 430523,
            name_cn: "邵阳县",
            name_en: "Shaoyang",
            name_tw: "邵陽縣",
            yzcode: "422100",
          },
          {
            code: 430524,
            name_cn: "隆回县",
            name_en: "Longhui",
            name_tw: "隆回縣",
            yzcode: "422200",
          },
          {
            code: 430525,
            name_cn: "洞口县",
            name_en: "Dongkou",
            name_tw: "洞口縣",
            yzcode: "422300",
          },
          {
            code: 430527,
            name_cn: "绥宁县",
            name_en: "Suining",
            name_tw: "綏寧縣",
            yzcode: "422600",
          },
          {
            code: 430528,
            name_cn: "新宁县",
            name_en: "Xinning",
            name_tw: "新寧縣",
            yzcode: "422700",
          },
          {
            code: 430529,
            name_cn: "城步苗族自治县",
            name_en: "Chengbu",
            name_tw: "城步苗族自治縣",
            yzcode: "422500",
          },
          {
            code: 430581,
            name_cn: "武冈市",
            name_en: "Wugang",
            name_tw: "武岡市",
            yzcode: "422400",
          },
        ],
      },
      {
        code: 430600,
        name_cn: "岳阳市",
        name_en: "Yueyang",
        name_tw: "嶽陽市",
        children: [
          {
            code: 430602,
            name_cn: "岳阳楼区",
            name_en: "Yueyanglou",
            name_tw: "嶽陽樓區",
            yzcode: "414000",
          },
          {
            code: 430603,
            name_cn: "云溪区",
            name_en: "Yunxi",
            name_tw: "雲溪區",
            yzcode: "414009",
          },
          {
            code: 430611,
            name_cn: "君山区",
            name_en: "Junshan",
            name_tw: "君山區",
            yzcode: "414005",
          },
          {
            code: 430621,
            name_cn: "岳阳县",
            name_en: "Yueyang",
            name_tw: "嶽陽縣",
            yzcode: "414100",
          },
          {
            code: 430623,
            name_cn: "华容县",
            name_en: "Huarong",
            name_tw: "華容縣",
            yzcode: "414200",
          },
          {
            code: 430624,
            name_cn: "湘阴县",
            name_en: "Xiangyin",
            name_tw: "湘陰縣",
            yzcode: "414600",
          },
          {
            code: 430626,
            name_cn: "平江县",
            name_en: "Pingjiang",
            name_tw: "平江縣",
            yzcode: "414500",
          },
          {
            code: 430681,
            name_cn: "汨罗市",
            name_en: "Miluo",
            name_tw: "汨羅市",
            yzcode: "414400",
          },
          {
            code: 430682,
            name_cn: "临湘市",
            name_en: "Linxiang",
            name_tw: "臨湘市",
            yzcode: "414300",
          },
        ],
      },
      {
        code: 430700,
        name_cn: "常德市",
        name_en: "Changde",
        name_tw: "常德市",
        children: [
          {
            code: 430702,
            name_cn: "武陵区",
            name_en: "Wuling",
            name_tw: "武陵區",
            yzcode: "415000",
          },
          {
            code: 430703,
            name_cn: "鼎城区",
            name_en: "Dingcheng",
            name_tw: "鼎城區",
            yzcode: "415101",
          },
          {
            code: 430721,
            name_cn: "安乡县",
            name_en: "Anxiang",
            name_tw: "安鄉縣",
            yzcode: "415600",
          },
          {
            code: 430722,
            name_cn: "汉寿县",
            name_en: "Hanshou",
            name_tw: "漢壽縣",
            yzcode: "415900",
          },
          {
            code: 430723,
            name_cn: "澧县",
            name_en: "Lixian",
            name_tw: "澧縣",
            yzcode: "415500",
          },
          {
            code: 430724,
            name_cn: "临澧县",
            name_en: "Linli",
            name_tw: "臨澧縣",
            yzcode: "415200",
          },
          {
            code: 430725,
            name_cn: "桃源县",
            name_en: "Taoyuan",
            name_tw: "桃源縣",
            yzcode: "415700",
          },
          {
            code: 430726,
            name_cn: "石门县",
            name_en: "Shimen",
            name_tw: "石門縣",
            yzcode: "415300",
          },
          {
            code: 430781,
            name_cn: "津市市",
            name_en: "Jinshi",
            name_tw: "津市市",
            yzcode: "415400",
          },
        ],
      },
      {
        code: 430800,
        name_cn: "张家界市",
        name_en: "Zhangjiajie",
        name_tw: "張家界市",
        children: [
          {
            code: 430802,
            name_cn: "永定区",
            name_en: "Yongding",
            name_tw: "永定區",
            yzcode: "427000",
          },
          {
            code: 430811,
            name_cn: "武陵源区",
            name_en: "Wulingyuan",
            name_tw: "武陵源區",
            yzcode: "427400",
          },
          {
            code: 430821,
            name_cn: "慈利县",
            name_en: "Cili",
            name_tw: "慈利縣",
            yzcode: "427200",
          },
          {
            code: 430822,
            name_cn: "桑植县",
            name_en: "Sangzhi",
            name_tw: "桑植縣",
            yzcode: "427100",
          },
        ],
      },
      {
        code: 430900,
        name_cn: "益阳市",
        name_en: "Yiyang",
        name_tw: "益陽市",
        children: [
          {
            code: 430902,
            name_cn: "资阳区",
            name_en: "Ziyang",
            name_tw: "資陽區",
            yzcode: "413001",
          },
          {
            code: 430903,
            name_cn: "赫山区",
            name_en: "Heshan",
            name_tw: "赫山區",
            yzcode: "413002",
          },
          {
            code: 430921,
            name_cn: "南县",
            name_en: "Nanxian",
            name_tw: "南縣",
            yzcode: "413200",
          },
          {
            code: 430922,
            name_cn: "桃江县",
            name_en: "Taojiang",
            name_tw: "桃江縣",
            yzcode: "413400",
          },
          {
            code: 430923,
            name_cn: "安化县",
            name_en: "Anhua",
            name_tw: "安化縣",
            yzcode: "413500",
          },
          {
            code: 430981,
            name_cn: "沅江市",
            name_en: "Yuanjiang",
            name_tw: "沅江市",
            yzcode: "413100",
          },
        ],
      },
      {
        code: 431000,
        name_cn: "郴州市",
        name_en: "Chenzhou",
        name_tw: "郴州市",
        children: [
          {
            code: 431002,
            name_cn: "北湖区",
            name_en: "Beihu",
            name_tw: "北湖區",
            yzcode: "423000",
          },
          {
            code: 431003,
            name_cn: "苏仙区",
            name_en: "Suxian",
            name_tw: "蘇仙區",
            yzcode: "423000",
          },
          {
            code: 431021,
            name_cn: "桂阳县",
            name_en: "Guiyang",
            name_tw: "桂陽縣",
            yzcode: "424400",
          },
          {
            code: 431022,
            name_cn: "宜章县",
            name_en: "Yizhang",
            name_tw: "宜章縣",
            yzcode: "424200",
          },
          {
            code: 431023,
            name_cn: "永兴县",
            name_en: "Yongxing",
            name_tw: "永興縣",
            yzcode: "423300",
          },
          {
            code: 431024,
            name_cn: "嘉禾县",
            name_en: "Jiahe",
            name_tw: "嘉禾縣",
            yzcode: "424500",
          },
          {
            code: 431025,
            name_cn: "临武县",
            name_en: "Linwu",
            name_tw: "臨武縣",
            yzcode: "424300",
          },
          {
            code: 431026,
            name_cn: "汝城县",
            name_en: "Rucheng",
            name_tw: "汝城縣",
            yzcode: "424100",
          },
          {
            code: 431027,
            name_cn: "桂东县",
            name_en: "Guidong",
            name_tw: "桂東縣",
            yzcode: "423500",
          },
          {
            code: 431028,
            name_cn: "安仁县",
            name_en: "Anren",
            name_tw: "安仁縣",
            yzcode: "423600",
          },
          {
            code: 431081,
            name_cn: "资兴市",
            name_en: "Zixing",
            name_tw: "資興市",
            yzcode: "423400",
          },
        ],
      },
      {
        code: 431100,
        name_cn: "永州市",
        name_en: "Yongzhou",
        name_tw: "永州市",
        children: [
          {
            code: 431102,
            name_cn: "零陵区",
            name_en: "Lingling",
            name_tw: "零陵區",
            yzcode: "425100",
          },
          {
            code: 431103,
            name_cn: "冷水滩区",
            name_en: "Lengshuitan",
            name_tw: "冷水灘區",
            yzcode: "425100",
          },
          {
            code: 431121,
            name_cn: "祁阳县",
            name_en: "Qiyang",
            name_tw: "祁陽縣",
            yzcode: "426100",
          },
          {
            code: 431122,
            name_cn: "东安县",
            name_en: "Dong'an",
            name_tw: "東安縣",
            yzcode: "425900",
          },
          {
            code: 431123,
            name_cn: "双牌县",
            name_en: "Shuangpai",
            name_tw: "雙牌縣",
            yzcode: "425200",
          },
          {
            code: 431124,
            name_cn: "道县",
            name_en: "Daoxian",
            name_tw: "道縣",
            yzcode: "425300",
          },
          {
            code: 431125,
            name_cn: "江永县",
            name_en: "Jiangyong",
            name_tw: "江永縣",
            yzcode: "425400",
          },
          {
            code: 431126,
            name_cn: "宁远县",
            name_en: "Ningyuan",
            name_tw: "寧遠縣",
            yzcode: "425600",
          },
          {
            code: 431127,
            name_cn: "蓝山县",
            name_en: "Lanshan",
            name_tw: "藍山縣",
            yzcode: "425800",
          },
          {
            code: 431128,
            name_cn: "新田县",
            name_en: "Xintian",
            name_tw: "新田縣",
            yzcode: "425700",
          },
          {
            code: 431129,
            name_cn: "江华瑶族自治县",
            name_en: "Jianghua",
            name_tw: "江華瑤族自治縣",
            yzcode: "425500",
          },
        ],
      },
      {
        code: 431200,
        name_cn: "怀化市",
        name_en: "Huaihua",
        name_tw: "懷化市",
        children: [
          {
            code: 431202,
            name_cn: "鹤城区",
            name_en: "Hecheng",
            name_tw: "鶴城區",
            yzcode: "418000",
          },
          {
            code: 431221,
            name_cn: "中方县",
            name_en: "Zhongfang",
            name_tw: "中方縣",
            yzcode: "418005",
          },
          {
            code: 431222,
            name_cn: "沅陵县",
            name_en: "Yuanling",
            name_tw: "沅陵縣",
            yzcode: "419600",
          },
          {
            code: 431223,
            name_cn: "辰溪县",
            name_en: "Chenxi",
            name_tw: "辰溪縣",
            yzcode: "419500",
          },
          {
            code: 431224,
            name_cn: "溆浦县",
            name_en: "Xupu",
            name_tw: "漵浦縣",
            yzcode: "419300",
          },
          {
            code: 431225,
            name_cn: "会同县",
            name_en: "Huitong",
            name_tw: "會同縣",
            yzcode: "418300",
          },
          {
            code: 431226,
            name_cn: "麻阳苗族自治县",
            name_en: "Mayang",
            name_tw: "麻陽苗族自治縣",
            yzcode: "419400",
          },
          {
            code: 431227,
            name_cn: "新晃侗族自治县",
            name_en: "Xinhuang",
            name_tw: "新晃侗族自治縣",
            yzcode: "419200",
          },
          {
            code: 431228,
            name_cn: "芷江侗族自治县",
            name_en: "Zhijiang",
            name_tw: "芷江侗族自治縣",
            yzcode: "419100",
          },
          {
            code: 431229,
            name_cn: "靖州苗族侗族自治县",
            name_en: "Jingzhou",
            name_tw: "靖州苗族侗族自治縣",
            yzcode: "418400",
          },
          {
            code: 431230,
            name_cn: "通道侗族自治县",
            name_en: "Tongdao",
            name_tw: "通道侗族自治縣",
            yzcode: "418500",
          },
          {
            code: 431281,
            name_cn: "洪江市",
            name_en: "Hongjiang",
            name_tw: "洪江市",
            yzcode: "418100",
          },
        ],
      },
      {
        code: 431300,
        name_cn: "娄底市",
        name_en: "Loudi",
        name_tw: "婁底市",
        children: [
          {
            code: 431302,
            name_cn: "娄星区",
            name_en: "Louxing",
            name_tw: "婁星區",
            yzcode: "417000",
          },
          {
            code: 431321,
            name_cn: "双峰县",
            name_en: "Shuangfeng",
            name_tw: "雙峰縣",
            yzcode: "417700",
          },
          {
            code: 431322,
            name_cn: "新化县",
            name_en: "Xinhua",
            name_tw: "新化縣",
            yzcode: "417600",
          },
          {
            code: 431381,
            name_cn: "冷水江市",
            name_en: "Lengshuijiang",
            name_tw: "冷水江市",
            yzcode: "417500",
          },
          {
            code: 431382,
            name_cn: "涟源市",
            name_en: "Lianyuan",
            name_tw: "漣源市",
            yzcode: "417100",
          },
        ],
      },
      {
        code: 433100,
        name_cn: "湘西土家族苗族自治州",
        name_en: "Xiangxi",
        name_tw: "湘西土家族苗族自治州",
        children: [
          {
            code: 433101,
            name_cn: "吉首市",
            name_en: "Jishou",
            name_tw: "吉首市",
            yzcode: "416000",
          },
          {
            code: 433122,
            name_cn: "泸溪县",
            name_en: "Luxi",
            name_tw: "瀘溪縣",
            yzcode: "416100",
          },
          {
            code: 433123,
            name_cn: "凤凰县",
            name_en: "Fenghuang",
            name_tw: "鳳凰縣",
            yzcode: "416200",
          },
          {
            code: 433124,
            name_cn: "花垣县",
            name_en: "Huayuan",
            name_tw: "花垣縣",
            yzcode: "416400",
          },
          {
            code: 433125,
            name_cn: "保靖县",
            name_en: "Baojing",
            name_tw: "保靖縣",
            yzcode: "416500",
          },
          {
            code: 433126,
            name_cn: "古丈县",
            name_en: "Guzhang",
            name_tw: "古丈縣",
            yzcode: "416300",
          },
          {
            code: 433127,
            name_cn: "永顺县",
            name_en: "Yongshun",
            name_tw: "永順縣",
            yzcode: "416700",
          },
          {
            code: 433130,
            name_cn: "龙山县",
            name_en: "Longshan",
            name_tw: "龍山縣",
            yzcode: "416800",
          },
        ],
      },
    ],
  },
  {
    code: 440000,
    name_cn: "广东省",
    name_en: "Guangdong",
    name_tw: "廣東省",
    children: [
      {
        code: 440100,
        name_cn: "广州市",
        name_en: "Guangzhou",
        name_tw: "廣州市",
        children: [
          {
            code: 440103,
            name_cn: "荔湾区",
            name_en: "Liwan",
            name_tw: "荔灣區",
            yzcode: "510170",
          },
          {
            code: 440104,
            name_cn: "越秀区",
            name_en: "Yuexiu",
            name_tw: "越秀區",
            yzcode: "510030",
          },
          {
            code: 440105,
            name_cn: "海珠区",
            name_en: "Haizhu",
            name_tw: "海珠區",
            yzcode: "510300",
          },
          {
            code: 440106,
            name_cn: "天河区",
            name_en: "Tianhe",
            name_tw: "天河區",
            yzcode: "510665",
          },
          {
            code: 440111,
            name_cn: "白云区",
            name_en: "Baiyun",
            name_tw: "白雲區",
            yzcode: "510405",
          },
          {
            code: 440112,
            name_cn: "黄埔区",
            name_en: "Huangpu",
            name_tw: "黃埔區",
            yzcode: "510700",
          },
          {
            code: 440113,
            name_cn: "番禺区",
            name_en: "Panyu",
            name_tw: "番禺區",
            yzcode: "511400",
          },
          {
            code: 440114,
            name_cn: "花都区",
            name_en: "Huadu",
            name_tw: "花都區",
            yzcode: "510800",
          },
          {
            code: 440115,
            name_cn: "南沙区",
            name_en: "Nansha",
            name_tw: "南沙區",
            yzcode: "511458",
          },
          {
            code: 440117,
            name_cn: "从化区",
            name_en: "Conghua",
            name_tw: "從化區",
            yzcode: "510900",
          },
          {
            code: 440118,
            name_cn: "增城区",
            name_en: "Zengcheng",
            name_tw: "增城區",
            yzcode: "511300",
          },
        ],
      },
      {
        code: 440200,
        name_cn: "韶关市",
        name_en: "Shaoguan",
        name_tw: "韶關市",
        children: [
          {
            code: 440203,
            name_cn: "武江区",
            name_en: "Wujiang",
            name_tw: "武江區",
            yzcode: "512026",
          },
          {
            code: 440204,
            name_cn: "浈江区",
            name_en: "Zhenjiang",
            name_tw: "湞江區",
            yzcode: "512023",
          },
          {
            code: 440205,
            name_cn: "曲江区",
            name_en: "Qujiang",
            name_tw: "曲江區",
            yzcode: "512101",
          },
          {
            code: 440222,
            name_cn: "始兴县",
            name_en: "Shixing",
            name_tw: "始興縣",
            yzcode: "512500",
          },
          {
            code: 440224,
            name_cn: "仁化县",
            name_en: "Renhua",
            name_tw: "仁化縣",
            yzcode: "512300",
          },
          {
            code: 440229,
            name_cn: "翁源县",
            name_en: "Wengyuan",
            name_tw: "翁源縣",
            yzcode: "512600",
          },
          {
            code: 440232,
            name_cn: "乳源瑶族自治县",
            name_en: "Ruyuan",
            name_tw: "乳源瑤族自治縣",
            yzcode: "512700",
          },
          {
            code: 440233,
            name_cn: "新丰县",
            name_en: "Xinfeng",
            name_tw: "新豐縣",
            yzcode: "511100",
          },
          {
            code: 440281,
            name_cn: "乐昌市",
            name_en: "Lechang",
            name_tw: "樂昌市",
            yzcode: "512200",
          },
          {
            code: 440282,
            name_cn: "南雄市",
            name_en: "Nanxiong",
            name_tw: "南雄市",
            yzcode: "512400",
          },
        ],
      },
      {
        code: 440300,
        name_cn: "深圳市",
        name_en: "Shenzhen",
        name_tw: "深圳市",
        children: [
          {
            code: 440303,
            name_cn: "罗湖区",
            name_en: "Luohu",
            name_tw: "羅湖區",
            yzcode: "518021",
          },
          {
            code: 440304,
            name_cn: "福田区",
            name_en: "Futian",
            name_tw: "福田區",
            yzcode: "518048",
          },
          {
            code: 440305,
            name_cn: "南山区",
            name_en: "Nanshan",
            name_tw: "南山區",
            yzcode: "518051",
          },
          {
            code: 440306,
            name_cn: "宝安区",
            name_en: "Bao'an",
            name_tw: "寶安區",
            yzcode: "518101",
          },
          {
            code: 440307,
            name_cn: "龙岗区",
            name_en: "Longgang",
            name_tw: "龍崗區",
            yzcode: "518172",
          },
          {
            code: 440308,
            name_cn: "盐田区",
            name_en: "Yantian",
            name_tw: "鹽田區",
            yzcode: "518081",
          },
          {
            code: 440309,
            name_cn: "光明新区",
            name_en: "Guangmingxinqu",
            name_tw: "光明新區",
            yzcode: "518100",
          },
          {
            code: 440310,
            name_cn: "坪山新区",
            name_en: "Pingshanxinqu",
            name_tw: "坪山新區",
            yzcode: "518000",
          },
          {
            code: 440311,
            name_cn: "大鹏新区",
            name_en: "Dapengxinqu",
            name_tw: "大鵬新區",
            yzcode: "518000",
          },
          {
            code: 440312,
            name_cn: "龙华新区",
            name_en: "Longhuaxinqu",
            name_tw: "龍華新區",
            yzcode: "518100",
          },
        ],
      },
      {
        code: 440400,
        name_cn: "珠海市",
        name_en: "Zhuhai",
        name_tw: "珠海市",
        children: [
          {
            code: 440402,
            name_cn: "香洲区",
            name_en: "Xiangzhou",
            name_tw: "香洲區",
            yzcode: "519000",
          },
          {
            code: 440403,
            name_cn: "斗门区",
            name_en: "Doumen",
            name_tw: "鬥門區",
            yzcode: "519110",
          },
          {
            code: 440404,
            name_cn: "金湾区",
            name_en: "Jinwan",
            name_tw: "金灣區",
            yzcode: "519040",
          },
        ],
      },
      {
        code: 440500,
        name_cn: "汕头市",
        name_en: "Shantou",
        name_tw: "汕頭市",
        children: [
          {
            code: 440507,
            name_cn: "龙湖区",
            name_en: "Longhu",
            name_tw: "龍湖區",
            yzcode: "515041",
          },
          {
            code: 440511,
            name_cn: "金平区",
            name_en: "Jinping",
            name_tw: "金平區",
            yzcode: "515041",
          },
          {
            code: 440512,
            name_cn: "濠江区",
            name_en: "Haojiang",
            name_tw: "濠江區",
            yzcode: "515071",
          },
          {
            code: 440513,
            name_cn: "潮阳区",
            name_en: "Chaoyang",
            name_tw: "潮陽區",
            yzcode: "515100",
          },
          {
            code: 440514,
            name_cn: "潮南区",
            name_en: "Chaonan",
            name_tw: "潮南區",
            yzcode: "515144",
          },
          {
            code: 440515,
            name_cn: "澄海区",
            name_en: "Chenghai",
            name_tw: "澄海區",
            yzcode: "515800",
          },
          {
            code: 440523,
            name_cn: "南澳县",
            name_en: "Nanao",
            name_tw: "南澳縣",
            yzcode: "515900",
          },
        ],
      },
      {
        code: 440600,
        name_cn: "佛山市",
        name_en: "Foshan",
        name_tw: "佛山市",
        children: [
          {
            code: 440604,
            name_cn: "禅城区",
            name_en: "Chancheng",
            name_tw: "禪城區",
            yzcode: "528000",
          },
          {
            code: 440605,
            name_cn: "南海区",
            name_en: "Nanhai",
            name_tw: "南海區",
            yzcode: "528251",
          },
          {
            code: 440606,
            name_cn: "顺德区",
            name_en: "Shunde",
            name_tw: "順德區",
            yzcode: "528300",
          },
          {
            code: 440607,
            name_cn: "三水区",
            name_en: "Sanshui",
            name_tw: "三水區",
            yzcode: "528133",
          },
          {
            code: 440608,
            name_cn: "高明区",
            name_en: "Gaoming",
            name_tw: "高明區",
            yzcode: "528500",
          },
        ],
      },
      {
        code: 440700,
        name_cn: "江门市",
        name_en: "Jiangmen",
        name_tw: "江門市",
        children: [
          {
            code: 440703,
            name_cn: "蓬江区",
            name_en: "Pengjiang",
            name_tw: "蓬江區",
            yzcode: "529000",
          },
          {
            code: 440704,
            name_cn: "江海区",
            name_en: "Jianghai",
            name_tw: "江海區",
            yzcode: "529040",
          },
          {
            code: 440705,
            name_cn: "新会区",
            name_en: "Xinhui",
            name_tw: "新會區",
            yzcode: "529100",
          },
          {
            code: 440781,
            name_cn: "台山市",
            name_en: "Taishan",
            name_tw: "台山市",
            yzcode: "529200",
          },
          {
            code: 440783,
            name_cn: "开平市",
            name_en: "Kaiping",
            name_tw: "開平市",
            yzcode: "529337",
          },
          {
            code: 440784,
            name_cn: "鹤山市",
            name_en: "Heshan",
            name_tw: "鶴山市",
            yzcode: "529700",
          },
          {
            code: 440785,
            name_cn: "恩平市",
            name_en: "Enping",
            name_tw: "恩平市",
            yzcode: "529400",
          },
        ],
      },
      {
        code: 440800,
        name_cn: "湛江市",
        name_en: "Zhanjiang",
        name_tw: "湛江市",
        children: [
          {
            code: 440802,
            name_cn: "赤坎区",
            name_en: "Chikan",
            name_tw: "赤坎區",
            yzcode: "524033",
          },
          {
            code: 440803,
            name_cn: "霞山区",
            name_en: "Xiashan",
            name_tw: "霞山區",
            yzcode: "524011",
          },
          {
            code: 440804,
            name_cn: "坡头区",
            name_en: "Potou",
            name_tw: "坡頭區",
            yzcode: "524057",
          },
          {
            code: 440811,
            name_cn: "麻章区",
            name_en: "Mazhang",
            name_tw: "麻章區",
            yzcode: "524094",
          },
          {
            code: 440823,
            name_cn: "遂溪县",
            name_en: "Suixi",
            name_tw: "遂溪縣",
            yzcode: "524300",
          },
          {
            code: 440825,
            name_cn: "徐闻县",
            name_en: "Xuwen",
            name_tw: "徐聞縣",
            yzcode: "524100",
          },
          {
            code: 440881,
            name_cn: "廉江市",
            name_en: "Lianjiang",
            name_tw: "廉江市",
            yzcode: "524400",
          },
          {
            code: 440882,
            name_cn: "雷州市",
            name_en: "Leizhou",
            name_tw: "雷州市",
            yzcode: "524200",
          },
          {
            code: 440883,
            name_cn: "吴川市",
            name_en: "Wuchuan",
            name_tw: "吳川市",
            yzcode: "524500",
          },
        ],
      },
      {
        code: 440900,
        name_cn: "茂名市",
        name_en: "Maoming",
        name_tw: "茂名市",
        children: [
          {
            code: 440902,
            name_cn: "茂南区",
            name_en: "Maonan",
            name_tw: "茂南區",
            yzcode: "525000",
          },
          {
            code: 440904,
            name_cn: "电白区",
            name_en: "Dianbai",
            name_tw: "電白區",
            yzcode: "525400",
          },
          {
            code: 440981,
            name_cn: "高州市",
            name_en: "Gaozhou",
            name_tw: "高州市",
            yzcode: "525200",
          },
          {
            code: 440982,
            name_cn: "化州市",
            name_en: "Huazhou",
            name_tw: "化州市",
            yzcode: "525100",
          },
          {
            code: 440983,
            name_cn: "信宜市",
            name_en: "Xinyi",
            name_tw: "信宜市",
            yzcode: "525300",
          },
        ],
      },
      {
        code: 441200,
        name_cn: "肇庆市",
        name_en: "Zhaoqing",
        name_tw: "肇慶市",
        children: [
          {
            code: 441202,
            name_cn: "端州区",
            name_en: "Duanzhou",
            name_tw: "端州區",
            yzcode: "526060",
          },
          {
            code: 441203,
            name_cn: "鼎湖区",
            name_en: "Dinghu",
            name_tw: "鼎湖區",
            yzcode: "526070",
          },
          {
            code: 441223,
            name_cn: "广宁县",
            name_en: "Guangning",
            name_tw: "廣寧縣",
            yzcode: "526300",
          },
          {
            code: 441224,
            name_cn: "怀集县",
            name_en: "Huaiji",
            name_tw: "懷集縣",
            yzcode: "526400",
          },
          {
            code: 441225,
            name_cn: "封开县",
            name_en: "Fengkai",
            name_tw: "封開縣",
            yzcode: "526500",
          },
          {
            code: 441226,
            name_cn: "德庆县",
            name_en: "Deqing",
            name_tw: "德慶縣",
            yzcode: "526600",
          },
          {
            code: 441283,
            name_cn: "高要市",
            name_en: "Gaoyao",
            name_tw: "高要市",
            yzcode: "526100",
          },
          {
            code: 441284,
            name_cn: "四会市",
            name_en: "Sihui",
            name_tw: "四會市",
            yzcode: "526200",
          },
        ],
      },
      {
        code: 441300,
        name_cn: "惠州市",
        name_en: "Huizhou",
        name_tw: "惠州市",
        children: [
          {
            code: 441302,
            name_cn: "惠城区",
            name_en: "Huicheng",
            name_tw: "惠城區",
            yzcode: "516008",
          },
          {
            code: 441303,
            name_cn: "惠阳区",
            name_en: "Huiyang",
            name_tw: "惠陽區",
            yzcode: "516211",
          },
          {
            code: 441322,
            name_cn: "博罗县",
            name_en: "Boluo",
            name_tw: "博羅縣",
            yzcode: "516100",
          },
          {
            code: 441323,
            name_cn: "惠东县",
            name_en: "Huidong",
            name_tw: "惠東縣",
            yzcode: "516300",
          },
          {
            code: 441324,
            name_cn: "龙门县",
            name_en: "Longmen",
            name_tw: "龍門縣",
            yzcode: "516800",
          },
        ],
      },
      {
        code: 441400,
        name_cn: "梅州市",
        name_en: "Meizhou",
        name_tw: "梅州市",
        children: [
          {
            code: 441402,
            name_cn: "梅江区",
            name_en: "Meijiang",
            name_tw: "梅江區",
            yzcode: "514000",
          },
          {
            code: 441403,
            name_cn: "梅县区",
            name_en: "Meixian",
            name_tw: "梅縣區",
            yzcode: "514787",
          },
          {
            code: 441422,
            name_cn: "大埔县",
            name_en: "Dabu",
            name_tw: "大埔縣",
            yzcode: "514200",
          },
          {
            code: 441423,
            name_cn: "丰顺县",
            name_en: "Fengshun",
            name_tw: "豐順縣",
            yzcode: "514300",
          },
          {
            code: 441424,
            name_cn: "五华县",
            name_en: "Wuhua",
            name_tw: "五華縣",
            yzcode: "514400",
          },
          {
            code: 441426,
            name_cn: "平远县",
            name_en: "Pingyuan",
            name_tw: "平遠縣",
            yzcode: "514600",
          },
          {
            code: 441427,
            name_cn: "蕉岭县",
            name_en: "Jiaoling",
            name_tw: "蕉嶺縣",
            yzcode: "514100",
          },
          {
            code: 441481,
            name_cn: "兴宁市",
            name_en: "Xingning",
            name_tw: "興寧市",
            yzcode: "514500",
          },
        ],
      },
      {
        code: 441500,
        name_cn: "汕尾市",
        name_en: "Shanwei",
        name_tw: "汕尾市",
        children: [
          {
            code: 441502,
            name_cn: "城区",
            name_en: "Chengqu",
            name_tw: "城區",
            yzcode: "516600",
          },
          {
            code: 441521,
            name_cn: "海丰县",
            name_en: "Haifeng",
            name_tw: "海豐縣",
            yzcode: "516400",
          },
          {
            code: 441523,
            name_cn: "陆河县",
            name_en: "Luhe",
            name_tw: "陸河縣",
            yzcode: "516700",
          },
          {
            code: 441581,
            name_cn: "陆丰市",
            name_en: "Lufeng",
            name_tw: "陸豐市",
            yzcode: "516500",
          },
        ],
      },
      {
        code: 441600,
        name_cn: "河源市",
        name_en: "Heyuan",
        name_tw: "河源市",
        children: [
          {
            code: 441602,
            name_cn: "源城区",
            name_en: "Yuancheng",
            name_tw: "源城區",
            yzcode: "517000",
          },
          {
            code: 441621,
            name_cn: "紫金县",
            name_en: "Zijin",
            name_tw: "紫金縣",
            yzcode: "517400",
          },
          {
            code: 441622,
            name_cn: "龙川县",
            name_en: "Longchuan",
            name_tw: "龍川縣",
            yzcode: "517300",
          },
          {
            code: 441623,
            name_cn: "连平县",
            name_en: "Lianping",
            name_tw: "連平縣",
            yzcode: "517100",
          },
          {
            code: 441624,
            name_cn: "和平县",
            name_en: "Heping",
            name_tw: "和平縣",
            yzcode: "517200",
          },
          {
            code: 441625,
            name_cn: "东源县",
            name_en: "Dongyuan",
            name_tw: "東源縣",
            yzcode: "517583",
          },
        ],
      },
      {
        code: 441700,
        name_cn: "阳江市",
        name_en: "Yangjiang",
        name_tw: "陽江市",
        children: [
          {
            code: 441702,
            name_cn: "江城区",
            name_en: "Jiangcheng",
            name_tw: "江城區",
            yzcode: "529500",
          },
          {
            code: 441704,
            name_cn: "阳东区",
            name_en: "Yangdong",
            name_tw: "陽東區",
            yzcode: "529900",
          },
          {
            code: 441721,
            name_cn: "阳西县",
            name_en: "Yangxi",
            name_tw: "陽西縣",
            yzcode: "529800",
          },
          {
            code: 441781,
            name_cn: "阳春市",
            name_en: "Yangchun",
            name_tw: "陽春市",
            yzcode: "529600",
          },
        ],
      },
      {
        code: 441800,
        name_cn: "清远市",
        name_en: "Qingyuan",
        name_tw: "清遠市",
        children: [
          {
            code: 441802,
            name_cn: "清城区",
            name_en: "Qingcheng",
            name_tw: "清城區",
            yzcode: "511515",
          },
          {
            code: 441803,
            name_cn: "清新区",
            name_en: "Qingxin",
            name_tw: "清新區",
            yzcode: "511810",
          },
          {
            code: 441821,
            name_cn: "佛冈县",
            name_en: "Fogang",
            name_tw: "佛岡縣",
            yzcode: "511600",
          },
          {
            code: 441823,
            name_cn: "阳山县",
            name_en: "Yangshan",
            name_tw: "陽山縣",
            yzcode: "513100",
          },
          {
            code: 441825,
            name_cn: "连山壮族瑶族自治县",
            name_en: "Lianshan",
            name_tw: "連山壯族瑤族自治縣",
            yzcode: "513200",
          },
          {
            code: 441826,
            name_cn: "连南瑶族自治县",
            name_en: "Liannan",
            name_tw: "連南瑤族自治縣",
            yzcode: "513300",
          },
          {
            code: 441881,
            name_cn: "英德市",
            name_en: "Yingde",
            name_tw: "英德市",
            yzcode: "513000",
          },
          {
            code: 441882,
            name_cn: "连州市",
            name_en: "Lianzhou",
            name_tw: "連州市",
            yzcode: "513400",
          },
        ],
      },
      {
        code: 441900,
        name_cn: "东莞市",
        name_en: "Dongguan",
        name_tw: "東莞市",
        children: [
          {
            code: 441901,
            name_cn: "莞城区",
            name_en: "Guancheng",
            name_tw: "莞城區",
            yzcode: "523128",
          },
          {
            code: 441902,
            name_cn: "南城区",
            name_en: "Nancheng",
            name_tw: "南城區",
            yzcode: "523617",
          },
          {
            code: 441904,
            name_cn: "万江区",
            name_en: "Wanjiang",
            name_tw: "萬江區",
            yzcode: "523039",
          },
          {
            code: 441905,
            name_cn: "石碣镇",
            name_en: "Shijie",
            name_tw: "石碣鎮",
            yzcode: "523290",
          },
          {
            code: 441906,
            name_cn: "石龙镇",
            name_en: "Shilong",
            name_tw: "石龍鎮",
            yzcode: "523326",
          },
          {
            code: 441907,
            name_cn: "茶山镇",
            name_en: "Chashan",
            name_tw: "茶山鎮",
            yzcode: "523380",
          },
          {
            code: 441908,
            name_cn: "石排镇",
            name_en: "Shipai",
            name_tw: "石排鎮",
            yzcode: "523346",
          },
          {
            code: 441909,
            name_cn: "企石镇",
            name_en: "Qishi",
            name_tw: "企石鎮",
            yzcode: "523507",
          },
          {
            code: 441910,
            name_cn: "横沥镇",
            name_en: "Hengli",
            name_tw: "橫瀝鎮",
            yzcode: "523471",
          },
          {
            code: 441911,
            name_cn: "桥头镇",
            name_en: "Qiaotou",
            name_tw: "橋頭鎮",
            yzcode: "523520",
          },
          {
            code: 441912,
            name_cn: "谢岗镇",
            name_en: "Xiegang",
            name_tw: "謝崗鎮",
            yzcode: "523592",
          },
          {
            code: 441913,
            name_cn: "东坑镇",
            name_en: "Dongkeng",
            name_tw: "東坑鎮",
            yzcode: "523451",
          },
          {
            code: 441914,
            name_cn: "常平镇",
            name_en: "Changping",
            name_tw: "常平鎮",
            yzcode: "523560",
          },
          {
            code: 441915,
            name_cn: "寮步镇",
            name_en: "Liaobu",
            name_tw: "寮步鎮",
            yzcode: "523411",
          },
          {
            code: 441916,
            name_cn: "大朗镇",
            name_en: "Dalang",
            name_tw: "大朗鎮",
            yzcode: "523770",
          },
          {
            code: 441917,
            name_cn: "麻涌镇",
            name_en: "Machong",
            name_tw: "麻湧鎮",
            yzcode: "523143",
          },
          {
            code: 441918,
            name_cn: "中堂镇",
            name_en: "Zhongtang",
            name_tw: "中堂鎮",
            yzcode: "523233",
          },
          {
            code: 441919,
            name_cn: "高埗镇",
            name_en: "Gaobu",
            name_tw: "高埗鎮",
            yzcode: "523282",
          },
          {
            code: 441920,
            name_cn: "樟木头镇",
            name_en: "Zhangmutou",
            name_tw: "樟木頭鎮",
            yzcode: "523619",
          },
          {
            code: 441921,
            name_cn: "大岭山镇",
            name_en: "Dalingshan",
            name_tw: "大嶺山鎮",
            yzcode: "523835",
          },
          {
            code: 441922,
            name_cn: "望牛墩镇",
            name_en: "Wangniudun",
            name_tw: "望牛墩鎮",
            yzcode: "523203",
          },
          {
            code: 441923,
            name_cn: "黄江镇",
            name_en: "Huangjiang",
            name_tw: "黃江鎮",
            yzcode: "523755",
          },
          {
            code: 441924,
            name_cn: "洪梅镇",
            name_en: "Hongmei",
            name_tw: "洪梅鎮",
            yzcode: "523163",
          },
          {
            code: 441925,
            name_cn: "清溪镇",
            name_en: "Qingxi",
            name_tw: "清溪鎮",
            yzcode: "523660",
          },
          {
            code: 441926,
            name_cn: "沙田镇",
            name_en: "Shatian",
            name_tw: "沙田鎮",
            yzcode: "523988",
          },
          {
            code: 441927,
            name_cn: "道滘镇",
            name_en: "Daojiao",
            name_tw: "道滘鎮",
            yzcode: "523171",
          },
          {
            code: 441928,
            name_cn: "塘厦镇",
            name_en: "Tangxia",
            name_tw: "塘廈鎮",
            yzcode: "523713",
          },
          {
            code: 441929,
            name_cn: "虎门镇",
            name_en: "Humen",
            name_tw: "虎門鎮",
            yzcode: "523932",
          },
          {
            code: 441930,
            name_cn: "厚街镇",
            name_en: "Houjie",
            name_tw: "厚街鎮",
            yzcode: "523960",
          },
          {
            code: 441931,
            name_cn: "凤岗镇",
            name_en: "Fenggang",
            name_tw: "鳳崗鎮",
            yzcode: "523690",
          },
          {
            code: 441932,
            name_cn: "长安镇",
            name_en: "Chang'an",
            name_tw: "長安鎮",
            yzcode: "523850",
          },
        ],
      },
      {
        code: 442000,
        name_cn: "中山市",
        name_en: "Zhongshan",
        name_tw: "中山市",
        children: [
          {
            code: 442001,
            name_cn: "石岐区",
            name_en: "Shiqi",
            name_tw: "石岐區",
            yzcode: "528400",
          },
          {
            code: 442004,
            name_cn: "南区",
            name_en: "Nanqu",
            name_tw: "南區",
            yzcode: "528400",
          },
          {
            code: 442005,
            name_cn: "五桂山区",
            name_en: "Wuguishan",
            name_tw: "五桂山區",
            yzcode: "528458",
          },
          {
            code: 442006,
            name_cn: "火炬开发区",
            name_en: "Huoju",
            name_tw: "火炬開發區",
            yzcode: "528437",
          },
          {
            code: 442007,
            name_cn: "黄圃镇",
            name_en: "Huangpu",
            name_tw: "黃圃鎮",
            yzcode: "528429",
          },
          {
            code: 442008,
            name_cn: "南头镇",
            name_en: "Nantou",
            name_tw: "南頭鎮",
            yzcode: "528421",
          },
          {
            code: 442009,
            name_cn: "东凤镇",
            name_en: "Dongfeng",
            name_tw: "東鳳鎮",
            yzcode: "528425",
          },
          {
            code: 442010,
            name_cn: "阜沙镇",
            name_en: "Fusha",
            name_tw: "阜沙鎮",
            yzcode: "528434",
          },
          {
            code: 442011,
            name_cn: "小榄镇",
            name_en: "Xiaolan",
            name_tw: "小欖鎮",
            yzcode: "528415",
          },
          {
            code: 442012,
            name_cn: "东升镇",
            name_en: "Dongsheng",
            name_tw: "東升鎮",
            yzcode: "528400",
          },
          {
            code: 442013,
            name_cn: "古镇镇",
            name_en: "Guzhen",
            name_tw: "古鎮鎮",
            yzcode: "528422",
          },
          {
            code: 442014,
            name_cn: "横栏镇",
            name_en: "Henglan",
            name_tw: "橫欄鎮",
            yzcode: "528478",
          },
          {
            code: 442015,
            name_cn: "三角镇",
            name_en: "Sanjiao",
            name_tw: "三角鎮",
            yzcode: "528422",
          },
          {
            code: 442016,
            name_cn: "民众镇",
            name_en: "Minzhong",
            name_tw: "民眾鎮",
            yzcode: "528441",
          },
          {
            code: 442017,
            name_cn: "南朗镇",
            name_en: "Nanlang",
            name_tw: "南朗鎮",
            yzcode: "528454",
          },
          {
            code: 442018,
            name_cn: "港口镇",
            name_en: "Gangkou",
            name_tw: "港口鎮",
            yzcode: "528447",
          },
          {
            code: 442019,
            name_cn: "大涌镇",
            name_en: "Dayong",
            name_tw: "大湧鎮",
            yzcode: "528476",
          },
          {
            code: 442020,
            name_cn: "沙溪镇",
            name_en: "Shaxi",
            name_tw: "沙溪鎮",
            yzcode: "528471",
          },
          {
            code: 442021,
            name_cn: "三乡镇",
            name_en: "Sanxiang",
            name_tw: "三鄉鎮",
            yzcode: "528463",
          },
          {
            code: 442022,
            name_cn: "板芙镇",
            name_en: "Banfu",
            name_tw: "板芙鎮",
            yzcode: "528459",
          },
          {
            code: 442023,
            name_cn: "神湾镇",
            name_en: "Shenwan",
            name_tw: "神灣鎮",
            yzcode: "528462",
          },
          {
            code: 442024,
            name_cn: "坦洲镇",
            name_en: "Tanzhou",
            name_tw: "坦洲鎮",
            yzcode: "528467",
          },
        ],
      },
      {
        code: 445100,
        name_cn: "潮州市",
        name_en: "Chaozhou",
        name_tw: "潮州市",
        children: [
          {
            code: 445102,
            name_cn: "湘桥区",
            name_en: "Xiangqiao",
            name_tw: "湘橋區",
            yzcode: "521000",
          },
          {
            code: 445103,
            name_cn: "潮安区",
            name_en: "Chao'an",
            name_tw: "潮安區",
            yzcode: "515638",
          },
          {
            code: 445122,
            name_cn: "饶平县",
            name_en: "Raoping",
            name_tw: "饒平縣",
            yzcode: "515700",
          },
        ],
      },
      {
        code: 445200,
        name_cn: "揭阳市",
        name_en: "Jieyang",
        name_tw: "揭陽市",
        children: [
          {
            code: 445202,
            name_cn: "榕城区",
            name_en: "Rongcheng",
            name_tw: "榕城區",
            yzcode: "522000",
          },
          {
            code: 445203,
            name_cn: "揭东区",
            name_en: "Jiedong",
            name_tw: "揭東區",
            yzcode: "515500",
          },
          {
            code: 445222,
            name_cn: "揭西县",
            name_en: "Jiexi",
            name_tw: "揭西縣",
            yzcode: "515400",
          },
          {
            code: 445224,
            name_cn: "惠来县",
            name_en: "Huilai",
            name_tw: "惠來縣",
            yzcode: "515200",
          },
          {
            code: 445281,
            name_cn: "普宁市",
            name_en: "Puning",
            name_tw: "普寧市",
            yzcode: "515300",
          },
        ],
      },
      {
        code: 445300,
        name_cn: "云浮市",
        name_en: "Yunfu",
        name_tw: "雲浮市",
        children: [
          {
            code: 445302,
            name_cn: "云城区",
            name_en: "Yuncheng",
            name_tw: "雲城區",
            yzcode: "527300",
          },
          {
            code: 445303,
            name_cn: "云安区",
            name_en: "Yun'an",
            name_tw: "雲安區",
            yzcode: "527500",
          },
          {
            code: 445321,
            name_cn: "新兴县",
            name_en: "Xinxing",
            name_tw: "新興縣",
            yzcode: "527400",
          },
          {
            code: 445322,
            name_cn: "郁南县",
            name_en: "Yunan",
            name_tw: "郁南縣",
            yzcode: "527100",
          },
          {
            code: 445381,
            name_cn: "罗定市",
            name_en: "Luoding",
            name_tw: "羅定市",
            yzcode: "527200",
          },
        ],
      },
    ],
  },
  {
    code: 450000,
    name_cn: "广西壮族自治区",
    name_en: "Guangxi",
    name_tw: "廣西壯族自治區",
    children: [
      {
        code: 450100,
        name_cn: "南宁市",
        name_en: "Nanning",
        name_tw: "南寧市",
        children: [
          {
            code: 450102,
            name_cn: "兴宁区",
            name_en: "Xingning",
            name_tw: "興寧區",
            yzcode: "530023",
          },
          {
            code: 450103,
            name_cn: "青秀区",
            name_en: "Qingxiu",
            name_tw: "青秀區",
            yzcode: "530213",
          },
          {
            code: 450105,
            name_cn: "江南区",
            name_en: "Jiangnan",
            name_tw: "江南區",
            yzcode: "530031",
          },
          {
            code: 450107,
            name_cn: "西乡塘区",
            name_en: "Xixiangtang",
            name_tw: "西鄉塘區",
            yzcode: "530001",
          },
          {
            code: 450108,
            name_cn: "良庆区",
            name_en: "Liangqing",
            name_tw: "良慶區",
            yzcode: "530219",
          },
          {
            code: 450109,
            name_cn: "邕宁区",
            name_en: "Yongning",
            name_tw: "邕寧區",
            yzcode: "530200",
          },
          {
            code: 450122,
            name_cn: "武鸣县",
            name_en: "Wuming",
            name_tw: "武鳴縣",
            yzcode: "530100",
          },
          {
            code: 450123,
            name_cn: "隆安县",
            name_en: "Long'an",
            name_tw: "隆安縣",
            yzcode: "532700",
          },
          {
            code: 450124,
            name_cn: "马山县",
            name_en: "Mashan",
            name_tw: "馬山縣",
            yzcode: "530600",
          },
          {
            code: 450125,
            name_cn: "上林县",
            name_en: "Shanglin",
            name_tw: "上林縣",
            yzcode: "530500",
          },
          {
            code: 450126,
            name_cn: "宾阳县",
            name_en: "Binyang",
            name_tw: "賓陽縣",
            yzcode: "530400",
          },
          {
            code: 450127,
            name_cn: "横县",
            name_en: "Hengxian",
            name_tw: "橫縣",
            yzcode: "530300",
          },
          {
            code: 450128,
            name_cn: "埌东新区",
            name_en: "Langdong",
            name_tw: "埌東新區",
            yzcode: "530000",
          },
        ],
      },
      {
        code: 450200,
        name_cn: "柳州市",
        name_en: "Liuzhou",
        name_tw: "柳州市",
        children: [
          {
            code: 450202,
            name_cn: "城中区",
            name_en: "Chengzhong",
            name_tw: "城中區",
            yzcode: "545001",
          },
          {
            code: 450203,
            name_cn: "鱼峰区",
            name_en: "Yufeng",
            name_tw: "魚峰區",
            yzcode: "545005",
          },
          {
            code: 450204,
            name_cn: "柳南区",
            name_en: "Liunan",
            name_tw: "柳南區",
            yzcode: "545007",
          },
          {
            code: 450205,
            name_cn: "柳北区",
            name_en: "Liubei",
            name_tw: "柳北區",
            yzcode: "545002",
          },
          {
            code: 450221,
            name_cn: "柳江县",
            name_en: "Liujiang",
            name_tw: "柳江縣",
            yzcode: "545100",
          },
          {
            code: 450222,
            name_cn: "柳城县",
            name_en: "Liucheng",
            name_tw: "柳城縣",
            yzcode: "545200",
          },
          {
            code: 450223,
            name_cn: "鹿寨县",
            name_en: "Luzhai",
            name_tw: "鹿寨縣",
            yzcode: "545600",
          },
          {
            code: 450224,
            name_cn: "融安县",
            name_en: "Rong'an",
            name_tw: "融安縣",
            yzcode: "545400",
          },
          {
            code: 450225,
            name_cn: "融水苗族自治县",
            name_en: "Rongshui",
            name_tw: "融水苗族自治縣",
            yzcode: "545300",
          },
          {
            code: 450226,
            name_cn: "三江侗族自治县",
            name_en: "Sanjiang",
            name_tw: "三江侗族自治縣",
            yzcode: "545500",
          },
          {
            code: 450227,
            name_cn: "柳东新区",
            name_en: "Liudong",
            name_tw: "柳東新區",
            yzcode: "545000",
          },
        ],
      },
      {
        code: 450300,
        name_cn: "桂林市",
        name_en: "Guilin",
        name_tw: "桂林市",
        children: [
          {
            code: 450302,
            name_cn: "秀峰区",
            name_en: "Xiufeng",
            name_tw: "秀峰區",
            yzcode: "541001",
          },
          {
            code: 450303,
            name_cn: "叠彩区",
            name_en: "Diecai",
            name_tw: "疊彩區",
            yzcode: "541001",
          },
          {
            code: 450304,
            name_cn: "象山区",
            name_en: "Xiangshan",
            name_tw: "象山區",
            yzcode: "541002",
          },
          {
            code: 450305,
            name_cn: "七星区",
            name_en: "Qixing",
            name_tw: "七星區",
            yzcode: "541004",
          },
          {
            code: 450311,
            name_cn: "雁山区",
            name_en: "Yanshan",
            name_tw: "雁山區",
            yzcode: "541006",
          },
          {
            code: 450312,
            name_cn: "临桂区",
            name_en: "Lingui",
            name_tw: "臨桂區",
            yzcode: "541100",
          },
          {
            code: 450321,
            name_cn: "阳朔县",
            name_en: "Yangshuo",
            name_tw: "陽朔縣",
            yzcode: "541900",
          },
          {
            code: 450323,
            name_cn: "灵川县",
            name_en: "Lingchuan",
            name_tw: "靈川縣",
            yzcode: "541200",
          },
          {
            code: 450324,
            name_cn: "全州县",
            name_en: "Quanzhou",
            name_tw: "全州縣",
            yzcode: "541503",
          },
          {
            code: 450325,
            name_cn: "兴安县",
            name_en: "Xing'an",
            name_tw: "興安縣",
            yzcode: "541300",
          },
          {
            code: 450326,
            name_cn: "永福县",
            name_en: "Yongfu",
            name_tw: "永福縣",
            yzcode: "541800",
          },
          {
            code: 450327,
            name_cn: "灌阳县",
            name_en: "Guanyang",
            name_tw: "灌陽縣",
            yzcode: "541600",
          },
          {
            code: 450328,
            name_cn: "龙胜各族自治县",
            name_en: "Longsheng",
            name_tw: "龍勝各族自治縣",
            yzcode: "541700",
          },
          {
            code: 450329,
            name_cn: "资源县",
            name_en: "Ziyuan",
            name_tw: "資源縣",
            yzcode: "541400",
          },
          {
            code: 450330,
            name_cn: "平乐县",
            name_en: "Pingle",
            name_tw: "平樂縣",
            yzcode: "542400",
          },
          {
            code: 450331,
            name_cn: "荔浦县",
            name_en: "Lipu",
            name_tw: "荔浦縣",
            yzcode: "546600",
          },
          {
            code: 450332,
            name_cn: "恭城瑶族自治县",
            name_en: "Gongcheng",
            name_tw: "恭城瑤族自治縣",
            yzcode: "542500",
          },
        ],
      },
      {
        code: 450400,
        name_cn: "梧州市",
        name_en: "Wuzhou",
        name_tw: "梧州市",
        children: [
          {
            code: 450403,
            name_cn: "万秀区",
            name_en: "Wanxiu",
            name_tw: "萬秀區",
            yzcode: "543000",
          },
          {
            code: 450405,
            name_cn: "长洲区",
            name_en: "Changzhou",
            name_tw: "長洲區",
            yzcode: "543003",
          },
          {
            code: 450406,
            name_cn: "龙圩区",
            name_en: "Longxu",
            name_tw: "龍圩區",
            yzcode: "543002",
          },
          {
            code: 450421,
            name_cn: "苍梧县",
            name_en: "Cangwu",
            name_tw: "蒼梧縣",
            yzcode: "543100",
          },
          {
            code: 450422,
            name_cn: "藤县",
            name_en: "Tengxian",
            name_tw: "藤縣",
            yzcode: "543300",
          },
          {
            code: 450423,
            name_cn: "蒙山县",
            name_en: "Mengshan",
            name_tw: "蒙山縣",
            yzcode: "546700",
          },
          {
            code: 450481,
            name_cn: "岑溪市",
            name_en: "Cenxi",
            name_tw: "岑溪市",
            yzcode: "543200",
          },
        ],
      },
      {
        code: 450500,
        name_cn: "北海市",
        name_en: "Beihai",
        name_tw: "北海市",
        children: [
          {
            code: 450502,
            name_cn: "海城区",
            name_en: "Haicheng",
            name_tw: "海城區",
            yzcode: "536000",
          },
          {
            code: 450503,
            name_cn: "银海区",
            name_en: "Yinhai",
            name_tw: "銀海區",
            yzcode: "536000",
          },
          {
            code: 450512,
            name_cn: "铁山港区",
            name_en: "Tieshangang",
            name_tw: "鐵山港區",
            yzcode: "536017",
          },
          {
            code: 450521,
            name_cn: "合浦县",
            name_en: "Hepu",
            name_tw: "合浦縣",
            yzcode: "536100",
          },
        ],
      },
      {
        code: 450600,
        name_cn: "防城港市",
        name_en: "Fangchenggang",
        name_tw: "防城港市",
        children: [
          {
            code: 450602,
            name_cn: "港口区",
            name_en: "Gangkou",
            name_tw: "港口區",
            yzcode: "538001",
          },
          {
            code: 450603,
            name_cn: "防城区",
            name_en: "Fangcheng",
            name_tw: "防城區",
            yzcode: "538021",
          },
          {
            code: 450621,
            name_cn: "上思县",
            name_en: "Shangsi",
            name_tw: "上思縣",
            yzcode: "535500",
          },
          {
            code: 450681,
            name_cn: "东兴市",
            name_en: "Dongxing",
            name_tw: "東興市",
            yzcode: "538100",
          },
        ],
      },
      {
        code: 450700,
        name_cn: "钦州市",
        name_en: "Qinzhou",
        name_tw: "欽州市",
        children: [
          {
            code: 450702,
            name_cn: "钦南区",
            name_en: "Qinnan",
            name_tw: "欽南區",
            yzcode: "535099",
          },
          {
            code: 450703,
            name_cn: "钦北区",
            name_en: "Qinbei",
            name_tw: "欽北區",
            yzcode: "535099",
          },
          {
            code: 450721,
            name_cn: "灵山县",
            name_en: "Lingshan",
            name_tw: "靈山縣",
            yzcode: "535099",
          },
          {
            code: 450722,
            name_cn: "浦北县",
            name_en: "Pubei",
            name_tw: "浦北縣",
            yzcode: "535099",
          },
        ],
      },
      {
        code: 450800,
        name_cn: "贵港市",
        name_en: "Guigang",
        name_tw: "貴港市",
        children: [
          {
            code: 450802,
            name_cn: "港北区",
            name_en: "Gangbei",
            name_tw: "港北區",
            yzcode: "537100",
          },
          {
            code: 450803,
            name_cn: "港南区",
            name_en: "Gangnan",
            name_tw: "港南區",
            yzcode: "537100",
          },
          {
            code: 450804,
            name_cn: "覃塘区",
            name_en: "Qintang",
            name_tw: "覃塘區",
            yzcode: "537121",
          },
          {
            code: 450821,
            name_cn: "平南县",
            name_en: "Pingnan",
            name_tw: "平南縣",
            yzcode: "537300",
          },
          {
            code: 450881,
            name_cn: "桂平市",
            name_en: "Guiping",
            name_tw: "桂平市",
            yzcode: "537200",
          },
        ],
      },
      {
        code: 450900,
        name_cn: "玉林市",
        name_en: "Yulin",
        name_tw: "玉林市",
        children: [
          {
            code: 450902,
            name_cn: "玉州区",
            name_en: "Yuzhou",
            name_tw: "玉州區",
            yzcode: "537000",
          },
          {
            code: 450903,
            name_cn: "福绵区",
            name_en: "Fumian",
            name_tw: "福綿區",
            yzcode: "537023",
          },
          {
            code: 450904,
            name_cn: "玉东新区",
            name_en: "Yudong",
            name_tw: "玉東新區",
            yzcode: "537000",
          },
          {
            code: 450921,
            name_cn: "容县",
            name_en: "Rongxian",
            name_tw: "容縣",
            yzcode: "537500",
          },
          {
            code: 450922,
            name_cn: "陆川县",
            name_en: "Luchuan",
            name_tw: "陸川縣",
            yzcode: "537700",
          },
          {
            code: 450923,
            name_cn: "博白县",
            name_en: "Bobai",
            name_tw: "博白縣",
            yzcode: "537600",
          },
          {
            code: 450924,
            name_cn: "兴业县",
            name_en: "Xingye",
            name_tw: "興業縣",
            yzcode: "537800",
          },
          {
            code: 450981,
            name_cn: "北流市",
            name_en: "Beiliu",
            name_tw: "北流市",
            yzcode: "537400",
          },
        ],
      },
      {
        code: 451000,
        name_cn: "百色市",
        name_en: "Baise",
        name_tw: "百色市",
        children: [
          {
            code: 451002,
            name_cn: "右江区",
            name_en: "Youjiang",
            name_tw: "右江區",
            yzcode: "533000",
          },
          {
            code: 451021,
            name_cn: "田阳县",
            name_en: "Tianyang",
            name_tw: "田陽縣",
            yzcode: "533600",
          },
          {
            code: 451022,
            name_cn: "田东县",
            name_en: "Tiandong",
            name_tw: "田東縣",
            yzcode: "531500",
          },
          {
            code: 451023,
            name_cn: "平果县",
            name_en: "Pingguo",
            name_tw: "平果縣",
            yzcode: "531400",
          },
          {
            code: 451024,
            name_cn: "德保县",
            name_en: "Debao",
            name_tw: "德保縣",
            yzcode: "533700",
          },
          {
            code: 451025,
            name_cn: "靖西县",
            name_en: "Jingxi",
            name_tw: "靖西縣",
            yzcode: "533800",
          },
          {
            code: 451026,
            name_cn: "那坡县",
            name_en: "Napo",
            name_tw: "那坡縣",
            yzcode: "533900",
          },
          {
            code: 451027,
            name_cn: "凌云县",
            name_en: "Lingyun",
            name_tw: "淩雲縣",
            yzcode: "533100",
          },
          {
            code: 451028,
            name_cn: "乐业县",
            name_en: "Leye",
            name_tw: "樂業縣",
            yzcode: "533200",
          },
          {
            code: 451029,
            name_cn: "田林县",
            name_en: "Tianlin",
            name_tw: "田林縣",
            yzcode: "533300",
          },
          {
            code: 451030,
            name_cn: "西林县",
            name_en: "Xilin",
            name_tw: "西林縣",
            yzcode: "533500",
          },
          {
            code: 451031,
            name_cn: "隆林各族自治县",
            name_en: "Longlin",
            name_tw: "隆林各族自治縣",
            yzcode: "533400",
          },
        ],
      },
      {
        code: 451100,
        name_cn: "贺州市",
        name_en: "Hezhou",
        name_tw: "賀州市",
        children: [
          {
            code: 451102,
            name_cn: "八步区",
            name_en: "Babu",
            name_tw: "八步區",
            yzcode: "542800",
          },
          {
            code: 451121,
            name_cn: "昭平县",
            name_en: "Zhaoping",
            name_tw: "昭平縣",
            yzcode: "546800",
          },
          {
            code: 451122,
            name_cn: "钟山县",
            name_en: "Zhongshan",
            name_tw: "鐘山縣",
            yzcode: "542600",
          },
          {
            code: 451123,
            name_cn: "富川瑶族自治县",
            name_en: "Fuchuan",
            name_tw: "富川瑤族自治縣",
            yzcode: "542700",
          },
          {
            code: 451124,
            name_cn: "平桂管理区",
            name_en: "Pingui",
            name_tw: "平桂管理區",
            yzcode: "542800",
          },
        ],
      },
      {
        code: 451200,
        name_cn: "河池市",
        name_en: "Hechi",
        name_tw: "河池市",
        children: [
          {
            code: 451202,
            name_cn: "金城江区",
            name_en: "Jinchengjiang",
            name_tw: "金城江區",
            yzcode: "547000",
          },
          {
            code: 451221,
            name_cn: "南丹县",
            name_en: "Nandan",
            name_tw: "南丹縣",
            yzcode: "547200",
          },
          {
            code: 451222,
            name_cn: "天峨县",
            name_en: "Tiane",
            name_tw: "天峨縣",
            yzcode: "547300",
          },
          {
            code: 451223,
            name_cn: "凤山县",
            name_en: "Fengshan",
            name_tw: "鳳山縣",
            yzcode: "547600",
          },
          {
            code: 451224,
            name_cn: "东兰县",
            name_en: "Donglan",
            name_tw: "東蘭縣",
            yzcode: "547400",
          },
          {
            code: 451225,
            name_cn: "罗城仫佬族自治县",
            name_en: "Luocheng",
            name_tw: "羅城仫佬族自治縣",
            yzcode: "546400",
          },
          {
            code: 451226,
            name_cn: "环江毛南族自治县",
            name_en: "Huanjiang",
            name_tw: "環江毛南族自治縣",
            yzcode: "547100",
          },
          {
            code: 451227,
            name_cn: "巴马瑶族自治县",
            name_en: "Bama",
            name_tw: "巴馬瑤族自治縣",
            yzcode: "547500",
          },
          {
            code: 451228,
            name_cn: "都安瑶族自治县",
            name_en: "Du'an",
            name_tw: "都安瑤族自治縣",
            yzcode: "530700",
          },
          {
            code: 451229,
            name_cn: "大化瑶族自治县",
            name_en: "Dahua",
            name_tw: "大化瑤族自治縣",
            yzcode: "530800",
          },
          {
            code: 451281,
            name_cn: "宜州市",
            name_en: "Yizhou",
            name_tw: "宜州市",
            yzcode: "546300",
          },
        ],
      },
      {
        code: 451300,
        name_cn: "来宾市",
        name_en: "Laibin",
        name_tw: "來賓市",
        children: [
          {
            code: 451302,
            name_cn: "兴宾区",
            name_en: "Xingbin",
            name_tw: "興賓區",
            yzcode: "546100",
          },
          {
            code: 451321,
            name_cn: "忻城县",
            name_en: "Xincheng",
            name_tw: "忻城縣",
            yzcode: "546200",
          },
          {
            code: 451322,
            name_cn: "象州县",
            name_en: "Xiangzhou",
            name_tw: "象州縣",
            yzcode: "545800",
          },
          {
            code: 451323,
            name_cn: "武宣县",
            name_en: "Wuxuan",
            name_tw: "武宣縣",
            yzcode: "545900",
          },
          {
            code: 451324,
            name_cn: "金秀瑶族自治县",
            name_en: "Jinxiu",
            name_tw: "金秀瑤族自治縣",
            yzcode: "545799",
          },
          {
            code: 451381,
            name_cn: "合山市",
            name_en: "Heshan",
            name_tw: "合山市",
            yzcode: "546500",
          },
        ],
      },
      {
        code: 451400,
        name_cn: "崇左市",
        name_en: "Chongzuo",
        name_tw: "崇左市",
        children: [
          {
            code: 451402,
            name_cn: "江州区",
            name_en: "Jiangzhou",
            name_tw: "江州區",
            yzcode: "532299",
          },
          {
            code: 451421,
            name_cn: "扶绥县",
            name_en: "Fusui",
            name_tw: "扶綏縣",
            yzcode: "532199",
          },
          {
            code: 451422,
            name_cn: "宁明县",
            name_en: "Ningming",
            name_tw: "寧明縣",
            yzcode: "532599",
          },
          {
            code: 451423,
            name_cn: "龙州县",
            name_en: "Longzhou",
            name_tw: "龍州縣",
            yzcode: "532499",
          },
          {
            code: 451424,
            name_cn: "大新县",
            name_en: "Daxin",
            name_tw: "大新縣",
            yzcode: "532399",
          },
          {
            code: 451425,
            name_cn: "天等县",
            name_en: "Tiandeng",
            name_tw: "天等縣",
            yzcode: "532899",
          },
          {
            code: 451481,
            name_cn: "凭祥市",
            name_en: "Pingxiang",
            name_tw: "憑祥市",
            yzcode: "532699",
          },
        ],
      },
    ],
  },
  {
    code: 460000,
    name_cn: "海南省",
    name_en: "Hainan",
    name_tw: "海南省",
    children: [
      {
        code: 460100,
        name_cn: "海口市",
        name_en: "Haikou",
        name_tw: "海口市",
        children: [
          {
            code: 460105,
            name_cn: "秀英区",
            name_en: "Xiuying",
            name_tw: "秀英區",
            yzcode: "570311",
          },
          {
            code: 460106,
            name_cn: "龙华区",
            name_en: "Longhua",
            name_tw: "龍華區",
            yzcode: "570145",
          },
          {
            code: 460107,
            name_cn: "琼山区",
            name_en: "Qiongshan",
            name_tw: "瓊山區",
            yzcode: "571100",
          },
          {
            code: 460108,
            name_cn: "美兰区",
            name_en: "Meilan",
            name_tw: "美蘭區",
            yzcode: "570203",
          },
        ],
      },
      {
        code: 460200,
        name_cn: "三亚市",
        name_en: "Sanya",
        name_tw: "三亞市",
        children: [
          {
            code: 460202,
            name_cn: "海棠区",
            name_en: "Haitang",
            name_tw: "海棠區",
            yzcode: "572000",
          },
          {
            code: 460203,
            name_cn: "吉阳区",
            name_en: "Jiyang",
            name_tw: "吉陽區",
            yzcode: "572000",
          },
          {
            code: 460204,
            name_cn: "天涯区",
            name_en: "Tianya",
            name_tw: "天涯區",
            yzcode: "572000",
          },
          {
            code: 460205,
            name_cn: "崖州区",
            name_en: "Yazhou",
            name_tw: "崖州區",
            yzcode: "572000",
          },
        ],
      },
      {
        code: 460300,
        name_cn: "三沙市",
        name_en: "Sansha",
        name_tw: "三沙市",
        children: [
          {
            code: 460321,
            name_cn: "西沙群岛",
            name_en: "Xisha Islands",
            name_tw: "西沙群島",
            yzcode: "572000",
          },
          {
            code: 460322,
            name_cn: "南沙群岛",
            name_en: "Nansha Islands",
            name_tw: "南沙群島",
            yzcode: "573100",
          },
          {
            code: 460323,
            name_cn: "中沙群岛",
            name_en: "Zhongsha Islands",
            name_tw: "中沙群島",
            yzcode: "573100",
          },
        ],
      },
      {
        code: 469000,
        name_cn: "直辖县级",
        name_en: "",
        name_tw: "直轄縣級",
        children: [
          {
            code: 469001,
            name_cn: "五指山市",
            name_en: "Wuzhishan",
            name_tw: "五指山市",
            yzcode: "572200",
          },
          {
            code: 469002,
            name_cn: "琼海市",
            name_en: "Qionghai",
            name_tw: "瓊海市",
            yzcode: "571400",
          },
          {
            code: 469003,
            name_cn: "儋州市",
            name_en: "Danzhou",
            name_tw: "儋州市",
            yzcode: "571700",
          },
          {
            code: 469005,
            name_cn: "文昌市",
            name_en: "Wenchang",
            name_tw: "文昌市",
            yzcode: "571339",
          },
          {
            code: 469006,
            name_cn: "万宁市",
            name_en: "Wanning",
            name_tw: "萬寧市",
            yzcode: "571500",
          },
          {
            code: 469007,
            name_cn: "东方市",
            name_en: "Dongfang",
            name_tw: "東方市",
            yzcode: "572600",
          },
          {
            code: 469021,
            name_cn: "定安县",
            name_en: "Ding'an",
            name_tw: "定安縣",
            yzcode: "571200",
          },
          {
            code: 469022,
            name_cn: "屯昌县",
            name_en: "Tunchang",
            name_tw: "屯昌縣",
            yzcode: "571600",
          },
          {
            code: 469023,
            name_cn: "澄迈县",
            name_en: "Chengmai",
            name_tw: "澄邁縣",
            yzcode: "571900",
          },
          {
            code: 469024,
            name_cn: "临高县",
            name_en: "Lingao",
            name_tw: "臨高縣",
            yzcode: "571800",
          },
          {
            code: 469025,
            name_cn: "白沙黎族自治县",
            name_en: "Baisha",
            name_tw: "白沙黎族自治縣",
            yzcode: "572800",
          },
          {
            code: 469026,
            name_cn: "昌江黎族自治县",
            name_en: "Changjiang",
            name_tw: "昌江黎族自治縣",
            yzcode: "572700",
          },
          {
            code: 469027,
            name_cn: "乐东黎族自治县",
            name_en: "Ledong",
            name_tw: "樂東黎族自治縣",
            yzcode: "572500",
          },
          {
            code: 469028,
            name_cn: "陵水黎族自治县",
            name_en: "Lingshui",
            name_tw: "陵水黎族自治縣",
            yzcode: "572400",
          },
          {
            code: 469029,
            name_cn: "保亭黎族苗族自治县",
            name_en: "Baoting",
            name_tw: "保亭黎族苗族自治縣",
            yzcode: "572300",
          },
          {
            code: 469030,
            name_cn: "琼中黎族苗族自治县",
            name_en: "Qiongzhong",
            name_tw: "瓊中黎族苗族自治縣",
            yzcode: "572900",
          },
        ],
      },
    ],
  },
  {
    code: 500000,
    name_cn: "重庆",
    name_en: "Chongqing",
    name_tw: "重慶",
    children: [
      {
        code: 500100,
        name_cn: "重庆市",
        name_en: "Chongqing",
        name_tw: "重慶市",
        children: [
          {
            code: 500101,
            name_cn: "万州区",
            name_en: "Wanzhou",
            name_tw: "萬州區",
            yzcode: "404000",
          },
          {
            code: 500102,
            name_cn: "涪陵区",
            name_en: "Fuling",
            name_tw: "涪陵區",
            yzcode: "408000",
          },
          {
            code: 500103,
            name_cn: "渝中区",
            name_en: "Yuzhong",
            name_tw: "渝中區",
            yzcode: "400010",
          },
          {
            code: 500104,
            name_cn: "大渡口区",
            name_en: "Dadukou",
            name_tw: "大渡口區",
            yzcode: "400080",
          },
          {
            code: 500105,
            name_cn: "江北区",
            name_en: "Jiangbei",
            name_tw: "江北區",
            yzcode: "400020",
          },
          {
            code: 500106,
            name_cn: "沙坪坝区",
            name_en: "Shapingba",
            name_tw: "沙坪壩區",
            yzcode: "400030",
          },
          {
            code: 500107,
            name_cn: "九龙坡区",
            name_en: "Jiulongpo",
            name_tw: "九龍坡區",
            yzcode: "400050",
          },
          {
            code: 500108,
            name_cn: "南岸区",
            name_en: "Nan'an",
            name_tw: "南岸區",
            yzcode: "400064",
          },
          {
            code: 500109,
            name_cn: "北碚区",
            name_en: "Beibei",
            name_tw: "北碚區",
            yzcode: "400700",
          },
          {
            code: 500110,
            name_cn: "綦江区",
            name_en: "Qijiang",
            name_tw: "綦江區",
            yzcode: "400800",
          },
          {
            code: 500111,
            name_cn: "大足区",
            name_en: "Dazu",
            name_tw: "大足區",
            yzcode: "400900",
          },
          {
            code: 500112,
            name_cn: "渝北区",
            name_en: "Yubei",
            name_tw: "渝北區",
            yzcode: "401120",
          },
          {
            code: 500113,
            name_cn: "巴南区",
            name_en: "Banan",
            name_tw: "巴南區",
            yzcode: "401320",
          },
          {
            code: 500114,
            name_cn: "黔江区",
            name_en: "Qianjiang",
            name_tw: "黔江區",
            yzcode: "409700",
          },
          {
            code: 500115,
            name_cn: "长寿区",
            name_en: "Changshou",
            name_tw: "長壽區",
            yzcode: "401220",
          },
          {
            code: 500116,
            name_cn: "江津区",
            name_en: "Jiangjin",
            name_tw: "江津區",
            yzcode: "402260",
          },
          {
            code: 500117,
            name_cn: "合川区",
            name_en: "Hechuan",
            name_tw: "合川區",
            yzcode: "401520",
          },
          {
            code: 500118,
            name_cn: "永川区",
            name_en: "Yongchuan",
            name_tw: "永川區",
            yzcode: "402160",
          },
          {
            code: 500119,
            name_cn: "南川区",
            name_en: "Nanchuan",
            name_tw: "南川區",
            yzcode: "408400",
          },
          {
            code: 500120,
            name_cn: "璧山区",
            name_en: "Bishan",
            name_tw: "璧山區",
            yzcode: "402760",
          },
          {
            code: 500151,
            name_cn: "铜梁区",
            name_en: "Tongliang",
            name_tw: "銅梁區",
            yzcode: "402560",
          },
          {
            code: 500223,
            name_cn: "潼南县",
            name_en: "Tongnan",
            name_tw: "潼南縣",
            yzcode: "402660",
          },
          {
            code: 500226,
            name_cn: "荣昌县",
            name_en: "Rongchang",
            name_tw: "榮昌縣",
            yzcode: "402460",
          },
          {
            code: 500228,
            name_cn: "梁平县",
            name_en: "Liangping",
            name_tw: "梁平縣",
            yzcode: "405200",
          },
          {
            code: 500229,
            name_cn: "城口县",
            name_en: "Chengkou",
            name_tw: "城口縣",
            yzcode: "405900",
          },
          {
            code: 500230,
            name_cn: "丰都县",
            name_en: "Fengdu",
            name_tw: "豐都縣",
            yzcode: "408200",
          },
          {
            code: 500231,
            name_cn: "垫江县",
            name_en: "Dianjiang",
            name_tw: "墊江縣",
            yzcode: "408300",
          },
          {
            code: 500232,
            name_cn: "武隆县",
            name_en: "Wulong",
            name_tw: "武隆縣",
            yzcode: "408500",
          },
          {
            code: 500233,
            name_cn: "忠县",
            name_en: "Zhongxian",
            name_tw: "忠縣",
            yzcode: "404300",
          },
          {
            code: 500234,
            name_cn: "开县",
            name_en: "Kaixian",
            name_tw: "開縣",
            yzcode: "405400",
          },
          {
            code: 500235,
            name_cn: "云阳县",
            name_en: "Yunyang",
            name_tw: "雲陽縣",
            yzcode: "404500",
          },
          {
            code: 500236,
            name_cn: "奉节县",
            name_en: "Fengjie",
            name_tw: "奉節縣",
            yzcode: "404600",
          },
          {
            code: 500237,
            name_cn: "巫山县",
            name_en: "Wushan",
            name_tw: "巫山縣",
            yzcode: "404700",
          },
          {
            code: 500238,
            name_cn: "巫溪县",
            name_en: "Wuxi",
            name_tw: "巫溪縣",
            yzcode: "405800",
          },
          {
            code: 500240,
            name_cn: "石柱土家族自治县",
            name_en: "Shizhu",
            name_tw: "石柱土家族自治縣",
            yzcode: "409100",
          },
          {
            code: 500241,
            name_cn: "秀山土家族苗族自治县",
            name_en: "Xiushan",
            name_tw: "秀山土家族苗族自治縣",
            yzcode: "409900",
          },
          {
            code: 500242,
            name_cn: "酉阳土家族苗族自治县",
            name_en: "Youyang",
            name_tw: "酉陽土家族苗族自治縣",
            yzcode: "409800",
          },
          {
            code: 500243,
            name_cn: "彭水苗族土家族自治县",
            name_en: "Pengshui",
            name_tw: "彭水苗族土家族自治縣",
            yzcode: "409600",
          },
        ],
      },
      {
        code: 500300,
        name_cn: "两江新区",
        name_en: "Liangjiangxinqu",
        name_tw: "兩江新區",
        children: [
          {
            code: 500301,
            name_cn: "北部新区",
            name_en: "Beibuxinqu",
            name_tw: "北部新區",
            yzcode: "400000",
          },
          {
            code: 500302,
            name_cn: "保税港区",
            name_en: "Baoshuigangqu",
            name_tw: "保稅港區",
            yzcode: "400000",
          },
          {
            code: 500303,
            name_cn: "工业园区",
            name_en: "Gongyeyuanqu",
            name_tw: "工業園區",
            yzcode: "400000",
          },
        ],
      },
    ],
  },
  {
    code: 510000,
    name_cn: "四川省",
    name_en: "Sichuan",
    name_tw: "四川省",
    children: [
      {
        code: 510100,
        name_cn: "成都市",
        name_en: "Chengdu",
        name_tw: "成都市",
        children: [
          {
            code: 510104,
            name_cn: "锦江区",
            name_en: "Jinjiang",
            name_tw: "錦江區",
            yzcode: "610021",
          },
          {
            code: 510105,
            name_cn: "青羊区",
            name_en: "Qingyang",
            name_tw: "青羊區",
            yzcode: "610031",
          },
          {
            code: 510106,
            name_cn: "金牛区",
            name_en: "Jinniu",
            name_tw: "金牛區",
            yzcode: "610036",
          },
          {
            code: 510107,
            name_cn: "武侯区",
            name_en: "Wuhou",
            name_tw: "武侯區",
            yzcode: "610041",
          },
          {
            code: 510108,
            name_cn: "成华区",
            name_en: "Chenghua",
            name_tw: "成華區",
            yzcode: "610066",
          },
          {
            code: 510112,
            name_cn: "龙泉驿区",
            name_en: "Longquanyi",
            name_tw: "龍泉驛區",
            yzcode: "610100",
          },
          {
            code: 510113,
            name_cn: "青白江区",
            name_en: "Qingbaijiang",
            name_tw: "青白江區",
            yzcode: "610300",
          },
          {
            code: 510114,
            name_cn: "新都区",
            name_en: "Xindu",
            name_tw: "新都區",
            yzcode: "610500",
          },
          {
            code: 510115,
            name_cn: "温江区",
            name_en: "Wenjiang",
            name_tw: "溫江區",
            yzcode: "611130",
          },
          {
            code: 510121,
            name_cn: "金堂县",
            name_en: "Jintang",
            name_tw: "金堂縣",
            yzcode: "610400",
          },
          {
            code: 510122,
            name_cn: "双流县",
            name_en: "Shuangliu",
            name_tw: "雙流縣",
            yzcode: "610200",
          },
          {
            code: 510124,
            name_cn: "郫县",
            name_en: "Pixian",
            name_tw: "郫縣",
            yzcode: "611730",
          },
          {
            code: 510129,
            name_cn: "大邑县",
            name_en: "Dayi",
            name_tw: "大邑縣",
            yzcode: "611330",
          },
          {
            code: 510131,
            name_cn: "蒲江县",
            name_en: "Pujiang",
            name_tw: "蒲江縣",
            yzcode: "611630",
          },
          {
            code: 510132,
            name_cn: "新津县",
            name_en: "Xinjin",
            name_tw: "新津縣",
            yzcode: "611430",
          },
          {
            code: 510181,
            name_cn: "都江堰市",
            name_en: "Dujiangyan",
            name_tw: "都江堰市",
            yzcode: "611830",
          },
          {
            code: 510182,
            name_cn: "彭州市",
            name_en: "Pengzhou",
            name_tw: "彭州市",
            yzcode: "611930",
          },
          {
            code: 510183,
            name_cn: "邛崃市",
            name_en: "Qionglai",
            name_tw: "邛崍市",
            yzcode: "611530",
          },
          {
            code: 510184,
            name_cn: "崇州市",
            name_en: "Chongzhou",
            name_tw: "崇州市",
            yzcode: "611230",
          },
        ],
      },
      {
        code: 510300,
        name_cn: "自贡市",
        name_en: "Zigong",
        name_tw: "自貢市",
        children: [
          {
            code: 510302,
            name_cn: "自流井区",
            name_en: "Ziliujing",
            name_tw: "自流井區",
            yzcode: "643000",
          },
          {
            code: 510303,
            name_cn: "贡井区",
            name_en: "Gongjing",
            name_tw: "貢井區",
            yzcode: "643020",
          },
          {
            code: 510304,
            name_cn: "大安区",
            name_en: "Da'an",
            name_tw: "大安區",
            yzcode: "643010",
          },
          {
            code: 510311,
            name_cn: "沿滩区",
            name_en: "Yantan",
            name_tw: "沿灘區",
            yzcode: "643030",
          },
          {
            code: 510321,
            name_cn: "荣县",
            name_en: "Rongxian",
            name_tw: "榮縣",
            yzcode: "643100",
          },
          {
            code: 510322,
            name_cn: "富顺县",
            name_en: "Fushun",
            name_tw: "富順縣",
            yzcode: "643200",
          },
        ],
      },
      {
        code: 510400,
        name_cn: "攀枝花市",
        name_en: "Panzhihua",
        name_tw: "攀枝花市",
        children: [
          {
            code: 510402,
            name_cn: "东区",
            name_en: "Dongqu",
            name_tw: "東區",
            yzcode: "617067",
          },
          {
            code: 510403,
            name_cn: "西区",
            name_en: "Xiqu",
            name_tw: "西區",
            yzcode: "617068",
          },
          {
            code: 510411,
            name_cn: "仁和区",
            name_en: "Renhe",
            name_tw: "仁和區",
            yzcode: "617061",
          },
          {
            code: 510421,
            name_cn: "米易县",
            name_en: "Miyi",
            name_tw: "米易縣",
            yzcode: "617200",
          },
          {
            code: 510422,
            name_cn: "盐边县",
            name_en: "Yanbian",
            name_tw: "鹽邊縣",
            yzcode: "617100",
          },
        ],
      },
      {
        code: 510500,
        name_cn: "泸州市",
        name_en: "Luzhou",
        name_tw: "瀘州市",
        children: [
          {
            code: 510502,
            name_cn: "江阳区",
            name_en: "Jiangyang",
            name_tw: "江陽區",
            yzcode: "646000",
          },
          {
            code: 510503,
            name_cn: "纳溪区",
            name_en: "Naxi",
            name_tw: "納溪區",
            yzcode: "646300",
          },
          {
            code: 510504,
            name_cn: "龙马潭区",
            name_en: "Longmatan",
            name_tw: "龍馬潭區",
            yzcode: "646000",
          },
          {
            code: 510521,
            name_cn: "泸县",
            name_en: "Luxian",
            name_tw: "瀘縣",
            yzcode: "646106",
          },
          {
            code: 510522,
            name_cn: "合江县",
            name_en: "Hejiang",
            name_tw: "合江縣",
            yzcode: "646200",
          },
          {
            code: 510524,
            name_cn: "叙永县",
            name_en: "Xuyong",
            name_tw: "敘永縣",
            yzcode: "646400",
          },
          {
            code: 510525,
            name_cn: "古蔺县",
            name_en: "Gulin",
            name_tw: "古藺縣",
            yzcode: "646500",
          },
        ],
      },
      {
        code: 510600,
        name_cn: "德阳市",
        name_en: "Deyang",
        name_tw: "德陽市",
        children: [
          {
            code: 510603,
            name_cn: "旌阳区",
            name_en: "Jingyang",
            name_tw: "旌陽區",
            yzcode: "618000",
          },
          {
            code: 510623,
            name_cn: "中江县",
            name_en: "Zhongjiang",
            name_tw: "中江縣",
            yzcode: "618100",
          },
          {
            code: 510626,
            name_cn: "罗江县",
            name_en: "Luojiang",
            name_tw: "羅江縣",
            yzcode: "618500",
          },
          {
            code: 510681,
            name_cn: "广汉市",
            name_en: "Guanghan",
            name_tw: "廣漢市",
            yzcode: "618300",
          },
          {
            code: 510682,
            name_cn: "什邡市",
            name_en: "Shifang",
            name_tw: "什邡市",
            yzcode: "618400",
          },
          {
            code: 510683,
            name_cn: "绵竹市",
            name_en: "Mianzhu",
            name_tw: "綿竹市",
            yzcode: "618200",
          },
        ],
      },
      {
        code: 510700,
        name_cn: "绵阳市",
        name_en: "Mianyang",
        name_tw: "綿陽市",
        children: [
          {
            code: 510703,
            name_cn: "涪城区",
            name_en: "Fucheng",
            name_tw: "涪城區",
            yzcode: "621000",
          },
          {
            code: 510704,
            name_cn: "游仙区",
            name_en: "Youxian",
            name_tw: "遊仙區",
            yzcode: "621022",
          },
          {
            code: 510722,
            name_cn: "三台县",
            name_en: "Santai",
            name_tw: "三台縣",
            yzcode: "621100",
          },
          {
            code: 510723,
            name_cn: "盐亭县",
            name_en: "Yanting",
            name_tw: "鹽亭縣",
            yzcode: "621600",
          },
          {
            code: 510724,
            name_cn: "安县",
            name_en: "Anxian",
            name_tw: "安縣",
            yzcode: "622650",
          },
          {
            code: 510725,
            name_cn: "梓潼县",
            name_en: "Zitong",
            name_tw: "梓潼縣",
            yzcode: "622150",
          },
          {
            code: 510726,
            name_cn: "北川羌族自治县",
            name_en: "Beichuan",
            name_tw: "北川羌族自治縣",
            yzcode: "622750",
          },
          {
            code: 510727,
            name_cn: "平武县",
            name_en: "Pingwu",
            name_tw: "平武縣",
            yzcode: "622550",
          },
          {
            code: 510781,
            name_cn: "江油市",
            name_en: "Jiangyou",
            name_tw: "江油市",
            yzcode: "621700",
          },
        ],
      },
      {
        code: 510800,
        name_cn: "广元市",
        name_en: "Guangyuan",
        name_tw: "廣元市",
        children: [
          {
            code: 510802,
            name_cn: "利州区",
            name_en: "Lizhou",
            name_tw: "利州區",
            yzcode: "628017",
          },
          {
            code: 510811,
            name_cn: "昭化区",
            name_en: "Zhaohua",
            name_tw: "昭化區",
            yzcode: "628017",
          },
          {
            code: 510812,
            name_cn: "朝天区",
            name_en: "Chaotian",
            name_tw: "朝天區",
            yzcode: "628017",
          },
          {
            code: 510821,
            name_cn: "旺苍县",
            name_en: "Wangcang",
            name_tw: "旺蒼縣",
            yzcode: "628200",
          },
          {
            code: 510822,
            name_cn: "青川县",
            name_en: "Qingchuan",
            name_tw: "青川縣",
            yzcode: "628100",
          },
          {
            code: 510823,
            name_cn: "剑阁县",
            name_en: "Jiange",
            name_tw: "劍閣縣",
            yzcode: "628300",
          },
          {
            code: 510824,
            name_cn: "苍溪县",
            name_en: "Cangxi",
            name_tw: "蒼溪縣",
            yzcode: "628400",
          },
        ],
      },
      {
        code: 510900,
        name_cn: "遂宁市",
        name_en: "Suining",
        name_tw: "遂寧市",
        children: [
          {
            code: 510903,
            name_cn: "船山区",
            name_en: "Chuanshan",
            name_tw: "船山區",
            yzcode: "629000",
          },
          {
            code: 510904,
            name_cn: "安居区",
            name_en: "Anju",
            name_tw: "安居區",
            yzcode: "629000",
          },
          {
            code: 510921,
            name_cn: "蓬溪县",
            name_en: "Pengxi",
            name_tw: "蓬溪縣",
            yzcode: "629100",
          },
          {
            code: 510922,
            name_cn: "射洪县",
            name_en: "Shehong",
            name_tw: "射洪縣",
            yzcode: "629200",
          },
          {
            code: 510923,
            name_cn: "大英县",
            name_en: "Daying",
            name_tw: "大英縣",
            yzcode: "629300",
          },
        ],
      },
      {
        code: 511000,
        name_cn: "内江市",
        name_en: "Neijiang",
        name_tw: "內江市",
        children: [
          {
            code: 511002,
            name_cn: "市中区",
            name_en: "Shizhongqu",
            name_tw: "市中區",
            yzcode: "641000",
          },
          {
            code: 511011,
            name_cn: "东兴区",
            name_en: "Dongxing",
            name_tw: "東興區",
            yzcode: "641100",
          },
          {
            code: 511024,
            name_cn: "威远县",
            name_en: "Weiyuan",
            name_tw: "威遠縣",
            yzcode: "642450",
          },
          {
            code: 511025,
            name_cn: "资中县",
            name_en: "Zizhong",
            name_tw: "資中縣",
            yzcode: "641200",
          },
          {
            code: 511028,
            name_cn: "隆昌县",
            name_en: "Longchang",
            name_tw: "隆昌縣",
            yzcode: "642150",
          },
        ],
      },
      {
        code: 511100,
        name_cn: "乐山市",
        name_en: "Leshan",
        name_tw: "樂山市",
        children: [
          {
            code: 511102,
            name_cn: "市中区",
            name_en: "Shizhongqu",
            name_tw: "市中區",
            yzcode: "614000",
          },
          {
            code: 511111,
            name_cn: "沙湾区",
            name_en: "Shawan",
            name_tw: "沙灣區",
            yzcode: "614900",
          },
          {
            code: 511112,
            name_cn: "五通桥区",
            name_en: "Wutongqiao",
            name_tw: "五通橋區",
            yzcode: "614800",
          },
          {
            code: 511113,
            name_cn: "金口河区",
            name_en: "Jinkouhe",
            name_tw: "金口河區",
            yzcode: "614700",
          },
          {
            code: 511123,
            name_cn: "犍为县",
            name_en: "Qianwei",
            name_tw: "犍為縣",
            yzcode: "614400",
          },
          {
            code: 511124,
            name_cn: "井研县",
            name_en: "Jingyan",
            name_tw: "井研縣",
            yzcode: "613100",
          },
          {
            code: 511126,
            name_cn: "夹江县",
            name_en: "Jiajiang",
            name_tw: "夾江縣",
            yzcode: "614100",
          },
          {
            code: 511129,
            name_cn: "沐川县",
            name_en: "Muchuan",
            name_tw: "沐川縣",
            yzcode: "614500",
          },
          {
            code: 511132,
            name_cn: "峨边彝族自治县",
            name_en: "Ebian",
            name_tw: "峨邊彜族自治縣",
            yzcode: "614300",
          },
          {
            code: 511133,
            name_cn: "马边彝族自治县",
            name_en: "Mabian",
            name_tw: "馬邊彜族自治縣",
            yzcode: "614600",
          },
          {
            code: 511181,
            name_cn: "峨眉山市",
            name_en: "Emeishan",
            name_tw: "峨眉山市",
            yzcode: "614200",
          },
        ],
      },
      {
        code: 511300,
        name_cn: "南充市",
        name_en: "Nanchong",
        name_tw: "南充市",
        children: [
          {
            code: 511302,
            name_cn: "顺庆区",
            name_en: "Shunqing",
            name_tw: "順慶區",
            yzcode: "637000",
          },
          {
            code: 511303,
            name_cn: "高坪区",
            name_en: "Gaoping",
            name_tw: "高坪區",
            yzcode: "637100",
          },
          {
            code: 511304,
            name_cn: "嘉陵区",
            name_en: "Jialing",
            name_tw: "嘉陵區",
            yzcode: "637100",
          },
          {
            code: 511321,
            name_cn: "南部县",
            name_en: "Nanbu",
            name_tw: "南部縣",
            yzcode: "637300",
          },
          {
            code: 511322,
            name_cn: "营山县",
            name_en: "Yingshan",
            name_tw: "營山縣",
            yzcode: "637700",
          },
          {
            code: 511323,
            name_cn: "蓬安县",
            name_en: "Peng'an",
            name_tw: "蓬安縣",
            yzcode: "637800",
          },
          {
            code: 511324,
            name_cn: "仪陇县",
            name_en: "Yilong",
            name_tw: "儀隴縣",
            yzcode: "637600",
          },
          {
            code: 511325,
            name_cn: "西充县",
            name_en: "Xichong",
            name_tw: "西充縣",
            yzcode: "637200",
          },
          {
            code: 511381,
            name_cn: "阆中市",
            name_en: "Langzhong",
            name_tw: "閬中市",
            yzcode: "637400",
          },
        ],
      },
      {
        code: 511400,
        name_cn: "眉山市",
        name_en: "Meishan",
        name_tw: "眉山市",
        children: [
          {
            code: 511402,
            name_cn: "东坡区",
            name_en: "Dongpo",
            name_tw: "東坡區",
            yzcode: "620010",
          },
          {
            code: 511403,
            name_cn: "彭山区",
            name_en: "Pengshan",
            name_tw: "彭山區",
            yzcode: "620860",
          },
          {
            code: 511421,
            name_cn: "仁寿县",
            name_en: "Renshou",
            name_tw: "仁壽縣",
            yzcode: "620500",
          },
          {
            code: 511423,
            name_cn: "洪雅县",
            name_en: "Hongya",
            name_tw: "洪雅縣",
            yzcode: "620360",
          },
          {
            code: 511424,
            name_cn: "丹棱县",
            name_en: "Danling",
            name_tw: "丹棱縣",
            yzcode: "620200",
          },
          {
            code: 511425,
            name_cn: "青神县",
            name_en: "Qingshen",
            name_tw: "青神縣",
            yzcode: "620460",
          },
        ],
      },
      {
        code: 511500,
        name_cn: "宜宾市",
        name_en: "Yibin",
        name_tw: "宜賓市",
        children: [
          {
            code: 511502,
            name_cn: "翠屏区",
            name_en: "Cuiping",
            name_tw: "翠屏區",
            yzcode: "644000",
          },
          {
            code: 511503,
            name_cn: "南溪区",
            name_en: "Nanxi",
            name_tw: "南溪區",
            yzcode: "644100",
          },
          {
            code: 511521,
            name_cn: "宜宾县",
            name_en: "Yibin",
            name_tw: "宜賓縣",
            yzcode: "644600",
          },
          {
            code: 511523,
            name_cn: "江安县",
            name_en: "Jiang'an",
            name_tw: "江安縣",
            yzcode: "644200",
          },
          {
            code: 511524,
            name_cn: "长宁县",
            name_en: "Changning",
            name_tw: "長寧縣",
            yzcode: "644300",
          },
          {
            code: 511525,
            name_cn: "高县",
            name_en: "Gaoxian",
            name_tw: "高縣",
            yzcode: "645150",
          },
          {
            code: 511526,
            name_cn: "珙县",
            name_en: "Gongxian",
            name_tw: "珙縣",
            yzcode: "644500",
          },
          {
            code: 511527,
            name_cn: "筠连县",
            name_en: "Junlian",
            name_tw: "筠連縣",
            yzcode: "645250",
          },
          {
            code: 511528,
            name_cn: "兴文县",
            name_en: "Xingwen",
            name_tw: "興文縣",
            yzcode: "644400",
          },
          {
            code: 511529,
            name_cn: "屏山县",
            name_en: "Pingshan",
            name_tw: "屏山縣",
            yzcode: "645350",
          },
        ],
      },
      {
        code: 511600,
        name_cn: "广安市",
        name_en: "Guang'an",
        name_tw: "廣安市",
        children: [
          {
            code: 511602,
            name_cn: "广安区",
            name_en: "Guang'an",
            name_tw: "廣安區",
            yzcode: "638000",
          },
          {
            code: 511603,
            name_cn: "前锋区",
            name_en: "Qianfeng",
            name_tw: "前鋒區",
            yzcode: "638019",
          },
          {
            code: 511621,
            name_cn: "岳池县",
            name_en: "Yuechi",
            name_tw: "嶽池縣",
            yzcode: "638300",
          },
          {
            code: 511622,
            name_cn: "武胜县",
            name_en: "Wusheng",
            name_tw: "武勝縣",
            yzcode: "638400",
          },
          {
            code: 511623,
            name_cn: "邻水县",
            name_en: "Linshui",
            name_tw: "鄰水縣",
            yzcode: "638500",
          },
          {
            code: 511681,
            name_cn: "华蓥市",
            name_en: "Huaying",
            name_tw: "華鎣市",
            yzcode: "638600",
          },
        ],
      },
      {
        code: 511700,
        name_cn: "达州市",
        name_en: "Dazhou",
        name_tw: "達州市",
        children: [
          {
            code: 511702,
            name_cn: "通川区",
            name_en: "Tongchuan",
            name_tw: "通川區",
            yzcode: "635000",
          },
          {
            code: 511703,
            name_cn: "达川区",
            name_en: "Dachuan",
            name_tw: "達川區",
            yzcode: "635000",
          },
          {
            code: 511722,
            name_cn: "宣汉县",
            name_en: "Xuanhan",
            name_tw: "宣漢縣",
            yzcode: "636150",
          },
          {
            code: 511723,
            name_cn: "开江县",
            name_en: "Kaijiang",
            name_tw: "開江縣",
            yzcode: "636250",
          },
          {
            code: 511724,
            name_cn: "大竹县",
            name_en: "Dazhu",
            name_tw: "大竹縣",
            yzcode: "635100",
          },
          {
            code: 511725,
            name_cn: "渠县",
            name_en: "Quxian",
            name_tw: "渠縣",
            yzcode: "635200",
          },
          {
            code: 511781,
            name_cn: "万源市",
            name_en: "Wanyuan",
            name_tw: "萬源市",
            yzcode: "636350",
          },
        ],
      },
      {
        code: 511800,
        name_cn: "雅安市",
        name_en: "Ya'an",
        name_tw: "雅安市",
        children: [
          {
            code: 511802,
            name_cn: "雨城区",
            name_en: "Yucheng",
            name_tw: "雨城區",
            yzcode: "625000",
          },
          {
            code: 511803,
            name_cn: "名山区",
            name_en: "Mingshan",
            name_tw: "名山區",
            yzcode: "625100",
          },
          {
            code: 511822,
            name_cn: "荥经县",
            name_en: "Yingjing",
            name_tw: "滎經縣",
            yzcode: "625200",
          },
          {
            code: 511823,
            name_cn: "汉源县",
            name_en: "Hanyuan",
            name_tw: "漢源縣",
            yzcode: "625300",
          },
          {
            code: 511824,
            name_cn: "石棉县",
            name_en: "Shimian",
            name_tw: "石棉縣",
            yzcode: "625400",
          },
          {
            code: 511825,
            name_cn: "天全县",
            name_en: "Tianquan",
            name_tw: "天全縣",
            yzcode: "625500",
          },
          {
            code: 511826,
            name_cn: "芦山县",
            name_en: "Lushan",
            name_tw: "蘆山縣",
            yzcode: "625600",
          },
          {
            code: 511827,
            name_cn: "宝兴县",
            name_en: "Baoxing",
            name_tw: "寶興縣",
            yzcode: "625700",
          },
        ],
      },
      {
        code: 511900,
        name_cn: "巴中市",
        name_en: "Bazhong",
        name_tw: "巴中市",
        children: [
          {
            code: 511902,
            name_cn: "巴州区",
            name_en: "Bazhou",
            name_tw: "巴州區",
            yzcode: "636001",
          },
          {
            code: 511903,
            name_cn: "恩阳区",
            name_en: "Enyang",
            name_tw: "恩陽區",
            yzcode: "636064",
          },
          {
            code: 511921,
            name_cn: "通江县",
            name_en: "Tongjiang",
            name_tw: "通江縣",
            yzcode: "636700",
          },
          {
            code: 511922,
            name_cn: "南江县",
            name_en: "Nanjiang",
            name_tw: "南江縣",
            yzcode: "636600",
          },
          {
            code: 511923,
            name_cn: "平昌县",
            name_en: "Pingchang",
            name_tw: "平昌縣",
            yzcode: "636400",
          },
        ],
      },
      {
        code: 512000,
        name_cn: "资阳市",
        name_en: "Ziyang",
        name_tw: "資陽市",
        children: [
          {
            code: 512002,
            name_cn: "雁江区",
            name_en: "Yanjiang",
            name_tw: "雁江區",
            yzcode: "641300",
          },
          {
            code: 512021,
            name_cn: "安岳县",
            name_en: "Anyue",
            name_tw: "安嶽縣",
            yzcode: "642350",
          },
          {
            code: 512022,
            name_cn: "乐至县",
            name_en: "Lezhi",
            name_tw: "樂至縣",
            yzcode: "641500",
          },
          {
            code: 512081,
            name_cn: "简阳市",
            name_en: "Jianyang",
            name_tw: "簡陽市",
            yzcode: "641400",
          },
        ],
      },
      {
        code: 513200,
        name_cn: "阿坝藏族羌族自治州",
        name_en: "Aba",
        name_tw: "阿壩藏族羌族自治州",
        children: [
          {
            code: 513221,
            name_cn: "汶川县",
            name_en: "Wenchuan",
            name_tw: "汶川縣",
            yzcode: "623000",
          },
          {
            code: 513222,
            name_cn: "理县",
            name_en: "Lixian",
            name_tw: "理縣",
            yzcode: "623100",
          },
          {
            code: 513223,
            name_cn: "茂县",
            name_en: "Maoxian",
            name_tw: "茂縣",
            yzcode: "623200",
          },
          {
            code: 513224,
            name_cn: "松潘县",
            name_en: "Songpan",
            name_tw: "松潘縣",
            yzcode: "623300",
          },
          {
            code: 513225,
            name_cn: "九寨沟县",
            name_en: "Jiuzhaigou",
            name_tw: "九寨溝縣",
            yzcode: "623400",
          },
          {
            code: 513226,
            name_cn: "金川县",
            name_en: "Jinchuan",
            name_tw: "金川縣",
            yzcode: "624100",
          },
          {
            code: 513227,
            name_cn: "小金县",
            name_en: "Xiaojin",
            name_tw: "小金縣",
            yzcode: "624200",
          },
          {
            code: 513228,
            name_cn: "黑水县",
            name_en: "Heishui",
            name_tw: "黑水縣",
            yzcode: "623500",
          },
          {
            code: 513229,
            name_cn: "马尔康县",
            name_en: "Maerkang",
            name_tw: "馬爾康縣",
            yzcode: "624000",
          },
          {
            code: 513230,
            name_cn: "壤塘县",
            name_en: "Rangtang",
            name_tw: "壤塘縣",
            yzcode: "624300",
          },
          {
            code: 513231,
            name_cn: "阿坝县",
            name_en: "Aba",
            name_tw: "阿壩縣",
            yzcode: "624600",
          },
          {
            code: 513232,
            name_cn: "若尔盖县",
            name_en: "Ruoergai",
            name_tw: "若爾蓋縣",
            yzcode: "624500",
          },
          {
            code: 513233,
            name_cn: "红原县",
            name_en: "Hongyuan",
            name_tw: "紅原縣",
            yzcode: "624400",
          },
        ],
      },
      {
        code: 513300,
        name_cn: "甘孜藏族自治州",
        name_en: "Garze",
        name_tw: "甘孜藏族自治州",
        children: [
          {
            code: 513321,
            name_cn: "康定县",
            name_en: "Kangding",
            name_tw: "康定縣",
            yzcode: "626000",
          },
          {
            code: 513322,
            name_cn: "泸定县",
            name_en: "Luding",
            name_tw: "瀘定縣",
            yzcode: "626100",
          },
          {
            code: 513323,
            name_cn: "丹巴县",
            name_en: "Danba",
            name_tw: "丹巴縣",
            yzcode: "626300",
          },
          {
            code: 513324,
            name_cn: "九龙县",
            name_en: "Jiulong",
            name_tw: "九龍縣",
            yzcode: "626200",
          },
          {
            code: 513325,
            name_cn: "雅江县",
            name_en: "Yajiang",
            name_tw: "雅江縣",
            yzcode: "627450",
          },
          {
            code: 513326,
            name_cn: "道孚县",
            name_en: "Daofu",
            name_tw: "道孚縣",
            yzcode: "626400",
          },
          {
            code: 513327,
            name_cn: "炉霍县",
            name_en: "Luhuo",
            name_tw: "爐霍縣",
            yzcode: "626500",
          },
          {
            code: 513328,
            name_cn: "甘孜县",
            name_en: "Ganzi",
            name_tw: "甘孜縣",
            yzcode: "626700",
          },
          {
            code: 513329,
            name_cn: "新龙县",
            name_en: "Xinlong",
            name_tw: "新龍縣",
            yzcode: "626800",
          },
          {
            code: 513330,
            name_cn: "德格县",
            name_en: "Dege",
            name_tw: "德格縣",
            yzcode: "627250",
          },
          {
            code: 513331,
            name_cn: "白玉县",
            name_en: "Baiyu",
            name_tw: "白玉縣",
            yzcode: "627150",
          },
          {
            code: 513332,
            name_cn: "石渠县",
            name_en: "Shiqu",
            name_tw: "石渠縣",
            yzcode: "627350",
          },
          {
            code: 513333,
            name_cn: "色达县",
            name_en: "Seda",
            name_tw: "色達縣",
            yzcode: "626600",
          },
          {
            code: 513334,
            name_cn: "理塘县",
            name_en: "Litang",
            name_tw: "理塘縣",
            yzcode: "627550",
          },
          {
            code: 513335,
            name_cn: "巴塘县",
            name_en: "Batang",
            name_tw: "巴塘縣",
            yzcode: "627650",
          },
          {
            code: 513336,
            name_cn: "乡城县",
            name_en: "Xiangcheng",
            name_tw: "鄉城縣",
            yzcode: "627850",
          },
          {
            code: 513337,
            name_cn: "稻城县",
            name_en: "Daocheng",
            name_tw: "稻城縣",
            yzcode: "627750",
          },
          {
            code: 513338,
            name_cn: "得荣县",
            name_en: "Derong",
            name_tw: "得榮縣",
            yzcode: "627950",
          },
        ],
      },
      {
        code: 513400,
        name_cn: "凉山彝族自治州",
        name_en: "Liangshan",
        name_tw: "涼山彜族自治州",
        children: [
          {
            code: 513401,
            name_cn: "西昌市",
            name_en: "Xichang",
            name_tw: "西昌市",
            yzcode: "615000",
          },
          {
            code: 513422,
            name_cn: "木里藏族自治县",
            name_en: "Muli",
            name_tw: "木裏藏族自治縣",
            yzcode: "615800",
          },
          {
            code: 513423,
            name_cn: "盐源县",
            name_en: "Yanyuan",
            name_tw: "鹽源縣",
            yzcode: "615700",
          },
          {
            code: 513424,
            name_cn: "德昌县",
            name_en: "Dechang",
            name_tw: "德昌縣",
            yzcode: "615500",
          },
          {
            code: 513425,
            name_cn: "会理县",
            name_en: "Huili",
            name_tw: "會理縣",
            yzcode: "615100",
          },
          {
            code: 513426,
            name_cn: "会东县",
            name_en: "Huidong",
            name_tw: "會東縣",
            yzcode: "615200",
          },
          {
            code: 513427,
            name_cn: "宁南县",
            name_en: "Ningnan",
            name_tw: "寧南縣",
            yzcode: "615400",
          },
          {
            code: 513428,
            name_cn: "普格县",
            name_en: "Puge",
            name_tw: "普格縣",
            yzcode: "615300",
          },
          {
            code: 513429,
            name_cn: "布拖县",
            name_en: "Butuo",
            name_tw: "布拖縣",
            yzcode: "616350",
          },
          {
            code: 513430,
            name_cn: "金阳县",
            name_en: "Jinyang",
            name_tw: "金陽縣",
            yzcode: "616250",
          },
          {
            code: 513431,
            name_cn: "昭觉县",
            name_en: "Zhaojue",
            name_tw: "昭覺縣",
            yzcode: "616150",
          },
          {
            code: 513432,
            name_cn: "喜德县",
            name_en: "Xide",
            name_tw: "喜德縣",
            yzcode: "616750",
          },
          {
            code: 513433,
            name_cn: "冕宁县",
            name_en: "Mianning",
            name_tw: "冕寧縣",
            yzcode: "615600",
          },
          {
            code: 513434,
            name_cn: "越西县",
            name_en: "Yuexi",
            name_tw: "越西縣",
            yzcode: "616650",
          },
          {
            code: 513435,
            name_cn: "甘洛县",
            name_en: "Ganluo",
            name_tw: "甘洛縣",
            yzcode: "616850",
          },
          {
            code: 513436,
            name_cn: "美姑县",
            name_en: "Meigu",
            name_tw: "美姑縣",
            yzcode: "616450",
          },
          {
            code: 513437,
            name_cn: "雷波县",
            name_en: "Leibo",
            name_tw: "雷波縣",
            yzcode: "616550",
          },
        ],
      },
    ],
  },
  {
    code: 520000,
    name_cn: "贵州省",
    name_en: "Guizhou",
    name_tw: "貴州省",
    children: [
      {
        code: 520100,
        name_cn: "贵阳市",
        name_en: "Guiyang",
        name_tw: "貴陽市",
        children: [
          {
            code: 520102,
            name_cn: "南明区",
            name_en: "Nanming",
            name_tw: "南明區",
            yzcode: "550001",
          },
          {
            code: 520103,
            name_cn: "云岩区",
            name_en: "Yunyan",
            name_tw: "雲巖區",
            yzcode: "550001",
          },
          {
            code: 520111,
            name_cn: "花溪区",
            name_en: "Huaxi",
            name_tw: "花溪區",
            yzcode: "550025",
          },
          {
            code: 520112,
            name_cn: "乌当区",
            name_en: "Wudang",
            name_tw: "烏當區",
            yzcode: "550018",
          },
          {
            code: 520113,
            name_cn: "白云区",
            name_en: "Baiyun",
            name_tw: "白雲區",
            yzcode: "550014",
          },
          {
            code: 520115,
            name_cn: "观山湖区",
            name_en: "Guanshanhu",
            name_tw: "觀山湖區",
            yzcode: "550009",
          },
          {
            code: 520121,
            name_cn: "开阳县",
            name_en: "Kaiyang",
            name_tw: "開陽縣",
            yzcode: "550300",
          },
          {
            code: 520122,
            name_cn: "息烽县",
            name_en: "Xifeng",
            name_tw: "息烽縣",
            yzcode: "551100",
          },
          {
            code: 520123,
            name_cn: "修文县",
            name_en: "Xiuwen",
            name_tw: "修文縣",
            yzcode: "550200",
          },
          {
            code: 520181,
            name_cn: "清镇市",
            name_en: "Qingzhen",
            name_tw: "清鎮市",
            yzcode: "551400",
          },
        ],
      },
      {
        code: 520200,
        name_cn: "六盘水市",
        name_en: "Liupanshui",
        name_tw: "六盤水市",
        children: [
          {
            code: 520201,
            name_cn: "钟山区",
            name_en: "Zhongshan",
            name_tw: "鐘山區",
            yzcode: "553000",
          },
          {
            code: 520203,
            name_cn: "六枝特区",
            name_en: "Liuzhi",
            name_tw: "六枝特區",
            yzcode: "553400",
          },
          {
            code: 520221,
            name_cn: "水城县",
            name_en: "Shuicheng",
            name_tw: "水城縣",
            yzcode: "553000",
          },
          {
            code: 520222,
            name_cn: "盘县",
            name_en: "Panxian",
            name_tw: "盤縣",
            yzcode: "561601",
          },
        ],
      },
      {
        code: 520300,
        name_cn: "遵义市",
        name_en: "Zunyi",
        name_tw: "遵義市",
        children: [
          {
            code: 520302,
            name_cn: "红花岗区",
            name_en: "Honghuagang",
            name_tw: "紅花崗區",
            yzcode: "563000",
          },
          {
            code: 520303,
            name_cn: "汇川区",
            name_en: "Huichuan",
            name_tw: "匯川區",
            yzcode: "563000",
          },
          {
            code: 520321,
            name_cn: "遵义县",
            name_en: "Zunyi",
            name_tw: "遵義縣",
            yzcode: "563100",
          },
          {
            code: 520322,
            name_cn: "桐梓县",
            name_en: "Tongzi",
            name_tw: "桐梓縣",
            yzcode: "563200",
          },
          {
            code: 520323,
            name_cn: "绥阳县",
            name_en: "Suiyang",
            name_tw: "綏陽縣",
            yzcode: "563300",
          },
          {
            code: 520324,
            name_cn: "正安县",
            name_en: "Zheng'an",
            name_tw: "正安縣",
            yzcode: "563400",
          },
          {
            code: 520325,
            name_cn: "道真仡佬族苗族自治县",
            name_en: "Daozhen",
            name_tw: "道真仡佬族苗族自治縣",
            yzcode: "563500",
          },
          {
            code: 520326,
            name_cn: "务川仡佬族苗族自治县",
            name_en: "Wuchuan",
            name_tw: "務川仡佬族苗族自治縣",
            yzcode: "564300",
          },
          {
            code: 520327,
            name_cn: "凤冈县",
            name_en: "Fenggang",
            name_tw: "鳳岡縣",
            yzcode: "564200",
          },
          {
            code: 520328,
            name_cn: "湄潭县",
            name_en: "Meitan",
            name_tw: "湄潭縣",
            yzcode: "564100",
          },
          {
            code: 520329,
            name_cn: "余庆县",
            name_en: "Yuqing",
            name_tw: "余慶縣",
            yzcode: "564400",
          },
          {
            code: 520330,
            name_cn: "习水县",
            name_en: "Xishui",
            name_tw: "習水縣",
            yzcode: "564600",
          },
          {
            code: 520381,
            name_cn: "赤水市",
            name_en: "Chishui",
            name_tw: "赤水市",
            yzcode: "564700",
          },
          {
            code: 520382,
            name_cn: "仁怀市",
            name_en: "Renhuai",
            name_tw: "仁懷市",
            yzcode: "564500",
          },
        ],
      },
      {
        code: 520400,
        name_cn: "安顺市",
        name_en: "Anshun",
        name_tw: "安順市",
        children: [
          {
            code: 520402,
            name_cn: "西秀区",
            name_en: "Xixiu",
            name_tw: "西秀區",
            yzcode: "561000",
          },
          {
            code: 520421,
            name_cn: "平坝区",
            name_en: "Pingba",
            name_tw: "平壩區",
            yzcode: "561100",
          },
          {
            code: 520422,
            name_cn: "普定县",
            name_en: "Puding",
            name_tw: "普定縣",
            yzcode: "562100",
          },
          {
            code: 520423,
            name_cn: "镇宁布依族苗族自治县",
            name_en: "Zhenning",
            name_tw: "鎮寧布依族苗族自治縣",
            yzcode: "561200",
          },
          {
            code: 520424,
            name_cn: "关岭布依族苗族自治县",
            name_en: "Guanling",
            name_tw: "關嶺布依族苗族自治縣",
            yzcode: "561300",
          },
          {
            code: 520425,
            name_cn: "紫云苗族布依族自治县",
            name_en: "Ziyun",
            name_tw: "紫雲苗族布依族自治縣",
            yzcode: "550800",
          },
        ],
      },
      {
        code: 520500,
        name_cn: "毕节市",
        name_en: "Bijie",
        name_tw: "畢節市",
        children: [
          {
            code: 520502,
            name_cn: "七星关区",
            name_en: "Qixingguan",
            name_tw: "七星關區",
            yzcode: "551700",
          },
          {
            code: 520521,
            name_cn: "大方县",
            name_en: "Dafang",
            name_tw: "大方縣",
            yzcode: "551600",
          },
          {
            code: 520522,
            name_cn: "黔西县",
            name_en: "Qianxi",
            name_tw: "黔西縣",
            yzcode: "551500",
          },
          {
            code: 520523,
            name_cn: "金沙县",
            name_en: "Jinsha",
            name_tw: "金沙縣",
            yzcode: "551800",
          },
          {
            code: 520524,
            name_cn: "织金县",
            name_en: "Zhijin",
            name_tw: "織金縣",
            yzcode: "552100",
          },
          {
            code: 520525,
            name_cn: "纳雍县",
            name_en: "Nayong",
            name_tw: "納雍縣",
            yzcode: "553300",
          },
          {
            code: 520526,
            name_cn: "威宁彝族回族苗族自治县",
            name_en: "Weining",
            name_tw: "威寧彜族回族苗族自治縣",
            yzcode: "553100",
          },
          {
            code: 520527,
            name_cn: "赫章县",
            name_en: "Hezhang",
            name_tw: "赫章縣",
            yzcode: "553200",
          },
        ],
      },
      {
        code: 520600,
        name_cn: "铜仁市",
        name_en: "Tongren",
        name_tw: "銅仁市",
        children: [
          {
            code: 520602,
            name_cn: "碧江区",
            name_en: "Bijiang",
            name_tw: "碧江區",
            yzcode: "554300",
          },
          {
            code: 520603,
            name_cn: "万山区",
            name_en: "Wanshan",
            name_tw: "萬山區",
            yzcode: "554200",
          },
          {
            code: 520621,
            name_cn: "江口县",
            name_en: "Jiangkou",
            name_tw: "江口縣",
            yzcode: "554400",
          },
          {
            code: 520622,
            name_cn: "玉屏侗族自治县",
            name_en: "Yuping",
            name_tw: "玉屏侗族自治縣",
            yzcode: "554004",
          },
          {
            code: 520623,
            name_cn: "石阡县",
            name_en: "Shiqian",
            name_tw: "石阡縣",
            yzcode: "555100",
          },
          {
            code: 520624,
            name_cn: "思南县",
            name_en: "Sinan",
            name_tw: "思南縣",
            yzcode: "565100",
          },
          {
            code: 520625,
            name_cn: "印江土家族苗族自治县",
            name_en: "Yinjiang",
            name_tw: "印江土家族苗族自治縣",
            yzcode: "555200",
          },
          {
            code: 520626,
            name_cn: "德江县",
            name_en: "Dejiang",
            name_tw: "德江縣",
            yzcode: "565200",
          },
          {
            code: 520627,
            name_cn: "沿河土家族自治县",
            name_en: "Yuanhe",
            name_tw: "沿河土家族自治縣",
            yzcode: "565300",
          },
          {
            code: 520628,
            name_cn: "松桃苗族自治县",
            name_en: "Songtao",
            name_tw: "松桃苗族自治縣",
            yzcode: "554100",
          },
        ],
      },
      {
        code: 522300,
        name_cn: "黔西南布依族苗族自治州",
        name_en: "Qianxinan",
        name_tw: "黔西南布依族苗族自治州",
        children: [
          {
            code: 522301,
            name_cn: "兴义市 ",
            name_en: "Xingyi",
            name_tw: "興義市 ",
            yzcode: "562400",
          },
          {
            code: 522322,
            name_cn: "兴仁县",
            name_en: "Xingren",
            name_tw: "興仁縣",
            yzcode: "562300",
          },
          {
            code: 522323,
            name_cn: "普安县",
            name_en: "Pu'an",
            name_tw: "普安縣",
            yzcode: "561500",
          },
          {
            code: 522324,
            name_cn: "晴隆县",
            name_en: "Qinglong",
            name_tw: "晴隆縣",
            yzcode: "561400",
          },
          {
            code: 522325,
            name_cn: "贞丰县",
            name_en: "Zhenfeng",
            name_tw: "貞豐縣",
            yzcode: "562200",
          },
          {
            code: 522326,
            name_cn: "望谟县",
            name_en: "Wangmo",
            name_tw: "望謨縣",
            yzcode: "552300",
          },
          {
            code: 522327,
            name_cn: "册亨县",
            name_en: "Ceheng",
            name_tw: "冊亨縣",
            yzcode: "552200",
          },
          {
            code: 522328,
            name_cn: "安龙县",
            name_en: "Anlong",
            name_tw: "安龍縣",
            yzcode: "552400",
          },
        ],
      },
      {
        code: 522600,
        name_cn: "黔东南苗族侗族自治州",
        name_en: "Qiandongnan",
        name_tw: "黔東南苗族侗族自治州",
        children: [
          {
            code: 522601,
            name_cn: "凯里市",
            name_en: "Kaili",
            name_tw: "凱裏市",
            yzcode: "556000",
          },
          {
            code: 522622,
            name_cn: "黄平县",
            name_en: "Huangping",
            name_tw: "黃平縣",
            yzcode: "556100",
          },
          {
            code: 522623,
            name_cn: "施秉县",
            name_en: "Shibing",
            name_tw: "施秉縣",
            yzcode: "556200",
          },
          {
            code: 522624,
            name_cn: "三穗县",
            name_en: "Sansui",
            name_tw: "三穗縣",
            yzcode: "556500",
          },
          {
            code: 522625,
            name_cn: "镇远县",
            name_en: "Zhenyuan",
            name_tw: "鎮遠縣",
            yzcode: "557700",
          },
          {
            code: 522626,
            name_cn: "岑巩县",
            name_en: "Cengong",
            name_tw: "岑鞏縣",
            yzcode: "557800",
          },
          {
            code: 522627,
            name_cn: "天柱县",
            name_en: "Tianzhu",
            name_tw: "天柱縣",
            yzcode: "556600",
          },
          {
            code: 522628,
            name_cn: "锦屏县",
            name_en: "Jinping",
            name_tw: "錦屏縣",
            yzcode: "556700",
          },
          {
            code: 522629,
            name_cn: "剑河县",
            name_en: "Jianhe",
            name_tw: "劍河縣",
            yzcode: "556400",
          },
          {
            code: 522630,
            name_cn: "台江县",
            name_en: "Taijiang",
            name_tw: "台江縣",
            yzcode: "556300",
          },
          {
            code: 522631,
            name_cn: "黎平县",
            name_en: "Liping",
            name_tw: "黎平縣",
            yzcode: "557300",
          },
          {
            code: 522632,
            name_cn: "榕江县",
            name_en: "Rongjiang",
            name_tw: "榕江縣",
            yzcode: "557200",
          },
          {
            code: 522633,
            name_cn: "从江县",
            name_en: "Congjiang",
            name_tw: "從江縣",
            yzcode: "557400",
          },
          {
            code: 522634,
            name_cn: "雷山县",
            name_en: "Leishan",
            name_tw: "雷山縣",
            yzcode: "557100",
          },
          {
            code: 522635,
            name_cn: "麻江县",
            name_en: "Majiang",
            name_tw: "麻江縣",
            yzcode: "557600",
          },
          {
            code: 522636,
            name_cn: "丹寨县",
            name_en: "Danzhai",
            name_tw: "丹寨縣",
            yzcode: "557500",
          },
        ],
      },
      {
        code: 522700,
        name_cn: "黔南布依族苗族自治州",
        name_en: "Qiannan",
        name_tw: "黔南布依族苗族自治州",
        children: [
          {
            code: 522701,
            name_cn: "都匀市",
            name_en: "Duyun",
            name_tw: "都勻市",
            yzcode: "558000",
          },
          {
            code: 522702,
            name_cn: "福泉市",
            name_en: "Fuquan",
            name_tw: "福泉市",
            yzcode: "550500",
          },
          {
            code: 522722,
            name_cn: "荔波县",
            name_en: "Libo",
            name_tw: "荔波縣",
            yzcode: "558400",
          },
          {
            code: 522723,
            name_cn: "贵定县",
            name_en: "Guiding",
            name_tw: "貴定縣",
            yzcode: "551300",
          },
          {
            code: 522725,
            name_cn: "瓮安县",
            name_en: "Weng'an",
            name_tw: "甕安縣",
            yzcode: "550400",
          },
          {
            code: 522726,
            name_cn: "独山县",
            name_en: "Dushan",
            name_tw: "獨山縣",
            yzcode: "558200",
          },
          {
            code: 522727,
            name_cn: "平塘县",
            name_en: "Pingtang",
            name_tw: "平塘縣",
            yzcode: "558300",
          },
          {
            code: 522728,
            name_cn: "罗甸县",
            name_en: "Luodian",
            name_tw: "羅甸縣",
            yzcode: "550100",
          },
          {
            code: 522729,
            name_cn: "长顺县",
            name_en: "Changshun",
            name_tw: "長順縣",
            yzcode: "550700",
          },
          {
            code: 522730,
            name_cn: "龙里县",
            name_en: "Longli",
            name_tw: "龍裏縣",
            yzcode: "551200",
          },
          {
            code: 522731,
            name_cn: "惠水县",
            name_en: "Huishui",
            name_tw: "惠水縣",
            yzcode: "550600",
          },
          {
            code: 522732,
            name_cn: "三都水族自治县",
            name_en: "Sandu",
            name_tw: "三都水族自治縣",
            yzcode: "558100",
          },
        ],
      },
    ],
  },
  {
    code: 530000,
    name_cn: "云南省",
    name_en: "Yunnan",
    name_tw: "雲南省",
    children: [
      {
        code: 530100,
        name_cn: "昆明市",
        name_en: "Kunming",
        name_tw: "昆明市",
        children: [
          {
            code: 530102,
            name_cn: "五华区",
            name_en: "Wuhua",
            name_tw: "五華區",
            yzcode: "650021",
          },
          {
            code: 530103,
            name_cn: "盘龙区",
            name_en: "Panlong",
            name_tw: "盤龍區",
            yzcode: "650051",
          },
          {
            code: 530111,
            name_cn: "官渡区",
            name_en: "Guandu",
            name_tw: "官渡區",
            yzcode: "650200",
          },
          {
            code: 530112,
            name_cn: "西山区",
            name_en: "Xishan",
            name_tw: "西山區",
            yzcode: "650118",
          },
          {
            code: 530113,
            name_cn: "东川区",
            name_en: "Dongchuan",
            name_tw: "東川區",
            yzcode: "654100",
          },
          {
            code: 530114,
            name_cn: "呈贡区",
            name_en: "Chenggong",
            name_tw: "呈貢區",
            yzcode: "650500",
          },
          {
            code: 530122,
            name_cn: "晋宁县",
            name_en: "Jinning",
            name_tw: "晉寧縣",
            yzcode: "650600",
          },
          {
            code: 530124,
            name_cn: "富民县",
            name_en: "Fumin",
            name_tw: "富民縣",
            yzcode: "650400",
          },
          {
            code: 530125,
            name_cn: "宜良县",
            name_en: "Yiliang",
            name_tw: "宜良縣",
            yzcode: "652100",
          },
          {
            code: 530126,
            name_cn: "石林彝族自治县",
            name_en: "Shilin",
            name_tw: "石林彜族自治縣",
            yzcode: "652200",
          },
          {
            code: 530127,
            name_cn: "嵩明县",
            name_en: "Songming",
            name_tw: "嵩明縣",
            yzcode: "651700",
          },
          {
            code: 530128,
            name_cn: "禄劝彝族苗族自治县",
            name_en: "Luquan",
            name_tw: "祿勸彜族苗族自治縣",
            yzcode: "651500",
          },
          {
            code: 530129,
            name_cn: "寻甸回族彝族自治县 ",
            name_en: "Xundian",
            name_tw: "尋甸回族彜族自治縣 ",
            yzcode: "655200",
          },
          {
            code: 530181,
            name_cn: "安宁市",
            name_en: "Anning",
            name_tw: "安寧市",
            yzcode: "650300",
          },
        ],
      },
      {
        code: 530300,
        name_cn: "曲靖市",
        name_en: "Qujing",
        name_tw: "曲靖市",
        children: [
          {
            code: 530302,
            name_cn: "麒麟区",
            name_en: "Qilin",
            name_tw: "麒麟區",
            yzcode: "655000",
          },
          {
            code: 530321,
            name_cn: "马龙县",
            name_en: "Malong",
            name_tw: "馬龍縣",
            yzcode: "655100",
          },
          {
            code: 530322,
            name_cn: "陆良县",
            name_en: "Luliang",
            name_tw: "陸良縣",
            yzcode: "655600",
          },
          {
            code: 530323,
            name_cn: "师宗县",
            name_en: "Shizong",
            name_tw: "師宗縣",
            yzcode: "655700",
          },
          {
            code: 530324,
            name_cn: "罗平县",
            name_en: "Luoping",
            name_tw: "羅平縣",
            yzcode: "655800",
          },
          {
            code: 530325,
            name_cn: "富源县",
            name_en: "Fuyuan",
            name_tw: "富源縣",
            yzcode: "655500",
          },
          {
            code: 530326,
            name_cn: "会泽县",
            name_en: "Huize",
            name_tw: "會澤縣",
            yzcode: "654200",
          },
          {
            code: 530328,
            name_cn: "沾益县",
            name_en: "Zhanyi",
            name_tw: "沾益縣",
            yzcode: "655331",
          },
          {
            code: 530381,
            name_cn: "宣威市",
            name_en: "Xuanwei",
            name_tw: "宣威市",
            yzcode: "655400",
          },
        ],
      },
      {
        code: 530400,
        name_cn: "玉溪市",
        name_en: "Yuxi",
        name_tw: "玉溪市",
        children: [
          {
            code: 530402,
            name_cn: "红塔区",
            name_en: "Hongta",
            name_tw: "紅塔區",
            yzcode: "653100",
          },
          {
            code: 530421,
            name_cn: "江川县",
            name_en: "Jiangchuan",
            name_tw: "江川縣",
            yzcode: "652600",
          },
          {
            code: 530422,
            name_cn: "澄江县",
            name_en: "Chengjiang",
            name_tw: "澄江縣",
            yzcode: "652500",
          },
          {
            code: 530423,
            name_cn: "通海县",
            name_en: "Tonghai",
            name_tw: "通海縣",
            yzcode: "652700",
          },
          {
            code: 530424,
            name_cn: "华宁县",
            name_en: "Huaning",
            name_tw: "華寧縣",
            yzcode: "652800",
          },
          {
            code: 530425,
            name_cn: "易门县",
            name_en: "Yimen",
            name_tw: "易門縣",
            yzcode: "651100",
          },
          {
            code: 530426,
            name_cn: "峨山彝族自治县",
            name_en: "Eshan",
            name_tw: "峨山彜族自治縣",
            yzcode: "653200",
          },
          {
            code: 530427,
            name_cn: "新平彝族傣族自治县",
            name_en: "Xinping",
            name_tw: "新平彜族傣族自治縣",
            yzcode: "653400",
          },
          {
            code: 530428,
            name_cn: "元江哈尼族彝族傣族自治县",
            name_en: "Yuanjiang",
            name_tw: "元江哈尼族彜族傣族自治縣",
            yzcode: "653300",
          },
        ],
      },
      {
        code: 530500,
        name_cn: "保山市",
        name_en: "Baoshan",
        name_tw: "保山市",
        children: [
          {
            code: 530502,
            name_cn: "隆阳区",
            name_en: "Longyang",
            name_tw: "隆陽區",
            yzcode: "678000",
          },
          {
            code: 530521,
            name_cn: "施甸县",
            name_en: "Shidian",
            name_tw: "施甸縣",
            yzcode: "678200",
          },
          {
            code: 530522,
            name_cn: "腾冲县",
            name_en: "Tengchong",
            name_tw: "騰沖縣",
            yzcode: "679100",
          },
          {
            code: 530523,
            name_cn: "龙陵县",
            name_en: "Longling",
            name_tw: "龍陵縣",
            yzcode: "678300",
          },
          {
            code: 530524,
            name_cn: "昌宁县",
            name_en: "Changning",
            name_tw: "昌寧縣",
            yzcode: "678100",
          },
        ],
      },
      {
        code: 530600,
        name_cn: "昭通市",
        name_en: "Zhaotong",
        name_tw: "昭通市",
        children: [
          {
            code: 530602,
            name_cn: "昭阳区",
            name_en: "Zhaoyang",
            name_tw: "昭陽區",
            yzcode: "657000",
          },
          {
            code: 530621,
            name_cn: "鲁甸县",
            name_en: "Ludian",
            name_tw: "魯甸縣",
            yzcode: "657100",
          },
          {
            code: 530622,
            name_cn: "巧家县",
            name_en: "Qiaojia",
            name_tw: "巧家縣",
            yzcode: "654600",
          },
          {
            code: 530623,
            name_cn: "盐津县",
            name_en: "Yanjin",
            name_tw: "鹽津縣",
            yzcode: "657500",
          },
          {
            code: 530624,
            name_cn: "大关县",
            name_en: "Daguan",
            name_tw: "大關縣",
            yzcode: "657400",
          },
          {
            code: 530625,
            name_cn: "永善县",
            name_en: "Yongshan",
            name_tw: "永善縣",
            yzcode: "657300",
          },
          {
            code: 530626,
            name_cn: "绥江县",
            name_en: "Suijiang",
            name_tw: "綏江縣",
            yzcode: "657700",
          },
          {
            code: 530627,
            name_cn: "镇雄县",
            name_en: "Zhenxiong",
            name_tw: "鎮雄縣",
            yzcode: "657200",
          },
          {
            code: 530628,
            name_cn: "彝良县",
            name_en: "Yiliang",
            name_tw: "彜良縣",
            yzcode: "657600",
          },
          {
            code: 530629,
            name_cn: "威信县",
            name_en: "Weixin",
            name_tw: "威信縣",
            yzcode: "657900",
          },
          {
            code: 530630,
            name_cn: "水富县",
            name_en: "Shuifu",
            name_tw: "水富縣",
            yzcode: "657800",
          },
        ],
      },
      {
        code: 530700,
        name_cn: "丽江市",
        name_en: "Lijiang",
        name_tw: "麗江市",
        children: [
          {
            code: 530702,
            name_cn: "古城区",
            name_en: "Gucheng",
            name_tw: "古城區",
            yzcode: "674100",
          },
          {
            code: 530721,
            name_cn: "玉龙纳西族自治县",
            name_en: "Yulong",
            name_tw: "玉龍納西族自治縣",
            yzcode: "674100",
          },
          {
            code: 530722,
            name_cn: "永胜县",
            name_en: "Yongsheng",
            name_tw: "永勝縣",
            yzcode: "674200",
          },
          {
            code: 530723,
            name_cn: "华坪县",
            name_en: "Huaping",
            name_tw: "華坪縣",
            yzcode: "674800",
          },
          {
            code: 530724,
            name_cn: "宁蒗彝族自治县",
            name_en: "Ninglang",
            name_tw: "寧蒗彜族自治縣",
            yzcode: "674300",
          },
        ],
      },
      {
        code: 530800,
        name_cn: "普洱市",
        name_en: "Pu'er",
        name_tw: "普洱市",
        children: [
          {
            code: 530802,
            name_cn: "思茅区",
            name_en: "Simao",
            name_tw: "思茅區",
            yzcode: "665000",
          },
          {
            code: 530821,
            name_cn: "宁洱哈尼族彝族自治县",
            name_en: "Ninger",
            name_tw: "寧洱哈尼族彜族自治縣",
            yzcode: "665100",
          },
          {
            code: 530822,
            name_cn: "墨江哈尼族自治县",
            name_en: "Mojiang",
            name_tw: "墨江哈尼族自治縣",
            yzcode: "654800",
          },
          {
            code: 530823,
            name_cn: "景东彝族自治县",
            name_en: "Jingdong",
            name_tw: "景東彜族自治縣",
            yzcode: "676200",
          },
          {
            code: 530824,
            name_cn: "景谷傣族彝族自治县",
            name_en: "Jinggu",
            name_tw: "景谷傣族彜族自治縣",
            yzcode: "666400",
          },
          {
            code: 530825,
            name_cn: "镇沅彝族哈尼族拉祜族自治县",
            name_en: "Zhenyuan",
            name_tw: "鎮沅彜族哈尼族拉祜族自治縣",
            yzcode: "666500",
          },
          {
            code: 530826,
            name_cn: "江城哈尼族彝族自治县",
            name_en: "Jiangcheng",
            name_tw: "江城哈尼族彜族自治縣",
            yzcode: "665900",
          },
          {
            code: 530827,
            name_cn: "孟连傣族拉祜族佤族自治县",
            name_en: "Menglian",
            name_tw: "孟連傣族拉祜族佤族自治縣",
            yzcode: "665800",
          },
          {
            code: 530828,
            name_cn: "澜沧拉祜族自治县",
            name_en: "Lancang",
            name_tw: "瀾滄拉祜族自治縣",
            yzcode: "665600",
          },
          {
            code: 530829,
            name_cn: "西盟佤族自治县",
            name_en: "Ximeng",
            name_tw: "西盟佤族自治縣",
            yzcode: "665700",
          },
        ],
      },
      {
        code: 530900,
        name_cn: "临沧市",
        name_en: "Lincang",
        name_tw: "臨滄市",
        children: [
          {
            code: 530902,
            name_cn: "临翔区",
            name_en: "Linxiang",
            name_tw: "臨翔區",
            yzcode: "677000",
          },
          {
            code: 530921,
            name_cn: "凤庆县",
            name_en: "Fengqing",
            name_tw: "鳳慶縣",
            yzcode: "675900",
          },
          {
            code: 530922,
            name_cn: "云县",
            name_en: "Yunxian",
            name_tw: "雲縣",
            yzcode: "675800",
          },
          {
            code: 530923,
            name_cn: "永德县",
            name_en: "Yongde",
            name_tw: "永德縣",
            yzcode: "677600",
          },
          {
            code: 530924,
            name_cn: "镇康县",
            name_en: "Zhenkang",
            name_tw: "鎮康縣",
            yzcode: "677704",
          },
          {
            code: 530925,
            name_cn: "双江拉祜族佤族布朗族傣族自治县",
            name_en: "Shuangjiang",
            name_tw: "雙江拉祜族佤族布朗族傣族自治縣",
            yzcode: "677300",
          },
          {
            code: 530926,
            name_cn: "耿马傣族佤族自治县",
            name_en: "Gengma",
            name_tw: "耿馬傣族佤族自治縣",
            yzcode: "677500",
          },
          {
            code: 530927,
            name_cn: "沧源佤族自治县",
            name_en: "Cangyuan",
            name_tw: "滄源佤族自治縣",
            yzcode: "677400",
          },
        ],
      },
      {
        code: 532300,
        name_cn: "楚雄彝族自治州",
        name_en: "Chuxiong",
        name_tw: "楚雄彜族自治州",
        children: [
          {
            code: 532301,
            name_cn: "楚雄市",
            name_en: "Chuxiong",
            name_tw: "楚雄市",
            yzcode: "675000",
          },
          {
            code: 532322,
            name_cn: "双柏县",
            name_en: "Shuangbai",
            name_tw: "雙柏縣",
            yzcode: "675100",
          },
          {
            code: 532323,
            name_cn: "牟定县",
            name_en: "Mouding",
            name_tw: "牟定縣",
            yzcode: "675500",
          },
          {
            code: 532324,
            name_cn: "南华县",
            name_en: "Nanhua",
            name_tw: "南華縣",
            yzcode: "675200",
          },
          {
            code: 532325,
            name_cn: "姚安县",
            name_en: "Yao'an",
            name_tw: "姚安縣",
            yzcode: "675300",
          },
          {
            code: 532326,
            name_cn: "大姚县",
            name_en: "Dayao",
            name_tw: "大姚縣",
            yzcode: "675400",
          },
          {
            code: 532327,
            name_cn: "永仁县",
            name_en: "Yongren",
            name_tw: "永仁縣",
            yzcode: "651400",
          },
          {
            code: 532328,
            name_cn: "元谋县",
            name_en: "Yuanmou",
            name_tw: "元謀縣",
            yzcode: "651300",
          },
          {
            code: 532329,
            name_cn: "武定县",
            name_en: "Wuding",
            name_tw: "武定縣",
            yzcode: "651600",
          },
          {
            code: 532331,
            name_cn: "禄丰县",
            name_en: "Lufeng",
            name_tw: "祿豐縣",
            yzcode: "651200",
          },
        ],
      },
      {
        code: 532500,
        name_cn: "红河哈尼族彝族自治州",
        name_en: "Honghe",
        name_tw: "紅河哈尼族彜族自治州",
        children: [
          {
            code: 532501,
            name_cn: "个旧市",
            name_en: "Gejiu",
            name_tw: "個舊市",
            yzcode: "661000",
          },
          {
            code: 532502,
            name_cn: "开远市",
            name_en: "Kaiyuan",
            name_tw: "開遠市",
            yzcode: "661600",
          },
          {
            code: 532503,
            name_cn: "蒙自市",
            name_en: "Mengzi",
            name_tw: "蒙自市",
            yzcode: "661101",
          },
          {
            code: 532504,
            name_cn: "弥勒市",
            name_en: "Mile ",
            name_tw: "彌勒市",
            yzcode: "652300",
          },
          {
            code: 532523,
            name_cn: "屏边苗族自治县",
            name_en: "Pingbian",
            name_tw: "屏邊苗族自治縣",
            yzcode: "661200",
          },
          {
            code: 532524,
            name_cn: "建水县",
            name_en: "Jianshui",
            name_tw: "建水縣",
            yzcode: "654300",
          },
          {
            code: 532525,
            name_cn: "石屏县",
            name_en: "Shiping",
            name_tw: "石屏縣",
            yzcode: "662200",
          },
          {
            code: 532527,
            name_cn: "泸西县",
            name_en: "Luxi",
            name_tw: "瀘西縣",
            yzcode: "652400",
          },
          {
            code: 532528,
            name_cn: "元阳县",
            name_en: "Yuanyang",
            name_tw: "元陽縣",
            yzcode: "662400",
          },
          {
            code: 532529,
            name_cn: "红河县",
            name_en: "Honghexian",
            name_tw: "紅河縣",
            yzcode: "654400",
          },
          {
            code: 532530,
            name_cn: "金平苗族瑶族傣族自治县",
            name_en: "Jinping",
            name_tw: "金平苗族瑤族傣族自治縣",
            yzcode: "661500",
          },
          {
            code: 532531,
            name_cn: "绿春县",
            name_en: "Lvchun",
            name_tw: "綠春縣",
            yzcode: "662500",
          },
          {
            code: 532532,
            name_cn: "河口瑶族自治县",
            name_en: "Hekou",
            name_tw: "河口瑤族自治縣",
            yzcode: "661300",
          },
        ],
      },
      {
        code: 532600,
        name_cn: "文山壮族苗族自治州",
        name_en: "Wenshan",
        name_tw: "文山壯族苗族自治州",
        children: [
          {
            code: 532601,
            name_cn: "文山市",
            name_en: "Wenshan",
            name_tw: "文山市",
            yzcode: "663000",
          },
          {
            code: 532622,
            name_cn: "砚山县",
            name_en: "Yanshan",
            name_tw: "硯山縣",
            yzcode: "663100",
          },
          {
            code: 532623,
            name_cn: "西畴县",
            name_en: "Xichou",
            name_tw: "西疇縣",
            yzcode: "663500",
          },
          {
            code: 532624,
            name_cn: "麻栗坡县",
            name_en: "Malipo",
            name_tw: "麻栗坡縣",
            yzcode: "663600",
          },
          {
            code: 532625,
            name_cn: "马关县",
            name_en: "Maguan",
            name_tw: "馬關縣",
            yzcode: "663700",
          },
          {
            code: 532626,
            name_cn: "丘北县",
            name_en: "Qiubei",
            name_tw: "丘北縣",
            yzcode: "663200",
          },
          {
            code: 532627,
            name_cn: "广南县",
            name_en: "Guangnan",
            name_tw: "廣南縣",
            yzcode: "663300",
          },
          {
            code: 532628,
            name_cn: "富宁县",
            name_en: "Funing",
            name_tw: "富寧縣",
            yzcode: "663400",
          },
        ],
      },
      {
        code: 532800,
        name_cn: "西双版纳傣族自治州",
        name_en: "Xishuangbanna",
        name_tw: "西雙版納傣族自治州",
        children: [
          {
            code: 532801,
            name_cn: "景洪市",
            name_en: "Jinghong",
            name_tw: "景洪市",
            yzcode: "666100",
          },
          {
            code: 532822,
            name_cn: "勐海县",
            name_en: "Menghai",
            name_tw: "猛海縣",
            yzcode: "666200",
          },
          {
            code: 532823,
            name_cn: "勐腊县",
            name_en: "Mengla",
            name_tw: "猛臘縣",
            yzcode: "666300",
          },
        ],
      },
      {
        code: 532900,
        name_cn: "大理白族自治州",
        name_en: "Dali",
        name_tw: "大理白族自治州",
        children: [
          {
            code: 532901,
            name_cn: "大理市",
            name_en: "Dali",
            name_tw: "大理市",
            yzcode: "671000",
          },
          {
            code: 532922,
            name_cn: "漾濞彝族自治县",
            name_en: "Yangbi",
            name_tw: "漾濞彜族自治縣",
            yzcode: "672500",
          },
          {
            code: 532923,
            name_cn: "祥云县",
            name_en: "Xiangyun",
            name_tw: "祥雲縣",
            yzcode: "672100",
          },
          {
            code: 532924,
            name_cn: "宾川县",
            name_en: "Binchuan",
            name_tw: "賓川縣",
            yzcode: "671600",
          },
          {
            code: 532925,
            name_cn: "弥渡县",
            name_en: "Midu",
            name_tw: "彌渡縣",
            yzcode: "675600",
          },
          {
            code: 532926,
            name_cn: "南涧彝族自治县",
            name_en: "Nanjian",
            name_tw: "南澗彜族自治縣",
            yzcode: "675700",
          },
          {
            code: 532927,
            name_cn: "巍山彝族回族自治县",
            name_en: "Weishan",
            name_tw: "巍山彜族回族自治縣",
            yzcode: "672400",
          },
          {
            code: 532928,
            name_cn: "永平县",
            name_en: "Yongping",
            name_tw: "永平縣",
            yzcode: "672600",
          },
          {
            code: 532929,
            name_cn: "云龙县",
            name_en: "Yunlong",
            name_tw: "雲龍縣",
            yzcode: "672700",
          },
          {
            code: 532930,
            name_cn: "洱源县",
            name_en: "Eryuan",
            name_tw: "洱源縣",
            yzcode: "671200",
          },
          {
            code: 532931,
            name_cn: "剑川县",
            name_en: "Jianchuan",
            name_tw: "劍川縣",
            yzcode: "671300",
          },
          {
            code: 532932,
            name_cn: "鹤庆县",
            name_en: "Heqing",
            name_tw: "鶴慶縣",
            yzcode: "671500",
          },
        ],
      },
      {
        code: 533100,
        name_cn: "德宏傣族景颇族自治州",
        name_en: "Dehong",
        name_tw: "德宏傣族景頗族自治州",
        children: [
          {
            code: 533102,
            name_cn: "瑞丽市",
            name_en: "Ruili",
            name_tw: "瑞麗市",
            yzcode: "678600",
          },
          {
            code: 533103,
            name_cn: "芒市",
            name_en: "Mangshi",
            name_tw: "芒市",
            yzcode: "678400",
          },
          {
            code: 533122,
            name_cn: "梁河县",
            name_en: "Lianghe",
            name_tw: "梁河縣",
            yzcode: "679200",
          },
          {
            code: 533123,
            name_cn: "盈江县",
            name_en: "Yingjiang",
            name_tw: "盈江縣",
            yzcode: "679300",
          },
          {
            code: 533124,
            name_cn: "陇川县",
            name_en: "Longchuan",
            name_tw: "隴川縣",
            yzcode: "678700",
          },
        ],
      },
      {
        code: 533300,
        name_cn: "怒江傈僳族自治州",
        name_en: "Nujiang",
        name_tw: "怒江傈僳族自治州",
        children: [
          {
            code: 533321,
            name_cn: "泸水县",
            name_en: "Lushui",
            name_tw: "瀘水縣",
            yzcode: "673100",
          },
          {
            code: 533323,
            name_cn: "福贡县",
            name_en: "Fugong",
            name_tw: "福貢縣",
            yzcode: "673400",
          },
          {
            code: 533324,
            name_cn: "贡山独龙族怒族自治县",
            name_en: "Gongshan",
            name_tw: "貢山獨龍族怒族自治縣",
            yzcode: "673500",
          },
          {
            code: 533325,
            name_cn: "兰坪白族普米族自治县",
            name_en: "Lanping",
            name_tw: "蘭坪白族普米族自治縣",
            yzcode: "671400",
          },
        ],
      },
      {
        code: 533400,
        name_cn: "迪庆藏族自治州",
        name_en: "Deqen",
        name_tw: "迪慶藏族自治州",
        children: [
          {
            code: 533421,
            name_cn: "香格里拉市",
            name_en: "Xianggelila",
            name_tw: "香格裏拉市",
            yzcode: "674400",
          },
          {
            code: 533422,
            name_cn: "德钦县",
            name_en: "Deqin",
            name_tw: "德欽縣",
            yzcode: "674500",
          },
          {
            code: 533423,
            name_cn: "维西傈僳族自治县",
            name_en: "Weixi",
            name_tw: "維西傈僳族自治縣",
            yzcode: "674600",
          },
        ],
      },
    ],
  },
  {
    code: 540000,
    name_cn: "西藏自治区",
    name_en: "Tibet",
    name_tw: "西藏自治區",
    children: [
      {
        code: 540100,
        name_cn: "拉萨市",
        name_en: "Lhasa",
        name_tw: "拉薩市",
        children: [
          {
            code: 540102,
            name_cn: "城关区",
            name_en: "Chengguan",
            name_tw: "城關區",
            yzcode: "850000",
          },
          {
            code: 540121,
            name_cn: "林周县",
            name_en: "Linzhou",
            name_tw: "林周縣",
            yzcode: "851600",
          },
          {
            code: 540122,
            name_cn: "当雄县",
            name_en: "Dangxiong",
            name_tw: "當雄縣",
            yzcode: "851500",
          },
          {
            code: 540123,
            name_cn: "尼木县",
            name_en: "Nimu",
            name_tw: "尼木縣",
            yzcode: "851300",
          },
          {
            code: 540124,
            name_cn: "曲水县",
            name_en: "Qushui",
            name_tw: "曲水縣",
            yzcode: "850600",
          },
          {
            code: 540125,
            name_cn: "堆龙德庆县",
            name_en: "Duilongdeqing",
            name_tw: "堆龍德慶縣",
            yzcode: "851400",
          },
          {
            code: 540126,
            name_cn: "达孜县",
            name_en: "Dazi",
            name_tw: "達孜縣",
            yzcode: "850100",
          },
          {
            code: 540127,
            name_cn: "墨竹工卡县",
            name_en: "Mozhugongka",
            name_tw: "墨竹工卡縣",
            yzcode: "850200",
          },
        ],
      },
      {
        code: 540200,
        name_cn: "日喀则市",
        name_en: "Rikaze",
        name_tw: "日喀則市",
        children: [
          {
            code: 540202,
            name_cn: "桑珠孜区",
            name_en: "Sangzhuzi",
            name_tw: "桑珠孜區",
            yzcode: "857000",
          },
          {
            code: 540221,
            name_cn: "南木林县",
            name_en: "Nanmulin",
            name_tw: "南木林縣",
            yzcode: "857100",
          },
          {
            code: 540222,
            name_cn: "江孜县",
            name_en: "Jiangzi",
            name_tw: "江孜縣",
            yzcode: "857400",
          },
          {
            code: 540223,
            name_cn: "定日县",
            name_en: "Dingri",
            name_tw: "定日縣",
            yzcode: "858200",
          },
          {
            code: 540224,
            name_cn: "萨迦县",
            name_en: "Sajia",
            name_tw: "薩迦縣",
            yzcode: "857800",
          },
          {
            code: 540225,
            name_cn: "拉孜县",
            name_en: "Lazi",
            name_tw: "拉孜縣",
            yzcode: "858100",
          },
          {
            code: 540226,
            name_cn: "昂仁县",
            name_en: "Angren",
            name_tw: "昂仁縣",
            yzcode: "858500",
          },
          {
            code: 540227,
            name_cn: "谢通门县",
            name_en: "Xietongmen",
            name_tw: "謝通門縣",
            yzcode: "858900",
          },
          {
            code: 540228,
            name_cn: "白朗县",
            name_en: "Bailang",
            name_tw: "白朗縣",
            yzcode: "857300",
          },
          {
            code: 540229,
            name_cn: "仁布县",
            name_en: "Renbu",
            name_tw: "仁布縣",
            yzcode: "857200",
          },
          {
            code: 540230,
            name_cn: "康马县",
            name_en: "Kangma",
            name_tw: "康馬縣",
            yzcode: "857500",
          },
          {
            code: 540231,
            name_cn: "定结县",
            name_en: "Dingjie",
            name_tw: "定結縣",
            yzcode: "857900",
          },
          {
            code: 540232,
            name_cn: "仲巴县",
            name_en: "Zhongba",
            name_tw: "仲巴縣",
            yzcode: "858800",
          },
          {
            code: 540233,
            name_cn: "亚东县",
            name_en: "Yadong",
            name_tw: "亞東縣",
            yzcode: "857600",
          },
          {
            code: 540234,
            name_cn: "吉隆县",
            name_en: "Jilong",
            name_tw: "吉隆縣",
            yzcode: "858700",
          },
          {
            code: 540235,
            name_cn: "聂拉木县",
            name_en: "Nielamu",
            name_tw: "聶拉木縣",
            yzcode: "858300",
          },
          {
            code: 540236,
            name_cn: "萨嘎县",
            name_en: "Saga",
            name_tw: "薩嘎縣",
            yzcode: "857800",
          },
          {
            code: 540237,
            name_cn: "岗巴县",
            name_en: "Gangba",
            name_tw: "崗巴縣",
            yzcode: "857700",
          },
        ],
      },
      {
        code: 540300,
        name_cn: "昌都市",
        name_en: "Qamdo",
        name_tw: "昌都市",
        children: [
          {
            code: 540302,
            name_cn: "卡若区",
            name_en: "Karuo",
            name_tw: "卡若區",
            yzcode: "854000",
          },
          {
            code: 540321,
            name_cn: "江达县",
            name_en: "Jiangda",
            name_tw: "江達縣",
            yzcode: "854100",
          },
          {
            code: 540322,
            name_cn: "贡觉县",
            name_en: "Gongjue",
            name_tw: "貢覺縣",
            yzcode: "854200",
          },
          {
            code: 540323,
            name_cn: "类乌齐县",
            name_en: "Leiwuqi",
            name_tw: "類烏齊縣",
            yzcode: "855600",
          },
          {
            code: 540324,
            name_cn: "丁青县",
            name_en: "Dingqing",
            name_tw: "丁青縣",
            yzcode: "855700",
          },
          {
            code: 540325,
            name_cn: "察雅县",
            name_en: "Chaya",
            name_tw: "察雅縣",
            yzcode: "854300",
          },
          {
            code: 540326,
            name_cn: "八宿县",
            name_en: "Basu",
            name_tw: "八宿縣",
            yzcode: "854600",
          },
          {
            code: 540327,
            name_cn: "左贡县",
            name_en: "Zuogong",
            name_tw: "左貢縣",
            yzcode: "854400",
          },
          {
            code: 540328,
            name_cn: "芒康县",
            name_en: "Mangkang",
            name_tw: "芒康縣",
            yzcode: "854500",
          },
          {
            code: 540329,
            name_cn: "洛隆县",
            name_en: "Luolong",
            name_tw: "洛隆縣",
            yzcode: "855400",
          },
          {
            code: 540330,
            name_cn: "边坝县",
            name_en: "Bianba",
            name_tw: "邊壩縣",
            yzcode: "855500",
          },
        ],
      },
      {
        code: 542200,
        name_cn: "山南地区",
        name_en: "Shannan",
        name_tw: "山南地區",
        children: [
          {
            code: 542221,
            name_cn: "乃东县",
            name_en: "Naidong",
            name_tw: "乃東縣",
            yzcode: "856100",
          },
          {
            code: 542222,
            name_cn: "扎囊县",
            name_en: "Zhanang",
            name_tw: "紮囊縣",
            yzcode: "850800",
          },
          {
            code: 542223,
            name_cn: "贡嘎县",
            name_en: "Gongga",
            name_tw: "貢嘎縣",
            yzcode: "850700",
          },
          {
            code: 542224,
            name_cn: "桑日县",
            name_en: "Sangri",
            name_tw: "桑日縣",
            yzcode: "856200",
          },
          {
            code: 542225,
            name_cn: "琼结县",
            name_en: "Qiongjie",
            name_tw: "瓊結縣",
            yzcode: "856800",
          },
          {
            code: 542226,
            name_cn: "曲松县",
            name_en: "Qusong",
            name_tw: "曲松縣",
            yzcode: "856300",
          },
          {
            code: 542227,
            name_cn: "措美县",
            name_en: "Cuomei",
            name_tw: "措美縣",
            yzcode: "856900",
          },
          {
            code: 542228,
            name_cn: "洛扎县",
            name_en: "Luozha",
            name_tw: "洛紮縣",
            yzcode: "856600",
          },
          {
            code: 542229,
            name_cn: "加查县",
            name_en: "Jiacha",
            name_tw: "加查縣",
            yzcode: "856400",
          },
          {
            code: 542231,
            name_cn: "隆子县",
            name_en: "Longzi",
            name_tw: "隆子縣",
            yzcode: "856600",
          },
          {
            code: 542232,
            name_cn: "错那县",
            name_en: "Cuona",
            name_tw: "錯那縣",
            yzcode: "856700",
          },
          {
            code: 542233,
            name_cn: "浪卡子县",
            name_en: "Langkazi",
            name_tw: "浪卡子縣",
            yzcode: "851100",
          },
        ],
      },
      {
        code: 542400,
        name_cn: "那曲地区",
        name_en: "Nagqu",
        name_tw: "那曲地區",
        children: [
          {
            code: 542421,
            name_cn: "那曲县",
            name_en: "Naqu",
            name_tw: "那曲縣",
            yzcode: "852000",
          },
          {
            code: 542422,
            name_cn: "嘉黎县",
            name_en: "Jiali",
            name_tw: "嘉黎縣",
            yzcode: "852400",
          },
          {
            code: 542423,
            name_cn: "比如县",
            name_en: "Biru",
            name_tw: "比如縣",
            yzcode: "852300",
          },
          {
            code: 542424,
            name_cn: "聂荣县",
            name_en: "Nierong",
            name_tw: "聶榮縣",
            yzcode: "853500",
          },
          {
            code: 542425,
            name_cn: "安多县",
            name_en: "Anduo",
            name_tw: "安多縣",
            yzcode: "853400",
          },
          {
            code: 542426,
            name_cn: "申扎县",
            name_en: "Shenzha",
            name_tw: "申紮縣",
            yzcode: "853100",
          },
          {
            code: 542427,
            name_cn: "索县",
            name_en: "Suoxian",
            name_tw: "索縣",
            yzcode: "852200",
          },
          {
            code: 542428,
            name_cn: "班戈县",
            name_en: "Bange",
            name_tw: "班戈縣",
            yzcode: "852500",
          },
          {
            code: 542429,
            name_cn: "巴青县",
            name_en: "Baqing",
            name_tw: "巴青縣",
            yzcode: "852100",
          },
          {
            code: 542430,
            name_cn: "尼玛县",
            name_en: "Nima",
            name_tw: "尼瑪縣",
            yzcode: "852600",
          },
          {
            code: 542431,
            name_cn: "双湖县",
            name_en: "Shuanghu",
            name_tw: "雙湖縣",
            yzcode: "852600",
          },
        ],
      },
      {
        code: 542500,
        name_cn: "阿里地区",
        name_en: "Ngari",
        name_tw: "阿裏地區",
        children: [
          {
            code: 542521,
            name_cn: "普兰县",
            name_en: "Pulan",
            name_tw: "普蘭縣",
            yzcode: "859500",
          },
          {
            code: 542522,
            name_cn: "札达县",
            name_en: "Zhada",
            name_tw: "劄達縣",
            yzcode: "859600",
          },
          {
            code: 542523,
            name_cn: "噶尔县",
            name_en: "Gaer",
            name_tw: "噶爾縣",
            yzcode: "859400",
          },
          {
            code: 542524,
            name_cn: "日土县",
            name_en: "Ritu",
            name_tw: "日土縣",
            yzcode: "859700",
          },
          {
            code: 542525,
            name_cn: "革吉县",
            name_en: "Geji",
            name_tw: "革吉縣",
            yzcode: "859100",
          },
          {
            code: 542526,
            name_cn: "改则县",
            name_en: "Gaize",
            name_tw: "改則縣",
            yzcode: "859200",
          },
          {
            code: 542527,
            name_cn: "措勤县",
            name_en: "Cuoqin",
            name_tw: "措勤縣",
            yzcode: "859300",
          },
        ],
      },
      {
        code: 542600,
        name_cn: "林芝地区",
        name_en: "Nyingchi",
        name_tw: "林芝地區",
        children: [
          {
            code: 542621,
            name_cn: "林芝县",
            name_en: "Linzhi",
            name_tw: "林芝縣",
            yzcode: "850400",
          },
          {
            code: 542622,
            name_cn: "工布江达县",
            name_en: "Gongbujiangda",
            name_tw: "工布江達縣",
            yzcode: "850300",
          },
          {
            code: 542623,
            name_cn: "米林县",
            name_en: "Milin",
            name_tw: "米林縣",
            yzcode: "850500",
          },
          {
            code: 542624,
            name_cn: "墨脱县",
            name_en: "Motuo",
            name_tw: "墨脫縣",
            yzcode: "855300",
          },
          {
            code: 542625,
            name_cn: "波密县",
            name_en: "Bomi",
            name_tw: "波密縣",
            yzcode: "855200",
          },
          {
            code: 542626,
            name_cn: "察隅县",
            name_en: "Chayu",
            name_tw: "察隅縣",
            yzcode: "855100",
          },
          {
            code: 542627,
            name_cn: "朗县",
            name_en: "Langxian",
            name_tw: "朗縣",
            yzcode: "856500",
          },
        ],
      },
    ],
  },
  {
    code: 610000,
    name_cn: "陕西省",
    name_en: "Shaanxi",
    name_tw: "陜西省",
    children: [
      {
        code: 610100,
        name_cn: "西安市",
        name_en: "Xi'an",
        name_tw: "西安市",
        children: [
          {
            code: 610102,
            name_cn: "新城区",
            name_en: "Xincheng",
            name_tw: "新城區",
            yzcode: "710004",
          },
          {
            code: 610103,
            name_cn: "碑林区",
            name_en: "Beilin",
            name_tw: "碑林區",
            yzcode: "710001",
          },
          {
            code: 610104,
            name_cn: "莲湖区",
            name_en: "Lianhu",
            name_tw: "蓮湖區",
            yzcode: "710003",
          },
          {
            code: 610111,
            name_cn: "灞桥区",
            name_en: "Baqiao",
            name_tw: "灞橋區",
            yzcode: "710038",
          },
          {
            code: 610112,
            name_cn: "未央区",
            name_en: "Weiyang",
            name_tw: "未央區",
            yzcode: "710014",
          },
          {
            code: 610113,
            name_cn: "雁塔区",
            name_en: "Yanta",
            name_tw: "雁塔區",
            yzcode: "710061",
          },
          {
            code: 610114,
            name_cn: "阎良区",
            name_en: "Yanliang",
            name_tw: "閻良區",
            yzcode: "710087",
          },
          {
            code: 610115,
            name_cn: "临潼区",
            name_en: "Lintong",
            name_tw: "臨潼區",
            yzcode: "710600",
          },
          {
            code: 610116,
            name_cn: "长安区",
            name_en: "Chang'an",
            name_tw: "長安區",
            yzcode: "710100",
          },
          {
            code: 610122,
            name_cn: "蓝田县",
            name_en: "Lantian",
            name_tw: "藍田縣",
            yzcode: "710500",
          },
          {
            code: 610124,
            name_cn: "周至县",
            name_en: "Zhouzhi",
            name_tw: "周至縣",
            yzcode: "710400",
          },
          {
            code: 610125,
            name_cn: "户县",
            name_en: "Huxian",
            name_tw: "戶縣",
            yzcode: "710300",
          },
          {
            code: 610126,
            name_cn: "高陵区",
            name_en: "Gaoling",
            name_tw: "高陵區",
            yzcode: "710200",
          },
        ],
      },
      {
        code: 610200,
        name_cn: "铜川市",
        name_en: "Tongchuan",
        name_tw: "銅川市",
        children: [
          {
            code: 610202,
            name_cn: "王益区",
            name_en: "Wangyi",
            name_tw: "王益區",
            yzcode: "727000",
          },
          {
            code: 610203,
            name_cn: "印台区",
            name_en: "Yintai",
            name_tw: "印台區",
            yzcode: "727007",
          },
          {
            code: 610204,
            name_cn: "耀州区",
            name_en: "Yaozhou",
            name_tw: "耀州區",
            yzcode: "727100",
          },
          {
            code: 610222,
            name_cn: "宜君县",
            name_en: "Yijun",
            name_tw: "宜君縣",
            yzcode: "727200",
          },
        ],
      },
      {
        code: 610300,
        name_cn: "宝鸡市",
        name_en: "Baoji",
        name_tw: "寶雞市",
        children: [
          {
            code: 610302,
            name_cn: "渭滨区",
            name_en: "Weibin",
            name_tw: "渭濱區",
            yzcode: "721000",
          },
          {
            code: 610303,
            name_cn: "金台区",
            name_en: "Jintai",
            name_tw: "金台區",
            yzcode: "721000",
          },
          {
            code: 610304,
            name_cn: "陈仓区",
            name_en: "Chencang",
            name_tw: "陳倉區",
            yzcode: "721300",
          },
          {
            code: 610322,
            name_cn: "凤翔县",
            name_en: "Fengxiang",
            name_tw: "鳳翔縣",
            yzcode: "721400",
          },
          {
            code: 610323,
            name_cn: "岐山县",
            name_en: "Qishan",
            name_tw: "岐山縣",
            yzcode: "722400",
          },
          {
            code: 610324,
            name_cn: "扶风县",
            name_en: "Fufeng",
            name_tw: "扶風縣",
            yzcode: "722200",
          },
          {
            code: 610326,
            name_cn: "眉县",
            name_en: "Meixian",
            name_tw: "眉縣",
            yzcode: "722300",
          },
          {
            code: 610327,
            name_cn: "陇县",
            name_en: "Longxian",
            name_tw: "隴縣",
            yzcode: "721200",
          },
          {
            code: 610328,
            name_cn: "千阳县",
            name_en: "Qianyang",
            name_tw: "千陽縣",
            yzcode: "721100",
          },
          {
            code: 610329,
            name_cn: "麟游县",
            name_en: "Linyou",
            name_tw: "麟遊縣",
            yzcode: "721500",
          },
          {
            code: 610330,
            name_cn: "凤县",
            name_en: "Fengxian",
            name_tw: "鳳縣",
            yzcode: "721700",
          },
          {
            code: 610331,
            name_cn: "太白县",
            name_en: "Taibai",
            name_tw: "太白縣",
            yzcode: "721600",
          },
        ],
      },
      {
        code: 610400,
        name_cn: "咸阳市",
        name_en: "Xianyang",
        name_tw: "鹹陽市",
        children: [
          {
            code: 610402,
            name_cn: "秦都区",
            name_en: "Qindu",
            name_tw: "秦都區",
            yzcode: "712000",
          },
          {
            code: 610403,
            name_cn: "杨陵区",
            name_en: "Yangling",
            name_tw: "楊陵區",
            yzcode: "712100",
          },
          {
            code: 610404,
            name_cn: "渭城区",
            name_en: "Weicheng",
            name_tw: "渭城區",
            yzcode: "712000",
          },
          {
            code: 610422,
            name_cn: "三原县",
            name_en: "Sanyuan",
            name_tw: "三原縣",
            yzcode: "713800",
          },
          {
            code: 610423,
            name_cn: "泾阳县",
            name_en: "Jingyang",
            name_tw: "涇陽縣",
            yzcode: "713700",
          },
          {
            code: 610424,
            name_cn: "乾县",
            name_en: "Qianxian",
            name_tw: "乾縣",
            yzcode: "713300",
          },
          {
            code: 610425,
            name_cn: "礼泉县",
            name_en: "Liquan",
            name_tw: "禮泉縣",
            yzcode: "713200",
          },
          {
            code: 610426,
            name_cn: "永寿县",
            name_en: "Yongshou",
            name_tw: "永壽縣",
            yzcode: "713400",
          },
          {
            code: 610427,
            name_cn: "彬县",
            name_en: "Binxian",
            name_tw: "彬縣",
            yzcode: "713500",
          },
          {
            code: 610428,
            name_cn: "长武县",
            name_en: "Changwu",
            name_tw: "長武縣",
            yzcode: "713600",
          },
          {
            code: 610429,
            name_cn: "旬邑县",
            name_en: "Xunyi",
            name_tw: "旬邑縣",
            yzcode: "711300",
          },
          {
            code: 610430,
            name_cn: "淳化县",
            name_en: "Chunhua",
            name_tw: "淳化縣",
            yzcode: "711200",
          },
          {
            code: 610431,
            name_cn: "武功县",
            name_en: "Wugong",
            name_tw: "武功縣",
            yzcode: "712200",
          },
          {
            code: 610481,
            name_cn: "兴平市",
            name_en: "Xingping",
            name_tw: "興平市",
            yzcode: "713100",
          },
        ],
      },
      {
        code: 610500,
        name_cn: "渭南市",
        name_en: "Weinan",
        name_tw: "渭南市",
        children: [
          {
            code: 610502,
            name_cn: "临渭区",
            name_en: "Linwei",
            name_tw: "臨渭區",
            yzcode: "714000",
          },
          {
            code: 610521,
            name_cn: "华县",
            name_en: "Huaxian",
            name_tw: "華縣",
            yzcode: "714100",
          },
          {
            code: 610522,
            name_cn: "潼关县",
            name_en: "Tongguan",
            name_tw: "潼關縣",
            yzcode: "714300",
          },
          {
            code: 610523,
            name_cn: "大荔县",
            name_en: "Dali",
            name_tw: "大荔縣",
            yzcode: "715100",
          },
          {
            code: 610524,
            name_cn: "合阳县",
            name_en: "Heyang",
            name_tw: "合陽縣",
            yzcode: "715300",
          },
          {
            code: 610525,
            name_cn: "澄城县",
            name_en: "Chengcheng",
            name_tw: "澄城縣",
            yzcode: "715200",
          },
          {
            code: 610526,
            name_cn: "蒲城县",
            name_en: "Pucheng",
            name_tw: "蒲城縣",
            yzcode: "715500",
          },
          {
            code: 610527,
            name_cn: "白水县",
            name_en: "Baishui",
            name_tw: "白水縣",
            yzcode: "715600",
          },
          {
            code: 610528,
            name_cn: "富平县",
            name_en: "Fuping",
            name_tw: "富平縣",
            yzcode: "711700",
          },
          {
            code: 610581,
            name_cn: "韩城市",
            name_en: "Hancheng",
            name_tw: "韓城市",
            yzcode: "715400",
          },
          {
            code: 610582,
            name_cn: "华阴市",
            name_en: "Huayin",
            name_tw: "華陰市",
            yzcode: "714200",
          },
        ],
      },
      {
        code: 610600,
        name_cn: "延安市",
        name_en: "Yan'an",
        name_tw: "延安市",
        children: [
          {
            code: 610602,
            name_cn: "宝塔区",
            name_en: "Baota",
            name_tw: "寶塔區",
            yzcode: "716000",
          },
          {
            code: 610621,
            name_cn: "延长县",
            name_en: "Yanchang",
            name_tw: "延長縣",
            yzcode: "717100",
          },
          {
            code: 610622,
            name_cn: "延川县",
            name_en: "Yanchuan",
            name_tw: "延川縣",
            yzcode: "717200",
          },
          {
            code: 610623,
            name_cn: "子长县",
            name_en: "Zichang",
            name_tw: "子長縣",
            yzcode: "717300",
          },
          {
            code: 610624,
            name_cn: "安塞县",
            name_en: "Ansai",
            name_tw: "安塞縣",
            yzcode: "717400",
          },
          {
            code: 610625,
            name_cn: "志丹县",
            name_en: "Zhidan",
            name_tw: "誌丹縣",
            yzcode: "717500",
          },
          {
            code: 610626,
            name_cn: "吴起县",
            name_en: "Wuqi",
            name_tw: "吳起縣",
            yzcode: "717600",
          },
          {
            code: 610627,
            name_cn: "甘泉县",
            name_en: "Ganquan",
            name_tw: "甘泉縣",
            yzcode: "716100",
          },
          {
            code: 610628,
            name_cn: "富县",
            name_en: "Fuxian",
            name_tw: "富縣",
            yzcode: "727500",
          },
          {
            code: 610629,
            name_cn: "洛川县",
            name_en: "Luochuan",
            name_tw: "洛川縣",
            yzcode: "727400",
          },
          {
            code: 610630,
            name_cn: "宜川县",
            name_en: "Yichuan",
            name_tw: "宜川縣",
            yzcode: "716200",
          },
          {
            code: 610631,
            name_cn: "黄龙县",
            name_en: "Huanglong",
            name_tw: "黃龍縣",
            yzcode: "715700",
          },
          {
            code: 610632,
            name_cn: "黄陵县",
            name_en: "Huangling",
            name_tw: "黃陵縣",
            yzcode: "727300",
          },
        ],
      },
      {
        code: 610700,
        name_cn: "汉中市",
        name_en: "Hanzhong",
        name_tw: "漢中市",
        children: [
          {
            code: 610702,
            name_cn: "汉台区",
            name_en: "Hantai",
            name_tw: "漢台區",
            yzcode: "723000",
          },
          {
            code: 610721,
            name_cn: "南郑县",
            name_en: "Nanzheng",
            name_tw: "南鄭縣",
            yzcode: "723100",
          },
          {
            code: 610722,
            name_cn: "城固县",
            name_en: "Chenggu",
            name_tw: "城固縣",
            yzcode: "723200",
          },
          {
            code: 610723,
            name_cn: "洋县",
            name_en: "Yangxian",
            name_tw: "洋縣",
            yzcode: "723300",
          },
          {
            code: 610724,
            name_cn: "西乡县",
            name_en: "Xixiang",
            name_tw: "西鄉縣",
            yzcode: "723500",
          },
          {
            code: 610725,
            name_cn: "勉县",
            name_en: "Mianxian",
            name_tw: "勉縣",
            yzcode: "724200",
          },
          {
            code: 610726,
            name_cn: "宁强县",
            name_en: "Ningqiang",
            name_tw: "寧強縣",
            yzcode: "724400",
          },
          {
            code: 610727,
            name_cn: "略阳县",
            name_en: "Lueyang",
            name_tw: "略陽縣",
            yzcode: "724300",
          },
          {
            code: 610728,
            name_cn: "镇巴县",
            name_en: "Zhenba",
            name_tw: "鎮巴縣",
            yzcode: "723600",
          },
          {
            code: 610729,
            name_cn: "留坝县",
            name_en: "Liuba",
            name_tw: "留壩縣",
            yzcode: "724100",
          },
          {
            code: 610730,
            name_cn: "佛坪县",
            name_en: "Foping",
            name_tw: "佛坪縣",
            yzcode: "723400",
          },
        ],
      },
      {
        code: 610800,
        name_cn: "榆林市",
        name_en: "Yulin",
        name_tw: "榆林市",
        children: [
          {
            code: 610802,
            name_cn: "榆阳区",
            name_en: "Yuyang",
            name_tw: "榆陽區",
            yzcode: "719000",
          },
          {
            code: 610821,
            name_cn: "神木县",
            name_en: "Shenmu",
            name_tw: "神木縣",
            yzcode: "719300",
          },
          {
            code: 610822,
            name_cn: "府谷县",
            name_en: "Fugu",
            name_tw: "府谷縣",
            yzcode: "719400",
          },
          {
            code: 610823,
            name_cn: "横山县",
            name_en: "Hengshan",
            name_tw: "橫山縣",
            yzcode: "719100",
          },
          {
            code: 610824,
            name_cn: "靖边县",
            name_en: "Jingbian",
            name_tw: "靖邊縣",
            yzcode: "718500",
          },
          {
            code: 610825,
            name_cn: "定边县",
            name_en: "Dingbian",
            name_tw: "定邊縣",
            yzcode: "718600",
          },
          {
            code: 610826,
            name_cn: "绥德县",
            name_en: "Suide",
            name_tw: "綏德縣",
            yzcode: "718000",
          },
          {
            code: 610827,
            name_cn: "米脂县",
            name_en: "Mizhi",
            name_tw: "米脂縣",
            yzcode: "718100",
          },
          {
            code: 610828,
            name_cn: "佳县",
            name_en: "Jiaxian",
            name_tw: "佳縣",
            yzcode: "719200",
          },
          {
            code: 610829,
            name_cn: "吴堡县",
            name_en: "Wubu",
            name_tw: "吳堡縣",
            yzcode: "718200",
          },
          {
            code: 610830,
            name_cn: "清涧县",
            name_en: "Qingjian",
            name_tw: "清澗縣",
            yzcode: "718300",
          },
          {
            code: 610831,
            name_cn: "子洲县",
            name_en: "Zizhou",
            name_tw: "子洲縣",
            yzcode: "718400",
          },
        ],
      },
      {
        code: 610900,
        name_cn: "安康市",
        name_en: "Ankang",
        name_tw: "安康市",
        children: [
          {
            code: 610902,
            name_cn: "汉滨区",
            name_en: "Hanbin",
            name_tw: "漢濱區",
            yzcode: "725000",
          },
          {
            code: 610921,
            name_cn: "汉阴县",
            name_en: "Hanyin",
            name_tw: "漢陰縣",
            yzcode: "725100",
          },
          {
            code: 610922,
            name_cn: "石泉县",
            name_en: "Shiquan",
            name_tw: "石泉縣",
            yzcode: "725200",
          },
          {
            code: 610923,
            name_cn: "宁陕县",
            name_en: "Ningshan",
            name_tw: "寧陜縣",
            yzcode: "711600",
          },
          {
            code: 610924,
            name_cn: "紫阳县",
            name_en: "Ziyang",
            name_tw: "紫陽縣",
            yzcode: "725300",
          },
          {
            code: 610925,
            name_cn: "岚皋县",
            name_en: "Langao",
            name_tw: "嵐臯縣",
            yzcode: "725400",
          },
          {
            code: 610926,
            name_cn: "平利县",
            name_en: "Pingli",
            name_tw: "平利縣",
            yzcode: "725500",
          },
          {
            code: 610927,
            name_cn: "镇坪县",
            name_en: "Zhenping",
            name_tw: "鎮坪縣",
            yzcode: "725600",
          },
          {
            code: 610928,
            name_cn: "旬阳县",
            name_en: "Xunyang",
            name_tw: "旬陽縣",
            yzcode: "725700",
          },
          {
            code: 610929,
            name_cn: "白河县",
            name_en: "Baihe",
            name_tw: "白河縣",
            yzcode: "725800",
          },
        ],
      },
      {
        code: 611000,
        name_cn: "商洛市",
        name_en: "Shangluo",
        name_tw: "商洛市",
        children: [
          {
            code: 611002,
            name_cn: "商州区",
            name_en: "Shangzhou",
            name_tw: "商州區",
            yzcode: "726000",
          },
          {
            code: 611021,
            name_cn: "洛南县",
            name_en: "Luonan",
            name_tw: "洛南縣",
            yzcode: "726100",
          },
          {
            code: 611022,
            name_cn: "丹凤县",
            name_en: "Danfeng",
            name_tw: "丹鳳縣",
            yzcode: "726200",
          },
          {
            code: 611023,
            name_cn: "商南县",
            name_en: "Shangnan",
            name_tw: "商南縣",
            yzcode: "726300",
          },
          {
            code: 611024,
            name_cn: "山阳县",
            name_en: "Shanyang",
            name_tw: "山陽縣",
            yzcode: "726400",
          },
          {
            code: 611025,
            name_cn: "镇安县",
            name_en: "Zhen'an",
            name_tw: "鎮安縣",
            yzcode: "711500",
          },
          {
            code: 611026,
            name_cn: "柞水县",
            name_en: "Zhashui",
            name_tw: "柞水縣",
            yzcode: "711400",
          },
        ],
      },
      {
        code: 611100,
        name_cn: "西咸新区",
        name_en: "Xixian",
        name_tw: "西鹹新區",
        children: [
          {
            code: 611101,
            name_cn: "空港新城",
            name_en: "Konggang",
            name_tw: "空港新城",
            yzcode: "461000",
          },
          {
            code: 611102,
            name_cn: "沣东新城",
            name_en: "Fengdong",
            name_tw: "灃東新城",
            yzcode: "710000",
          },
          {
            code: 611103,
            name_cn: "秦汉新城",
            name_en: "Qinhan",
            name_tw: "秦漢新城",
            yzcode: "712000",
          },
          {
            code: 611104,
            name_cn: "沣西新城",
            name_en: "Fengxi",
            name_tw: "灃西新城",
            yzcode: "710000",
          },
          {
            code: 611105,
            name_cn: "泾河新城",
            name_en: "Jinghe",
            name_tw: "涇河新城",
            yzcode: "713700",
          },
        ],
      },
    ],
  },
  {
    code: 620000,
    name_cn: "甘肃省",
    name_en: "Gansu",
    name_tw: "甘肅省",
    children: [
      {
        code: 620100,
        name_cn: "兰州市",
        name_en: "Lanzhou",
        name_tw: "蘭州市",
        children: [
          {
            code: 620102,
            name_cn: "城关区",
            name_en: "Chengguan",
            name_tw: "城關區",
            yzcode: "730030",
          },
          {
            code: 620103,
            name_cn: "七里河区",
            name_en: "Qilihe",
            name_tw: "七裏河區",
            yzcode: "730050",
          },
          {
            code: 620104,
            name_cn: "西固区",
            name_en: "Xigu",
            name_tw: "西固區",
            yzcode: "730060",
          },
          {
            code: 620105,
            name_cn: "安宁区",
            name_en: "Anning",
            name_tw: "安寧區",
            yzcode: "730070",
          },
          {
            code: 620111,
            name_cn: "红古区",
            name_en: "Honggu",
            name_tw: "紅古區",
            yzcode: "730084",
          },
          {
            code: 620121,
            name_cn: "永登县",
            name_en: "Yongdeng",
            name_tw: "永登縣",
            yzcode: "730300",
          },
          {
            code: 620122,
            name_cn: "皋兰县",
            name_en: "Gaolan",
            name_tw: "臯蘭縣",
            yzcode: "730200",
          },
          {
            code: 620123,
            name_cn: "榆中县",
            name_en: "Yuzhong",
            name_tw: "榆中縣",
            yzcode: "730100",
          },
        ],
      },
      {
        code: 620200,
        name_cn: "嘉峪关市",
        name_en: "Jiayuguan",
        name_tw: "嘉峪關市",
        children: [
          {
            code: 620201,
            name_cn: "雄关区",
            name_en: "Xiongguan",
            name_tw: "雄關區",
            yzcode: "735100",
          },
          {
            code: 620202,
            name_cn: "长城区",
            name_en: "Changcheng",
            name_tw: "長城區",
            yzcode: "735106",
          },
          {
            code: 620203,
            name_cn: "镜铁区",
            name_en: "Jingtie",
            name_tw: "鏡鐵區",
            yzcode: "735100",
          },
        ],
      },
      {
        code: 620300,
        name_cn: "金昌市",
        name_en: "Jinchang",
        name_tw: "金昌市",
        children: [
          {
            code: 620302,
            name_cn: "金川区",
            name_en: "Jinchuan",
            name_tw: "金川區",
            yzcode: "737100",
          },
          {
            code: 620321,
            name_cn: "永昌县",
            name_en: "Yongchang",
            name_tw: "永昌縣",
            yzcode: "737200",
          },
        ],
      },
      {
        code: 620400,
        name_cn: "白银市",
        name_en: "Baiyin",
        name_tw: "白銀市",
        children: [
          {
            code: 620402,
            name_cn: "白银区",
            name_en: "Baiyin",
            name_tw: "白銀區",
            yzcode: "730900",
          },
          {
            code: 620403,
            name_cn: "平川区",
            name_en: "Pingchuan",
            name_tw: "平川區",
            yzcode: "730913",
          },
          {
            code: 620421,
            name_cn: "靖远县",
            name_en: "Jingyuan",
            name_tw: "靖遠縣",
            yzcode: "730600",
          },
          {
            code: 620422,
            name_cn: "会宁县",
            name_en: "Huining",
            name_tw: "會寧縣",
            yzcode: "730700",
          },
          {
            code: 620423,
            name_cn: "景泰县",
            name_en: "Jingtai",
            name_tw: "景泰縣",
            yzcode: "730400",
          },
        ],
      },
      {
        code: 620500,
        name_cn: "天水市",
        name_en: "Tianshui",
        name_tw: "天水市",
        children: [
          {
            code: 620502,
            name_cn: "秦州区",
            name_en: "Qinzhou",
            name_tw: "秦州區",
            yzcode: "741000",
          },
          {
            code: 620503,
            name_cn: "麦积区",
            name_en: "Maiji",
            name_tw: "麥積區",
            yzcode: "741020",
          },
          {
            code: 620521,
            name_cn: "清水县",
            name_en: "Qingshui",
            name_tw: "清水縣",
            yzcode: "741400",
          },
          {
            code: 620522,
            name_cn: "秦安县",
            name_en: "Qin'an",
            name_tw: "秦安縣",
            yzcode: "741600",
          },
          {
            code: 620523,
            name_cn: "甘谷县",
            name_en: "Gangu",
            name_tw: "甘谷縣",
            yzcode: "741200",
          },
          {
            code: 620524,
            name_cn: "武山县",
            name_en: "Wushan",
            name_tw: "武山縣",
            yzcode: "741300",
          },
          {
            code: 620525,
            name_cn: "张家川回族自治县",
            name_en: "Zhangjiachuan",
            name_tw: "張家川回族自治縣",
            yzcode: "741500",
          },
        ],
      },
      {
        code: 620600,
        name_cn: "武威市",
        name_en: "Wuwei",
        name_tw: "武威市",
        children: [
          {
            code: 620602,
            name_cn: "凉州区",
            name_en: "Liangzhou",
            name_tw: "涼州區",
            yzcode: "733000",
          },
          {
            code: 620621,
            name_cn: "民勤县",
            name_en: "Minqin",
            name_tw: "民勤縣",
            yzcode: "733300",
          },
          {
            code: 620622,
            name_cn: "古浪县",
            name_en: "Gulang",
            name_tw: "古浪縣",
            yzcode: "733100",
          },
          {
            code: 620623,
            name_cn: "天祝藏族自治县",
            name_en: "Tianzhu",
            name_tw: "天祝藏族自治縣",
            yzcode: "733200",
          },
        ],
      },
      {
        code: 620700,
        name_cn: "张掖市",
        name_en: "Zhangye",
        name_tw: "張掖市",
        children: [
          {
            code: 620702,
            name_cn: "甘州区",
            name_en: "Ganzhou",
            name_tw: "甘州區",
            yzcode: "734000",
          },
          {
            code: 620721,
            name_cn: "肃南裕固族自治县",
            name_en: "Sunan",
            name_tw: "肅南裕固族自治縣",
            yzcode: "734400",
          },
          {
            code: 620722,
            name_cn: "民乐县",
            name_en: "Minle",
            name_tw: "民樂縣",
            yzcode: "734500",
          },
          {
            code: 620723,
            name_cn: "临泽县",
            name_en: "Linze",
            name_tw: "臨澤縣",
            yzcode: "734200",
          },
          {
            code: 620724,
            name_cn: "高台县",
            name_en: "Gaotai",
            name_tw: "高台縣",
            yzcode: "734300",
          },
          {
            code: 620725,
            name_cn: "山丹县",
            name_en: "Shandan",
            name_tw: "山丹縣",
            yzcode: "734100",
          },
        ],
      },
      {
        code: 620800,
        name_cn: "平凉市",
        name_en: "Pingliang",
        name_tw: "平涼市",
        children: [
          {
            code: 620802,
            name_cn: "崆峒区",
            name_en: "Kongtong",
            name_tw: "崆峒區",
            yzcode: "744000",
          },
          {
            code: 620821,
            name_cn: "泾川县",
            name_en: "Jingchuan",
            name_tw: "涇川縣",
            yzcode: "744300",
          },
          {
            code: 620822,
            name_cn: "灵台县",
            name_en: "Lingtai",
            name_tw: "靈台縣",
            yzcode: "744400",
          },
          {
            code: 620823,
            name_cn: "崇信县",
            name_en: "Chongxin",
            name_tw: "崇信縣",
            yzcode: "744200",
          },
          {
            code: 620824,
            name_cn: "华亭县",
            name_en: "Huating",
            name_tw: "華亭縣",
            yzcode: "744100",
          },
          {
            code: 620825,
            name_cn: "庄浪县",
            name_en: "Zhuanglang",
            name_tw: "莊浪縣",
            yzcode: "744600",
          },
          {
            code: 620826,
            name_cn: "静宁县",
            name_en: "Jingning",
            name_tw: "靜寧縣",
            yzcode: "743400",
          },
        ],
      },
      {
        code: 620900,
        name_cn: "酒泉市",
        name_en: "Jiuquan",
        name_tw: "酒泉市",
        children: [
          {
            code: 620902,
            name_cn: "肃州区",
            name_en: "Suzhou",
            name_tw: "肅州區",
            yzcode: "735000",
          },
          {
            code: 620921,
            name_cn: "金塔县",
            name_en: "Jinta",
            name_tw: "金塔縣",
            yzcode: "735300",
          },
          {
            code: 620922,
            name_cn: "瓜州县",
            name_en: "Guazhou",
            name_tw: "瓜州縣",
            yzcode: "736100",
          },
          {
            code: 620923,
            name_cn: "肃北蒙古族自治县",
            name_en: "Subei",
            name_tw: "肅北蒙古族自治縣",
            yzcode: "736300",
          },
          {
            code: 620924,
            name_cn: "阿克塞哈萨克族自治县",
            name_en: "Akesai",
            name_tw: "阿克塞哈薩克族自治縣",
            yzcode: "736400",
          },
          {
            code: 620981,
            name_cn: "玉门市",
            name_en: "Yumen",
            name_tw: "玉門市",
            yzcode: "735200",
          },
          {
            code: 620982,
            name_cn: "敦煌市",
            name_en: "Dunhuang",
            name_tw: "敦煌市",
            yzcode: "736200",
          },
        ],
      },
      {
        code: 621000,
        name_cn: "庆阳市",
        name_en: "Qingyang",
        name_tw: "慶陽市",
        children: [
          {
            code: 621002,
            name_cn: "西峰区",
            name_en: "Xifeng",
            name_tw: "西峰區",
            yzcode: "745000",
          },
          {
            code: 621021,
            name_cn: "庆城县",
            name_en: "Qingcheng",
            name_tw: "慶城縣",
            yzcode: "745100",
          },
          {
            code: 621022,
            name_cn: "环县",
            name_en: "Huanxian",
            name_tw: "環縣",
            yzcode: "745700",
          },
          {
            code: 621023,
            name_cn: "华池县",
            name_en: "Huachi",
            name_tw: "華池縣",
            yzcode: "745600",
          },
          {
            code: 621024,
            name_cn: "合水县",
            name_en: "Heshui",
            name_tw: "合水縣",
            yzcode: "745400",
          },
          {
            code: 621025,
            name_cn: "正宁县",
            name_en: "Zhengning",
            name_tw: "正寧縣",
            yzcode: "745300",
          },
          {
            code: 621026,
            name_cn: "宁县",
            name_en: "Ningxian",
            name_tw: "寧縣",
            yzcode: "745200",
          },
          {
            code: 621027,
            name_cn: "镇原县",
            name_en: "Zhenyuan",
            name_tw: "鎮原縣",
            yzcode: "744500",
          },
        ],
      },
      {
        code: 621100,
        name_cn: "定西市",
        name_en: "Dingxi",
        name_tw: "定西市",
        children: [
          {
            code: 621102,
            name_cn: "安定区",
            name_en: "Anding",
            name_tw: "安定區",
            yzcode: "743000",
          },
          {
            code: 621121,
            name_cn: "通渭县",
            name_en: "Tongwei",
            name_tw: "通渭縣",
            yzcode: "743300",
          },
          {
            code: 621122,
            name_cn: "陇西县",
            name_en: "Longxi",
            name_tw: "隴西縣",
            yzcode: "748100",
          },
          {
            code: 621123,
            name_cn: "渭源县",
            name_en: "Weiyuan",
            name_tw: "渭源縣",
            yzcode: "748200",
          },
          {
            code: 621124,
            name_cn: "临洮县",
            name_en: "Lintao",
            name_tw: "臨洮縣",
            yzcode: "730500",
          },
          {
            code: 621125,
            name_cn: "漳县",
            name_en: "Zhangxian",
            name_tw: "漳縣",
            yzcode: "748300",
          },
          {
            code: 621126,
            name_cn: "岷县",
            name_en: "Minxian",
            name_tw: "岷縣",
            yzcode: "748400",
          },
        ],
      },
      {
        code: 621200,
        name_cn: "陇南市",
        name_en: "Longnan",
        name_tw: "隴南市",
        children: [
          {
            code: 621202,
            name_cn: "武都区",
            name_en: "Wudu",
            name_tw: "武都區",
            yzcode: "746000",
          },
          {
            code: 621221,
            name_cn: "成县",
            name_en: "Chengxian",
            name_tw: "成縣",
            yzcode: "742500",
          },
          {
            code: 621222,
            name_cn: "文县",
            name_en: "Wenxian",
            name_tw: "文縣",
            yzcode: "746400",
          },
          {
            code: 621223,
            name_cn: "宕昌县",
            name_en: "Dangchang",
            name_tw: "宕昌縣",
            yzcode: "748500",
          },
          {
            code: 621224,
            name_cn: "康县",
            name_en: "Kangxian",
            name_tw: "康縣",
            yzcode: "746500",
          },
          {
            code: 621225,
            name_cn: "西和县",
            name_en: "Xihe",
            name_tw: "西和縣",
            yzcode: "742100",
          },
          {
            code: 621226,
            name_cn: "礼县",
            name_en: "Lixian",
            name_tw: "禮縣",
            yzcode: "742200",
          },
          {
            code: 621227,
            name_cn: "徽县",
            name_en: "Huixian",
            name_tw: "徽縣",
            yzcode: "742300",
          },
          {
            code: 621228,
            name_cn: "两当县",
            name_en: "Liangdang",
            name_tw: "兩當縣",
            yzcode: "742400",
          },
        ],
      },
      {
        code: 622900,
        name_cn: "临夏回族自治州",
        name_en: "Linxia",
        name_tw: "臨夏回族自治州",
        children: [
          {
            code: 622901,
            name_cn: "临夏市",
            name_en: "Linxia",
            name_tw: "臨夏市",
            yzcode: "731100",
          },
          {
            code: 622921,
            name_cn: "临夏县",
            name_en: "Linxia",
            name_tw: "臨夏縣",
            yzcode: "731800",
          },
          {
            code: 622922,
            name_cn: "康乐县",
            name_en: "Kangle",
            name_tw: "康樂縣",
            yzcode: "731500",
          },
          {
            code: 622923,
            name_cn: "永靖县",
            name_en: "Yongjing",
            name_tw: "永靖縣",
            yzcode: "731600",
          },
          {
            code: 622924,
            name_cn: "广河县",
            name_en: "Guanghe",
            name_tw: "廣河縣",
            yzcode: "731300",
          },
          {
            code: 622925,
            name_cn: "和政县",
            name_en: "Hezheng",
            name_tw: "和政縣",
            yzcode: "731200",
          },
          {
            code: 622926,
            name_cn: "东乡族自治县",
            name_en: "Dongxiangzu",
            name_tw: "東鄉族自治縣",
            yzcode: "731400",
          },
          {
            code: 622927,
            name_cn: "积石山保安族东乡族撒拉族自治县",
            name_en: "Jishishan",
            name_tw: "積石山保安族東鄉族撒拉族自治縣",
            yzcode: "731700",
          },
        ],
      },
      {
        code: 623000,
        name_cn: "甘南藏族自治州",
        name_en: "Gannan",
        name_tw: "甘南藏族自治州",
        children: [
          {
            code: 623001,
            name_cn: "合作市",
            name_en: "Hezuo",
            name_tw: "合作市",
            yzcode: "747000",
          },
          {
            code: 623021,
            name_cn: "临潭县",
            name_en: "Lintan",
            name_tw: "臨潭縣",
            yzcode: "747500",
          },
          {
            code: 623022,
            name_cn: "卓尼县",
            name_en: "Zhuoni",
            name_tw: "卓尼縣",
            yzcode: "747600",
          },
          {
            code: 623023,
            name_cn: "舟曲县",
            name_en: "Zhouqu",
            name_tw: "舟曲縣",
            yzcode: "746300",
          },
          {
            code: 623024,
            name_cn: "迭部县",
            name_en: "Diebu",
            name_tw: "叠部縣",
            yzcode: "747400",
          },
          {
            code: 623025,
            name_cn: "玛曲县",
            name_en: "Maqu",
            name_tw: "瑪曲縣",
            yzcode: "747300",
          },
          {
            code: 623026,
            name_cn: "碌曲县",
            name_en: "Luqu",
            name_tw: "碌曲縣",
            yzcode: "747200",
          },
          {
            code: 623027,
            name_cn: "夏河县",
            name_en: "Xiahe",
            name_tw: "夏河縣",
            yzcode: "747100",
          },
        ],
      },
    ],
  },
  {
    code: 630000,
    name_cn: "青海省",
    name_en: "Qinghai",
    name_tw: "青海省",
    children: [
      {
        code: 630100,
        name_cn: "西宁市",
        name_en: "Xining",
        name_tw: "西寧市",
        children: [
          {
            code: 630102,
            name_cn: "城东区",
            name_en: "Chengdong",
            name_tw: "城東區",
            yzcode: "810007",
          },
          {
            code: 630103,
            name_cn: "城中区",
            name_en: "Chengzhong",
            name_tw: "城中區",
            yzcode: "810000",
          },
          {
            code: 630104,
            name_cn: "城西区",
            name_en: "Chengxi",
            name_tw: "城西區",
            yzcode: "810001",
          },
          {
            code: 630105,
            name_cn: "城北区",
            name_en: "Chengbei",
            name_tw: "城北區",
            yzcode: "810003",
          },
          {
            code: 630121,
            name_cn: "大通回族土族自治县",
            name_en: "Datong",
            name_tw: "大通回族土族自治縣",
            yzcode: "810100",
          },
          {
            code: 630122,
            name_cn: "湟中县",
            name_en: "Huangzhong",
            name_tw: "湟中縣",
            yzcode: "811600",
          },
          {
            code: 630123,
            name_cn: "湟源县",
            name_en: "Huangyuan",
            name_tw: "湟源縣",
            yzcode: "812100",
          },
        ],
      },
      {
        code: 630200,
        name_cn: "海东市",
        name_en: "Haidong",
        name_tw: "海東市",
        children: [
          {
            code: 630202,
            name_cn: "乐都区",
            name_en: "Ledu",
            name_tw: "樂都區",
            yzcode: "810700",
          },
          {
            code: 630221,
            name_cn: "平安县",
            name_en: "Ping'an",
            name_tw: "平安縣",
            yzcode: "810600",
          },
          {
            code: 630222,
            name_cn: "民和回族土族自治县",
            name_en: "Minhe",
            name_tw: "民和回族土族自治縣",
            yzcode: "810800",
          },
          {
            code: 630223,
            name_cn: "互助土族自治县",
            name_en: "Huzhu",
            name_tw: "互助土族自治縣",
            yzcode: "810500",
          },
          {
            code: 630224,
            name_cn: "化隆回族自治县",
            name_en: "Hualong",
            name_tw: "化隆回族自治縣",
            yzcode: "810900",
          },
          {
            code: 630225,
            name_cn: "循化撒拉族自治县",
            name_en: "Xunhua",
            name_tw: "循化撒拉族自治縣",
            yzcode: "811100",
          },
        ],
      },
      {
        code: 632200,
        name_cn: "海北藏族自治州",
        name_en: "Haibei",
        name_tw: "海北藏族自治州",
        children: [
          {
            code: 632221,
            name_cn: "门源回族自治县",
            name_en: "Menyuan",
            name_tw: "門源回族自治縣",
            yzcode: "810300",
          },
          {
            code: 632222,
            name_cn: "祁连县",
            name_en: "Qilian",
            name_tw: "祁連縣",
            yzcode: "810400",
          },
          {
            code: 632223,
            name_cn: "海晏县",
            name_en: "Haiyan",
            name_tw: "海晏縣",
            yzcode: "812200",
          },
          {
            code: 632224,
            name_cn: "刚察县",
            name_en: "Gangcha",
            name_tw: "剛察縣",
            yzcode: "812300",
          },
        ],
      },
      {
        code: 632300,
        name_cn: "黄南藏族自治州",
        name_en: "Huangnan",
        name_tw: "黃南藏族自治州",
        children: [
          {
            code: 632321,
            name_cn: "同仁县",
            name_en: "Tongren",
            name_tw: "同仁縣",
            yzcode: "811300",
          },
          {
            code: 632322,
            name_cn: "尖扎县",
            name_en: "Jianzha",
            name_tw: "尖紮縣",
            yzcode: "811200",
          },
          {
            code: 632323,
            name_cn: "泽库县",
            name_en: "Zeku",
            name_tw: "澤庫縣",
            yzcode: "811400",
          },
          {
            code: 632324,
            name_cn: "河南蒙古族自治县",
            name_en: "Henan",
            name_tw: "河南蒙古族自治縣",
            yzcode: "811500",
          },
        ],
      },
      {
        code: 632500,
        name_cn: "海南藏族自治州",
        name_en: "Hainan",
        name_tw: "海南藏族自治州",
        children: [
          {
            code: 632521,
            name_cn: "共和县",
            name_en: "Gonghe",
            name_tw: "共和縣",
            yzcode: "813000",
          },
          {
            code: 632522,
            name_cn: "同德县",
            name_en: "Tongde",
            name_tw: "同德縣",
            yzcode: "813200",
          },
          {
            code: 632523,
            name_cn: "贵德县",
            name_en: "Guide",
            name_tw: "貴德縣",
            yzcode: "811700",
          },
          {
            code: 632524,
            name_cn: "兴海县",
            name_en: "Xinghai",
            name_tw: "興海縣",
            yzcode: "813300",
          },
          {
            code: 632525,
            name_cn: "贵南县",
            name_en: "Guinan",
            name_tw: "貴南縣",
            yzcode: "813100",
          },
        ],
      },
      {
        code: 632600,
        name_cn: "果洛藏族自治州",
        name_en: "Golog",
        name_tw: "果洛藏族自治州",
        children: [
          {
            code: 632621,
            name_cn: "玛沁县",
            name_en: "Maqin",
            name_tw: "瑪沁縣",
            yzcode: "814000",
          },
          {
            code: 632622,
            name_cn: "班玛县",
            name_en: "Banma",
            name_tw: "班瑪縣",
            yzcode: "814300",
          },
          {
            code: 632623,
            name_cn: "甘德县",
            name_en: "Gande",
            name_tw: "甘德縣",
            yzcode: "814100",
          },
          {
            code: 632624,
            name_cn: "达日县",
            name_en: "Dari",
            name_tw: "達日縣",
            yzcode: "814200",
          },
          {
            code: 632625,
            name_cn: "久治县",
            name_en: "Jiuzhi",
            name_tw: "久治縣",
            yzcode: "624700",
          },
          {
            code: 632626,
            name_cn: "玛多县",
            name_en: "Maduo",
            name_tw: "瑪多縣",
            yzcode: "813500",
          },
        ],
      },
      {
        code: 632700,
        name_cn: "玉树藏族自治州",
        name_en: "Yushu",
        name_tw: "玉樹藏族自治州",
        children: [
          {
            code: 632701,
            name_cn: "玉树市",
            name_en: "Yushu",
            name_tw: "玉樹市",
            yzcode: "815000",
          },
          {
            code: 632722,
            name_cn: "杂多县",
            name_en: "Zaduo",
            name_tw: "雜多縣",
            yzcode: "815300",
          },
          {
            code: 632723,
            name_cn: "称多县",
            name_en: "Chenduo",
            name_tw: "稱多縣",
            yzcode: "815100",
          },
          {
            code: 632724,
            name_cn: "治多县",
            name_en: "Zhiduo",
            name_tw: "治多縣",
            yzcode: "815400",
          },
          {
            code: 632725,
            name_cn: "囊谦县",
            name_en: "Nangqian",
            name_tw: "囊謙縣",
            yzcode: "815200",
          },
          {
            code: 632726,
            name_cn: "曲麻莱县",
            name_en: "Qumalai",
            name_tw: "曲麻萊縣",
            yzcode: "815500",
          },
        ],
      },
      {
        code: 632800,
        name_cn: "海西蒙古族藏族自治州",
        name_en: "Haixi",
        name_tw: "海西蒙古族藏族自治州",
        children: [
          {
            code: 632801,
            name_cn: "格尔木市",
            name_en: "Geermu",
            name_tw: "格爾木市",
            yzcode: "816000",
          },
          {
            code: 632802,
            name_cn: "德令哈市",
            name_en: "Delingha",
            name_tw: "德令哈市",
            yzcode: "817000",
          },
          {
            code: 632821,
            name_cn: "乌兰县",
            name_en: "Wulan",
            name_tw: "烏蘭縣",
            yzcode: "817100",
          },
          {
            code: 632822,
            name_cn: "都兰县",
            name_en: "Dulan",
            name_tw: "都蘭縣",
            yzcode: "816100",
          },
          {
            code: 632823,
            name_cn: "天峻县",
            name_en: "Tianjun",
            name_tw: "天峻縣",
            yzcode: "817200",
          },
        ],
      },
    ],
  },
  {
    code: 640000,
    name_cn: "宁夏回族自治区",
    name_en: "Ningxia",
    name_tw: "寧夏回族自治區",
    children: [
      {
        code: 640100,
        name_cn: "银川市",
        name_en: "Yinchuan",
        name_tw: "銀川市",
        children: [
          {
            code: 640104,
            name_cn: "兴庆区",
            name_en: "Xingqing",
            name_tw: "興慶區",
            yzcode: "750001",
          },
          {
            code: 640105,
            name_cn: "西夏区",
            name_en: "Xixia",
            name_tw: "西夏區",
            yzcode: "750021",
          },
          {
            code: 640106,
            name_cn: "金凤区",
            name_en: "Jinfeng",
            name_tw: "金鳳區",
            yzcode: "750011",
          },
          {
            code: 640121,
            name_cn: "永宁县",
            name_en: "Yongning",
            name_tw: "永寧縣",
            yzcode: "750100",
          },
          {
            code: 640122,
            name_cn: "贺兰县",
            name_en: "Helan",
            name_tw: "賀蘭縣",
            yzcode: "750200",
          },
          {
            code: 640181,
            name_cn: "灵武市",
            name_en: "Lingwu",
            name_tw: "靈武市",
            yzcode: "750004",
          },
        ],
      },
      {
        code: 640200,
        name_cn: "石嘴山市",
        name_en: "Shizuishan",
        name_tw: "石嘴山市",
        children: [
          {
            code: 640202,
            name_cn: "大武口区",
            name_en: "Dawukou",
            name_tw: "大武口區",
            yzcode: "753000",
          },
          {
            code: 640205,
            name_cn: "惠农区",
            name_en: "Huinong",
            name_tw: "惠農區",
            yzcode: "753600",
          },
          {
            code: 640221,
            name_cn: "平罗县",
            name_en: "Pingluo",
            name_tw: "平羅縣",
            yzcode: "753400",
          },
        ],
      },
      {
        code: 640300,
        name_cn: "吴忠市",
        name_en: "Wuzhong",
        name_tw: "吳忠市",
        children: [
          {
            code: 640302,
            name_cn: "利通区",
            name_en: "Litong",
            name_tw: "利通區",
            yzcode: "751100",
          },
          {
            code: 640303,
            name_cn: "红寺堡区",
            name_en: "Hongsibao",
            name_tw: "紅寺堡區",
            yzcode: "751900",
          },
          {
            code: 640323,
            name_cn: "盐池县",
            name_en: "Yanchi",
            name_tw: "鹽池縣",
            yzcode: "751500",
          },
          {
            code: 640324,
            name_cn: "同心县",
            name_en: "Tongxin",
            name_tw: "同心縣",
            yzcode: "751300",
          },
          {
            code: 640381,
            name_cn: "青铜峡市",
            name_en: "Qingtongxia",
            name_tw: "青銅峽市",
            yzcode: "751600",
          },
        ],
      },
      {
        code: 640400,
        name_cn: "固原市",
        name_en: "Guyuan",
        name_tw: "固原市",
        children: [
          {
            code: 640402,
            name_cn: "原州区",
            name_en: "Yuanzhou",
            name_tw: "原州區",
            yzcode: "756000",
          },
          {
            code: 640422,
            name_cn: "西吉县",
            name_en: "Xiji",
            name_tw: "西吉縣",
            yzcode: "756200",
          },
          {
            code: 640423,
            name_cn: "隆德县",
            name_en: "Longde",
            name_tw: "隆德縣",
            yzcode: "756300",
          },
          {
            code: 640424,
            name_cn: "泾源县",
            name_en: "Jingyuan",
            name_tw: "涇源縣",
            yzcode: "756400",
          },
          {
            code: 640425,
            name_cn: "彭阳县",
            name_en: "Pengyang",
            name_tw: "彭陽縣",
            yzcode: "756500",
          },
        ],
      },
      {
        code: 640500,
        name_cn: "中卫市",
        name_en: "Zhongwei",
        name_tw: "中衛市",
        children: [
          {
            code: 640502,
            name_cn: "沙坡头区",
            name_en: "Shapotou",
            name_tw: "沙坡頭區",
            yzcode: "755000",
          },
          {
            code: 640521,
            name_cn: "中宁县",
            name_en: "Zhongning",
            name_tw: "中寧縣",
            yzcode: "751200",
          },
          {
            code: 640522,
            name_cn: "海原县",
            name_en: "Haiyuan",
            name_tw: "海原縣",
            yzcode: "751800",
          },
        ],
      },
    ],
  },
  {
    code: 650000,
    name_cn: "新疆维吾尔自治区",
    name_en: "Xinjiang",
    name_tw: "新疆維吾爾自治區",
    children: [
      {
        code: 650100,
        name_cn: "乌鲁木齐市",
        name_en: "Urumqi",
        name_tw: "烏魯木齊市",
        children: [
          {
            code: 650102,
            name_cn: "天山区",
            name_en: "Tianshan",
            name_tw: "天山區",
            yzcode: "830002",
          },
          {
            code: 650103,
            name_cn: "沙依巴克区",
            name_en: "Shayibake",
            name_tw: "沙依巴克區",
            yzcode: "830000",
          },
          {
            code: 650104,
            name_cn: "新市区",
            name_en: "Xinshi",
            name_tw: "新市區",
            yzcode: "830011",
          },
          {
            code: 650105,
            name_cn: "水磨沟区",
            name_en: "Shuimogou",
            name_tw: "水磨溝區",
            yzcode: "830017",
          },
          {
            code: 650106,
            name_cn: "头屯河区",
            name_en: "Toutunhe",
            name_tw: "頭屯河區",
            yzcode: "830022",
          },
          {
            code: 650107,
            name_cn: "达坂城区",
            name_en: "Dabancheng",
            name_tw: "達阪城區",
            yzcode: "830039",
          },
          {
            code: 650109,
            name_cn: "米东区",
            name_en: "Midong",
            name_tw: "米東區",
            yzcode: "830019",
          },
          {
            code: 650121,
            name_cn: "乌鲁木齐县",
            name_en: "Wulumuqi",
            name_tw: "烏魯木齊縣",
            yzcode: "830063",
          },
        ],
      },
      {
        code: 650200,
        name_cn: "克拉玛依市",
        name_en: "Karamay",
        name_tw: "克拉瑪依市",
        children: [
          {
            code: 650202,
            name_cn: "独山子区",
            name_en: "Dushanzi",
            name_tw: "獨山子區",
            yzcode: "834021",
          },
          {
            code: 650203,
            name_cn: "克拉玛依区",
            name_en: "Kelamayi",
            name_tw: "克拉瑪依區",
            yzcode: "834000",
          },
          {
            code: 650204,
            name_cn: "白碱滩区",
            name_en: "Baijiantan",
            name_tw: "白堿灘區",
            yzcode: "834008",
          },
          {
            code: 650205,
            name_cn: "乌尔禾区",
            name_en: "Wuerhe",
            name_tw: "烏爾禾區",
            yzcode: "834012",
          },
        ],
      },
      {
        code: 652100,
        name_cn: "吐鲁番地区",
        name_en: "Turpan",
        name_tw: "吐魯番地區",
        children: [
          {
            code: 652101,
            name_cn: "吐鲁番市",
            name_en: "Tulufan",
            name_tw: "吐魯番市",
            yzcode: "838000",
          },
          {
            code: 652122,
            name_cn: "鄯善县",
            name_en: "Shanshan",
            name_tw: "鄯善縣",
            yzcode: "838200",
          },
          {
            code: 652123,
            name_cn: "托克逊县",
            name_en: "Tuokexun",
            name_tw: "托克遜縣",
            yzcode: "838100",
          },
        ],
      },
      {
        code: 652200,
        name_cn: "哈密地区",
        name_en: "Hami",
        name_tw: "哈密地區",
        children: [
          {
            code: 652201,
            name_cn: "哈密市",
            name_en: "Hami",
            name_tw: "哈密市",
            yzcode: "839000",
          },
          {
            code: 652222,
            name_cn: "巴里坤哈萨克自治县",
            name_en: "Balikun",
            name_tw: "巴裏坤哈薩克自治縣",
            yzcode: "839200",
          },
          {
            code: 652223,
            name_cn: "伊吾县",
            name_en: "Yiwu",
            name_tw: "伊吾縣",
            yzcode: "839300",
          },
        ],
      },
      {
        code: 652300,
        name_cn: "昌吉回族自治州",
        name_en: "Changji",
        name_tw: "昌吉回族自治州",
        children: [
          {
            code: 652301,
            name_cn: "昌吉市",
            name_en: "Changji",
            name_tw: "昌吉市",
            yzcode: "831100",
          },
          {
            code: 652302,
            name_cn: "阜康市",
            name_en: "Fukang",
            name_tw: "阜康市",
            yzcode: "831500",
          },
          {
            code: 652323,
            name_cn: "呼图壁县",
            name_en: "Hutubi",
            name_tw: "呼圖壁縣",
            yzcode: "831200",
          },
          {
            code: 652324,
            name_cn: "玛纳斯县",
            name_en: "Manasi",
            name_tw: "瑪納斯縣",
            yzcode: "832200",
          },
          {
            code: 652325,
            name_cn: "奇台县",
            name_en: "Qitai",
            name_tw: "奇台縣",
            yzcode: "831800",
          },
          {
            code: 652327,
            name_cn: "吉木萨尔县",
            name_en: "Jimusaer",
            name_tw: "吉木薩爾縣",
            yzcode: "831700",
          },
          {
            code: 652328,
            name_cn: "木垒哈萨克自治县",
            name_en: "Mulei",
            name_tw: "木壘哈薩克自治縣",
            yzcode: "831900",
          },
        ],
      },
      {
        code: 652700,
        name_cn: "博尔塔拉蒙古自治州",
        name_en: "Bortala",
        name_tw: "博爾塔拉蒙古自治州",
        children: [
          {
            code: 652701,
            name_cn: "博乐市",
            name_en: "Bole",
            name_tw: "博樂市",
            yzcode: "833400",
          },
          {
            code: 652702,
            name_cn: "阿拉山口市",
            name_en: "Alashankou",
            name_tw: "阿拉山口市",
            yzcode: "833400",
          },
          {
            code: 652722,
            name_cn: "精河县",
            name_en: "Jinghe",
            name_tw: "精河縣",
            yzcode: "833300",
          },
          {
            code: 652723,
            name_cn: "温泉县",
            name_en: "Wenquan",
            name_tw: "溫泉縣",
            yzcode: "833500",
          },
        ],
      },
      {
        code: 652800,
        name_cn: "巴音郭楞蒙古自治州",
        name_en: "Bayingol",
        name_tw: "巴音郭楞蒙古自治州",
        children: [
          {
            code: 652801,
            name_cn: "库尔勒市",
            name_en: "Kuerle",
            name_tw: "庫爾勒市",
            yzcode: "841000",
          },
          {
            code: 652822,
            name_cn: "轮台县",
            name_en: "Luntai",
            name_tw: "輪台縣",
            yzcode: "841600",
          },
          {
            code: 652823,
            name_cn: "尉犁县",
            name_en: "Yuli",
            name_tw: "尉犁縣",
            yzcode: "841500",
          },
          {
            code: 652824,
            name_cn: "若羌县",
            name_en: "Ruoqiang",
            name_tw: "若羌縣",
            yzcode: "841800",
          },
          {
            code: 652825,
            name_cn: "且末县",
            name_en: "Qiemo",
            name_tw: "且末縣",
            yzcode: "841900",
          },
          {
            code: 652826,
            name_cn: "焉耆回族自治县",
            name_en: "Yanqi",
            name_tw: "焉耆回族自治縣",
            yzcode: "841100",
          },
          {
            code: 652827,
            name_cn: "和静县",
            name_en: "Hejing",
            name_tw: "和靜縣",
            yzcode: "841300",
          },
          {
            code: 652828,
            name_cn: "和硕县",
            name_en: "Heshuo",
            name_tw: "和碩縣",
            yzcode: "841200",
          },
          {
            code: 652829,
            name_cn: "博湖县",
            name_en: "Bohu",
            name_tw: "博湖縣",
            yzcode: "841400",
          },
        ],
      },
      {
        code: 652900,
        name_cn: "阿克苏地区",
        name_en: "Aksu",
        name_tw: "阿克蘇地區",
        children: [
          {
            code: 652901,
            name_cn: "阿克苏市",
            name_en: "Akesu",
            name_tw: "阿克蘇市",
            yzcode: "843000",
          },
          {
            code: 652922,
            name_cn: "温宿县",
            name_en: "Wensu",
            name_tw: "溫宿縣",
            yzcode: "843100",
          },
          {
            code: 652923,
            name_cn: "库车县",
            name_en: "Kuche",
            name_tw: "庫車縣",
            yzcode: "842000",
          },
          {
            code: 652924,
            name_cn: "沙雅县",
            name_en: "Shaya",
            name_tw: "沙雅縣",
            yzcode: "842200",
          },
          {
            code: 652925,
            name_cn: "新和县",
            name_en: "Xinhe",
            name_tw: "新和縣",
            yzcode: "842100",
          },
          {
            code: 652926,
            name_cn: "拜城县",
            name_en: "Baicheng",
            name_tw: "拜城縣",
            yzcode: "842300",
          },
          {
            code: 652927,
            name_cn: "乌什县",
            name_en: "Wushi",
            name_tw: "烏什縣",
            yzcode: "843400",
          },
          {
            code: 652928,
            name_cn: "阿瓦提县",
            name_en: "Awati",
            name_tw: "阿瓦提縣",
            yzcode: "843200",
          },
          {
            code: 652929,
            name_cn: "柯坪县",
            name_en: "Keping",
            name_tw: "柯坪縣",
            yzcode: "843600",
          },
        ],
      },
      {
        code: 653000,
        name_cn: "克孜勒苏柯尔克孜自治州",
        name_en: "Kizilsu",
        name_tw: "克孜勒蘇柯爾克孜自治州",
        children: [
          {
            code: 653001,
            name_cn: "阿图什市",
            name_en: "Atushi",
            name_tw: "阿圖什市",
            yzcode: "845350",
          },
          {
            code: 653022,
            name_cn: "阿克陶县",
            name_en: "Aketao",
            name_tw: "阿克陶縣",
            yzcode: "845550",
          },
          {
            code: 653023,
            name_cn: "阿合奇县",
            name_en: "Aheqi",
            name_tw: "阿合奇縣",
            yzcode: "843500",
          },
          {
            code: 653024,
            name_cn: "乌恰县",
            name_en: "Wuqia",
            name_tw: "烏恰縣",
            yzcode: "845450",
          },
        ],
      },
      {
        code: 653100,
        name_cn: "喀什地区",
        name_en: "Kashgar",
        name_tw: "喀什地區",
        children: [
          {
            code: 653101,
            name_cn: "喀什市",
            name_en: "Kashi",
            name_tw: "喀什市",
            yzcode: "844000",
          },
          {
            code: 653121,
            name_cn: "疏附县",
            name_en: "Shufu",
            name_tw: "疏附縣",
            yzcode: "844100",
          },
          {
            code: 653122,
            name_cn: "疏勒县",
            name_en: "Shule",
            name_tw: "疏勒縣",
            yzcode: "844200",
          },
          {
            code: 653123,
            name_cn: "英吉沙县",
            name_en: "Yingjisha",
            name_tw: "英吉沙縣",
            yzcode: "844500",
          },
          {
            code: 653124,
            name_cn: "泽普县",
            name_en: "Zepu",
            name_tw: "澤普縣",
            yzcode: "844800",
          },
          {
            code: 653125,
            name_cn: "莎车县",
            name_en: "Shache",
            name_tw: "莎車縣",
            yzcode: "844700",
          },
          {
            code: 653126,
            name_cn: "叶城县",
            name_en: "Yecheng",
            name_tw: "葉城縣",
            yzcode: "844900",
          },
          {
            code: 653127,
            name_cn: "麦盖提县",
            name_en: "Maigaiti",
            name_tw: "麥蓋提縣",
            yzcode: "844600",
          },
          {
            code: 653128,
            name_cn: "岳普湖县",
            name_en: "Yuepuhu",
            name_tw: "嶽普湖縣",
            yzcode: "844400",
          },
          {
            code: 653129,
            name_cn: "伽师县",
            name_en: "Jiashi",
            name_tw: "伽師縣",
            yzcode: "844300",
          },
          {
            code: 653130,
            name_cn: "巴楚县",
            name_en: "Bachu",
            name_tw: "巴楚縣",
            yzcode: "843800",
          },
          {
            code: 653131,
            name_cn: "塔什库尔干塔吉克自治县",
            name_en: "Tashikuergantajike",
            name_tw: "塔什庫爾幹塔吉克自治縣",
            yzcode: "845250",
          },
        ],
      },
      {
        code: 653200,
        name_cn: "和田地区",
        name_en: "Hotan",
        name_tw: "和田地區",
        children: [
          {
            code: 653201,
            name_cn: "和田市",
            name_en: "Hetianshi",
            name_tw: "和田市",
            yzcode: "848000",
          },
          {
            code: 653221,
            name_cn: "和田县",
            name_en: "Hetianxian",
            name_tw: "和田縣",
            yzcode: "848000",
          },
          {
            code: 653222,
            name_cn: "墨玉县",
            name_en: "Moyu",
            name_tw: "墨玉縣",
            yzcode: "848100",
          },
          {
            code: 653223,
            name_cn: "皮山县",
            name_en: "Pishan",
            name_tw: "皮山縣",
            yzcode: "845150",
          },
          {
            code: 653224,
            name_cn: "洛浦县",
            name_en: "Luopu",
            name_tw: "洛浦縣",
            yzcode: "848200",
          },
          {
            code: 653225,
            name_cn: "策勒县",
            name_en: "Cele",
            name_tw: "策勒縣",
            yzcode: "848300",
          },
          {
            code: 653226,
            name_cn: "于田县",
            name_en: "Yutian",
            name_tw: "於田縣",
            yzcode: "848400",
          },
          {
            code: 653227,
            name_cn: "民丰县",
            name_en: "Minfeng",
            name_tw: "民豐縣",
            yzcode: "848500",
          },
        ],
      },
      {
        code: 654000,
        name_cn: "伊犁哈萨克自治州",
        name_en: "Ili",
        name_tw: "伊犁哈薩克自治州",
        children: [
          {
            code: 654002,
            name_cn: "伊宁市",
            name_en: "Yining",
            name_tw: "伊寧市",
            yzcode: "835000",
          },
          {
            code: 654003,
            name_cn: "奎屯市",
            name_en: "Kuitun",
            name_tw: "奎屯市",
            yzcode: "833200",
          },
          {
            code: 654004,
            name_cn: "霍尔果斯市",
            name_en: "Huoerguosi",
            name_tw: "霍爾果斯市",
            yzcode: "835221",
          },
          {
            code: 654021,
            name_cn: "伊宁县",
            name_en: "Yining",
            name_tw: "伊寧縣",
            yzcode: "835100",
          },
          {
            code: 654022,
            name_cn: "察布查尔锡伯自治县",
            name_en: "Chabuchaerxibo",
            name_tw: "察布查爾錫伯自治縣",
            yzcode: "835300",
          },
          {
            code: 654023,
            name_cn: "霍城县",
            name_en: "Huocheng",
            name_tw: "霍城縣",
            yzcode: "835200",
          },
          {
            code: 654024,
            name_cn: "巩留县",
            name_en: "Gongliu",
            name_tw: "鞏留縣",
            yzcode: "835400",
          },
          {
            code: 654025,
            name_cn: "新源县",
            name_en: "Xinyuan",
            name_tw: "新源縣",
            yzcode: "835800",
          },
          {
            code: 654026,
            name_cn: "昭苏县",
            name_en: "Zhaosu",
            name_tw: "昭蘇縣",
            yzcode: "835600",
          },
          {
            code: 654027,
            name_cn: "特克斯县",
            name_en: "Tekesi",
            name_tw: "特克斯縣",
            yzcode: "835500",
          },
          {
            code: 654028,
            name_cn: "尼勒克县",
            name_en: "Nileke",
            name_tw: "尼勒克縣",
            yzcode: "835700",
          },
        ],
      },
      {
        code: 654200,
        name_cn: "塔城地区",
        name_en: "Qoqek",
        name_tw: "塔城地區",
        children: [
          {
            code: 654201,
            name_cn: "塔城市",
            name_en: "Tacheng",
            name_tw: "塔城市",
            yzcode: "834700",
          },
          {
            code: 654202,
            name_cn: "乌苏市",
            name_en: "Wusu",
            name_tw: "烏蘇市",
            yzcode: "833000",
          },
          {
            code: 654221,
            name_cn: "额敏县",
            name_en: "Emin",
            name_tw: "額敏縣",
            yzcode: "834600",
          },
          {
            code: 654223,
            name_cn: "沙湾县",
            name_en: "Shawan",
            name_tw: "沙灣縣",
            yzcode: "832100",
          },
          {
            code: 654224,
            name_cn: "托里县",
            name_en: "Tuoli",
            name_tw: "托裏縣",
            yzcode: "834500",
          },
          {
            code: 654225,
            name_cn: "裕民县",
            name_en: "Yumin",
            name_tw: "裕民縣",
            yzcode: "834800",
          },
          {
            code: 654226,
            name_cn: "和布克赛尔蒙古自治县",
            name_en: "Hebukesaier",
            name_tw: "和布克賽爾蒙古自治縣",
            yzcode: "834400",
          },
        ],
      },
      {
        code: 654300,
        name_cn: "阿勒泰地区",
        name_en: "Altay",
        name_tw: "阿勒泰地區",
        children: [
          {
            code: 654301,
            name_cn: "阿勒泰市",
            name_en: "Aletai",
            name_tw: "阿勒泰市",
            yzcode: "836500",
          },
          {
            code: 654321,
            name_cn: "布尔津县",
            name_en: "Buerjin",
            name_tw: "布爾津縣",
            yzcode: "836600",
          },
          {
            code: 654322,
            name_cn: "富蕴县",
            name_en: "Fuyun",
            name_tw: "富蘊縣",
            yzcode: "836100",
          },
          {
            code: 654323,
            name_cn: "福海县",
            name_en: "Fuhai",
            name_tw: "福海縣",
            yzcode: "836400",
          },
          {
            code: 654324,
            name_cn: "哈巴河县",
            name_en: "Habahe",
            name_tw: "哈巴河縣",
            yzcode: "836700",
          },
          {
            code: 654325,
            name_cn: "青河县",
            name_en: "Qinghe",
            name_tw: "青河縣",
            yzcode: "836200",
          },
          {
            code: 654326,
            name_cn: "吉木乃县",
            name_en: "Jimunai",
            name_tw: "吉木乃縣",
            yzcode: "836800",
          },
        ],
      },
      {
        code: 659000,
        name_cn: "直辖县级",
        name_en: "",
        name_tw: "直轄縣級",
        children: [
          {
            code: 659001,
            name_cn: "石河子市",
            name_en: "Shihezi",
            name_tw: "石河子市",
            yzcode: "832000",
          },
          {
            code: 659002,
            name_cn: "阿拉尔市",
            name_en: "Aral",
            name_tw: "阿拉爾市",
            yzcode: "843300",
          },
          {
            code: 659003,
            name_cn: "图木舒克市",
            name_en: "Tumxuk",
            name_tw: "圖木舒克市",
            yzcode: "843806",
          },
          {
            code: 659004,
            name_cn: "五家渠市",
            name_en: "Wujiaqu",
            name_tw: "五家渠市",
            yzcode: "831300",
          },
          {
            code: 659005,
            name_cn: "北屯市",
            name_en: "Beitun",
            name_tw: "北屯市",
            yzcode: "836000",
          },
          {
            code: 659006,
            name_cn: "铁门关市",
            name_en: "Tiemenguan",
            name_tw: "鐵門關市",
            yzcode: "836000",
          },
          {
            code: 659007,
            name_cn: "双河市",
            name_en: "Shuanghe",
            name_tw: "雙河市",
            yzcode: "833408",
          },
        ],
      },
    ],
  },
];

export { AREA };
