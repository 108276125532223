<template>
  <div id="add">
    <van-overlay :show="show" :lock-scroll="false" z-index="1000">
      <div class="wrapper" @click.stop="addessShow = false, dropdown = false">
        <div class="block">
          <van-icon color="#666666" class="close" @click="close" style="cursor: pointer;" size="20px" name="cross" />
          <div class="modal-cont">
            <div class="title">{{ title }}</div>
            <div class="form">
              <van-form @submit="onSubmit">
                <van-field class="input" type="text" clearable v-model="data.consignee" :placeholder="$t('mine.add_1')"
                           :rules="[{ required: true, message: $t('mine.add_1') }]" />
                <div class="dropdown box flex-center-start">
                  <div class="area flex" @click.stop="dropdown = !dropdown, addessShow = false">
                    +{{ data.area }}
                  </div>
                  <van-field class="input" v-model="data.mobile" clearable :placeholder="$t('mine.user_14')"
                             :rules="[{ required: true, message: $t('mine.user_14') }]" />
                  <ul class="dropdown-menu" v-show="dropdown" style="overflow-y: auto;max-height: 200px;">
                    <li class="flex-center-between" @click.stop>{{ $t('login.area') }}</li>
                    <li class="flex-center-between" :class="{ colorBtn: item.area == data.area }" v-for="(item, index) in areaList"
                        :key="index" @click="data.area = item.area, dropdown = false">
                      <span v-if="$i18n.locale == 'zh-cn'">{{ item.cn }}</span>
                      <span v-else-if="$i18n.locale == 'zh-tw'">{{ item.tw }}</span>
                      <span v-else>{{ item.en }}</span>
                      <span>+{{ item.area }}</span>
                    </li>
                  </ul>
                </div>
                <div class="dropdown">
                  <van-field class="input" style="padding:10px 16px 10px 0;" v-model="data.district" is-link readonly
                             :label="$t('mine.add_2')" :placeholder="$t('mine.add_3')"
                             @click.stop="addessShow = !addessShow" />
                  <div class="dropdown-menu" v-show="addessShow" @click.stop>
                    <van-cascader v-if="show" v-model="cascaderValue" :options="options"
                                  :placeholder="$t('mine.add_10')" @finish="onFinish" :field-names="fieldNames"
                                  active-color="#2564F6" />
                  </div>
                </div>
                <van-field class="input" type="text" clearable v-model="data.address" :placeholder="$t('mine.add_5')"
                           :rules="[{ required: true, message: $t('mine.add_6') }]" />
                <van-field class="input" type="text" clearable v-model="data.postcode" :placeholder="$t('mine.add_7')"
                           :rules="[{ required: true, message: $t('mine.add_8') }]" />
                <div class="flex-center-end" v-show="type">
                  <div @click="isDefault" class="default">
                    <input type="radio" :checked="data.is_default == 1" />{{ $t('mine.add_4') }}
                  </div>
                </div>
                <van-button color='#2564F6' :disabled="isDisabled(data)" type="primary" native-type="submit"
                            block>{{ $t('common.ok') }}</van-button>
              </van-form>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { AREA } from '../common/pca-code'
import { LOGINAREA } from '../common/LOGINAREA'
import { message } from 'ant-design-vue';
export default {
  name:'AddAddress',
  props: ["show"],
  data() {
    return {
      areaList:Object.freeze(LOGINAREA),
      dropdown: false,
      addessShow: false,
      cascaderValue: "",
      title: '',
      type: 0,
      fieldNames: {
        text: 'name_cn',
        value: 'code',
        children: 'children',
      },
      areaList: [],
      options: [],
      data: {
        consignee: '',
        area: '86',
        mobile: '',
        district: '',
        postcode: '',
        address: '',
        is_default: 0,
        address_id: '',
      }
    }
  },
  created() {
    this.options = AREA
    this.checkLang()
    this.title = this.$t('common.btn_3')
  },
  methods: {
    checkLang() {
      switch (this.$i18n.locale) {
        case 'zh-tw':
          this.fieldNames = { text: 'name_tw', value: 'code', children: 'children', }
          break;
        case 'en':
          this.fieldNames = { text: 'name_en', value: 'code', children: 'children', }
          break;
        default:
          this.fieldNames = { text: 'name_cn', value: 'code', children: 'children', }
          break;
      }
    },
    async onEdit(id) {
      this.title = this.$t('mine.add_9');
      this.type = 1
      const result = await this.$http.post(this.APIURL.MYDATA.DETAILADDRESS, { address_id: id })
      const { address_id, consignee, area, mobile, district, postcode, address, is_default } = result.data.info
      this.data = { address_id, consignee, area, mobile, district, postcode, address, is_default }
    },
    onSubmit() {
      const { address, consignee } = this.data
      const regex = /[\-_\.!~*'()]/;
      if (regex.test(address) || regex.test(consignee)) return message.warning(this.$t('mine.add_tips'));
      if (this.type) {
        this.$http.post(this.APIURL.MYDATA.EDITADDRESS,
          this.data
        ).then(res => {
          if (res.code == 1) {
            message.success(res.msg);
            this.$emit('updata', false);
            this.close()
          } else {
            message.error(res.msg);
          }
        })
      } else {
        this.$http.post(this.APIURL.MYDATA.ADDADDRESS,
          this.data
        ).then(res => {
          if (res.code == 1) {
            message.success(res.msg);
            this.$emit('updata');
            this.close()
          } else {
            message.error(res.msg);
          }
        })
      }
    },
    isDefault() {
      switch (this.data.is_default) {
        case 0:
          this.data.is_default = 1
          break;
        case 1:
          this.data.is_default = 0
          break;
      }
    },
    onFinish({ selectedOptions }) {
      this.addessShow = false;
      this.data.postcode = selectedOptions[2].yzcode
      switch (this.$i18n.locale) {
        case 'zh-tw':
          this.data.district = selectedOptions.map((option) => option.name_tw).join(' ');
          break;
        case 'en':
          this.data.district = selectedOptions.map((option) => option.name_en).join(' ');
          break;
        default:
          this.data.district = selectedOptions.map((option) => option.name_cn).join(' ');
          break;
      }
    },
    close() {
      this.$emit('childFn', false);
      setTimeout(() => {
        Object.assign(this.$data, this.$options.data());
        this.options = AREA;
        this.areaList = Object.freeze(LOGINAREA);
        this.title = this.$t('common.btn_3');
        this.type = 0;
        this.data.address_id = '';
        this.checkLang()
      }, 500)
    },
    isDisabled(obj) {
      for (const key in obj) {
        if (key == 'address_id') {
          continue
        }
        if (!String(obj[key])) {
          return true
        }
      }
      return false
    }
  },
}
</script>
<style lang="scss" scoped>
.dropdown {
  position: relative;

  .dropdown-menu {
    border-radius: 3px;
    border: none;
    width: 100%;
    padding: 0 10px 0 20px;
    box-shadow: 0px 2px 12px 0px rgba(48, 49, 51, 0.1);
    display: block;
    position: absolute;
    left: 0;
    top: 110%;

    li {
      color: #333333;
      font-size: 14px;
      padding: 10px 0;
      cursor: pointer;
      border-bottom: 1px solid #D8D8D8;

      &:first-child {
        &:hover {
          color: #333;
        }
      }

      &:hover {
        color: $color;
      }
    }
  }
}

.default {
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  line-height: 14px;

  input {
    margin-right: 4px;
  }
}

@media(max-width:768px) {
  .dropdown {
    .dropdown-menu {
      li {
        &:hover {
          color: #333333;
        }
      }
    }
  }
}
</style>